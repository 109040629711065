"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCreateFiatWithdraw = void 0;
var _actions = require("../../actions");
var _useCreateWithdraw = require("./useCreateWithdraw");
var useCreateFiatWithdraw = function useCreateFiatWithdraw() {
  return (0, _useCreateWithdraw.useCreateWithdraw)({
    action: _actions.createFiatWithdrawAction,
    resetAction: _actions.resetCreateFiatWithdrawAction,
    statePath: 'lib.transactions.createFiatWithdraw'
  });
};
exports.useCreateFiatWithdraw = useCreateFiatWithdraw;