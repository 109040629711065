"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Route = require("./Route");
Object.keys(_Route).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Route[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Route[key];
    }
  });
});
var _PrivateRoute = require("./PrivateRoute");
Object.keys(_PrivateRoute).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _PrivateRoute[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _PrivateRoute[key];
    }
  });
});
var _PublicRoute = require("./PublicRoute");
Object.keys(_PublicRoute).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _PublicRoute[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _PublicRoute[key];
    }
  });
});