import { TransactionsNoData, Button, mediaQuery } from '@ppay/client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { routes } from '../../router'
import { Icon } from '../../ui'

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Button} {
    max-width: 331px;
    &:first-child {
      margin-right: 10px;
    }
  }

  ${mediaQuery.lessThan('md')`
    flex-direction: column;
    ${Button}:first-child {
      margin: 0 0 10px 0;
    }
  `}

  ${mediaQuery.lessThan('sm')`
    ${Button} {
      max-width: 100%;
    }
  `}
`

export const UserNoData = () => <TransactionsNoData replenishPath={routes.dashboard.replenish} />

export const MerchantNoData = () => {
  const { t } = useTranslation('transactionsTable')

  return (
    <TransactionsNoData
      replenishPath={routes.dashboard.replenish}
      title={t('noTransactions.title')}
      subtitle={t('noTransactions.text')}
      buttons={
        <Buttons>
          <Button
            color="secondary"
            iconPosition="left"
            icon={<Icon name="folder" />}
            onClick={() => window.open(process.env.REACT_APP_DOCS, '_blank')}
          >
            {t('noTransactions.primaryLightButton')}
          </Button>
          <Button
            icon={<Icon name="airplane" />}
            onClick={() => window.open(process.env.REACT_APP_CHAT, '_blank')}
          >
            {t('noTransactions.primaryButton')}
          </Button>
        </Buttons>
      }
    />
  )
}
