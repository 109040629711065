"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AccountCreatedSuccessNotification", {
  enumerable: true,
  get: function get() {
    return _auth.AccountCreatedSuccess;
  }
});
Object.defineProperty(exports, "ConfirmEmailSucccessNotification", {
  enumerable: true,
  get: function get() {
    return _settings.ConfirmEmailSucccess;
  }
});
Object.defineProperty(exports, "ConfirmEmailWarningNotification", {
  enumerable: true,
  get: function get() {
    return _auth.ConfirmEmailWarning;
  }
});
Object.defineProperty(exports, "CreateWithdrawSuccessNotification", {
  enumerable: true,
  get: function get() {
    return _transfers.CreateWithdrawSuccess;
  }
});
Object.defineProperty(exports, "ResendEmailTwoFactorSucccessNotification", {
  enumerable: true,
  get: function get() {
    return _auth.ResendEmailTwoFactorSucccess;
  }
});
Object.defineProperty(exports, "RestorePasswordSucccessNotification", {
  enumerable: true,
  get: function get() {
    return _auth.RestorePasswordSucccess;
  }
});
Object.defineProperty(exports, "SendConfirmEmailSuccessNotification", {
  enumerable: true,
  get: function get() {
    return _settings.SendConfirmEmailSuccess;
  }
});
Object.defineProperty(exports, "SendRestorePasswordCodeSuccessNotification", {
  enumerable: true,
  get: function get() {
    return _auth.SendRestorePasswordCodeSuccess;
  }
});
Object.defineProperty(exports, "SendWithdraw2faKeySuccessNotification", {
  enumerable: true,
  get: function get() {
    return _transfers.SendWithdraw2faKeySuccess;
  }
});
Object.defineProperty(exports, "UpdateEmailSuccessNotification", {
  enumerable: true,
  get: function get() {
    return _settings.UpdateEmailSuccess;
  }
});
Object.defineProperty(exports, "UpdatePasswordSuccessNotification", {
  enumerable: true,
  get: function get() {
    return _settings.UpdatePasswordSuccess;
  }
});
Object.defineProperty(exports, "UpdatePersonalSuccessNotification", {
  enumerable: true,
  get: function get() {
    return _settings.UpdatePersonalSuccess;
  }
});
var _auth = require("./auth");
var _settings = require("./settings");
var _transfers = require("./transfers");