"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  FooterLogo: true,
  FooterTitle: true,
  FooterContainer: true,
  FooterSeparator: true,
  FooterLanguageSelect: true,
  FooterMenuItem: true,
  FooterSocials: true,
  FooterDropdownCollapse: true
};
Object.defineProperty(exports, "FooterContainer", {
  enumerable: true,
  get: function get() {
    return _Container.Container;
  }
});
Object.defineProperty(exports, "FooterDropdownCollapse", {
  enumerable: true,
  get: function get() {
    return _DropdownCollapse.DropdownCollapse;
  }
});
Object.defineProperty(exports, "FooterLanguageSelect", {
  enumerable: true,
  get: function get() {
    return _LanguageSelect.LanguageSelect;
  }
});
Object.defineProperty(exports, "FooterLogo", {
  enumerable: true,
  get: function get() {
    return _Logo.Logo;
  }
});
Object.defineProperty(exports, "FooterMenuItem", {
  enumerable: true,
  get: function get() {
    return _MenuItem.MenuItem;
  }
});
Object.defineProperty(exports, "FooterSeparator", {
  enumerable: true,
  get: function get() {
    return _Separator.Separator;
  }
});
Object.defineProperty(exports, "FooterSocials", {
  enumerable: true,
  get: function get() {
    return _Socials.Socials;
  }
});
Object.defineProperty(exports, "FooterTitle", {
  enumerable: true,
  get: function get() {
    return _Title.Title;
  }
});
var _Footer = require("./Footer");
Object.keys(_Footer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Footer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Footer[key];
    }
  });
});
var _Logo = require("./Logo");
var _Title = require("./Title");
var _Container = require("./Container");
var _Separator = require("./Separator");
var _LanguageSelect = require("./LanguageSelect");
var _MenuItem = require("./MenuItem");
var _Socials = require("./Socials");
var _DropdownCollapse = require("./DropdownCollapse");