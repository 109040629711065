"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Row = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Data = require("./Data");
var _Header = require("./Header");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Row = _styledComponents.default.tr(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  ", " {\n    padding: 20px 10px !important;\n    &:first-child {\n      padding-left: 20px !important;\n    }\n    &:last-child {\n      padding-right: 20px !important;\n    }\n  }\n  ", " {\n    padding: 0 20px !important;\n  }\n  td {\n    border-color: ", " !important;\n  }\n"])), _Data.Data, _Header.Header, function (_ref) {
  var theme = _ref.theme,
    typeColor = _ref.typeColor;
  return typeColor ? theme.colors[typeColor] : '';
});
exports.Row = Row;