import { TestContext } from 'yup'

export const url = {
  name: 'url',
  test: function (this: TestContext, value: unknown, { options, createError }: TestContext) {
    const { withIpAddress } = (options.context as { withIpAddress?: boolean }) ?? {}

    if (!value || typeof value !== 'string') return true

    const isValid = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}' + // domain name
        (withIpAddress ? '|((\\d{1,3}\\.){3}\\d{1,3}))' : ')') + // ip address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ).test(value)

    if (isValid) return true

    return createError({
      message: `${this.path} must be valid`
    })
  }
}
