import React, { ReactNode, DOMAttributes } from 'react'
import styled from 'styled-components'
import { H4, Svg, icons, mediaQuery } from '@ppay/client'

const Title = styled(H4)`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;
  ${mediaQuery.lessThan('sm')`
    margin-bottom: 5px;
  `}
`

export const Back = styled(
  ({ children, ...props }: { children: ReactNode; onClick: DOMAttributes<{}>['onClick'] }) => (
    <Title {...props}>
      <Svg as={icons.longArrow} color="primary" className="me-3" /> {children}
    </Title>
  )
)`
  display: flex;
  align-items: center;
  cursor: pointer;
`
