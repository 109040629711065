import cardValidator from 'card-validator'
import * as yup from 'yup'

export interface CreditCardData {
  number: string
  expiryMonth: string
  expiryYear: string
  cvc: string
}

export const cardValidationSchema = yup.object({
  number: yup
    .string()
    .test(
      'card-number',
      'Invalid credit card number',
      (value) => cardValidator.number(value).isValid
    )
    .required(),
  expiryMonth: yup
    .string()
    .test(
      'expiry-month',
      'Invalid expiry month. Use two digits format (e.g. 02)',
      (value?: string) => value?.length === 2 && cardValidator.expirationMonth(value).isValid
    )
    .required(),
  expiryYear: yup
    .string()
    .test(
      'expiry-year',
      'Invalid expiry year. Use two digits format (e.g. 24)',
      (value?: string) => value?.length === 2 && cardValidator.expirationYear(value).isValid
    )
    .required(),
  cvc: yup
    .string()
    .test('cvv', 'Invalid card cvv', (value) => cardValidator.cvv(value).isValid)
    .required()
})
