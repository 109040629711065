"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NavLink = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _ui = require("../../../ui");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var NavLink = (0, _styledComponents.default)(_ui.RouterLink).attrs(function () {
  return {
    color: 'secondary'
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-size: 18px;\n  &:not(:last-child) {\n    margin-right: 32px;\n  }\n"])));
exports.NavLink = NavLink;