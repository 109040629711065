"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFiatForm = useFiatForm;
var _yup = require("@hookform/resolvers/yup");
var _decimal = _interopRequireDefault(require("decimal.js"));
var _reactHookForm = require("react-hook-form");
var yup = _interopRequireWildcard(require("yup"));
var _react = require("react");
var _useContextSelector = require("use-context-selector");
var _validations = require("../../validations");
var _state = require("../../state");
var _components = require("../../components");
var _useRecalculateAmount = require("./useRecalculateAmount");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var schema = yup.object().shape({
  amount: _validations.enoughBalanceValidation.test({
    name: 'limits',
    test: function test(value, _ref) {
      var options = _ref.options;
      var limits = options.context.limits;
      if (!(value !== null && value !== void 0 && value.toString()) || !limits) return true;
      var decimalValue = new _decimal.default(value);
      if (decimalValue.lt(limits.min)) return this.createError({
        message: "Min amount is ".concat(limits.min)
      });
      return true;
    }
  }).required(),
  card: _validations.cardNumberValidation.required()
}).required();
function useFiatForm(_ref2) {
  var _formProviderProps$fo;
  var withdrawDone = _ref2.withdrawDone;
  var ticker = (0, _useContextSelector.useContextSelector)(_components.SendPageContext, function (state) {
    return state.ticker;
  });
  var convertedTicker = (0, _useContextSelector.useContextSelector)(_components.SendPageContext, function (state) {
    return state.convertedTicker;
  });
  var setInfo = (0, _useContextSelector.useContextSelector)(_components.SendPageContext, function (state) {
    return state.setInfo;
  });
  var _useContext = (0, _react.useContext)(_components.SendBalanceContext),
    useBalances = _useContext.useBalances;
  var _useBalances = useBalances(),
    getBalanceByTicker = _useBalances.getBalanceByTicker;
  var balance = getBalanceByTicker(ticker);
  var price = (0, _state.usePrice)({
    ticker: ticker,
    convertedTicker: convertedTicker
  });
  var _useUser = (0, _state.useUser)(),
    user = _useUser.user;
  var _useContext2 = (0, _react.useContext)(_components.CreateWithdrawContext),
    useWithdrawFee = _useContext2.useWithdrawFee;
  var _ref3 = useWithdrawFee(),
    fee = _ref3.withdrawFeeState.value;
  var _useState = (0, _react.useState)('0'),
    _useState2 = _slicedToArray(_useState, 2),
    convertedAmount = _useState2[0],
    setConvertedAmount = _useState2[1];
  var _useFiatWithdrawLimit = (0, _state.useFiatWithdrawLimits)(),
    fiatWithdrawLimits = _useFiatWithdrawLimit.fiatWithdrawLimits,
    fiatWithdrawLoadingByTicker = _useFiatWithdrawLimit.fiatWithdrawLoadingByTicker,
    getFiatWithdrawLimits = _useFiatWithdrawLimit.getFiatWithdrawLimits;
  var limits = fiatWithdrawLimits[ticker];
  (0, _react.useEffect)(function () {
    if (!limits && fiatWithdrawLoadingByTicker !== ticker) {
      var provider = user === null || user === void 0 ? void 0 : user.fiat.withdrawGatewayProviders[ticker];
      if (!provider) return console.error('Withdraw gateway provider not provided');
      getFiatWithdrawLimits({
        ticker: ticker,
        provider: provider
      });
    }
  }, [ticker]);
  var formProviderProps = (0, _reactHookForm.useForm)({
    mode: 'onTouched',
    resolver: (0, _yup.yupResolver)(schema),
    context: {
      ticker: ticker,
      balance: new _decimal.default(balance.value).sub(fee !== null && fee !== void 0 ? fee : '0').toString(),
      limits: limits
    },
    defaultValues: {
      amount: '',
      card: ''
    }
  });
  var recalculateConvertedAmount = (0, _useRecalculateAmount.useRecalculateAmount)({
    ticker: convertedTicker,
    price: price.value
  });

  // watch inputs values change
  var amount = (0, _reactHookForm.useWatch)({
    name: 'amount',
    control: formProviderProps.control
  });
  var card = (0, _reactHookForm.useWatch)({
    name: 'card',
    control: formProviderProps.control
  });
  (0, _react.useEffect)(function () {
    setConvertedAmount(recalculateConvertedAmount(amount));
  }, [amount]);

  // trigger amount validation when balance changes (because of fee change)
  (0, _react.useEffect)(function () {
    if (amount) {
      formProviderProps.trigger('amount');
    }
  }, [balance]);
  (0, _react.useEffect)(function () {
    if (withdrawDone) {
      formProviderProps.reset({
        amount: '',
        card: ''
      }, {
        keepTouched: false,
        keepErrors: false
      });
    }
  }, [withdrawDone]);
  var isEnoughBalance = ((_formProviderProps$fo = formProviderProps.formState.errors.amount) === null || _formProviderProps$fo === void 0 ? void 0 : _formProviderProps$fo.type) !== _validations.ENOUGH_BALANCE_VALIDATION_NAME && !formProviderProps.formState.isValidating;
  (0, _react.useEffect)(function () {
    setInfo({
      amount: amount,
      isEnoughBalance: isEnoughBalance,
      to: card.replaceAll(' ', ''),
      convertedTickerAmount: convertedAmount
    });
  }, [amount, card, isEnoughBalance, convertedAmount]);
  return {
    formProviderProps: formProviderProps,
    limitsLoading: fiatWithdrawLoadingByTicker === ticker || !limits,
    amount: amount,
    card: card,
    isEnoughBalance: isEnoughBalance,
    isFormValid: formProviderProps.formState.isValid && !formProviderProps.formState.isValidating && formProviderProps.formState.isDirty
  };
}