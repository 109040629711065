"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Title = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _utils = require("../../../../utils");
var _ui = require("../../../ui");
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Title = (0, _styledComponents.default)(_ui.H3)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  margin-bottom: 32px;\n  ", "\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondaryShade;
}, _utils.mediaQuery.lessThan('sm')(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin-bottom: 15px;\n  "]))));
exports.Title = Title;