"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GlobalToastify = exports.Container = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _reactToastify = require("react-toastify");
var _utils = require("../../../utils");
var _templateObject, _templateObject2, _templateObject3;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var GlobalToastify = (0, _styledComponents.createGlobalStyle)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  .Toastify {\n    z-index: 99999;\n    position: absolute;\n  }\n"])));
exports.GlobalToastify = GlobalToastify;
var Container = (0, _styledComponents.default)(_reactToastify.ToastContainer)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 380px;\n  ", "\n\n  .Toastify__toast {\n    font-family: 'Formular', sans-serif !important;\n    background: ", " !important;\n    color: ", " !important;\n    box-shadow: none;\n    padding: 0;\n    margin-bottom: 1rem;\n  }\n  .Toastify__toast-body {\n    margin: 0;\n    padding: 0;\n  }\n  .Toastify__close-button > svg {\n    fill: ", ";\n    position: absolute;\n    top: 10px;\n    right: 10px;\n  }\n  & .Toastify__progress-bar {\n    height: 0;\n  }\n"])), _utils.mediaQuery.lessThan('sm')(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    width: 100%;\n    padding: 0 12px;\n    top: 1rem;\n    left: 0;\n    right: 0;\n    transform: translateX(0);\n  "]))), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondary + '00';
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.tertiary;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.tertiary;
});
exports.Container = Container;