import { SendPage } from '@ppay/client'

import { Content } from './Content'

export const Page = () => {
  return (
    <SendPage>
      <Content />
    </SendPage>
  )
}
