"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePrice = void 0;
var _definitions = require("@ppay/definitions");
var _reactRedux = require("react-redux");
var _react = require("react");
var _useSelector2 = require("../useSelector");
var _actions = require("../../actions");
var usePrice = function usePrice(_ref) {
  var _data$ticker;
  var ticker = _ref.ticker,
    _ref$convertedTicker = _ref.convertedTicker,
    convertedTicker = _ref$convertedTicker === void 0 ? _definitions.Ticker.USD : _ref$convertedTicker;
  var dispatch = (0, _reactRedux.useDispatch)();
  var _useSelector = (0, _useSelector2.useSelector)(function (state) {
      return state.lib.prices.get;
    }),
    data = _useSelector.data,
    loadingByTicker = _useSelector.loadingByTicker;
  var fetchPrice = (0, _react.useCallback)(function (ticker, toTicker) {
    return dispatch((0, _actions.getPriceAction)({
      ticker: ticker,
      toTicker: toTicker
    }));
  }, [dispatch]);
  var price = (_data$ticker = data[ticker]) === null || _data$ticker === void 0 ? void 0 : _data$ticker[convertedTicker];
  (0, _react.useEffect)(function () {
    if (!price) {
      fetchPrice(ticker, convertedTicker);
    }
  }, [price, ticker, convertedTicker]);
  return {
    value: price,
    loading: !price || loadingByTicker === ticker,
    fetchPrice: fetchPrice
  };
};
exports.usePrice = usePrice;