import { useTranslation } from 'react-i18next'

import { FiatBalanceTickerFees } from './TableValue'
import { getFeeValueString } from '../helpers'
import { CardRow, CardWrapper } from '../CardWrapper'

export const Card = ({ ticker, fee }: FiatBalanceTickerFees) => {
  const { t } = useTranslation('paymentMethodsTable')

  return (
    <CardWrapper ticker={ticker}>
      <CardRow>
        <span className="me-2">{t('fee')}</span>
        <span>{getFeeValueString(ticker, fee)}</span>
      </CardRow>
    </CardWrapper>
  )
}
