import { fetch } from '@ppay/client'
import { ManualReplenishOrder } from '../interfaces'

export const get = (orderId: string): Promise<ManualReplenishOrder> => {
  return fetch({
    url: `/merchants/invoices/fiat/${orderId}/order`,
    method: 'GET'
  })
}

export const queue = (orderId: string): Promise<void> => {
  return fetch({
    url: `/merchants/invoices/fiat/${orderId}/order`,
    method: 'POST'
  })
}
