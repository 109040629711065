import { createReducer } from '@reduxjs/toolkit'

import { createMerchantAction, resetCreateMerchantAction } from '../../actions'

type State = {
  loading: boolean
  done: boolean
}

const initialState: State = {
  loading: false,
  done: false
}

export const create = createReducer(initialState, (builder) => {
  builder
    .addCase(createMerchantAction.pending, (state) => {
      state.loading = true
    })
    .addCase(createMerchantAction.rejected, (state) => {
      state.loading = false
    })
    .addCase(createMerchantAction.fulfilled, (state) => {
      state.loading = false
      state.done = true
    })
    .addCase(resetCreateMerchantAction, () => initialState)
})
