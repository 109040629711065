import React, { ReactNode } from 'react'
import { MediumSpan } from '@ppay/client'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled(MediumSpan)`
  font-size: 16px;
`

export const Data = ({
  title,
  value,
  className = ''
}: {
  title: string
  value: ReactNode
  className?: string
}) => {
  if (!value) return null

  return (
    <Wrapper className={className}>
      <Title>{title}:</Title>
      {value}
    </Wrapper>
  )
}
