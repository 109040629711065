"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cryptoAddress = void 0;
var _cryptoConstraints = require("@ppay/crypto-constraints");
var _definitions = require("@ppay/definitions");
var yup = _interopRequireWildcard(require("yup"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var WAValidator = require('multicoin-address-validator');
var cryptoAddress = yup.string().test({
  name: 'cryptoAddress',
  test: function test(value, _ref) {
    var _ctx$validateAddressT;
    var options = _ref.options;
    var ctx = options.context;
    var ticker = (_ctx$validateAddressT = ctx.validateAddressTicker) !== null && _ctx$validateAddressT !== void 0 ? _ctx$validateAddressT : ctx.ticker;
    if (!value) return true;
    if (ticker === _definitions.Ticker.BTC && !process.env.REACT_APP_BTC_ADDRESS_VALIDATION_ENABLED) return true;
    try {
      var chain = _definitions.EVM_BLOCKCHAIN.includes(ctx.blockchain) ? _definitions.Blockchain.ETHEREUM : ctx.blockchain;
      if (WAValidator.validate(value, (0, _cryptoConstraints.getBlockchainCoin)(chain).toLowerCase())) return true;
    } catch (e) {}
    return this.createError({
      message: "Enter valid ".concat(ticker, " address")
    });
  }
});
exports.cryptoAddress = cryptoAddress;