"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  LayoutHeader: true,
  LayoutHeaderSettings: true,
  LayoutHeaderNavLink: true
};
Object.defineProperty(exports, "LayoutHeader", {
  enumerable: true,
  get: function get() {
    return _Header.Header;
  }
});
Object.defineProperty(exports, "LayoutHeaderNavLink", {
  enumerable: true,
  get: function get() {
    return _Header.HeaderNavLink;
  }
});
Object.defineProperty(exports, "LayoutHeaderSettings", {
  enumerable: true,
  get: function get() {
    return _Header.HeaderSettings;
  }
});
var _Header = require("./Header");
var _Layout = require("./Layout");
Object.keys(_Layout).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Layout[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Layout[key];
    }
  });
});