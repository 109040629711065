"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useExchangeFiatInfo = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useExchangeFiatInfo = function useExchangeFiatInfo() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.exchange.getFiatInfo;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    exchangeFiatInfoState: state,
    getExchangeFiatInfo: function getExchangeFiatInfo() {
      return dispatch(_actions.getExchangeFiatInfoAction.apply(void 0, arguments));
    },
    resetExchangeFiatInfo: function resetExchangeFiatInfo() {
      return dispatch((0, _actions.resetGetExchangeFiatInfoAction)());
    }
  };
};
exports.useExchangeFiatInfo = useExchangeFiatInfo;