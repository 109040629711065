import { useDispatch } from 'react-redux'

import {
  getMerchantCryptoExchangeInfoAction,
  resetMerchantGetExchangeCryptoInfoAction
} from '../../actions'
import { useSelector } from '../useSelector'

export const useMerchantExchangeCryptoInfo = (merchantId: string) => {
  const state = useSelector((state) => state.merchants.exchange.getCryptoInfo)
  const dispatch = useDispatch()

  return {
    exchangeCryptoInfoState: state,
    getExchangeCryptoInfo: (params: Parameters<typeof getMerchantCryptoExchangeInfoAction>[0]) =>
      dispatch(getMerchantCryptoExchangeInfoAction({ ...params, merchantId })),
    resetExchangeCryptoInfo: () => dispatch(resetMerchantGetExchangeCryptoInfoAction())
  }
}
