import { H4, Small as SmallUI, Button, RouterLink, breakpoints } from '@ppay/client'
import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { routes } from '../../router'
import { SettingsTabs } from '../settings'
import { Icon } from '../../ui'

const Small = styled(SmallUI)`
  color: ${({ theme }) => theme.colors.tertiary};
`

export const Without2fa = () => {
  const { t } = useTranslation('merchant')
  const isSm = useMediaQuery({ maxWidth: breakpoints.sm })
  const history = useHistory()

  return (
    <div className="d-flex flex-column align-items-center">
      <H4 className="mb-2 text-center">{t('without2fa.title')}</H4>
      <Small className="mb-3 mb-md-4">
        <Trans i18nKey="merchant:without2fa.subtitle">
          <RouterLink
            to={{
              pathname: routes.dashboard.settings,
              state: { tab: SettingsTabs.SECURITY }
            }}
          />
        </Trans>
      </Small>
      <Button
        wide={isSm}
        type="button"
        color="secondary"
        icon={<Icon name="arrowRight" />}
        onClick={() => history.push(routes.dashboard.settings, { tab: SettingsTabs.SECURITY })}
      >
        {t('without2fa.setup')}
      </Button>
    </div>
  )
}
