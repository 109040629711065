import { useSelector } from '../../useSelector'
import { useDispatch } from '../../useDispatch'

import { expireInvoiceCryptoAction, getInvoiceCryptoAction } from '../../../actions'

export const useCrypto = () => {
  const getState = useSelector((state) => state.invoice.crypto.get)
  const dispatch = useDispatch()

  return {
    invoice: getState.data,
    notExist: getState.notExist,
    fetchInvoice: (id: string) => dispatch(getInvoiceCryptoAction(id)),
    expireInvoice: () => dispatch(expireInvoiceCryptoAction())
  }
}
