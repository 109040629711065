"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSendEmailConfirm = exports.useConfirmEmail = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useConfirmEmail = function useConfirmEmail() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.email.confirm;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    done: state.done,
    loading: state.loading,
    confirmEmail: function confirmEmail(key) {
      return dispatch((0, _actions.confirmEmailAction)({
        key: key
      }));
    }
  };
};
exports.useConfirmEmail = useConfirmEmail;
var useSendEmailConfirm = function useSendEmailConfirm() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.email.sendConfirm;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    sendConfirmEmailState: {
      remainingTime: state.remainingTime,
      loading: state.loading
    },
    sendConfirmEmail: function sendConfirmEmail() {
      return dispatch((0, _actions.sendConfirmEmailAction)());
    }
  };
};
exports.useSendEmailConfirm = useSendEmailConfirm;