"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  Fiat: true,
  FiatSuccessModal: true,
  FiatFailModal: true
};
Object.defineProperty(exports, "Fiat", {
  enumerable: true,
  get: function get() {
    return _Component.Component;
  }
});
Object.defineProperty(exports, "FiatFailModal", {
  enumerable: true,
  get: function get() {
    return _modals.FailModal;
  }
});
Object.defineProperty(exports, "FiatSuccessModal", {
  enumerable: true,
  get: function get() {
    return _modals.SuccessModal;
  }
});
var _Component = require("./Component");
var _transactionInfo = require("./transaction-info");
Object.keys(_transactionInfo).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _transactionInfo[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _transactionInfo[key];
    }
  });
});
var _modals = require("./modals");