import { InvoiceApi } from '../../../../../api'

export const getStatus = async (
  invoiceId: string,
  externalId: string
): Promise<{ acsUrl?: string; status: string }> => {
  const { status, acsUrl } = await InvoiceApi.fiatInvoiceGetH2HStatus(invoiceId, externalId)
  if (status === 'wait' && acsUrl) return { acsUrl, status }
  if (status === 'fail' || status === 'expired') return { status }
  await new Promise((resolve) => setTimeout(resolve, 2000))
  return getStatus(invoiceId, externalId)
}
