"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useExchangeAvailablePairs = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useExchangeAvailablePairs = function useExchangeAvailablePairs() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.exchange.getAvailablePairs;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    exchangeAvailablePairsState: state,
    getExchangeAvailablePairs: function getExchangeAvailablePairs() {
      return dispatch((0, _actions.getExchangeAvailablePairsAction)());
    }
  };
};
exports.useExchangeAvailablePairs = useExchangeAvailablePairs;