import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button, H4, Small, Svg, useTranslation, A } from '@ppay/client'
import { Ticker } from '@ppay/definitions'

import { useKYCFormUrl } from '../../../../state'
import { Icon } from '../../../ui'

import { ReactComponent as failed } from './failed.svg'
import { Wrapper } from './Wrapper'
import { FiatInvoice } from '../../../../interfaces'
import { Trans } from 'react-i18next'

const Text = styled(Small)`
  color: ${({ theme }) => theme.colors.tertiaryShade};
`

const StyledSvg = styled(Svg)`
  margin-bottom: 10px;
`

export const Failed = ({
  invoiceRequirements,
  ticker
}: {
  invoiceRequirements: FiatInvoice['requirements']
  ticker: Ticker
}) => {
  const { t } = useTranslation('kyc')
  const { url, loading, fetchUrl } = useKYCFormUrl()

  const supportUrl = process.env.REACT_APP_CHAT!

  useEffect(() => {
    fetchUrl({ requirementsId: invoiceRequirements.id, redirectUrl: window.location.href, ticker })
  }, [])

  return (
    <>
      <Wrapper>
        <StyledSvg as={failed} />
        <H4> {t('failed.title')}</H4>
        <Text>
          <Trans i18nKey={'failed.description'} t={t}>
            <A href={supportUrl} />
          </Trans>
        </Text>
      </Wrapper>
      <Button
        loading={loading}
        wide
        icon={<Icon name="arrowRight" />}
        onClick={() => {
          window.open(url)
        }}
      >
        {t('tryAgain')}
      </Button>
    </>
  )
}
