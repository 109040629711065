"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auth = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _login = require("./login");
var _twoFactor = require("./twoFactor");
var _signUp = require("./signUp");
var _restorePassword = require("./restorePassword");
var _oAuth = require("./oAuth");
var _email = require("./email");
var _emailAuth = require("./emailAuth");
var auth = (0, _toolkit.combineReducers)({
  login: _login.login,
  twoFactor: _twoFactor.twoFactor,
  signUp: _signUp.signUp,
  oAuth: _oAuth.oAuth,
  restorePassword: _restorePassword.restorePassword,
  email: _email.email,
  emailAuth: _emailAuth.emailAuth
});
exports.auth = auth;