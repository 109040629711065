import { InvoiceApi } from '../../../../api'

import { updateInvoiceCryptoAction } from '../../../actions'
import { useDispatch } from '../../useDispatch'
import { useSelector } from '../../useSelector'

export const useUpdate = () => {
  const getState = useSelector((state) => state.invoice.crypto.update)
  const dispatch = useDispatch()

  return {
    loading: getState.loading,
    updateInvoice: (params: Parameters<typeof InvoiceApi.updateCrypto>[0]) =>
      dispatch(updateInvoiceCryptoAction(params))
  }
}
