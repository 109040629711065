import React from 'react'
import { DashboardSettingsProfile } from '@ppay/client'

import { UpdatePersonalForm } from './UpdatePersonalForm'

export const Component = ({ showContactSupportAlert }: { showContactSupportAlert: boolean }) => {
  return (
    <DashboardSettingsProfile showContactSupportAlert={showContactSupportAlert}>
      <UpdatePersonalForm />
    </DashboardSettingsProfile>
  )
}
