import { FC } from 'react'
import { CryptoInvoiceStatus } from '@ppay/definitions'
import { CryptoInvoice } from '../../../interfaces'
import { Continue } from './Continue'
import { Pay } from './Pay'
import { TimerExpired } from './TimerExpired'
import { CardSuccess } from '../card'

export interface InvoiceProps {
  invoice: CryptoInvoice
}

const invoiceComponent: Record<CryptoInvoiceStatus, FC<InvoiceProps>> = {
  [CryptoInvoiceStatus.CREATED]: Continue,
  [CryptoInvoiceStatus.REGISTERED]: Pay,
  [CryptoInvoiceStatus.PENDING]: Pay,
  [CryptoInvoiceStatus.PAID]: CardSuccess,
  [CryptoInvoiceStatus.EXPIRED]: TimerExpired
}

export const Component = ({ invoice }: InvoiceProps) => {
  const status =
    invoice?.clientExpiredAt && invoice?.clientExpiredAt <= new Date().getTime()
      ? CryptoInvoiceStatus.EXPIRED
      : invoice.status

  const InvoiceComponent = invoiceComponent[status]

  return <InvoiceComponent invoice={invoice} />
}
