import { FiatBalanceTicker } from '@ppay/definitions'

import { getFeeValueString } from '../helpers'
import { FeeValue } from '../../../../../../interfaces/merchant/Fees'

export interface FiatBalanceTickerFees {
  ticker: FiatBalanceTicker
  fee: FeeValue
}

export const TableValue = ({ ticker, fee }: FiatBalanceTickerFees) => {
  return <span>{getFeeValueString(ticker, fee)}</span>
}
