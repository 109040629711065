import { Ticker } from '@ppay/definitions'
import {
  Table as TableLib,
  TableBody,
  TableData as TableDataLib,
  TableHead as TableHeadLib,
  TableHeader,
  TableRow,
  H4,
  H5,
  TickerNumberFormat,
  mediaQuery
} from '@ppay/client'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Merchant } from '../../../../interfaces'
import { Website } from './Website'
import { Actions } from './Actions'
import { MerchantStatus } from '../../merchant'

const TableData = styled(TableDataLib)`
  width: 25%;
`

const TableHead = styled(TableHeadLib)`
  ${mediaQuery.lessThan('lg')`
    display: none;
`}
`

export const Table = ({ merchants }: { merchants: Merchant[] }) => {
  const { t } = useTranslation(['merchant', 'libSettings'])

  return (
    <>
      <TableLib>
        <TableHead>
          <TableRow>
            <TableHeader>{t('table.name')}</TableHeader>
            <TableHeader>{t('table.balance')}</TableHeader>
            <TableHeader>{t('table.website')}</TableHeader>
            <TableHeader>{t('table.actions')}</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {merchants.map((m) => (
            <TableRow key={m.id}>
              <TableData>
                <H5 className="mb-0">{m.name}</H5>
                <MerchantStatus verified={m.verified} />
              </TableData>
              <TableData>
                <H4 className="mb-0">
                  $<TickerNumberFormat ticker={Ticker.USD} value={m.totalBalance} />
                </H4>
              </TableData>
              <TableData>
                <Website value={m.domain} />
              </TableData>
              <TableData>
                <Actions merchantId={m.id} />
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </TableLib>
    </>
  )
}
