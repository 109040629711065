"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useForm = useForm;
var _yup = require("@hookform/resolvers/yup");
var _react = require("react");
var _useContextSelector = require("use-context-selector");
var _reactHookForm = require("react-hook-form");
var yup = _interopRequireWildcard(require("yup"));
var _decimal = _interopRequireDefault(require("decimal.js"));
var _components = require("../../components");
var _validations = require("../../validations");
var _useBlockchainByTicker = require("../useBlockchainByTicker");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var schema = yup.object().shape({
  address: _validations.cryptoAddressValidation.required(),
  amount: _validations.enoughBalanceValidation.required()
}).required();
function useForm(_ref) {
  var _value, _formProviderProps$fo;
  var withdrawDone = _ref.withdrawDone;
  var setInfo = (0, _useContextSelector.useContextSelector)(_components.SendPageContext, function (_ref2) {
    var setInfo = _ref2.setInfo;
    return setInfo;
  });
  var ticker = (0, _useContextSelector.useContextSelector)(_components.SendPageContext, function (_ref3) {
    var ticker = _ref3.ticker;
    return ticker;
  });
  var _useContext = (0, _react.useContext)(_components.SendBalanceContext),
    useBalances = _useContext.useBalances,
    balanceName = _useContext.balanceName;
  var _useBalances = useBalances(),
    getBalanceByTicker = _useBalances.getBalanceByTicker;
  var balance = getBalanceByTicker(ticker);
  var _useBlockchainByTicke = (0, _useBlockchainByTicker.useBlockchainByTicker)(ticker),
    blockchain = _useBlockchainByTicke.blockchain,
    blockchains = _useBlockchainByTicke.blockchains,
    setBlockchain = _useBlockchainByTicke.setBlockchain;
  var _useContext2 = (0, _react.useContext)(_components.CreateWithdrawContext),
    useWithdrawFee = _useContext2.useWithdrawFee;
  var _ref4 = useWithdrawFee(),
    fee = _ref4.withdrawFeeState.value;
  var formProviderProps = (0, _reactHookForm.useForm)({
    mode: 'onTouched',
    resolver: (0, _yup.yupResolver)(schema),
    context: {
      ticker: ticker,
      blockchain: blockchain,
      balance: new _decimal.default((_value = balance.value) !== null && _value !== void 0 ? _value : '0').sub(fee !== null && fee !== void 0 ? fee : '0').toString()
    },
    defaultValues: {
      amount: '',
      convertedAmount: '',
      address: ''
    }
  });
  var resetForm = function resetForm() {
    formProviderProps.reset({
      amount: '',
      convertedAmount: '',
      address: ''
    }, {
      keepTouched: false,
      keepIsSubmitted: false,
      keepErrors: false,
      keepDirty: false
    });
  };

  // watch inputs values change
  var amount = (0, _reactHookForm.useWatch)({
    name: 'amount',
    control: formProviderProps.control
  });
  var convertedAmount = (0, _reactHookForm.useWatch)({
    name: 'convertedAmount',
    control: formProviderProps.control
  });
  var address = (0, _reactHookForm.useWatch)({
    name: 'address',
    control: formProviderProps.control
  });

  // trigger amount validation when balance changes (because of fee change)
  (0, _react.useEffect)(function () {
    if (amount) {
      formProviderProps.trigger('amount');
    }
  }, [fee, balance]);

  // trigger address validation when ticker changes
  (0, _react.useEffect)(function () {
    if (address) formProviderProps.trigger('address');
  }, [ticker, blockchain]);
  (0, _react.useEffect)(function () {
    if (withdrawDone) resetForm();
  }, [withdrawDone]);
  (0, _react.useEffect)(function () {
    resetForm();
  }, [balanceName]);
  var isEnoughBalance = ((_formProviderProps$fo = formProviderProps.formState.errors.amount) === null || _formProviderProps$fo === void 0 ? void 0 : _formProviderProps$fo.type) !== _validations.ENOUGH_BALANCE_VALIDATION_NAME && !formProviderProps.formState.isValidating;
  (0, _react.useEffect)(function () {
    setInfo({
      amount: amount,
      convertedTickerAmount: convertedAmount,
      isEnoughBalance: isEnoughBalance,
      to: address
    });
  }, [amount, address, convertedAmount, isEnoughBalance]);
  return {
    formProviderProps: formProviderProps,
    isFormValid: formProviderProps.formState.isValid && !formProviderProps.formState.isValidating && formProviderProps.formState.isDirty,
    blockchain: blockchain,
    blockchains: blockchains,
    setBlockchain: setBlockchain,
    amount: amount,
    convertedAmount: convertedAmount
  };
}