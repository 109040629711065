"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NavItem = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactstrap = require("reactstrap");
var _utils = require("../../../utils");
var _fonts = require("../../ui/fonts");
var _Link = require("../../ui/links/Link");
var _templateObject, _templateObject2, _templateObject3;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var NavItem = (0, _styledComponents.default)(_reactstrap.NavItem)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  color: ", ";\n  ", " {\n    margin-bottom: 0;\n    cursor: pointer;\n  }\n  ", "\n  ", "\n  ", " {\n    color: ", ";\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondary;
}, _fonts.H5, _utils.mediaQuery.lessThan('md')(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 20px 0;\n  "]))), _utils.mediaQuery.greaterThan('md')(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 0 0 0 35px;\n  "]))), _Link.Link, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.secondary;
});
exports.NavItem = NavItem;