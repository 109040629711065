"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUserRequirements = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useUserRequirements = function useUserRequirements() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.user.requirements;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    fetchRequirements: function fetchRequirements() {
      return dispatch((0, _actions.fetchUserRequirementsAction)());
    },
    loading: state.loading,
    requirements: state.data
  };
};
exports.useUserRequirements = useUserRequirements;