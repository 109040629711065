import { useTranslation } from '@ppay/client'
import { Ticker } from '@ppay/definitions'
import { useDispatch } from 'react-redux'
import { getKYCFormUrlAction } from '../../actions'

import { useSelector } from '../useSelector'

export const useKYCFormUrl = () => {
  const state = useSelector((state) => state.kyc)
  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  return {
    url: state.data ? state.data + `?lang=${langMapper(i18n.language)}` : undefined,
    loading: state.loading,
    fetchUrl: (data: { redirectUrl?: string; ticker: Ticker; requirementsId: string }) =>
      dispatch(getKYCFormUrlAction(data))
  }
}

function langMapper(lang?: string): string {
  switch (lang) {
    case 'ua':
      return 'uk'
    case 'ru':
      return 'ru'
    default:
      return 'en'
  }
}
