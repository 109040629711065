"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Title = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _mediaQuery = require("../../../utils/mediaQuery");
var _fonts = require("../../ui/fonts");
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Title = (0, _styledComponents.default)(_fonts.H5)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: fit-content;\n  color: ", ";\n  margin-bottom: 20px;\n  ", "\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondary;
}, _mediaQuery.mediaQuery.greaterThan('lg')(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    white-space: nowrap;\n  "]))));
exports.Title = Title;