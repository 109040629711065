import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TabsNav as TabsNavUI, TabsNavLink, TabsNavItem, mediaQuery } from '@ppay/client'

export const SettingsTabs = {
  PROFILE: 'profile',
  SECURITY: 'security'
}

const TabsNavLib = styled(TabsNavUI)`
  ${mediaQuery.lessThan('md')`
    margin-bottom: 0;
    border-radius: 8px 8px 0 0;
  `}
  ${mediaQuery.greaterThan('md')`
    max-width: 502px;
  `}
`

export const TabsNav = ({
  activeTab,
  onTabChange
}: {
  activeTab: string
  onTabChange: (at: string) => void
}) => {
  const { t } = useTranslation('settings')

  return (
    <TabsNavLib>
      <TabsNavItem>
        <TabsNavLink
          onClick={() => onTabChange(SettingsTabs.PROFILE)}
          active={activeTab === SettingsTabs.PROFILE}
        >
          {t('profile')}
        </TabsNavLink>
      </TabsNavItem>
      <TabsNavItem>
        <TabsNavLink
          onClick={() => onTabChange(SettingsTabs.SECURITY)}
          active={activeTab === SettingsTabs.SECURITY}
        >
          {t('security')}
        </TabsNavLink>
      </TabsNavItem>
    </TabsNavLib>
  )
}
