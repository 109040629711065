"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _SelectInput = require("./SelectInput");
Object.keys(_SelectInput).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _SelectInput[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _SelectInput[key];
    }
  });
});
var _MultiSelectInput = require("./MultiSelectInput");
Object.keys(_MultiSelectInput).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _MultiSelectInput[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _MultiSelectInput[key];
    }
  });
});