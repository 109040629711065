import { ApiError, fetch } from '@ppay/client'
import { Blockchain, CryptoTicker, Ticker } from '@ppay/definitions'

import { FiatInvoice, CryptoInvoice, CardInfo } from '../interfaces'
import { InvoiceNotExistError } from './errors'

export const getFiat = async (id: string): Promise<FiatInvoice> => {
  try {
    return await fetch({
      url: `/merchants/invoices/fiat/${id}`
    })
  } catch (e) {
    if (e instanceof ApiError && e.code === 404) {
      throw new InvoiceNotExistError()
    }
    throw e
  }
}

export const updateFiat = async ({
  email,
  amount,
  invoiceId
}: {
  email: string
  amount?: {
    value: string
    ticker: Ticker
  }
  invoiceId: string
}): Promise<FiatInvoice> =>
  await fetch({
    url: `/merchants/invoices/fiat/${invoiceId}`,
    data: {
      email,
      amount
    },
    method: 'PUT'
  })

export const failFiat = async ({
  code,
  message,
  invoiceId
}: {
  code: number
  message: string
  invoiceId: string
}) =>
  await fetch({
    url: `/merchants/invoices/fiat/${invoiceId}/failed`,
    data: {
      code,
      message
    },
    method: 'PUT'
  })

export const getCrypto = async (id: string): Promise<CryptoInvoice> => {
  try {
    return await fetch({
      url: `/merchants/invoices/crypto/${id}`,
      'axios-retry': {
        retries: 3,
        retryCondition: (retry) => retry.request.status === 404,
        retryDelay: (retryCount) => retryCount * 1000
      }
    })
  } catch (e) {
    if (e instanceof ApiError && e.code === 404) {
      throw new InvoiceNotExistError()
    }
    throw e
  }
}

export const updateCrypto = async ({
  email,
  amount,
  invoiceId
}: {
  email: string
  amount?: {
    value: string
    ticker: CryptoTicker
    blockchain: Blockchain
  }
  invoiceId: string
}): Promise<CryptoInvoice> =>
  await fetch({
    url: `/merchants/invoices/crypto/${invoiceId}`,
    data: {
      email,
      amount
    },
    method: 'PUT'
  })

export const payCrypto = async (): Promise<CryptoInvoice> =>
  await fetch({
    url: `/merchants/invoices/crypto/pay`
  })

export const fiatInvoiceCreateH2H = (invoiceId: string, cardInfo: CardInfo): Promise<string> => {
  return fetch({
    url: `/merchants/invoices/fiat/${invoiceId}/h2h`,
    data: cardInfo,
    method: 'POST'
  })
}

export const fiatInvoiceGetH2HStatus = (
  invoiceId: string,
  h2hExternalId: string
): Promise<{ acsUrl?: string; status: string }> => {
  return fetch({
    url: `/merchants/invoices/fiat/${invoiceId}/h2h/status/${h2hExternalId}`,
    method: 'GET'
  })
}

export const reserveCardFiat = (invoiceId: string): Promise<FiatInvoice> => {
  return fetch({
    url: `/merchants/invoices/fiat/${invoiceId}/card`,
    method: 'GET'
  })
}

export const createOrderFiat = (invoiceId: string): Promise<void> => {
  return fetch({
    url: `/merchants/invoices/fiat/${invoiceId}/order`,
    method: 'POST'
  })
}
