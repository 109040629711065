"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEmail2fa = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useEmail2fa = function useEmail2fa() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.user.email2fa;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    loading: state.enable.loading || state.disable.loading,
    enableEmail2fa: function enableEmail2fa() {
      return dispatch((0, _actions.enableEmail2faAction)());
    },
    disableEmail2fa: function disableEmail2fa() {
      return dispatch((0, _actions.disableEmail2faAction)());
    }
  };
};
exports.useEmail2fa = useEmail2fa;