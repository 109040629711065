"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePage = void 0;
var _definitions = require("@ppay/definitions");
var _decimal = _interopRequireDefault(require("decimal.js"));
var _react = require("react");
var _reactI18next = require("react-i18next");
var _reactRouterDom = require("react-router-dom");
var _lodash = _interopRequireDefault(require("lodash.uniq"));
var _state = require("../../state");
var _useForm2 = require("./useForm");
var _useExchange2 = require("./useExchange");
var _useBlockchainByTicker = require("../useBlockchainByTicker");
var _validations = require("../../validations");
var _useFiatForm = require("./useFiatForm");
var _useFiatExchange = require("./useFiatExchange");
var _useCryptoInfo = require("./useCryptoInfo");
var _useFiatInfo = require("./useFiatInfo");
var _usePolling = require("../usePolling");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var TIMEOUT = 10000;
var usePage = function usePage(params) {
  var _ref, _balances$find, _params$useFiatExchan, _params$useExchange, _exchangeAvailablePai2, _tickerPrice$value, _toTickerPrice$value, _tickerPrice$value2, _formProviderProps$fo, _formProviderProps$fo2, _exchangeInfo$data$wi, _exchangeInfo$data, _exchangeInfo$data$ex, _exchangeInfo$data2, _exchangeInfo$data$pr, _exchangeInfo$data3, _formProviderProps$fo4, _formProviderProps$fo5, _ref2, _ref3, _exchangePair, _exchangeInfo$data4, _exchangeInfo$data5;
  var location = (0, _reactRouterDom.useLocation)();
  var _useTranslation = (0, _reactI18next.useTranslation)('libTransfers'),
    t = _useTranslation.t;

  // redux state
  var _useUser = (0, _state.useUser)(),
    user = _useUser.user;
  var _useExchangeAvailable = (0, _state.useExchangeAvailablePairs)(),
    getExchangeAvailablePairs = _useExchangeAvailable.getExchangeAvailablePairs,
    exchangeAvailablePairsState = _useExchangeAvailable.exchangeAvailablePairsState;
  var _useFiatWithdrawLimit = (0, _state.useFiatWithdrawLimits)(),
    fiatWithdrawLimits = _useFiatWithdrawLimit.fiatWithdrawLimits,
    fiatWithdrawLoadingByTicker = _useFiatWithdrawLimit.fiatWithdrawLoadingByTicker,
    getFiatWithdrawLimits = _useFiatWithdrawLimit.getFiatWithdrawLimits;
  (0, _usePolling.usePolling)((0, _react.useCallback)(getExchangeAvailablePairs, []), true);

  // local state
  var _useState = (0, _react.useState)(),
    _useState2 = _slicedToArray(_useState, 2),
    maxLiquidity = _useState2[0],
    setMaxLiquidity = _useState2[1];
  var _useState3 = (0, _react.useState)(0),
    _useState4 = _slicedToArray(_useState3, 2),
    infoTimeout = _useState4[0],
    setInfoTimeout = _useState4[1];
  var _useState5 = (0, _react.useState)((_ref = location.state && location.state.ticker) !== null && _ref !== void 0 ? _ref : _definitions.CRYPTO_TICKERS[0]),
    _useState6 = _slicedToArray(_useState5, 2),
    ticker = _useState6[0],
    setTicker = _useState6[1];
  var _useState7 = (0, _react.useState)(_definitions.FIAT_TICKERS[0]),
    _useState8 = _slicedToArray(_useState7, 2),
    fiatTicker = _useState8[0],
    setFiatTicker = _useState8[1];
  var _useState9 = (0, _react.useState)(),
    _useState10 = _slicedToArray(_useState9, 2),
    toTicker = _useState10[0],
    setToTicker = _useState10[1];
  var _useState11 = (0, _react.useState)([_definitions.Ticker.BTC]),
    _useState12 = _slicedToArray(_useState11, 2),
    tickers = _useState12[0],
    setTickers = _useState12[1];
  var _useState13 = (0, _react.useState)([_definitions.Ticker.BTC]),
    _useState14 = _slicedToArray(_useState13, 2),
    toTickers = _useState14[0],
    setToTickers = _useState14[1];
  var _useBlockchainByTicke = (0, _useBlockchainByTicker.useBlockchainByTicker)(_definitions.FIAT_TICKERS.includes(toTicker) ? _definitions.Ticker.BTC : toTicker !== null && toTicker !== void 0 ? toTicker : _definitions.Ticker.BTC),
    blockchain = _useBlockchainByTicke.blockchain,
    blockchains = _useBlockchainByTicke.blockchains,
    setBlockchain = _useBlockchainByTicke.setBlockchain;
  var _useState15 = (0, _react.useState)(false),
    _useState16 = _slicedToArray(_useState15, 2),
    modalOpen = _useState16[0],
    setModalOpened = _useState16[1];
  var _useState17 = (0, _react.useState)('withdraw'),
    _useState18 = _slicedToArray(_useState17, 2),
    side = _useState18[0],
    setSide = _useState18[1];
  var _useState19 = (0, _react.useState)(false),
    _useState20 = _slicedToArray(_useState19, 2),
    exchangeLoading = _useState20[0],
    setExchangeLoading = _useState20[1];
  var tickerPrice = (0, _state.usePrice)({
    ticker: ticker,
    convertedTicker: fiatTicker
  });
  var toTickerPrice = (0, _state.usePrice)({
    ticker: toTicker || _definitions.Ticker.BTC,
    convertedTicker: fiatTicker
  });
  var limits = fiatWithdrawLimits[toTicker];
  (0, _react.useEffect)(function () {
    if (!limits && fiatWithdrawLoadingByTicker !== toTicker && isFiat) {
      var provider = user === null || user === void 0 ? void 0 : user.fiat.withdrawGatewayProviders[ticker];
      if (!provider) return console.error('Withdraw gateway provider not provided');
      getFiatWithdrawLimits({
        provider: provider,
        ticker: ticker
      });
    }
  }, [toTicker]);
  var _useBalances = (0, _state.useBalances)(),
    getBalanceByTicker = _useBalances.getBalanceByTicker;
  (0, _react.useEffect)(function () {
    if (exchangeAvailablePairsState.data) {
      var _tickers = (0, _lodash.default)(exchangeAvailablePairsState.data.map(function (p) {
        return p.exchange.ticker;
      }));
      setTickers(_tickers);
      setTicker(_tickers.includes(ticker) ? ticker : _tickers[0]);
    }
  }, [exchangeAvailablePairsState.data]);
  (0, _react.useEffect)(function () {
    if (exchangeAvailablePairsState.data) {
      var _exchangeAvailablePai;
      var _toTickers = (0, _lodash.default)((_exchangeAvailablePai = exchangeAvailablePairsState.data) === null || _exchangeAvailablePai === void 0 ? void 0 : _exchangeAvailablePai.filter(function (p) {
        return p.exchange.ticker === ticker;
      }).map(function (p) {
        return p.target.ticker;
      }));
      setToTickers(_toTickers);
      setToTicker(toTicker ? _toTickers.includes(toTicker) ? toTicker : _toTickers[0] : _toTickers[0]);
    }
  }, [ticker, exchangeAvailablePairsState.data]);
  var balances = params.balances;
  var balance = (_balances$find = balances === null || balances === void 0 ? void 0 : balances.find(function (b) {
    return b.ticker === ticker;
  })) !== null && _balances$find !== void 0 ? _balances$find : getBalanceByTicker(ticker);
  var isFiat = _definitions.FIAT_TICKERS.includes(toTicker);
  var useForm = isFiat ? _useFiatForm.useFiatForm : _useForm2.useForm;
  var useExchange = isFiat ? (_params$useFiatExchan = params.useFiatExchange) !== null && _params$useFiatExchan !== void 0 ? _params$useFiatExchan : _useFiatExchange.useFiatExchange : (_params$useExchange = params.useExchange) !== null && _params$useExchange !== void 0 ? _params$useExchange : _useExchange2.useExchange;
  var useInfo = isFiat ? function (props) {
    return (0, _useFiatInfo.useFiatInfo)(_objectSpread(_objectSpread({}, props), {}, {
      useGetInfo: params.useFiatExchangeInfo
    }));
  } : function (props) {
    return (0, _useCryptoInfo.useCryptoInfo)(_objectSpread(_objectSpread({}, props), {}, {
      useGetInfo: params.useCryptoExchangeInfo
    }));
  };
  var props = {
    balance: '0',
    fee: '0',
    total: '0',
    balanceAfterTransaction: '0',
    fiatTickerBalance: '0',
    fiatTickerFee: '0',
    fiatTickerTotal: '0',
    fiatBalanceAfterTransaction: '0',
    fiatTickerToAmount: '0'
  };
  if (balance) {
    props.balance = balance.value;
    props.fiatTickerBalance = balance.converted;
    props.balanceAfterTransaction = balance.value;
    props.fiatBalanceAfterTransaction = balance.converted;
  }
  var exchangePair = (_exchangeAvailablePai2 = exchangeAvailablePairsState.data) === null || _exchangeAvailablePai2 === void 0 ? void 0 : _exchangeAvailablePai2.find(function (p) {
    return p.exchange.ticker === ticker && p.target.ticker === toTicker;
  });
  var toTickerBalanceFixed = new _decimal.default(props.balance).mul((_tickerPrice$value = tickerPrice.value) !== null && _tickerPrice$value !== void 0 ? _tickerPrice$value : '0').div((_toTickerPrice$value = toTickerPrice.value) !== null && _toTickerPrice$value !== void 0 ? _toTickerPrice$value : '1').toString();
  var _useForm = useForm({
      ticker: ticker,
      toTicker: toTicker,
      fiatTicker: fiatTicker,
      price: (_tickerPrice$value2 = tickerPrice.value) !== null && _tickerPrice$value2 !== void 0 ? _tickerPrice$value2 : '0',
      blockchain: blockchain,
      balance: props.balance,
      toTickerBalance: toTickerBalanceFixed,
      exchangePair: exchangePair,
      side: side,
      maxLiquidity: maxLiquidity,
      limits: limits
    }),
    amount = _useForm.amount,
    fiatTickerAmount = _useForm.fiatTickerAmount,
    toAmount = _useForm.toAmount,
    address = _useForm.address,
    formProviderProps = _useForm.formProviderProps,
    setFocusedInput = _useForm.setFocusedInput;
  var isEnoughBalance = ((_formProviderProps$fo = formProviderProps.formState.errors.amount) === null || _formProviderProps$fo === void 0 ? void 0 : _formProviderProps$fo.type) !== _validations.ENOUGH_BALANCE_VALIDATION_NAME && ((_formProviderProps$fo2 = formProviderProps.formState.errors.toAmount) === null || _formProviderProps$fo2 === void 0 ? void 0 : _formProviderProps$fo2.type) !== _validations.ENOUGH_BALANCE_VALIDATION_NAME;
  (0, _react.useEffect)(function () {
    if (!isEnoughBalance) setInfoTimeout(0);
  }, [isEnoughBalance]);
  (0, _react.useEffect)(function () {
    var _formProviderProps$fo3;
    if ((_formProviderProps$fo3 = formProviderProps.formState.errors.toAmount) !== null && _formProviderProps$fo3 !== void 0 ? _formProviderProps$fo3 : formProviderProps.formState.errors.amount) setModalOpened(false);
  }, [formProviderProps.formState.errors.amount, formProviderProps.formState.errors.toAmount]);
  var _useInfo = useInfo({
      amount: amount,
      ticker: ticker,
      blockchain: blockchain,
      toTicker: toTicker,
      toAmount: toAmount,
      balance: balance,
      side: side,
      setFormValue: formProviderProps.setValue,
      exchangeLoading: exchangeLoading,
      address: address
    }),
    exchangeInfo = _useInfo.exchangeInfo,
    _getExchangeInfo = _useInfo.getExchangeInfo,
    resetExchangeInfo = _useInfo.resetExchangeInfo;
  var exchangePrice = exchangeInfo.data && (exchangeInfo.data.price.pair[0] === ticker ? exchangeInfo.data.price.value : new _decimal.default(1).div(exchangeInfo.data.price.value).toString());
  var toTickerBalanceExchange = exchangePrice && new _decimal.default(props.balance).mul(exchangePrice).toString();
  (0, _react.useEffect)(function () {
    if (exchangeInfo.maxLiquidity) setMaxLiquidity(exchangeInfo.maxLiquidity);else setMaxLiquidity(undefined);
  }, [exchangeInfo.maxLiquidity]);
  if (isEnoughBalance) {
    if (amount) props.total = amount;
    if (fiatTickerAmount) props.fiatTickerTotal = fiatTickerAmount;
  }
  if (exchangeInfo.data) {
    var _toTickerPrice$value2, _tickerPrice$value3, _tickerPrice$value4;
    props.fiatTickerToAmount = new _decimal.default(toAmount || '0').mul((_toTickerPrice$value2 = toTickerPrice.value) !== null && _toTickerPrice$value2 !== void 0 ? _toTickerPrice$value2 : '0').toString();
    props.fee = new _decimal.default(exchangeInfo.data.exchange.fee).mul(exchangePrice !== null && exchangePrice !== void 0 ? exchangePrice : '0').add(exchangeInfo.data.withdraw.fee).toString();
    props.fiatTickerFee = new _decimal.default(fiatTickerAmount || '0').sub(props.fiatTickerToAmount).toString();
    props.total = new _decimal.default(exchangeInfo.data.exchange.amount).mul(exchangePrice !== null && exchangePrice !== void 0 ? exchangePrice : '0').toString();
    props.fiatTickerTotal = new _decimal.default(exchangeInfo.data.exchange.amount).mul((_tickerPrice$value3 = tickerPrice.value) !== null && _tickerPrice$value3 !== void 0 ? _tickerPrice$value3 : '0').toString();
    props.balanceAfterTransaction = new _decimal.default(props.balance).sub(exchangeInfo.data.spend.amount).toString();
    props.fiatBalanceAfterTransaction = new _decimal.default(props.fiatTickerBalance).sub(new _decimal.default(exchangeInfo.data.spend.amount).mul((_tickerPrice$value4 = tickerPrice.value) !== null && _tickerPrice$value4 !== void 0 ? _tickerPrice$value4 : '0')).toString();
  }
  var _useExchange = useExchange({
      blockchain: blockchain,
      amount: amount,
      toAmount: toAmount,
      fiatTickerAmount: fiatTickerAmount,
      ticker: ticker,
      toTicker: toTicker,
      fee: (_exchangeInfo$data$wi = (_exchangeInfo$data = exchangeInfo.data) === null || _exchangeInfo$data === void 0 ? void 0 : _exchangeInfo$data.withdraw.fee) !== null && _exchangeInfo$data$wi !== void 0 ? _exchangeInfo$data$wi : '0',
      exchangeFee: (_exchangeInfo$data$ex = (_exchangeInfo$data2 = exchangeInfo.data) === null || _exchangeInfo$data2 === void 0 ? void 0 : _exchangeInfo$data2.exchange.fee) !== null && _exchangeInfo$data$ex !== void 0 ? _exchangeInfo$data$ex : '0',
      price: (_exchangeInfo$data$pr = (_exchangeInfo$data3 = exchangeInfo.data) === null || _exchangeInfo$data3 === void 0 ? void 0 : _exchangeInfo$data3.price.value) !== null && _exchangeInfo$data$pr !== void 0 ? _exchangeInfo$data$pr : '0',
      side: side,
      fiatTicker: fiatTicker,
      address: address,
      isEnoughBalance: isEnoughBalance,
      isValidating: formProviderProps.formState.isValidating,
      onSuccess: function onSuccess() {
        formProviderProps.reset({
          amount: '',
          toAmount: '',
          address: ''
        }, {
          keepTouched: false
        });
        setModalOpened(false);
        setInfoTimeout(0);
      }
    }),
    createExchange = _useExchange.createExchange,
    createExchangeLoading = _useExchange.createExchangeLoading,
    feeLessThanRequired = _useExchange.feeLessThanRequired;

  // temporary fix
  (0, _react.useEffect)(function () {
    setExchangeLoading(!!createExchangeLoading);
  }, [createExchangeLoading]);
  (0, _react.useEffect)(function () {
    if (exchangeInfo.data) setInfoTimeout(TIMEOUT);
  }, [exchangeInfo.data]);
  return _objectSpread(_objectSpread({
    minOrMaxChanged: ((_formProviderProps$fo4 = formProviderProps.formState.errors.amount) === null || _formProviderProps$fo4 === void 0 ? void 0 : _formProviderProps$fo4.type) === _useForm2.MIN_OR_MAX_VALIDATION_NAME || ((_formProviderProps$fo5 = formProviderProps.formState.errors.toAmount) === null || _formProviderProps$fo5 === void 0 ? void 0 : _formProviderProps$fo5.type) === _useForm2.MIN_OR_MAX_VALIDATION_NAME,
    isEnoughBalance: isEnoughBalance,
    exchangePair: exchangePair,
    balances: balances,
    toTickerBalance: toTickerBalanceExchange,
    blockchain: blockchain,
    blockchains: blockchains,
    setBlockchain: setBlockchain,
    loading: (_ref2 = (_ref3 = (_exchangePair = !exchangePair) !== null && _exchangePair !== void 0 ? _exchangePair : !balance) !== null && _ref3 !== void 0 ? _ref3 : !toTicker) !== null && _ref2 !== void 0 ? _ref2 : fiatWithdrawLoadingByTicker === toTicker || !limits && _definitions.FIAT_TICKERS.includes(toTicker),
    fiatTickerAmount: fiatTickerAmount || '0',
    amount: amount || '0',
    toAmount: toAmount || '0',
    fiatTicker: fiatTicker,
    ticker: ticker,
    setFiatTicker: setFiatTicker,
    setTicker: setTicker,
    toTicker: toTicker,
    setToTicker: setToTicker,
    tickers: tickers,
    toTickers: toTickers,
    infoTimeout: infoTimeout,
    setInfoTimeout: setInfoTimeout,
    clearInfoTimeout: function clearInfoTimeout() {
      return setInfoTimeout(0);
    },
    exchangeInfo: exchangeInfo,
    getExchangeInfo: function getExchangeInfo() {
      _getExchangeInfo();
    },
    formBtnText: !exchangeInfo.data ? 'Calculate' : 'Send',
    formBtnLoading: exchangeInfo.loading,
    modalOpen: modalOpen,
    toggleModal: function toggleModal() {
      return setModalOpened(!modalOpen);
    },
    address: address,
    onAmountInputFocus: function onAmountInputFocus() {
      return setFocusedInput('amount');
    },
    onToAmountInputFocus: function onToAmountInputFocus() {
      return setFocusedInput('toAmount');
    },
    onAmountChange: function onAmountChange() {
      resetExchangeInfo();
      setInfoTimeout(0);
      formProviderProps.setValue('toAmount', '');
      setSide('spend');
    },
    onToAmountChange: function onToAmountChange() {
      resetExchangeInfo();
      setInfoTimeout(0);
      formProviderProps.setValue('amount', '');
      setSide('withdraw');
    },
    onUseMaxClick: function onUseMaxClick() {},
    onModalSubmit: createExchange,
    modalSubmitLoading: createExchangeLoading,
    priceLoading: !exchangeInfo.data,
    price: (_exchangeInfo$data4 = exchangeInfo.data) === null || _exchangeInfo$data4 === void 0 ? void 0 : _exchangeInfo$data4.price,
    modalSubmitBtnText: t(!isEnoughBalance ? 'notEnoughBalance' : feeLessThanRequired ? 'rateChanged' : 'confirm&send')
  }, props), {}, {
    isInternalAddress: ((_exchangeInfo$data5 = exchangeInfo.data) === null || _exchangeInfo$data5 === void 0 ? void 0 : _exchangeInfo$data5.exchange.fee) === '0',
    formProviderProps: formProviderProps,
    isFiat: isFiat,
    maxLiquidity: exchangePair === null || exchangePair === void 0 ? void 0 : exchangePair.exchange.max
  });
};
exports.usePage = usePage;