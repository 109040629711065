"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  transactions: true
};
exports.transactions = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var _createWithdraw = require("./createWithdraw");
Object.keys(_createWithdraw).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _createWithdraw[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _createWithdraw[key];
    }
  });
});
var _get = require("./get");
Object.keys(_get).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _get[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _get[key];
    }
  });
});
var _getFiatWithdrawFee = require("./getFiatWithdrawFee");
var _getWithdrawFee = require("./getWithdrawFee");
var _getFiatWithdrawLimits = require("./getFiatWithdrawLimits");
var _getFiatReplenishFee = require("./getFiatReplenishFee");
var transactions = (0, _toolkit.combineReducers)({
  createFiatWithdraw: (0, _createWithdraw.createWithdrawReducer)(_actions.createFiatWithdrawAction),
  createWithdraw: (0, _createWithdraw.createWithdrawReducer)(),
  get: (0, _get.createGetTransactionsReducer)(),
  getFiatWithdrawFee: _getFiatWithdrawFee.getFiatWithdrawFee,
  getWithdrawFee: _getWithdrawFee.getWithdrawFee,
  getFiatWithdrawLimits: _getFiatWithdrawLimits.getFiatWithdrawLimits,
  getFiatReplenishFee: _getFiatReplenishFee.getFiatReplenishFee
});
exports.transactions = transactions;