import {
  FormGroup,
  InputField,
  Label,
  ProvidePersonalForm,
  useProvidePersonalForm
} from '@ppay/client'
import { useTranslation } from 'react-i18next'

import { useUpdatePersonal } from '../../../state'
import { nameValidation } from '../../../validations'

const Validation = {
  surname: nameValidation().required()
}

export const Form = () => {
  const { t } = useTranslation('auth')
  const { updatePersonal, updatePersonalLoading, personal } = useUpdatePersonal()
  const { control, handleSubmit } = useProvidePersonalForm<{ surname: string }>({
    validationSchema: Validation
  })

  return (
    <ProvidePersonalForm
      control={control}
      submitLoading={updatePersonalLoading}
      onSubmit={handleSubmit((formData) => updatePersonal({ ...formData, name: personal!.name! }))}
    >
      <FormGroup>
        <Label>{t('auth:lastName')}</Label>
        <InputField
          control={control}
          inputProps={{
            name: 'surname',
            placeholder: t('auth:lastName')
          }}
        />
      </FormGroup>
    </ProvidePersonalForm>
  )
}
