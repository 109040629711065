import {
  Card as CardUI,
  H6 as H6UI,
  Small,
  MediumSpan,
  mediaQuery,
  Button as ButtonLib
} from '@ppay/client'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Avatar as AvatarComponent } from './Avatar'
import { Icon } from '../../../../../ui'

const Card = styled(CardUI)`
  padding: 20px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.blue};
  ${mediaQuery.lessThan('xl')`
    margin-top: 20px
  `}
`

const H6 = styled(H6UI)`
  margin-bottom: 20px;
`

const Avatar = styled(AvatarComponent)`
  margin-right: 10px;
`

const SupportName = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 5px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mediaQuery.lessThan('md')`
    align-items: flex-start;
    flex-direction: column;
  `}
`

const Button = styled(ButtonLib)`
  max-width: 239px;
  ${mediaQuery.lessThan('md')`
    max-width: 100%;
    margin-top: 20px;
  `}
`

export const Component = ({
  name,
  surname,
  logo,
  chatLink
}: {
  name?: string
  surname?: string
  logo: string
  chatLink: string
}) => {
  const { t } = useTranslation('merchant')

  return (
    <Card>
      <H6>{t('connectionInformation.supportTitle')}</H6>
      <Wrapper>
        <div className="d-flex align-items-center">
          <Avatar />
          <div>
            <MediumSpan>
              <SupportName>
                {name} {surname}
              </SupportName>
            </MediumSpan>
            <Small>{t('connectionInformation.supportPosition')}</Small>
          </div>
        </div>
        <Button icon={<Icon name="airplane" />} onClick={() => window.open(chatLink, '_blank')}>
          {t('connectionInformation.supportButton')}
        </Button>
      </Wrapper>
    </Card>
  )
}
