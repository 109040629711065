"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRestorePasswordWithWizard = void 0;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _state = require("../state");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var useRestorePasswordWithWizard = function useRestorePasswordWithWizard(_ref) {
  var successPath = _ref.successPath;
  var _useRestorePassword = (0, _state.useRestorePassword)(),
    restored = _useRestorePassword.restored,
    reset = _useRestorePassword.reset,
    _restore = _useRestorePassword.restore,
    loading = _useRestorePassword.loading,
    email = _useRestorePassword.email,
    _useRestorePassword$c = _useRestorePassword.code,
    sent = _useRestorePassword$c.sent,
    validated = _useRestorePassword$c.validated,
    _validate = _useRestorePassword$c.validate,
    send = _useRestorePassword$c.send,
    remainingTime = _useRestorePassword$c.remainingTime,
    sendLoading = _useRestorePassword$c.sendLoading;
  var _useState = (0, _react.useState)(0),
    _useState2 = _slicedToArray(_useState, 2),
    step = _useState2[0],
    setStep = _useState2[1];
  var history = (0, _reactRouterDom.useHistory)();
  var _useState3 = (0, _react.useState)(''),
    _useState4 = _slicedToArray(_useState3, 2),
    code = _useState4[0],
    setCode = _useState4[1];
  (0, _react.useEffect)(function () {
    if (restored) {
      history.push(successPath);
    }
  }, [restored]);
  (0, _react.useEffect)(function () {
    if (sent) setStep(1);
  }, [sent]);
  (0, _react.useEffect)(function () {
    if (validated) setStep(2);
  }, [validated]);
  (0, _react.useEffect)(function () {
    return function () {
      reset();
    };
  }, []);
  return {
    redirectToSuccessPath: function redirectToSuccessPath() {
      return history.push(successPath);
    },
    restore: function restore(password) {
      _restore({
        code: code,
        password: password
      });
    },
    step: step,
    email: email,
    setStep: setStep,
    loading: loading,
    code: {
      validate: function validate(code) {
        setCode(code);
        _validate(code);
      },
      send: send,
      sendLoading: sendLoading,
      remainingTime: remainingTime
    }
  };
};
exports.useRestorePasswordWithWizard = useRestorePasswordWithWizard;