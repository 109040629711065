"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFiatInfo = void 0;
var _react = require("react");
var _state = require("../../state");
var useFiatInfo = function useFiatInfo(_ref) {
  var amount = _ref.amount,
    toAmount = _ref.toAmount,
    ticker = _ref.ticker,
    toTicker = _ref.toTicker,
    blockchain = _ref.blockchain,
    exchangeDone = _ref.exchangeDone,
    exchangeLoading = _ref.exchangeLoading,
    feeLessThanRequired = _ref.feeLessThanRequired,
    side = _ref.side,
    balance = _ref.balance,
    setFormValue = _ref.setFormValue,
    useGetInfo = _ref.useGetInfo;
  var infoAmount = side === 'spend' ? amount : toAmount;
  var _ref2 = (useGetInfo !== null && useGetInfo !== void 0 ? useGetInfo : _state.useExchangeFiatInfo)(),
    exchangeFiatInfoState = _ref2.exchangeFiatInfoState,
    getExchangeFiatInfo = _ref2.getExchangeFiatInfo,
    resetExchangeFiatInfo = _ref2.resetExchangeFiatInfo;
  (0, _react.useEffect)(function () {
    return function () {
      resetExchangeFiatInfo();
    };
  }, []);
  (0, _react.useEffect)(function () {
    if (exchangeFiatInfoState.data) {
      if (side === 'spend') {
        setFormValue('toAmount', exchangeFiatInfoState.data.withdraw.amount);
      } else {
        setFormValue('amount', exchangeFiatInfoState.data.spend.amount);
      }
    }
  }, [exchangeFiatInfoState.data]);
  (0, _react.useEffect)(function () {
    if (exchangeDone) {
      resetExchangeFiatInfo();
    }
  }, [exchangeDone]);
  (0, _react.useEffect)(function () {
    if (!amount) resetExchangeFiatInfo();
  }, [amount]);
  return {
    exchangeInfo: exchangeFiatInfoState,
    resetExchangeInfo: resetExchangeFiatInfo,
    getExchangeInfo: (0, _react.useCallback)(function () {
      getExchangeFiatInfo({
        side: side,
        spendTicker: ticker,
        ticker: toTicker,
        amount: infoAmount
      });
    }, [infoAmount, side, ticker, toTicker, feeLessThanRequired])
  };
};
exports.useFiatInfo = useFiatInfo;