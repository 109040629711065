import { Button, H4 as H4Lib } from '@ppay/client'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Icon } from '../../../ui'
import image from './image.svg'

const Image = styled.img.attrs(() => ({ src: image }))`
  margin-bottom: 20px;
`

const H4 = styled(H4Lib)`
  margin-bottom: 20px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`

export const NoData = ({
  onCreateMerchantClick,
  className
}: {
  onCreateMerchantClick: () => void
  className?: string
}) => {
  const { t } = useTranslation('merchant')

  return (
    <Wrapper className={className}>
      <Image alt="merchant" />
      <H4>{t('noMerchantsYet')}</H4>
      <Button
        onClick={onCreateMerchantClick}
        color="secondary"
        icon={<Icon name="createMerchant" />}
      >
        {t('createMerchant')}
      </Button>
    </Wrapper>
  )
}
