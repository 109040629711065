"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.oAuth = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  google: {
    authorize: {
      loading: false
    },
    login: {
      loading: false
    }
  }
};
var oAuth = (0, _toolkit.createReducer)(initialState, function (builder) {
  builder.addCase(_actions.oAuthGoogleAuthorizeAction.pending, function (state) {
    state.google.authorize = {
      loading: true
    };
  });
  builder.addCase(_actions.oAuthGoogleAuthorizeAction.rejected, function (state) {
    state.google.authorize.loading = false;
  });
  builder.addCase(_actions.oAuthGoogleLoginAction.pending, function (state) {
    state.google.login.loading = true;
  });
  builder.addCase(_actions.oAuthGoogleLoginAction.rejected, function (state) {
    state.google.login.loading = false;
    state.google.login.done = true;
  });
  builder.addCase(_actions.oAuthGoogleLoginAction.fulfilled, function (state) {
    state.google.login.loading = false;
    state.google.login.done = true;
  });
});
exports.oAuth = oAuth;