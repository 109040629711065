import { ManualReplenishOrderStatus } from '@ppay/definitions'
import { AnyAction, Middleware, ThunkDispatch } from '@reduxjs/toolkit'

import { getManualReplenishOrderAction, getInvoiceFiatAction } from '../actions'
import type { State } from '../store'

export const manualReplenishOrder: Middleware<{}, {}, ThunkDispatch<State, null, AnyAction>> =
  (store) => (next) => (action) => {
    if (action.type === getManualReplenishOrderAction.fulfilled.type) {
      if (
        action.payload.status !== ManualReplenishOrderStatus.QUEUED ||
        action.payload.status !== ManualReplenishOrderStatus.CREATED
      ) {
        store.dispatch(getInvoiceFiatAction(action.payload.id))
      }
    }
    next(action)
  }
