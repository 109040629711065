"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCreateWithdraw = void 0;
var _reactRedux = require("react-redux");
var _lodash = _interopRequireDefault(require("lodash.get"));
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var useCreateWithdraw = function useCreateWithdraw(params) {
  var _params$action, _params$resetAction, _params$statePath;
  var action = (_params$action = params === null || params === void 0 ? void 0 : params.action) !== null && _params$action !== void 0 ? _params$action : _actions.createWithdrawAction;
  var resetAction = (_params$resetAction = params === null || params === void 0 ? void 0 : params.resetAction) !== null && _params$resetAction !== void 0 ? _params$resetAction : _actions.resetCreateWithdrawAction;
  var statePath = (_params$statePath = params === null || params === void 0 ? void 0 : params.statePath) !== null && _params$statePath !== void 0 ? _params$statePath : 'lib.transactions.createWithdraw';
  var state = (0, _useSelector.useSelector)(function (state) {
    return (0, _lodash.default)(state, statePath);
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    withdrawState: {
      done: state.done,
      loading: state.loading,
      feeLessThanRequired: state.feeLessThanRequired
    },
    createWithdraw: function createWithdraw(data) {
      return dispatch(action(data));
    },
    resetWithdraw: function resetWithdraw() {
      return dispatch(resetAction());
    }
  };
};
exports.useCreateWithdraw = useCreateWithdraw;