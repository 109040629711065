import React, { createRef } from 'react'
import styled from 'styled-components'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next'

import { MerchantPaymentsStats } from '../../../../../interfaces'
import { theme } from '../../../../../theme'

const Wrapper = styled.div`
  margin-bottom: 20px;
`

export const Chart = ({
  data = [],
  setPointIndex,
  pointIndex
}: {
  data?: MerchantPaymentsStats[]
  setPointIndex: (index: number) => void
  pointIndex: number
}) => {
  const ref = createRef<HTMLDivElement>()
  const { t } = useTranslation('libCommon')

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 100,
      events: {
        click: function (e) {
          const point = this.series[0].searchPoint(e, true)
          if (!point || (point && point.y === 0)) setPointIndex(data.length - 1)
        }
      }
    },
    plotOptions: {
      series: {
        point: {
          events: {
            click: function () {
              setPointIndex(this.index)
            }
          }
        }
      },
      column: {
        states: {
          hover: {
            color: theme.colors.primary
          }
        }
      }
    },
    title: undefined,
    legend: {
      enabled: false
    },
    yAxis: {
      type: 'linear',
      gridLineWidth: 0,
      crosshair: false,
      labels: { enabled: false },
      title: undefined
    },
    xAxis: {
      type: 'datetime',
      tickLength: 0,
      labels: { enabled: false },
      title: undefined
    },
    tooltip: {
      backgroundColor: theme.colors.tertiary,
      borderColor: theme.colors.tertiary,
      borderRadius: 4,
      hideDelay: 300,
      style: {
        color: theme.colors.secondary,
        padding: '8px',
        fontSize: '14px'
      },
      formatter: function () {
        const res = data[this.point.index]

        return (
          t('from') +
          ' ' +
          new Date(res.interval[0]).toLocaleString('en-GB') +
          ' UTC' +
          '<br/>' +
          t('till') +
          ' ' +
          new Date(res.interval[1]).toLocaleString('en-GB') +
          ' UTC'
        )
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        pointPadding: 0,
        groupPadding: 0,
        color: theme.colors.success,
        type: 'column',
        data: data.map(({ interval, stats }, index) => {
          return {
            x: interval[0],
            color: index === pointIndex ? theme.colors.primary : theme.colors.success,
            y: stats.length ? stats.reduce((acc, c) => acc + Number(c.convertedAmount), 0) : 0
          }
        })
      }
    ]
  }

  return (
    <Wrapper ref={ref}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Wrapper>
  )
}
