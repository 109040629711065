"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useContext = void 0;
var _react = require("react");
var _components = require("../../components");
var useContext = function useContext() {
  return (0, _react.useContext)(_components.DashboardTransfersExchangeContext);
};
exports.useContext = useContext;