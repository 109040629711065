import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'

import './sass/index.scss'
import './i18n'

import { App } from './App'
import { theme } from './theme'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root')
)
