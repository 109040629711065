import { Card as CardLib } from '@ppay/client'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { Header } from './Header'
import { Footer } from './Footer'

const StyledCard = styled(CardLib)`
  box-shadow: 0 20px 50px ${({ theme }) => theme.colors.tertiary}0D,
    -20px 20px 50px ${({ theme }) => theme.colors.tertiary}0D;
  border-radius: 6px;
  padding: 20px;
  max-width: 520px;
  width: 100%;
  margin-bottom: 60px;
`

export const Card = ({
  children,
  id,
  name,
  description,
  back,
  isFiat
}: PropsWithChildren<{
  id: string
  name: string
  description?: string
  back?: () => void
  isFiat?: boolean
}>) => (
  <StyledCard>
    <Header id={id} name={name} description={description} back={back} />
    {children}
    <Footer isFiat={isFiat} />
  </StyledCard>
)
