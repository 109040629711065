import { Ticker } from '@ppay/definitions'
import {
  Card as CardUI,
  H4,
  H5,
  SeparativeLine as SeparativeLineLib,
  TickerSymbol,
  TickerNumberFormat
} from '@ppay/client'
import styled from 'styled-components'

import { Merchant } from '../../../../interfaces'
import { Website } from './Website'
import { Actions } from './Actions'
import { MerchantStatus } from '../../merchant'

const Card = styled(CardUI)`
  padding: 20px;
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledH5 = styled(H5)`
  margin-bottom: 5px !important;
`

const StyledWebsite = styled(Website)`
  margin-bottom: 5px !important;
`

const SeparativeLine = styled(SeparativeLineLib)`
  margin: 20px 0;
`

export const Cards = ({ merchants }: { merchants: Merchant[] }) => {
  return (
    <>
      {merchants.map((m) => (
        <Card key={m.id}>
          <div className="d-flex justify-content-between align-items-center">
            <Info>
              <StyledH5 className="mb-0">{m.name}</StyledH5>
              <StyledWebsite value={m.domain} />
              <MerchantStatus verified={m.verified} />
            </Info>
            <H4 className="mb-0">
              <TickerSymbol ticker={Ticker.USD} />
              <TickerNumberFormat ticker={Ticker.USD} value={m.totalBalance} />
            </H4>
          </div>
          <SeparativeLine />
          <Actions merchantId={m.id} />
        </Card>
      ))}
    </>
  )
}
