import React from 'react'
import { TFunction } from 'i18next'
import { Control, FieldValues } from 'react-hook-form'

import { FormGroup, InputField, Label } from '@ppay/client'

export const EmailFormGroup = ({
  t,
  control,
  readonly
}: {
  t: TFunction
  control: Control<FieldValues>
  readonly?: boolean
}) => (
  <FormGroup>
    <Label>{t('emailForBill')}</Label>
    <InputField
      control={control}
      inputProps={{
        readOnly: readonly,
        name: 'email',
        placeholder: 'example@mail.com'
      }}
    />
  </FormGroup>
)
