import { createReducer } from '@reduxjs/toolkit'
import { ManualReplenishOrderStatus } from '@ppay/definitions'

import { ManualReplenishOrder } from '../../interfaces'
import { getManualReplenishOrderAction, queueManualReplenishOrderAction } from '../actions'

type State = {
  getLoading: boolean
  queueLoading: boolean
  data?: ManualReplenishOrder
}

const initialState: State = {
  getLoading: false,
  queueLoading: false
}

export const manualReplenishOrder = createReducer(initialState, (builder) => {
  builder
    .addCase(getManualReplenishOrderAction.pending, (state) => {
      state.getLoading = true
    })
    .addCase(getManualReplenishOrderAction.rejected, (state, action) => {
      state.getLoading = false
    })
    .addCase(getManualReplenishOrderAction.fulfilled, (state, action) => {
      state.getLoading = false
      state.data = action.payload
    })
    .addCase(queueManualReplenishOrderAction.pending, (state) => {
      state.queueLoading = true
    })
    .addCase(queueManualReplenishOrderAction.rejected, (state) => {
      state.queueLoading = false
    })
    .addCase(queueManualReplenishOrderAction.fulfilled, (state) => {
      state.queueLoading = false
      state.data!.status = ManualReplenishOrderStatus.QUEUED
    })
})
