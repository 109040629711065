"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.balances = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _get = require("./get");
var _getTotal = require("./getTotal");
var balances = (0, _toolkit.combineReducers)({
  get: _get.get,
  getTotal: _getTotal.getTotal
});
exports.balances = balances;