"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TextButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _BaseButton = require("./BaseButton");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var TextButton = (0, _styledComponents.default)(_BaseButton.BaseButton)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: transparent;\n  height: auto;\n  width: auto;\n  border: none;\n  padding: 0;\n\n  &:not(:disabled):not(.disabled):hover,\n  &:focus,\n  &:not(:disabled):not(.disabled):active {\n    color: ", ";\n    background-color: transparent;\n    height: auto;\n    width: auto;\n    border: none;\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.primary;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.primary;
});
exports.TextButton = TextButton;