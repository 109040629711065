import React, { ReactNode } from 'react'
import { WithUserPersonal as WithUserPersonalLib } from '@ppay/client'

import { routes } from './router'
import { User } from '../interfaces'

const REQUIRED_USER_PERSONAL = ['surname'] as Array<keyof User['personal']>

export const WithUserPersonal = ({
  children,
  provided = true
}: {
  children: ReactNode
  provided?: boolean
}) => {
  return (
    <WithUserPersonalLib
      provided={provided}
      path={routes.auth.providePersonal}
      homePath={routes.dashboard.home}
      loginPath={routes.auth.login}
      requiredFields={REQUIRED_USER_PERSONAL}
    >
      {children}
    </WithUserPersonalLib>
  )
}
