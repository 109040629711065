import styled from 'styled-components'
import { mediaQuery } from '@ppay/client'

import bannerImage from './images/image.png'

export const Image = styled.img.attrs(() => ({ src: bannerImage }))`
  position: absolute;
  right: 40px;
  bottom: -40px;
  z-index: 1;
  width: 360px;
  height: auto;
  ${mediaQuery.lessThan('lg')`
    width: 320px;
  `}
  ${mediaQuery.lessThan('md')`
    left: 50%;
    right: auto;
    bottom: -60px;
    transform: translateX(-50%);
    width: 200px;
  `}
`
