"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DataAssets", {
  enumerable: true,
  get: function get() {
    return _Assets.Assets;
  }
});
Object.defineProperty(exports, "DataPriceChange", {
  enumerable: true,
  get: function get() {
    return _PriceChange.PriceChange;
  }
});
Object.defineProperty(exports, "DataTotalBalance", {
  enumerable: true,
  get: function get() {
    return _TotalBalance.TotalBalance;
  }
});
var _Assets = require("./Assets");
var _PriceChange = require("./PriceChange");
var _TotalBalance = require("./TotalBalance");