"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Dot = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Dot = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: 50%;\n  width: ", "px;\n  height: ", "px;\n  ", "\n  background-color: ", ";\n"])), function (_ref) {
  var size = _ref.size;
  return size !== null && size !== void 0 ? size : 8;
}, function (_ref2) {
  var size = _ref2.size;
  return size !== null && size !== void 0 ? size : 8;
}, function (_ref3) {
  var borderColor = _ref3.borderColor,
    theme = _ref3.theme;
  return borderColor && "border: 1px solid ".concat(theme.colors[borderColor], ";");
}, function (_ref4) {
  var color = _ref4.color,
    theme = _ref4.theme;
  return theme.colors[color];
});
exports.Dot = Dot;