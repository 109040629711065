import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CRYPTO_TICKERS, FIAT_BALANCE_TICKERS, Ticker } from '@ppay/definitions'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardSubtitle,
  H4,
  TabsNav as TabsNavLib,
  TabsNavItem,
  TickerMultiSelectInput as TickerMultiSelectInputLib,
  SelectControl as SelectControlLib,
  SelectValueContainer as SelectValueContainerLib,
  SelectSearchInput as SelectSearchInputLib,
  Spinner,
  SeparativeLine as SeparativeLineUI,
  TabsNavLink as TabsNavLinkLib,
  mediaQuery
} from '@ppay/client'

import { Icon } from '../../../ui'
import { MerchantPaymentsStats } from '../../../../interfaces'
import { MerchantPaymentsStatsPeriod, MerchantPaymentsStatsType } from '../../../../constants'

import { ChartWithLegend } from './ChartWithLegend'

const SeparativeLine = styled(SeparativeLineUI)`
  margin-bottom: 20px;
`

const TabsWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 30px;
  ${mediaQuery.lessThan('sm')`
    flex-direction:column;
  `}
`

const TabsNav = styled(TabsNavLib)`
  margin: 0;
  flex-shrink: 1;
  flex-wrap: nowrap;
  max-height: 45px;
`

const SelectControl = styled(SelectControlLib)`
  border: none !important;
`

const SelectValueContainer = styled(SelectValueContainerLib)`
  height: 45px !important;
`

const SelectSearchInput = styled(SelectSearchInputLib)`
  height: 45px !important;
`

const TabsNavLink = styled(TabsNavLinkLib)`
  padding: 8px 5px;
`

const TickerMultiSelectInput = styled(TickerMultiSelectInputLib)`
  flex-grow: 1;
`
export const Component = ({
  stats,
  merchantName,
  loading,
  tickers,
  setTickers,
  type,
  setType,
  period,
  setPeriod
}: {
  merchantName: string
  stats?: MerchantPaymentsStats[]
  loading: boolean
  tickers: Ticker[]
  setTickers: (tickers: Ticker[]) => void
  type: MerchantPaymentsStatsType
  setType: (type: MerchantPaymentsStatsType) => void
  period: MerchantPaymentsStatsPeriod
  setPeriod: (period: MerchantPaymentsStatsPeriod) => void
}) => {
  const { t } = useTranslation('merchant')

  return (
    <Card>
      <CardHeader>
        <CardTitle tag={H4}>{merchantName}</CardTitle>
        <CardSubtitle>{t('inputOutputFlowDesc')}</CardSubtitle>
      </CardHeader>
      <CardBody>
        <TabsWrapper>
          <TabsNav>
            <TabsNavItem>
              <TabsNavLink
                onClick={() => setType(MerchantPaymentsStatsType.IN)}
                active={type === MerchantPaymentsStatsType.IN}
              >
                <>
                  <Icon width={12} name="arrowRotate" />
                  {t('in')}
                </>
              </TabsNavLink>
            </TabsNavItem>
            <TabsNavItem>
              <TabsNavLink
                onClick={() => setType(MerchantPaymentsStatsType.OUT)}
                active={type === MerchantPaymentsStatsType.OUT}
              >
                <>
                  <Icon width={12} name="arrowRotate" rotate={180} />
                  {t('out')}
                </>
              </TabsNavLink>
            </TabsNavItem>
          </TabsNav>
          <TabsNav>
            <TabsNavItem>
              <TabsNavLink
                onClick={() => setPeriod(MerchantPaymentsStatsPeriod.DAILY)}
                active={period === MerchantPaymentsStatsPeriod.DAILY}
              >
                {t('daily')}
              </TabsNavLink>
            </TabsNavItem>
            <TabsNavItem>
              <TabsNavLink
                onClick={() => setPeriod(MerchantPaymentsStatsPeriod.WEAKLY)}
                active={period === MerchantPaymentsStatsPeriod.WEAKLY}
              >
                {t('weekly')}
              </TabsNavLink>
            </TabsNavItem>
            <TabsNavItem>
              <TabsNavLink
                onClick={() => setPeriod(MerchantPaymentsStatsPeriod.MONTHLY)}
                active={period === MerchantPaymentsStatsPeriod.MONTHLY}
              >
                {t('monthly')}
              </TabsNavLink>
            </TabsNavItem>
          </TabsNav>
          <TickerMultiSelectInput
            components={{
              Control: SelectControl,
              ValueContainer: SelectValueContainer,
              Input: SelectSearchInput
            }}
            value={tickers}
            tickers={[...CRYPTO_TICKERS, ...FIAT_BALANCE_TICKERS]}
            onChange={setTickers}
          />
        </TabsWrapper>
        {loading || !stats ? (
          <Spinner color="primary" />
        ) : (
          <ChartWithLegend stats={stats} type={type} />
        )}
        <SeparativeLine />
      </CardBody>
    </Card>
  )
}
