import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableHeader,
  TableData,
  breakpoints
} from '@ppay/client'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import { Kind } from './Kind'
import { TableValue as CryptoValue, Card as CryptoCard, CryptoTickerFees } from './crypto'
import { TableValue as FiatValue, Card as FiatBalanceCard, FiatBalanceTickerFees } from './fiat'

export interface Props {
  cryptoFees: CryptoTickerFees[]
  fiatBalanceFees: FiatBalanceTickerFees[]
}

export const Component = ({ fiatBalanceFees, cryptoFees }: Props) => {
  const { t } = useTranslation('paymentMethodsTable')
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md })

  return (
    <>
      {isMobile ? (
        <>
          {cryptoFees.map((f, i) => (
            <CryptoCard key={i} {...f} />
          ))}
          {fiatBalanceFees.map((f, i) => (
            <FiatBalanceCard key={i} {...f} />
          ))}
        </>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader>{t('name')}</TableHeader>
              <TableHeader>{t('serviceFee')}</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {cryptoFees.map((f, i) => (
              <TableRow key={i}>
                <TableData>
                  <Kind ticker={f.ticker} />
                </TableData>
                <TableData>
                  <CryptoValue {...f} />
                </TableData>
              </TableRow>
            ))}
            {fiatBalanceFees.map((f, i) => (
              <TableRow key={i}>
                <TableData>
                  <Kind ticker={f.ticker} />
                </TableData>
                <TableData>
                  <FiatValue {...f} />
                </TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  )
}
