"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ENOUGH_BALANCE_VALIDATION_NAME", {
  enumerable: true,
  get: function get() {
    return _enoughBalance.ENOUGH_BALANCE_VALIDATION_NAME;
  }
});
Object.defineProperty(exports, "cardNumberValidation", {
  enumerable: true,
  get: function get() {
    return _cardNumber.cardNumber;
  }
});
Object.defineProperty(exports, "confirmationValidation", {
  enumerable: true,
  get: function get() {
    return _confirmation.confirmation;
  }
});
Object.defineProperty(exports, "cryptoAddressValidation", {
  enumerable: true,
  get: function get() {
    return _cryptoAddress.cryptoAddress;
  }
});
Object.defineProperty(exports, "emailValidation", {
  enumerable: true,
  get: function get() {
    return _email.email;
  }
});
Object.defineProperty(exports, "enoughBalanceValidation", {
  enumerable: true,
  get: function get() {
    return _enoughBalance.enoughBalance;
  }
});
Object.defineProperty(exports, "numericalValidation", {
  enumerable: true,
  get: function get() {
    return _numerical.numerical;
  }
});
Object.defineProperty(exports, "passwordValidation", {
  enumerable: true,
  get: function get() {
    return _password.password;
  }
});
var _email = require("./email");
var _numerical = require("./numerical");
var _confirmation = require("./confirmation");
var _password = require("./password");
var _cryptoAddress = require("./cryptoAddress");
var _enoughBalance = require("./enoughBalance");
var _cardNumber = require("./cardNumber");