"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  LayoutBodyWizardControl: true,
  LayoutBodyCard: true,
  LayoutBody: true,
  LayoutTitle: true,
  LayoutBackTitle: true,
  LayoutHeader: true
};
Object.defineProperty(exports, "LayoutBackTitle", {
  enumerable: true,
  get: function get() {
    return _title.BackTitle;
  }
});
Object.defineProperty(exports, "LayoutBody", {
  enumerable: true,
  get: function get() {
    return _body.Body;
  }
});
Object.defineProperty(exports, "LayoutBodyCard", {
  enumerable: true,
  get: function get() {
    return _body.BodyCard;
  }
});
Object.defineProperty(exports, "LayoutBodyWizardControl", {
  enumerable: true,
  get: function get() {
    return _body.BodyWizardControl;
  }
});
Object.defineProperty(exports, "LayoutHeader", {
  enumerable: true,
  get: function get() {
    return _Header.Header;
  }
});
Object.defineProperty(exports, "LayoutTitle", {
  enumerable: true,
  get: function get() {
    return _title.Title;
  }
});
var _body = require("./body");
var _title = require("./title");
var _Layout = require("./Layout");
Object.keys(_Layout).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Layout[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Layout[key];
    }
  });
});
var _Header = require("./Header");