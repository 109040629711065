import {
  DashboardTransfersWithdrawFormTickerConverter as TickerConverter,
  DashboardTransfersWithdrawTickerFormGroup as TickerFormGroup,
  DashboardTransfersWithdrawBalanceFormGroup as BalanceFormGroup,
  DashboardTransfersWithdrawFormFooter as FormFooter,
  DashboardTransfersWithdrawFormProps as FormProps,
  Row,
  Col,
  FormGroup,
  Label,
  Spinner,
  useWithdrawContext
} from '@ppay/client'
import { PropsWithChildren } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form as FormRS } from 'reactstrap'

import { BalanceSelectInput } from '../BalanceSelectInput'

export const Form = ({
  balanceName,
  balanceOption,
  balanceOptions,
  toBalanceOption,
  onBalanceOptionChange,
  onToBalanceOptionChange,
  merchantsLoading
}: PropsWithChildren<
  FormProps & {
    balanceOptions: Array<{ value: string; balance: string; name: string }>
    balanceOption: string
    toBalanceOption: string
    merchantsLoading: boolean
    onBalanceOptionChange: (balance: string) => unknown
    onToBalanceOptionChange: (balance: string) => unknown
  }
>) => {
  const { t } = useTranslation(['transfers'])

  const { handleSubmit } = useFormContext()
  const formProps = useWithdrawContext()

  return (
    <FormRS onSubmit={handleSubmit(formProps.onFormSubmit)} noValidate>
      {formProps.loading || merchantsLoading ? (
        <Spinner size="lg" color="primary" />
      ) : (
        <>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label>{t('chooseBalanceToWithdraw')}:</Label>
                <BalanceSelectInput
                  value={balanceOption}
                  balanceOptions={balanceOptions}
                  onChange={onBalanceOptionChange}
                  isSearchable={false}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <FormGroup>
                <Label>{t('chooseBalanceToReceive')}:</Label>
                <BalanceSelectInput
                  value={toBalanceOption}
                  balanceOptions={balanceOptions}
                  onChange={onToBalanceOptionChange}
                  isSearchable={false}
                />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <TickerFormGroup />
            </Col>
          </Row>
          <BalanceFormGroup balanceName={balanceName} />
          <TickerConverter />
          <FormFooter />
        </>
      )}
    </FormRS>
  )
}
