import React, { ReactNode } from 'react'
import { DashboardLayout, DashboardLayoutHeaderProps } from '@ppay/client'
import { useHistory } from 'react-router-dom'

import { Header } from './Header'
import { Footer, LayoutImages } from '../../layout'
import { routes } from '../../router'

const HeaderComponent = (props: DashboardLayoutHeaderProps) => {
  const history = useHistory()
  return (
    <Header
      {...props}
      logo={LayoutImages.logo}
      onLogoClick={() => history.push(routes.dashboard.home)}
    />
  )
}

const FooterComponent = (props: any) => {
  const history = useHistory()
  return (
    <Footer
      {...props}
      logo={LayoutImages.logo}
      onLogoClick={() => history.push(routes.dashboard.home)}
    />
  )
}

export const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <DashboardLayout headerComponent={HeaderComponent} footerComponent={FooterComponent}>
      {children}
    </DashboardLayout>
  )
}
