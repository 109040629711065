import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import styled from 'styled-components'
import { FIAT_BALANCE_TICKERS, Ticker } from '@ppay/definitions'
import {
  emailValidation,
  FormGroup,
  Label,
  Button,
  TickerName,
  TickerIcon,
  TickerSelectInput,
  TickerNumberFormatInputField
} from '@ppay/client'

import { useInvoiceFiatUpdate } from '../../../state'
import { FiatInvoice } from '../../../interfaces'
import { CardContinue, CardAmountToPay, CardSeparativeLine, CardData } from '../card'
import { EmailFormGroup } from '../EmailFormGroup'
import { Icon } from '../../ui'

const schema = yup
  .object()
  .shape({
    email: emailValidation().required(),
    amount: yup
      .string()
      .when('$withAmount', (withAmount: boolean, schema: any) =>
        withAmount
          ? schema
          : schema.test('amount', 'should be positive number', (value: string) => Number(value) > 0)
      )
  })
  .required()

const TickerNameColor = styled.span`
  color: ${({ theme }) => theme.colors.tertiaryShade};
  margin-left: 4px;
`
const CardDataWithMargin = styled(CardData)`
  margin: 20px 0;
`

const CurrencyWrapper = styled.div`
  display: flex;
  align-items: center;
  ${TickerIcon} {
    margin-right: 6px;
  }
`

export const Continue = ({
  invoice,
  ticker,
  setTicker,
  hideButton,
  email,
  back,
  children
}: PropsWithChildren<{
  invoice: FiatInvoice
  setTicker: (ticker: Ticker) => void
  ticker: Ticker
  hideButton?: boolean
  email?: string
  back?: () => void
}>) => {
  const { t } = useTranslation('invoice')
  const { loading, updateInvoice } = useInvoiceFiatUpdate()

  const withAmount = invoice?.amount

  const onSubmit = (value: { email: string; amount: string }) => {
    const data = withAmount
      ? {
          email: email ?? value.email
        }
      : {
          email: email ?? value.email,
          amount: {
            value: value.amount,
            ticker
          }
        }

    return updateInvoice({ ...data, invoiceId: invoice.id })
  }

  const { handleSubmit, control } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: { email },
    context: {
      withAmount
    }
  })

  return (
    <CardContinue invoice={invoice} isFiat={true} back={back}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        {withAmount ? (
          <>
            <CardDataWithMargin
              title={t('currency')}
              value={
                <CurrencyWrapper>
                  <TickerIcon ticker={ticker} size={24} />
                  <TickerName ticker={ticker} />
                  <TickerNameColor>
                    <TickerName ticker={ticker} full />
                  </TickerNameColor>
                </CurrencyWrapper>
              }
            />
            <CardAmountToPay
              amount={invoice.amount!.value}
              ticker={invoice.amount!.ticker}
              text={t('amountToPay')}
            />
            <CardSeparativeLine />
            <EmailFormGroup t={t} control={control} readonly={!!email} />
          </>
        ) : (
          <>
            <CardSeparativeLine />
            <EmailFormGroup t={t} control={control} readonly={!!email} />
            <FormGroup>
              <Label>{t('currency')}</Label>
              <TickerSelectInput
                value={ticker}
                tickers={FIAT_BALANCE_TICKERS}
                onChange={setTicker}
                control={control}
                inputProps={{
                  name: 'ticker',
                  ticker
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>{t('amount')}</Label>
              <TickerNumberFormatInputField
                control={control}
                inputProps={{
                  name: 'amount',
                  ticker
                }}
              />
            </FormGroup>
          </>
        )}
        {children}
        {!hideButton && (
          <Button type="submit" loading={loading} wide icon={<Icon name="arrowRight" />}>
            {t('continueToPay')}
          </Button>
        )}
      </Form>
    </CardContinue>
  )
}
