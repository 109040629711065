"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.theme = void 0;
var _colors = require("./colors");
var _tickerColors = require("./tickerColors");
var _notificationColors = require("./notificationColors");
var theme = {
  colors: _colors.colors,
  tickerColors: _tickerColors.tickerColors,
  notificationColors: _notificationColors.notificationColors
};
exports.theme = theme;