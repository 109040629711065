import React from 'react'
import styled from 'styled-components'
import { H4, Small, Svg, useTranslation, A } from '@ppay/client'
import { Trans } from 'react-i18next'

import { ReactComponent as pending } from './pending.svg'
import { Wrapper } from './Wrapper'

const StyledSvg = styled(Svg)`
  margin-bottom: 10px;
`

const Text = styled(Small)`
  color: ${({ theme }) => theme.colors.tertiaryShade};
`

export const Pending = () => {
  const { t } = useTranslation('kyc')

  const supportUrl = process.env.REACT_APP_CHAT!

  return (
    <Wrapper>
      <StyledSvg as={pending} />
      <H4> {t('pending.title')}</H4>
      <Text>
        <Trans i18nKey="pending.description" t={t}>
          <b />
          <A href={supportUrl} />
        </Trans>
      </Text>
    </Wrapper>
  )
}
