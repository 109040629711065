import {
  InputGroupAbsolute,
  Label,
  PasswordInput,
  PasswordInputGroupAddon,
  mediaQuery,
  CopyToClipboard as CopyToClipboardUI
} from '@ppay/client'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const InputWrap = styled.div`
  display: flex;
  ${mediaQuery.lessThan('sm')`
    flex-direction: column;
  `}
`
const CopyToClipboard = styled(CopyToClipboardUI)`
  margin-left: 30px;
  ${mediaQuery.lessThan('sm')`
    margin-left: 0;
  `}
`
const InteractiveElements = styled.div`
  ${mediaQuery.lessThan('sm')`
    margin-top: 8px;
  `}
`

export const KeyInput = ({
  label,
  value,
  visibleText,
  className,
  copyDisabled
}: {
  label: string
  value: string
  visibleText?: boolean
  className?: string
  copyDisabled?: boolean
}) => {
  const { t } = useTranslation('libCommon')
  const [textHidden, setTextHidden] = useState(!visibleText)

  return (
    <div className={className}>
      <Label>{label}</Label>
      <InputWrap>
        <InputGroupAbsolute>
          {!visibleText && (
            <PasswordInputGroupAddon
              addonType="append"
              passwordHidden={textHidden}
              togglePasswordHidden={() => {
                setTextHidden(!textHidden)
              }}
            />
          )}
          <PasswordInput passwordHidden={textHidden} value={value} readOnly />
        </InputGroupAbsolute>
        <InteractiveElements className="d-flex align-items-center">
          <CopyToClipboard
            disabled={copyDisabled}
            value={value}
            className="d-inline-flex align-items-center"
          >
            <span className="me-1">{t('copy')}</span>
          </CopyToClipboard>
        </InteractiveElements>
      </InputWrap>
    </div>
  )
}
