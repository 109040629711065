"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.totp = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _enable = require("./enable");
var _disable = require("./disable");
var _getKey = require("./getKey");
var totp = (0, _toolkit.combineReducers)({
  enable: _enable.enable,
  disable: _disable.disable,
  getKey: _getKey.getKey
});
exports.totp = totp;