"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mediaQuery = exports.breakpoints = void 0;
var _styledMediaQuery = require("styled-media-query");
var breakpoints = {
  xs: 375,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};
exports.breakpoints = breakpoints;
var mediaQuery = (0, _styledMediaQuery.generateMedia)({
  xxl: "".concat(breakpoints.xxl, "px"),
  xl: "".concat(breakpoints.xl, "px"),
  lg: "".concat(breakpoints.lg, "px"),
  md: "".concat(breakpoints.md, "px"),
  sm: "".concat(breakpoints.sm, "px"),
  xs: "".concat(breakpoints.xs, "px")
});
exports.mediaQuery = mediaQuery;