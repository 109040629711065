"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.send = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../../actions");
var initialState = {
  loading: false
};
var send = (0, _toolkit.createReducer)(initialState, function (builder) {
  builder.addCase(_actions.sendWithdraw2faKeyAction.pending, function (state) {
    state.loading = true;
  });
  builder.addCase(_actions.sendWithdraw2faKeyAction.rejected, function (state, action) {
    var _action$payload;
    state.loading = false;
    state.remainingTime = (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data.time;
  });
  builder.addCase(_actions.sendWithdraw2faKeyAction.fulfilled, function (state, action) {
    state.loading = false;
    state.remainingTime = action.payload.time;
  });
});
exports.send = send;