import { icons, Svg, SvgProps } from '@ppay/client'
import styled from 'styled-components'

export type IconNames = keyof typeof icons

export interface IconProps extends SvgProps {
  name: IconNames
}

export const Icon = styled(({ name, ...rest }: IconProps) => (
  <Svg as={icons[name] as any} {...rest} />
))``
