import { useEffect } from 'react'
import styled from 'styled-components'
import { Modal, ModalBody, ModalHeader } from '@ppay/client'

interface Props {
  acsUrl?: string
  handlePaymentSuccess: () => unknown
  handlePaymentError: () => unknown
  close: () => unknown
}

const IFrame = styled.iframe`
  width: 100%;
  min-height: 650px;
`

export const ThreeDSecureAuth = ({
  acsUrl,
  handlePaymentSuccess,
  handlePaymentError,
  close
}: Props) => {
  function postMessageListener(event: MessageEvent) {
    if (event.origin !== 'https://card2.any.money')
      return console.warn('Wrong origin', event.origin)

    const typeMessage = event.data.func
    const status = event.data?.args?.[0]
    if (typeMessage === 'finalPayment') {
      // check for final status only
      if (status === 'success') {
        handlePaymentSuccess()
      } else if (status === 'fail') {
        handlePaymentError()
      }
      removePostMessageListener()
    }
  }

  function initPostMessageListener() {
    window.addEventListener('message', postMessageListener)
  }

  function removePostMessageListener() {
    window.removeEventListener('message', postMessageListener)
  }

  useEffect(() => {
    initPostMessageListener()
    return () => {
      removePostMessageListener()
    }
  }, [])

  return (
    <Modal isOpen={!!acsUrl}>
      <ModalHeader toggle={close}>Please, pass 3D Secure authentication</ModalHeader>
      <ModalBody>
        <IFrame src={acsUrl} />
      </ModalBody>
    </Modal>
  )
}
