"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Progress = void 0;
var _reactstrap = require("reactstrap");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Progress = (0, _styledComponents.default)(_reactstrap.Progress)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 12px;\n  border-radius: 4px;\n  background-color: ", ";\n  .progress-bar {\n    background-color: ", " !important;\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondaryShade;
}, function (_ref2) {
  var theme = _ref2.theme,
    color = _ref2.color;
  return color ? theme.colors[color] : theme.colors.primary;
});
exports.Progress = Progress;