"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useOAuth = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useOAuth = function useOAuth() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.oAuth;
  });
  return {
    google: {
      authorizeLoading: state.google.authorize.loading,
      loginLoading: state.google.login.loading,
      loginDone: state.google.login.done,
      authorize: function authorize() {
        return dispatch((0, _actions.oAuthGoogleAuthorizeAction)());
      },
      login: function login(params) {
        return dispatch((0, _actions.oAuthGoogleLoginAction)(params));
      }
    }
  };
};
exports.useOAuth = useOAuth;