import { useEffect, useState } from 'react'
import { Ticker } from '@ppay/definitions'

const SHOW_STATS = 5

export const useMerchantPaymentsTickersStats = ({
  tickersStats
}: {
  tickersStats: Array<{ amount: string; convertedAmount: string; ticker: Ticker }>
}) => {
  const [shownStats, setShownStats] = useState<
    Array<{
      ticker: Ticker
      amount: number
      convertedAmount: number
    }>
  >([])
  const [otherStats, setOthersStats] = useState<number | undefined>()

  useEffect(() => {
    const shown = []
    let other

    let i = 0
    for (const { amount, ticker, convertedAmount } of tickersStats) {
      if (i < SHOW_STATS) {
        shown.push({ amount: Number(amount), convertedAmount: Number(convertedAmount), ticker })
      } else {
        other = Number(other ?? 0) + Number(convertedAmount)
      }
      setShownStats(shown)
      setOthersStats(other)
      i++
    }
  }, [tickersStats])

  return {
    shownStats,
    otherStats
  }
}
