import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Label,
  FormProps as FormPropsUI,
  FormGroup,
  InputField,
  AuthFormRow,
  AuthFormCol,
  SignUpCredentialsForm,
  emailValidation
} from '@ppay/client'

import { routes } from '../../router'
import { nameValidation } from '../../../validations'

export interface CredentialsFormProps extends FormPropsUI {
  onSubmit: (params: yup.InferType<typeof schema>) => unknown
}

const schema = yup
  .object()
  .shape({
    email: emailValidation().required(),
    name: nameValidation().required(),
    surname: nameValidation().required()
  })
  .required()

export const CredentialsForm = ({ onSubmit }: CredentialsFormProps) => {
  const { t } = useTranslation(['libCommon', 'libAuth', 'auth', 'common'])
  const { handleSubmit, control } = useForm<yup.InferType<typeof schema>>({
    mode: 'onTouched',
    resolver: yupResolver(schema)
  })

  return (
    <SignUpCredentialsForm onSubmit={handleSubmit(onSubmit)} loginPath={routes.auth.login}>
      <AuthFormRow>
        <AuthFormCol xs={12}>
          <FormGroup>
            <Label>{t('libCommon:email')}</Label>
            <InputField
              control={control}
              inputProps={{
                name: 'email',
                type: 'email',
                placeholder: t('libCommon:email')
              }}
            />
          </FormGroup>
        </AuthFormCol>
        <AuthFormCol xs={12} md={6}>
          <FormGroup>
            <Label>{t('auth:name')}</Label>
            <InputField
              control={control}
              inputProps={{
                name: 'name',
                placeholder: t('auth:name')
              }}
            />
          </FormGroup>
        </AuthFormCol>
        <AuthFormCol xs={12} md={6}>
          <FormGroup>
            <Label>{t('auth:lastName')}</Label>
            <InputField
              control={control}
              inputProps={{
                name: 'surname',
                placeholder: t('auth:lastName')
              }}
            />
          </FormGroup>
        </AuthFormCol>
      </AuthFormRow>
    </SignUpCredentialsForm>
  )
}
