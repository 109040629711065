import { createReducer } from '@reduxjs/toolkit'

import { getKYCFormUrlAction } from '../actions'

type State = {
  loading: boolean
  data?: string
}

const initialState: State = {
  loading: false
}

export const kyc = createReducer(initialState, (builder) => {
  builder
    .addCase(getKYCFormUrlAction.pending, (state) => {
      return { ...state, loading: true }
    })
    .addCase(getKYCFormUrlAction.rejected, (state) => {
      state.loading = false
    })
    .addCase(getKYCFormUrlAction.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
})
