"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createUserGetWithAddons = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _lodash = _interopRequireDefault(require("lodash.merge"));
var _actions = require("../../actions");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var initialState = {
  loading: false
};
var createUserGetWithAddons = function createUserGetWithAddons(addon) {
  return (0, _toolkit.createReducer)(initialState, function (builder) {
    builder.addCase(_actions.fetchUserAction.pending, function (state) {
      state.loading = true;
    }).addCase(_actions.fetchUserAction.rejected, function (state) {
      state.loading = false;
    }).addCase(_actions.fetchUserAction.fulfilled, function (state, action) {
      state.loading = false;
      state.data = action.payload;
    }).addCase(_actions.updatePersonalAction.fulfilled, function (state, action) {
      state.data = (0, _lodash.default)(state.data, {
        personal: action.meta.arg
      });
    }).addCase(_actions.updateEmailAction.fulfilled, function (state, action) {
      state.data.credentials.email = action.meta.arg;
    }).addCase(_actions.enableEmail2faAction.fulfilled, function (state) {
      state.data.credentials.email2faEnabled = true;
    }).addCase(_actions.disableEmail2faAction.fulfilled, function (state) {
      state.data.credentials.email2faEnabled = false;
    }).addCase(_actions.enableTotpAction.fulfilled, function (state) {
      state.data.credentials.totpEnabled = true;
    }).addCase(_actions.disableTotpAction.fulfilled, function (state) {
      state.data.credentials.totpEnabled = false;
    }).addCase(_actions.enableWithdraw2faAction.fulfilled, function (state) {
      state.data.credentials.withdraw2faEnabled = true;
    }).addCase(_actions.disableWithdraw2faAction.fulfilled, function (state) {
      state.data.credentials.withdraw2faEnabled = false;
    }).addCase(_actions.updatePasswordAction.fulfilled, function (state) {
      state.data.credentials.passwordExists = true;
    });
    addon(builder);
  });
};
exports.createUserGetWithAddons = createUserGetWithAddons;