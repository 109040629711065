import { FiatReplenishGatewayProvider } from '@ppay/definitions'

import { AnyMoneyForm } from './forms/AnyMoneyForm'
import { PaySoftForm } from './forms/PaysoftForm'
import { ManualForm } from './forms/ManualForm'
import { InvoiceProps } from './Component'

export const Pay = (props: InvoiceProps) => {
  if (props.invoice.paymentProvider === FiatReplenishGatewayProvider.ANYMONEY)
    return <AnyMoneyForm {...props} />
  if (props.invoice.paymentProvider === FiatReplenishGatewayProvider.PAYSOFT)
    return <PaySoftForm {...props} />
  if (props.invoice.paymentProvider === FiatReplenishGatewayProvider.MANUAL)
    return <ManualForm {...props} />
  return null
}
