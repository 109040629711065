"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Control = void 0;
var _reactSelect = require("react-select");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Control = (0, _styledComponents.default)(_reactSelect.components.Control)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  box-shadow: none !important;\n  border: none !important;\n"])));
exports.Control = Control;