import { breakpoints, TabsNav as TabsNavLib, TabsNavItem, TabsNavLink } from '@ppay/client'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { Icon } from '../../../ui'

export const SettingsTabs = {
  PAYMENT_METHODS: 'paymentMethods',
  API_KEYS: 'apiKeys',
  NOTIFICATIONS: 'notifications'
}

export const TabsNav = ({
  activeTab,
  onTabChange
}: {
  activeTab: string
  onTabChange: (at: string) => void
}) => {
  const { t } = useTranslation('merchant')
  const isSm = useMediaQuery({ maxWidth: breakpoints.md })

  return (
    <>
      <TabsNavLib column={isSm} className="w-100">
        <TabsNavItem>
          <TabsNavLink
            onClick={() => onTabChange(SettingsTabs.PAYMENT_METHODS)}
            active={activeTab === SettingsTabs.PAYMENT_METHODS}
          >
            <>
              <Icon name="paymentMethod" />
              {t('paymentMethods')}
            </>
          </TabsNavLink>
        </TabsNavItem>
        <TabsNavItem>
          <TabsNavLink
            onClick={() => onTabChange(SettingsTabs.API_KEYS)}
            active={activeTab === SettingsTabs.API_KEYS}
          >
            <>
              <Icon name="key" />
              {t('apiKeys')}
            </>
          </TabsNavLink>
        </TabsNavItem>
        <TabsNavItem>
          <TabsNavLink
            onClick={() => onTabChange(SettingsTabs.NOTIFICATIONS)}
            active={activeTab === SettingsTabs.NOTIFICATIONS}
          >
            <>
              <Icon name="notification" />
              {t('notifications')}
            </>
          </TabsNavLink>
        </TabsNavItem>
      </TabsNavLib>
    </>
  )
}
