import { useCreateWithdraw } from '@ppay/client'

import { createMerchantWithdrawAction } from '../../actions'

export const useCreateMerchantWithdraw = (merchantId: string) => {
  const { createWithdraw, ...other } = useCreateWithdraw({
    action: createMerchantWithdrawAction,
    statePath: 'merchants.transactions.createWithdraw'
  })
  return {
    ...other,
    createWithdraw: (
      params: Omit<Parameters<typeof createMerchantWithdrawAction>[0], 'merchantId'>
    ) => createWithdraw({ ...params, merchantId })
  }
}
