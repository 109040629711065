import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link, H2, SmallMedium as SmallMediumUI } from '@ppay/client'
import { Ticker } from '@ppay/definitions'

import { FiatInvoice } from '../../../interfaces'
import {
  Card,
  CardAmountToPay as CardAmountToPayUI,
  CardData,
  CardSeparativeLine,
  CardPaidAmount,
  CardBackToWebsiteButton
} from '../card'
import { Icon } from '../../ui'

const Wrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Icon} {
    margin-right: 24px;
  }
  ${H2} {
    margin-bottom: 0;
  }
`

const SmallMedium = styled(SmallMediumUI)`
  text-align: center;
  margin-bottom: 20px;
`

const CardAmountToPay = styled(CardAmountToPayUI)`
  margin-bottom: 20px;
`

export const TimerExpired = ({ invoice }: { invoice: FiatInvoice }) => {
  const { t } = useTranslation('invoice')

  return (
    <Card id={invoice.id} name={invoice.name} description={invoice.description}>
      {invoice.company && (
        <CardData
          title={t('company')}
          value={
            <Link target="_blank" href={invoice.company.url}>
              {invoice.company.name}
            </Link>
          }
        />
      )}
      <CardSeparativeLine />

      <Wrapper>
        <Icon name="alarmClock" />
        <H2>{t('timerExpired')}</H2>
      </Wrapper>

      <SmallMedium>{t('timerExpiredDescription')}</SmallMedium>

      {invoice.amount?.paid && (
        <>
          <CardAmountToPay
            amount={invoice.amount!.value}
            ticker={invoice.amount!.ticker}
            text={t('amount')}
          />
          <CardPaidAmount
            amount={invoice.amount!.value}
            paidAmount={invoice.amount!.paid}
            ticker={invoice.amount!.ticker as Ticker}
          />
        </>
      )}

      {invoice.redirectUrl && <CardBackToWebsiteButton redirectUrl={invoice.redirectUrl} />}
    </Card>
  )
}
