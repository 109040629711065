import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { CryptoTicker, Blockchain } from '@ppay/definitions'

import { getFeeValueString } from '../helpers'
import { FeeValue } from '../../../../../../interfaces/merchant/Fees'

export interface CryptoTickerFees {
  ticker: CryptoTicker
  fees: Partial<Record<Blockchain, FeeValue | null>>
}

const List = styled.ul`
  padding: 0;
`

const ListItem = styled.li`
  list-style: none;
`

export const TableValue = ({ fees, ticker }: CryptoTickerFees) => {
  const { t } = useTranslation('libTransfers')

  return (
    <List>
      {Object.entries(fees).map(([blockchain, fee]) => (
        <ListItem>
          {t(`blockchains.${blockchain}`)}: {fee ? getFeeValueString(ticker, fee) : '0'}
        </ListItem>
      ))}
    </List>
  )
}
