import { Small, H2 } from '@ppay/client'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Back } from './Back'

const Wrapper = styled.div`
  margin-bottom: 30px;
`

const Id = styled(Small)`
  color: ${({ theme }) => theme.colors.tertiaryShade};
  margin-bottom: 10px;
  display: block;
`

const Name = styled(H2)`
  margin: 0;
  color: ${({ theme }) => theme.colors.tertiary};
`

const Description = styled(Small)`
  margin-top: 10px;
  display: block;
  color: ${({ theme }) => theme.colors.tertiary};
`

export const Header = ({
  id,
  name,
  description,
  back
}: {
  id: string
  name: string
  description?: string
  back?: () => void
}) => {
  const { t } = useTranslation('invoice')

  return (
    <Wrapper>
      {back && <Back onClick={back}>{t('back')}</Back>}
      <Id>
        {t('id')}: #{id}
      </Id>
      <Name>{name}</Name>
      {description && <Description>{description}</Description>}
    </Wrapper>
  )
}
