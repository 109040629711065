import { createReducer } from '@reduxjs/toolkit'
import { MerchantPaymentsStats } from '../../../interfaces'

import { getMerchantPaymentsStatsAction } from '../../actions'

type State = {
  loading: boolean
  data?: MerchantPaymentsStats[]
}

const initialState: State = {
  loading: false
}

export const getPaymentsStats = createReducer(initialState, (builder) => {
  builder.addCase(getMerchantPaymentsStatsAction.pending, (action) => {
    return { loading: true }
  })
  builder.addCase(getMerchantPaymentsStatsAction.rejected, (state) => {
    state.loading = false
  })
  builder.addCase(getMerchantPaymentsStatsAction.fulfilled, (state, action) => {
    state.loading = false
    state.data = action.payload
  })
})
