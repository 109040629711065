"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  AuthLayout: true,
  AuthLayoutBodyCard: true,
  AuthLayoutTitle: true,
  AuthLayoutBackTitle: true,
  AuthLayoutBodyWizardControl: true,
  AuthLayoutHeader: true,
  PasswordForm: true,
  TermsFields: true,
  AuthFormButton: true,
  AuthFormCol: true,
  AuthFormRow: true,
  AuthFormLink: true,
  AuthVerifyCodeFromMailForm: true
};
Object.defineProperty(exports, "AuthFormButton", {
  enumerable: true,
  get: function get() {
    return _form.FormButton;
  }
});
Object.defineProperty(exports, "AuthFormCol", {
  enumerable: true,
  get: function get() {
    return _form.FormCol;
  }
});
Object.defineProperty(exports, "AuthFormLink", {
  enumerable: true,
  get: function get() {
    return _form.FormLink;
  }
});
Object.defineProperty(exports, "AuthFormRow", {
  enumerable: true,
  get: function get() {
    return _form.FormRow;
  }
});
Object.defineProperty(exports, "AuthLayout", {
  enumerable: true,
  get: function get() {
    return _layout.Layout;
  }
});
Object.defineProperty(exports, "AuthLayoutBackTitle", {
  enumerable: true,
  get: function get() {
    return _layout.LayoutBackTitle;
  }
});
Object.defineProperty(exports, "AuthLayoutBodyCard", {
  enumerable: true,
  get: function get() {
    return _layout.LayoutBodyCard;
  }
});
Object.defineProperty(exports, "AuthLayoutBodyWizardControl", {
  enumerable: true,
  get: function get() {
    return _layout.LayoutBodyWizardControl;
  }
});
Object.defineProperty(exports, "AuthLayoutHeader", {
  enumerable: true,
  get: function get() {
    return _layout.LayoutHeader;
  }
});
Object.defineProperty(exports, "AuthLayoutTitle", {
  enumerable: true,
  get: function get() {
    return _layout.LayoutTitle;
  }
});
Object.defineProperty(exports, "AuthVerifyCodeFromMailForm", {
  enumerable: true,
  get: function get() {
    return _verifyCodeFromMail.VerifyCodeFromMailForm;
  }
});
Object.defineProperty(exports, "PasswordForm", {
  enumerable: true,
  get: function get() {
    return _form.PasswordForm;
  }
});
Object.defineProperty(exports, "TermsFields", {
  enumerable: true,
  get: function get() {
    return _form.TermsFields;
  }
});
var _layout = require("./layout");
var _TwoFactor = require("./TwoFactor");
Object.keys(_TwoFactor).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _TwoFactor[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _TwoFactor[key];
    }
  });
});
var _login = require("./login");
Object.keys(_login).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _login[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _login[key];
    }
  });
});
var _restorePassword = require("./restore-password");
Object.keys(_restorePassword).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _restorePassword[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _restorePassword[key];
    }
  });
});
var _signUp = require("./sign-up");
Object.keys(_signUp).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _signUp[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _signUp[key];
    }
  });
});
var _oAuth = require("./oAuth");
Object.keys(_oAuth).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _oAuth[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _oAuth[key];
    }
  });
});
var _confirmEmail = require("./confirm-email");
Object.keys(_confirmEmail).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _confirmEmail[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _confirmEmail[key];
    }
  });
});
var _providePersonal = require("./provide-personal");
Object.keys(_providePersonal).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _providePersonal[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _providePersonal[key];
    }
  });
});
var _form = require("./form");
var _verifyCodeFromMail = require("./verify-code-from-mail");