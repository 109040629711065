import {
  DashboardTransfersWithdrawTransactionInfoAmountItem as TransactionInfoAmountItem,
  useWithdrawContext,
  Balance
} from '@ppay/client'
import Decimal from 'decimal.js'
import { useTranslation } from 'react-i18next'

export const TransactionInfoToBalanceAfter = ({
  balanceName,
  ...props
}: {
  balanceName: string
  balances?: Array<Pick<Balance, 'converted' | 'value' | 'ticker'>>
}) => {
  const { t } = useTranslation(['libTransfers', 'libCommon'])
  const { ticker, fiatTicker, amount, fiatTickerAmount, balance, fiatTickerBalance } =
    useWithdrawContext()

  const propsBalance = props.balances?.find((b) => b.ticker === ticker)

  const value = new Decimal(propsBalance?.value ?? balance).add(amount).toString()
  const fiatValue = new Decimal(propsBalance?.converted ?? fiatTickerBalance)
    .add(fiatTickerAmount)
    .toString()

  return (
    <TransactionInfoAmountItem
      title={`${balanceName} ${t('libCommon:balance').toLowerCase()} ${t('after')}:`}
      ticker={ticker}
      fiatTicker={fiatTicker}
      amount={value}
      fiatTickerAmount={fiatValue}
    />
  )
}
