"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createUserWithAddons = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _get = require("./get");
var _walletAddress = require("./walletAddress");
var _updatePersonal = require("./updatePersonal");
var _updatePassword = require("./updatePassword");
var _updateEmail = require("./updateEmail");
var _email2fa = require("./email2fa");
var _withdraw2fa = require("./withdraw2fa");
var _totp = require("./totp");
var _requirements = require("./requirements");
var createUserWithAddons = function createUserWithAddons(userAddon) {
  return (0, _toolkit.combineReducers)({
    get: (0, _get.createUserGetWithAddons)(userAddon.get),
    walletAddress: _walletAddress.walletAddress,
    updatePersonal: _updatePersonal.updatePersonal,
    updatePassword: _updatePassword.updatePassword,
    updateEmail: _updateEmail.updateEmail,
    email2fa: _email2fa.email2fa,
    withdraw2fa: _withdraw2fa.withdraw2fa,
    totp: _totp.totp,
    requirements: _requirements.requirements
  });
};
exports.createUserWithAddons = createUserWithAddons;