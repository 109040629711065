"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "StatusIcon", {
  enumerable: true,
  get: function get() {
    return _Icon.Icon;
  }
});
Object.defineProperty(exports, "StatusTitle", {
  enumerable: true,
  get: function get() {
    return _Title.Title;
  }
});
var _Icon = require("./Icon");
var _Title = require("./Title");