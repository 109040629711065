import { fetch } from '@ppay/client'
import { Ticker } from '@ppay/definitions'

export const getFormUrl = async (dto: {
  redirectUrl?: string
  ticker: Ticker
  requirementsId: string
}): Promise<string> => {
  return fetch({
    url: `/kyc/form`,
    params: dto
  })
}
