import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  Link,
  Label,
  CountdownTime,
  Progress as ProgressUI,
  mediaQuery,
  breakpoints,
  Small
} from '@ppay/client'
import { ManualReplenishOrderStatus } from '@ppay/definitions'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { useMediaQuery } from 'react-responsive'

import { Card, CardSeparativeLine, CardData, CardAmountToPay } from '../../../card'
import { useManualReplenishOrder } from '../../../../../state'
import { NoOrder } from './NoOrder'
import { QueuedOrder } from './QueuedOrder'
import { CreatedOrder } from './CreatedOrder'
import { InvoiceProps } from '../../Component'

const CardDataWithMargin = styled(CardData)`
  margin-bottom: 20px;
`
const CountDownWrapper = styled.div`
  margin-top: 20px;
`

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  ${Small} {
    margin-left: 10px;
  }
`

const Progress = styled(ProgressUI)`
  display: flex;
  flex-grow: 1;
  ${mediaQuery.lessThan('sm')`
    width: 100%;
  `}
`

const Info = styled(Small)`
  color: ${({ theme }) => theme.colors.tertiaryShade};
`

const renderer = ({ days, hours, minutes, seconds }: CountdownRenderProps) => (
  <Small>
    <CountdownTime days={days} hours={hours} minutes={minutes} seconds={seconds} />
  </Small>
)

export const ManualForm = ({ invoice, children, hideButton }: InvoiceProps) => {
  const { t } = useTranslation('invoice')
  const isSm = useMediaQuery({ maxWidth: breakpoints.sm })
  const { getLoading, queueLoading, order, getOrder, queueOrder } = useManualReplenishOrder()

  const [progress, setProgress] = useState(0)
  const onTick = () => {
    if (invoice?.createdAt && invoice?.expiredAt)
      setProgress((Date.now() - invoice?.createdAt) / (invoice?.expiredAt - invoice?.createdAt))
  }

  useEffect(() => {
    if (order) {
      const widget = (window as any).fcWidget
      if (widget) {
        widget.show()
        widget.user.setEmail(invoice.email)
        widget.user.setProperties({
          cf_card_number: order.card.number,
          cf_order_id: order.id,
          cf_sum_insured: invoice.amount!.value,
          cf_order_status: order.status
        })
      }
    }

    if (!order) getOrder(invoice.id)
  }, [order])

  return (
    <Card id={invoice.id} name={invoice.name} description={invoice.description} isFiat={true}>
      {invoice.company && (
        <CardDataWithMargin
          title={t('company')}
          value={
            <Link target="_blank" href={invoice.company.url}>
              {invoice.company.name}
            </Link>
          }
        />
      )}
      <CardAmountToPay
        amount={invoice.amount!.value}
        ticker={invoice.amount!.ticker}
        text={t('amountToPay')}
      />
      {invoice.expiredAt && (
        <CountDownWrapper>
          <Label>
            {t('timeRemaining')}:{' '}
            {invoice.expiredAt && isSm && (
              <Countdown date={invoice.expiredAt} renderer={renderer} onTick={onTick} />
            )}
          </Label>
          <ProgressWrapper>
            <Progress value={progress * 100} color="success" />
            {invoice.expiredAt && !isSm && (
              <Countdown date={invoice.expiredAt} renderer={renderer} onTick={onTick} />
            )}
          </ProgressWrapper>
          <Info>{t('closeAfterPay')}</Info>
        </CountDownWrapper>
      )}
      <CardSeparativeLine />
      {children && hideButton ? (
        children
      ) : !order ? (
        <NoOrder loading={getLoading} get={() => getOrder(invoice.id)} />
      ) : order.status === ManualReplenishOrderStatus.QUEUED ? (
        <QueuedOrder id={order.id} />
      ) : (
        <CreatedOrder
          order={order}
          loading={queueLoading}
          queue={() => queueOrder(invoice.id)}
          ticker={invoice.amount!.ticker}
          amount={invoice.amount!.value}
        />
      )}
    </Card>
  )
}
