"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BodyContainer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _ui = require("../ui");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var BodyContainer = (0, _styledComponents.default)(_ui.Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-bottom: 80px;\n"])));
exports.BodyContainer = BodyContainer;