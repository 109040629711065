import { getManualReplenishOrderAction, queueManualReplenishOrderAction } from '../../actions'
import { useSelector } from '../useSelector'
import { useDispatch } from '../useDispatch'
import { ManualReplenishOrderStatus } from '@ppay/definitions'

export const useManualReplenishOrder = () => {
  const state = useSelector((state) => state.manualReplenishOrder)
  const dispatch = useDispatch()

  return {
    order: state.data,
    getLoading: state.getLoading,
    queueLoading: state.queueLoading,
    getOrder: (id: string) => dispatch(getManualReplenishOrderAction(id)),
    queueOrder: (id: string) =>
      state.data?.status !== ManualReplenishOrderStatus.QUEUED &&
      dispatch(queueManualReplenishOrderAction(id))
  }
}
