"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _login = require("./login");
Object.keys(_login).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _login[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _login[key];
    }
  });
});
var _restorePassword = require("./restorePassword");
Object.keys(_restorePassword).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _restorePassword[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _restorePassword[key];
    }
  });
});
var _signUp = require("./signUp");
Object.keys(_signUp).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _signUp[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _signUp[key];
    }
  });
});
var _email = require("./email");
Object.keys(_email).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _email[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _email[key];
    }
  });
});
var _emailAuth = require("./emailAuth");
Object.keys(_emailAuth).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _emailAuth[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _emailAuth[key];
    }
  });
});