import { Ticker } from '@ppay/definitions'
import { P, H3, mediaQuery, TickerNumberFormat, NumberFormatRounding } from '@ppay/client'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryShade};
  color: ${({ theme }) => theme.colors.tertiary};
  border-radius: 6px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaQuery.lessThan('xs')`
    flex-direction: column;
  `}
`

const Title = styled(P)`
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.tertiaryShade};
`

const Currency = styled(H3)`
  font-size: 28px;
  margin-bottom: 0;
`

export const AmountToPay = ({
  amount,
  ticker,
  text,
  className,
  children
}: PropsWithChildren<{
  amount: string
  ticker: Ticker
  text: string
  className?: string
}>) => {
  return (
    <Wrapper className={className}>
      <Title>{text}: </Title>
      <Currency>
        <TickerNumberFormat rounding={NumberFormatRounding.UP} value={amount} ticker={ticker} />{' '}
        {ticker}
      </Currency>
      {children}
    </Wrapper>
  )
}
