import { DashboardLayoutHeader } from '@ppay/client'

import { Nav } from './Nav'
import { routes } from '../../../router'
import { UserAccount } from './userAccount'

interface HeaderProps {
  logo: string
  onLogoClick: () => unknown
}

export const Header = (props: HeaderProps) => (
  <DashboardLayoutHeader
    navComponent={Nav}
    userAccountDropdownToggleInnerComponent={UserAccount}
    settingsPath={routes.dashboard.settings}
    sendPath={routes.dashboard.withdraw}
    receivePath={routes.dashboard.replenish}
    walletsPath={routes.dashboard.wallets}
    transactionsPath={routes.dashboard.transactions}
    {...props}
  />
)
