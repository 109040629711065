import {
  Awaited,
  WithdrawFeeLessThanRequiredError,
  ExchangeNotEnoughLiquidityError
} from '@ppay/client'
import { Ticker } from '@ppay/definitions'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { MerchantsApi, MerchantNotExistError } from '../../api'
import { Merchant } from '../../interfaces'

export const getMerchantAction = createAsyncThunk<
  Merchant,
  string,
  { rejectValue: { error: MerchantNotExistError; withoutNotification: boolean } }
>('merchants/getOne', async (id: string, { rejectWithValue }) => {
  try {
    return await MerchantsApi.getOne(id)
  } catch (e) {
    if (e instanceof MerchantNotExistError)
      return rejectWithValue({ error: e, withoutNotification: true })
    throw e
  }
})

export const updateMerchantNotificationsUrl = createAsyncThunk(
  'merchants/updateNotificationsUrl',
  async ({ id, notificationsUrl }: { id: string; notificationsUrl: string }) => {
    const response = await MerchantsApi.updateNotificationsUrl(id, notificationsUrl)
    return response
  }
)

export const createMerchantAction = createAsyncThunk(
  'merchants/create',
  async (...params: Parameters<typeof MerchantsApi.create>) => {
    const response = await MerchantsApi.create(...params)
    return response
  }
)

export const getMerchantsAction = createAsyncThunk('/merchants/get', MerchantsApi.get)

export const createMerchantWithdrawAction = createAsyncThunk<
  void,
  Parameters<typeof MerchantsApi.createWithdraw>[0],
  { rejectValue: WithdrawFeeLessThanRequiredError }
>('merchants/createWithdraw', async (params, { rejectWithValue }) => {
  try {
    await MerchantsApi.createWithdraw(params)
  } catch (e) {
    if (e instanceof WithdrawFeeLessThanRequiredError) return rejectWithValue(e)
    throw e
  }
})

export const createMerchantFiatWithdrawAction = createAsyncThunk<
  void,
  Parameters<typeof MerchantsApi.createFiatWithdraw>[0],
  { rejectValue: WithdrawFeeLessThanRequiredError }
>('merchants/createFiatWithdraw', async (params, { rejectWithValue }) => {
  try {
    await MerchantsApi.createFiatWithdraw(params)
  } catch (e) {
    if (e instanceof WithdrawFeeLessThanRequiredError) return rejectWithValue(e)
    throw e
  }
})

export const getMerchantWithdrawFeeAction = createAsyncThunk(
  'merchants/getWithdrawFee',
  async (params: Parameters<typeof MerchantsApi.getWithdrawFee>[0]) => {
    const response = await MerchantsApi.getWithdrawFee(params)
    return response
  }
)

export const getMerchantFiatWithdrawFeeAction = createAsyncThunk(
  'merchants/getFiatWithdrawFee',
  async (params: Parameters<typeof MerchantsApi.getFiatWithdrawFee>[0]) => {
    const response = await MerchantsApi.getFiatWithdrawFee(params)
    return response
  }
)

export const createMerchantCryptoExchangeAction = createAsyncThunk<
  void,
  Parameters<typeof MerchantsApi.createCryptoExchange>[0],
  { rejectValue: WithdrawFeeLessThanRequiredError }
>('merchants/createCryptoExchange', async (params, { rejectWithValue }) => {
  try {
    await MerchantsApi.createCryptoExchange(params)
  } catch (e) {
    if (e instanceof WithdrawFeeLessThanRequiredError) return rejectWithValue(e)
    throw e
  }
})

export const createMerchantFiatExchangeAction = createAsyncThunk<
  void,
  Parameters<typeof MerchantsApi.createFiatExchange>[0],
  { rejectValue: WithdrawFeeLessThanRequiredError }
>('merchants/createFiatExchange', async (params, { rejectWithValue }) => {
  try {
    await MerchantsApi.createFiatExchange(params)
  } catch (e) {
    if (e instanceof WithdrawFeeLessThanRequiredError) return rejectWithValue(e)
    throw e
  }
})

export const getMerchantCryptoExchangeInfoAction = createAsyncThunk<
  Awaited<ReturnType<typeof MerchantsApi.getCryptoExchangeInfo>>,
  Parameters<typeof MerchantsApi.getCryptoExchangeInfo>[0],
  { rejectValue: { error: ExchangeNotEnoughLiquidityError; withoutNotification: boolean } }
>(
  'merchants/getCryptoInfo',
  async (params: Parameters<typeof MerchantsApi.getCryptoExchangeInfo>[0], { rejectWithValue }) => {
    try {
      const response = await MerchantsApi.getCryptoExchangeInfo(params)
      return response
    } catch (e) {
      if (e instanceof ExchangeNotEnoughLiquidityError)
        return rejectWithValue({ error: e, withoutNotification: true })
      throw e
    }
  }
)

export const getMerchantFiatExchangeInfoAction = createAsyncThunk<
  Awaited<ReturnType<typeof MerchantsApi.getFiatExchangeInfo>>,
  Parameters<typeof MerchantsApi.getFiatExchangeInfo>[0],
  { rejectValue: { error: ExchangeNotEnoughLiquidityError; withoutNotification: boolean } }
>(
  'merchants/getFiatInfo',
  async (params: Parameters<typeof MerchantsApi.getFiatExchangeInfo>[0], { rejectWithValue }) => {
    try {
      const response = await MerchantsApi.getFiatExchangeInfo(params)
      return response
    } catch (e) {
      if (e instanceof ExchangeNotEnoughLiquidityError)
        return rejectWithValue({ error: e, withoutNotification: true })
      throw e
    }
  }
)

export const createMerchantTransferAction = createAsyncThunk(
  '/merchants/createTransfer',
  async ({
    from,
    ...params
  }: Parameters<typeof MerchantsApi.createTransferFromPersonal>[0] & { from?: boolean }) => {
    const response = await MerchantsApi[
      from ? 'createTransferFromPersonal' : 'createTransferToPersonal'
    ](params)
    return response
  }
)

export const getMerchantTransactionsAction = createAsyncThunk(
  '/merchants/getTransactions',
  async (params: Parameters<typeof MerchantsApi.getTransactions>[0]) => {
    const response = await MerchantsApi.getTransactions(params)
    return response
  }
)

export const getTotalMerchantBalanceAction = createAsyncThunk(
  'merchants/getTotalBalance',
  async (merchantId: string) => {
    const response = await MerchantsApi.getTotalBalance(merchantId)
    return response
  },
  {
    condition: (merchantId, { getState }) => {
      const state = (getState() as any).merchants.getTotalBalance
      if (state.loadingById === merchantId || (state.data && state.merchantId === merchantId))
        return false
      return true
    }
  }
)

export const getMerchantBalancesAction = createAsyncThunk(
  'merchants/getBalances',
  async (merchantId: string) => {
    const response = await MerchantsApi.getBalances(merchantId)
    return response
  },
  {
    condition: (merchantId, { getState }) => {
      const state = (getState() as any).merchants.getBalances
      if (state.loadingById === merchantId || (state.data && state.merchantId === merchantId))
        return false
      return true
    }
  }
)

export const recalculateMerchantBalanceAction = createAction(
  'merchants/recalculateBalance',
  ({
    value,
    ticker,
    convertedValue,
    increase,
    merchantId
  }: {
    value: string
    ticker: Ticker
    convertedValue: string
    increase?: boolean
    merchantId: string
  }) => ({
    payload: { value, ticker, convertedValue, increase, merchantId }
  })
)

export const resetCreateMerchantAction = createAction('merchants/create/reset')

export const resetCreateMerchantTransferAction = createAction('/merchants/createTransfer/reset')

export const resetMerchantAction = createAction('merchants/merchant/reset')

export const resetMerchantsAction = createAction('merchants/reset')

export const resetMerchantGetExchangeCryptoInfoAction = createAction(
  'merchants/getExchangeCryptoInfo/reset'
)

export const resetMerchantGetExchangeFiatInfoAction = createAction(
  'merchants/getExchangeFiatInfo/reset'
)

export const resetGetMerchantWithdrawFeeAction = createAction('merchants/resetGetWithdrawFee')

export const resetGetMerchantFiatWithdrawFeeAction = createAction(
  'merchants/resetGetFiatWithdrawFee'
)

export const getMerchantTransactionsCSVAction = createAsyncThunk(
  '/merchants/getTransactionsCSV',
  async (params: Parameters<typeof MerchantsApi.getTransactionsCSV>[0]) => {
    const response = await MerchantsApi.getTransactionsCSV(params)
    return response
  }
)

export const getMerchantPaymentsStatsAction = createAsyncThunk(
  '/merchants/getPaymentsStats',
  async (params: Parameters<typeof MerchantsApi.getPaymentsStats>[0]) => {
    const response = await MerchantsApi.getPaymentsStats(params)
    return response
  }
)
