"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AmountTableData", {
  enumerable: true,
  get: function get() {
    return _Amount.Amount;
  }
});
var _Amount = require("./Amount");