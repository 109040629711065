"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTotpKey = exports.useEnableTotp = exports.useDisableTotp = void 0;
var _reactRedux = require("react-redux");
var _useSelector = require("../useSelector");
var _actions = require("../../actions");
var useTotpKey = function useTotpKey() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.user.totp.getKey;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    getTotpKeyState: state,
    getTotpKey: function getTotpKey() {
      return dispatch((0, _actions.getTotpKeyAction)());
    },
    resetTotpKey: function resetTotpKey() {
      return dispatch((0, _actions.resetGetTotpKeyAction)());
    }
  };
};
exports.useTotpKey = useTotpKey;
var useEnableTotp = function useEnableTotp() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.user.totp.enable;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    enableTotpState: state,
    enableTotp: function enableTotp() {
      return dispatch(_actions.enableTotpAction.apply(void 0, arguments));
    },
    resetEnableTotp: function resetEnableTotp() {
      return dispatch((0, _actions.resetEnableTotpAction)());
    }
  };
};
exports.useEnableTotp = useEnableTotp;
var useDisableTotp = function useDisableTotp() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.user.totp.disable;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    disableTotpState: state,
    disableTotp: function disableTotp(token) {
      return dispatch((0, _actions.disableTotpAction)(token));
    },
    resetDisableTotp: function resetDisableTotp() {
      return dispatch((0, _actions.resetDisableTotpAction)());
    }
  };
};
exports.useDisableTotp = useDisableTotp;