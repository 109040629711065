"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCryptoPage = void 0;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var _definitions = require("@ppay/definitions");
var _state = require("../../state");
var _hooks = require("./../../hooks");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var useCryptoPage = function useCryptoPage() {
  var _location$state, _balance$value, _balance$converted;
  var _useBalances = (0, _state.useBalances)(),
    balances = _useBalances.balances,
    balancesLoading = _useBalances.balancesLoading,
    getBalanceByTicker = _useBalances.getBalanceByTicker;
  var _useGetWalletAddress = (0, _state.useGetWalletAddress)(),
    loadingByBlockchain = _useGetWalletAddress.loadingByBlockchain,
    addresses = _useGetWalletAddress.addresses,
    getWalletAddress = _useGetWalletAddress.getWalletAddress,
    resetGetWalletAddress = _useGetWalletAddress.resetGetWalletAddress;
  var _useCreateWalletAddre = (0, _state.useCreateWalletAddress)(),
    createWalletAddress = _useCreateWalletAddre.createWalletAddress,
    createLoadingByBlockchain = _useCreateWalletAddre.loadingByBlockchain;
  var location = (0, _reactRouterDom.useLocation)();
  var locationTicker = (_location$state = location.state) === null || _location$state === void 0 ? void 0 : _location$state.ticker;
  var isFiat = _definitions.FIAT_TICKERS.includes(locationTicker);
  var _useState = (0, _react.useState)(isFiat ? _definitions.Ticker.BTC : locationTicker !== null && locationTicker !== void 0 ? locationTicker : _definitions.Ticker.BTC),
    _useState2 = _slicedToArray(_useState, 2),
    ticker = _useState2[0],
    setTicker = _useState2[1];
  var _useBlockchainByTicke = (0, _hooks.useBlockchainByTicker)(ticker),
    blockchain = _useBlockchainByTicke.blockchain,
    blockchains = _useBlockchainByTicke.blockchains,
    setBlockchain = _useBlockchainByTicke.setBlockchain;
  var balance = getBalanceByTicker(ticker);
  var address = addresses[blockchain];
  var loading = balancesLoading || loadingByBlockchain === blockchain || ticker === locationTicker && !address;
  (0, _react.useEffect)(function () {
    return function () {
      resetGetWalletAddress();
    };
  }, []);

  // get wallet address on blockchain change
  (0, _react.useEffect)(function () {
    if (!address) {
      getWalletAddress(blockchain);
    }
  }, [blockchain]);

  // create wallet address if page loaded with location ticker and address not created yet
  (0, _react.useEffect)(function () {
    var shouldCreateAddress = address === null || address === '';
    if (ticker === locationTicker && !loadingByBlockchain && shouldCreateAddress) {
      createWalletAddress(blockchain);
    }
  }, [ticker, locationTicker, address, blockchain]);
  return {
    address: address !== null && address !== void 0 ? address : '',
    ticker: ticker,
    loading: loading,
    setTicker: setTicker,
    setBlockchain: setBlockchain,
    onReveal: function onReveal() {
      return createWalletAddress(blockchain);
    },
    blockchain: blockchain,
    blockchains: blockchains,
    balance: (_balance$value = balance === null || balance === void 0 ? void 0 : balance.value) !== null && _balance$value !== void 0 ? _balance$value : '0',
    balances: balances,
    convertedBalance: (_balance$converted = balance === null || balance === void 0 ? void 0 : balance.converted) !== null && _balance$converted !== void 0 ? _balance$converted : '0',
    revealDisabled: createLoadingByBlockchain === blockchain || !!address,
    tickers: _definitions.CRYPTO_TICKERS,
    convertedTicker: _definitions.Ticker.USD
  };
};
exports.useCryptoPage = useCryptoPage;