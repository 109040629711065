import { combineReducers } from '@reduxjs/toolkit'

import { get } from './get'
import { createTransfer } from './createTransfer'
import { createWithdraw } from './createWithdraw'
import { createFiatWithdraw } from './createFiatWithdraw'
import { getWithdrawFee } from './getWithdrawFee'
import { getFiatWithdrawFee } from './getFiatWithdrawFee'

export const transactions = combineReducers({
  get,
  createTransfer,
  createWithdraw,
  createFiatWithdraw,
  getWithdrawFee,
  getFiatWithdrawFee
})
