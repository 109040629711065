import { createReducer } from '@reduxjs/toolkit'

import { createMerchantTransferAction, resetCreateMerchantTransferAction } from '../../../actions'

type State = {
  loading: boolean
  done?: boolean
}

const initialState: State = { loading: false }

export const createTransfer = createReducer(initialState, (builder) => {
  builder.addCase(createMerchantTransferAction.pending, (state) => {
    state.loading = true
  })
  builder.addCase(createMerchantTransferAction.rejected, (state) => {
    state.loading = false
  })
  builder.addCase(createMerchantTransferAction.fulfilled, (state, action) => {
    state.loading = false
    state.done = true
  })
  builder.addCase(resetCreateMerchantTransferAction, () => initialState)
})
