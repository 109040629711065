import { NoData as NoDataLib } from '@ppay/client'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export const NoFilteredData = ({
  button,
  className
}: {
  button?: ReactNode
  className?: string
}) => {
  const { t } = useTranslation('merchant')

  return <NoDataLib title={t('noMerchantsYet')} button={button} className={className} />
}
