"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFiatFee = void 0;
var _react = require("react");
var _state = require("../../state");
var useFiatFee = function useFiatFee(_ref) {
  var _withdrawFeeState$val;
  var amount = _ref.amount,
    ticker = _ref.ticker,
    withdrawDone = _ref.withdrawDone,
    isLessThanRequired = _ref.isLessThanRequired,
    isFormValid = _ref.isFormValid;
  var _useFiatWithdrawFee = (0, _state.useFiatWithdrawFee)(),
    fetchWithdrawFee = _useFiatWithdrawFee.fetchWithdrawFee,
    withdrawFeeState = _useFiatWithdrawFee.withdrawFeeState,
    resetWithdrawFee = _useFiatWithdrawFee.resetWithdrawFee;
  (0, _react.useEffect)(function () {
    resetWithdrawFee();
  }, [amount, ticker]);
  (0, _react.useEffect)(function () {
    if (amount && isFormValid && !withdrawFeeState.loading && !withdrawFeeState.value) {
      fetchWithdrawFee({
        ticker: ticker,
        amount: amount
      });
    }
  }, [amount, ticker, isFormValid, withdrawFeeState]);
  (0, _react.useEffect)(function () {
    if (withdrawDone) {
      resetWithdrawFee();
    }
  }, [withdrawDone]);

  // fetch actual fee if current is less than required
  (0, _react.useEffect)(function () {
    if (isLessThanRequired) fetchWithdrawFee({
      ticker: ticker,
      amount: amount
    });
  }, [isLessThanRequired, ticker, amount]);
  return {
    fiatFee: (_withdrawFeeState$val = withdrawFeeState.value) !== null && _withdrawFeeState$val !== void 0 ? _withdrawFeeState$val : '0'
  };
};
exports.useFiatFee = useFiatFee;