"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _useCreateCryptoExchange = require("./useCreateCryptoExchange");
Object.keys(_useCreateCryptoExchange).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useCreateCryptoExchange[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useCreateCryptoExchange[key];
    }
  });
});
var _useCreateFiatExchange = require("./useCreateFiatExchange");
Object.keys(_useCreateFiatExchange).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useCreateFiatExchange[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useCreateFiatExchange[key];
    }
  });
});
var _useExchangeAvailablePairs = require("./useExchangeAvailablePairs");
Object.keys(_useExchangeAvailablePairs).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useExchangeAvailablePairs[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useExchangeAvailablePairs[key];
    }
  });
});
var _useExchangeCryptoInfo = require("./useExchangeCryptoInfo");
Object.keys(_useExchangeCryptoInfo).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useExchangeCryptoInfo[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useExchangeCryptoInfo[key];
    }
  });
});
var _useExchangeFiatInfo = require("./useExchangeFiatInfo");
Object.keys(_useExchangeFiatInfo).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useExchangeFiatInfo[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useExchangeFiatInfo[key];
    }
  });
});