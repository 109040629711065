import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Link, Small, P, H2, H4 } from '@ppay/client'

import { Icon } from '../../../../ui'
import { useManualReplenishOrderWithPolling } from '../../../../../state'

const Note = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.blue};
  display: flex;
`
const NoteIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

const Description = styled(P)`
  margin-top: 20px;
`

const PreTitle = styled(H4)`
  text-align: center;
  margin-bottom: 10px;
`

const Title = styled(H2)`
  text-align: center;
  margin-bottom: 20px;
`

export const QueuedOrder = ({ id }: { id: string }) => {
  const { t } = useTranslation('manualReplenishOrder')
  useManualReplenishOrderWithPolling(id)
  return (
    <>
      <PreTitle>{t('thanks')}</PreTitle>
      <Title>{t('waiting')}</Title>
      <Note>
        <NoteIconWrapper>
          <Icon name="info" size={20} />
        </NoteIconWrapper>
        <Small>{t('note3')}</Small>
      </Note>
      <Description>
        <Trans i18nKey="liveSupport" t={t}>
          <Link
            target="_blank"
            onClick={() => {
              ;(window as any).fcWidget.open()
            }}
          />
        </Trans>
      </Description>
    </>
  )
}
