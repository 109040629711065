"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Tooltip = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactstrap = require("reactstrap");
var _utils = require("../../utils");
var _fonts = require("./fonts");
var _links = require("./links");
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Tooltip = (0, _styledComponents.default)(_reactstrap.UncontrolledTooltip).attrs(function () {
  return {
    boundariesElement: 'window'
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  .bs-tooltip-bottom .arrow::before,\n  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before,\n  .bs-tooltip-top .arrow::before,\n  .bs-tooltip-auto[x-placement^='top'] .arrow::before {\n    border: none;\n  }\n\n  .tooltip {\n    opacity: 1;\n    .tooltip-inner {\n      border-radius: 4px;\n      max-width: 100%;\n      color: inherit;\n      border: none;\n      background-color: ", ";\n      color: ", ";\n      padding: 8px 10px;\n      font-size: 12px;\n      line-height: 1.2;\n      margin: 0 32px;\n      ", "\n    }\n  }\n\n  ", ", ", " {\n    text-decoration: underline;\n    color: inherit;\n    &:hover {\n      color: inherit;\n    }\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.tertiary;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.secondary;
}, _utils.mediaQuery.greaterThan('md')(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        margin: 0 50px;\n        max-width: 600px;\n      "]))), _fonts.A, _links.Link);
exports.Tooltip = Tooltip;