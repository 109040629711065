import { mediaQuery } from '@ppay/client'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  color: ${({ theme }) => theme.colors.tertiaryShade} !important;
  * {
    color: ${({ theme }) => theme.colors.tertiaryShade} !important;
    font-size: 14px;
  }
  ${mediaQuery.lessThan('sm')`
    margin-bottom: 20px;
  `}
`

export const Breadcrumbs = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Wrapper>
      {React.Children.map(children, (child, idx) =>
        idx === 0 ? child : <>&nbsp;/&nbsp;{child}</>
      )}
    </Wrapper>
  )
}
