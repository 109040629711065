"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.walletAddress = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _get = require("./get");
var _create = require("./create");
var walletAddress = (0, _toolkit.combineReducers)({
  get: _get.get,
  create: _create.create
});
exports.walletAddress = walletAddress;