"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Status = void 0;
var Status;
exports.Status = Status;
(function (Status) {
  Status["CREATED"] = "CREATED";
  Status["QUEUED"] = "QUEUED";
  Status["PENDING"] = "PENDING";
  Status["MINED"] = "MINED";
  Status["FAILED"] = "FAILED";
  Status["DONE"] = "DONE";
  Status["EXPIRED"] = "EXPIRED";
})(Status || (exports.Status = Status = {}));