import { Ticker } from '@ppay/definitions'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  getMerchantBalancesAction,
  getTotalMerchantBalanceAction,
  recalculateMerchantBalanceAction
} from '../../actions'
import { useSelector } from '../useSelector'

export const useBalances = (merchantId: string) => {
  const state = useSelector((state) => state.merchants.getBalances)
  const dispatch = useDispatch()

  const fetchBalances = () => {
    dispatch(getMerchantBalancesAction(merchantId))
  }

  useEffect(() => {
    fetchBalances()
  }, [merchantId])

  return {
    balancesLoading: !!state.loadingById,
    balances: state.data,
    fetchBalances,
    getBalanceByTicker: (ticker: Ticker) =>
      state.data?.find((balance) => balance.ticker === ticker),
    recalculateBalances: (props: {
      value: string
      ticker: Ticker
      convertedValue: string
      increase?: boolean
    }) => {
      dispatch(recalculateMerchantBalanceAction({ ...props, merchantId }))
    }
  }
}

export const useTotalBalance = (merchantId: string) => {
  const state = useSelector((state) => state.merchants.getTotalBalance)
  const dispatch = useDispatch()

  const fetchTotalBalance = () => dispatch(getTotalMerchantBalanceAction(merchantId))

  useEffect(() => {
    fetchTotalBalance()
  }, [merchantId])

  return {
    totalBalanceLoading: !!state.loadingById,
    totalBalance: state.data,
    fetchTotalBalance
  }
}
