import { fetch, TransactionsApi, ExchangeApi, ApiError } from '@ppay/client'
import { Blockchain, Ticker } from '@ppay/definitions'

import { MerchantPaymentsStatsPeriod, MerchantPaymentsStatsType } from '../constants'
import { Merchant, MerchantBalance, MerchantPaymentsStats } from '../interfaces'
import { MerchantNotExistError } from './errors'

export const getOne = async (id: string): Promise<Merchant> => {
  try {
    return await fetch({
      url: `/merchants/${id}`,
      'axios-retry': {
        retries: 3,
        retryCondition: (retry) => retry.request.status === 404,
        retryDelay: (retryCount) => retryCount * 1000
      }
    })
  } catch (e) {
    if (e instanceof ApiError && e.code === 404) throw new MerchantNotExistError()
    throw e as Error
  }
}

export const updateNotificationsUrl = async (
  id: string,
  notificationsUrl: string
): Promise<string> => {
  const res = await fetch<{ ipnUrl: string }>({
    url: `/merchants/${id}/ipn-url`,
    data: { url: notificationsUrl },
    method: 'PUT'
  })
  return res.ipnUrl
}

export const create = (
  data: Pick<Merchant, 'name' | 'companyName' | 'domain' | 'notificationsEmail'>
): Promise<string> => {
  return fetch({ url: '/merchants', method: 'POST', data })
}

export const get = (): Promise<Merchant[]> => {
  return fetch({ url: '/merchants' })
}

export const getBalances = (merchantId: string): Promise<MerchantBalance[]> => {
  return fetch({
    url: `/merchants/${merchantId}/balances`
  })
}

export const getTotalBalance = (merchantId: string): Promise<{ total: string }> => {
  return fetch({ url: `/merchants/${merchantId}/balances/total` })
}

export const getTransactions = ({
  merchantId,
  ...params
}: Parameters<typeof TransactionsApi.get>[0] & { merchantId: string }) => {
  return TransactionsApi.get(params, `/merchants/${merchantId}/payments`)
}

export const createWithdraw = async ({
  merchantId,
  ...params
}: Parameters<typeof TransactionsApi.createWithdraw>[0] & {
  merchantId: string
}) => TransactionsApi.createWithdraw(params, `/merchants/${merchantId}/crypto-withdraw`)

export const createFiatWithdraw = async ({
  merchantId,
  ...params
}: Parameters<typeof TransactionsApi.createFiatWithdraw>[0] & {
  merchantId: string
}) => TransactionsApi.createFiatWithdraw(params, `/merchants/${merchantId}/fiat-batch-withdraw`)

export const getWithdrawFee = ({
  merchantId,
  ...params
}: Parameters<typeof TransactionsApi.getWithdrawFee>[0] & { merchantId: string }): Promise<{
  value: string
  converted: string
}> => TransactionsApi.getWithdrawFee(params, `/merchants/${merchantId}/crypto-withdraw-fee`)

export const getFiatWithdrawFee = ({
  merchantId,
  ...params
}: Parameters<typeof TransactionsApi.getFiatWithdrawFee>[0] & { merchantId: string }): Promise<{
  value: string
  converted: string
}> => TransactionsApi.getFiatWithdrawFee(params, `/merchants/${merchantId}/fiat-withdraw-fee`)

export const createTransferFromPersonal = async ({
  merchantId,
  amount,
  ticker,
  blockchain
}: {
  merchantId: string
  amount: string
  ticker: Ticker
  blockchain?: Blockchain
}) =>
  fetch({
    url: `/merchants/${merchantId}/withdraw/from-personal`,
    method: 'POST',
    data: {
      amount,
      ticker,
      blockchain
    }
  })

export const createTransferToPersonal = async ({
  merchantId,
  amount,
  ticker,
  blockchain
}: {
  merchantId: string
  amount: string
  ticker: Ticker
  blockchain?: Blockchain
}) =>
  fetch({
    url: `/merchants/${merchantId}/withdraw/to-personal`,
    method: 'POST',
    data: {
      amount,
      ticker,
      blockchain
    }
  })

export const createCryptoExchange = async ({
  merchantId,
  ...params
}: Parameters<typeof ExchangeApi.createCrypto>[0] & {
  merchantId: string
}) => ExchangeApi.createCrypto(params, `/merchants/${merchantId}/exchanges/crypto/withdrawals`)

export const createFiatExchange = async ({
  merchantId,
  ...params
}: Parameters<typeof ExchangeApi.createFiat>[0] & {
  merchantId: string
}) => ExchangeApi.createFiat(params, `/merchants/${merchantId}/exchanges/fiat/withdrawals`)

export const getCryptoExchangeInfo = async ({
  merchantId,
  ...params
}: Parameters<typeof ExchangeApi.getCryptoInfo>[0] & {
  merchantId: string
}) =>
  ExchangeApi.getCryptoInfo(params, `/merchants/${merchantId}/exchanges/crypto/withdrawals/info`)

export const getFiatExchangeInfo = async ({
  merchantId,
  ...params
}: Parameters<typeof ExchangeApi.getFiatInfo>[0] & {
  merchantId: string
}) => ExchangeApi.getFiatInfo(params, `/merchants/${merchantId}/exchanges/fiat/withdrawals/info`)

export const getTransactionsCSV = ({
  merchantId,
  ...params
}: Parameters<typeof TransactionsApi.getCSV>[0] & { merchantId: string }) => {
  return TransactionsApi.getCSV(params, `/merchants/${merchantId}/payments/csv`)
}

export const getPaymentsStats = async ({
  merchantId,
  type,
  period,
  tickers
}: {
  merchantId: string
  type: MerchantPaymentsStatsType
  period: MerchantPaymentsStatsPeriod
  tickers: Ticker[]
}): Promise<MerchantPaymentsStats[]> =>
  fetch({
    url: `/merchants/${merchantId}/payments-stats`,
    method: 'GET',
    params: {
      type,
      period,
      tickers
    }
  })
