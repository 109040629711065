import { Ticker } from '@ppay/definitions'
import { H4, H6, P, TickerSymbol, TickerNumberFormat, mediaQuery } from '@ppay/client'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { NoData } from '../../merchants/NoData'
import { Merchant } from '../../../../interfaces'
import { MerchantStatus } from '../../merchant'
import { Icon } from '../../../ui'

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 10px;
  ${mediaQuery.lessThan('md')`
    display: none;
  `}
`

const MerchantItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.secondaryShade2};
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryShade};
    border-color: ${({ theme }) => theme.colors.lightPrimary};
  }
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`

const HeaderTitle = styled(P)`
  color: ${({ theme }) => theme.colors.tertiaryShade};
`
// const StatusTitle = styled(StatusTitleUI)`
//   color: ${({ theme }) => theme.colors.tertiary};
// `

export const List = ({
  merchants,
  onShowOne,
  onCreate
}: {
  merchants?: Merchant[]
  onShowOne: (merchantId: string) => void
  onCreate: () => void
}) => {
  const { t } = useTranslation(['merchant', 'libSettings'])

  if (!merchants?.length) return <NoData onCreateMerchantClick={onCreate} />

  return (
    <>
      <Header>
        <HeaderTitle>{t('table.merchants')}</HeaderTitle>
        <HeaderTitle>{t('table.balance')}</HeaderTitle>
      </Header>
      <div>
        {merchants!.map((m) => (
          <MerchantItem key={m.id} onClick={() => onShowOne(m.id)}>
            <div className="d-flex flex-column justify-content-center">
              <H6 className="mb-1">{m.name}</H6>
              <MerchantStatus verified={m.verified} />
            </div>
            <div className="d-flex align-items-center">
              <H4 className="mb-0 me-2">
                <TickerSymbol ticker={Ticker.USD} />
                <TickerNumberFormat ticker={Ticker.USD} value={m.totalBalance} />
              </H4>
              <Icon name="longArrow" rotate={180} color="primary" />
            </div>
          </MerchantItem>
        ))}
      </div>
    </>
  )
}
