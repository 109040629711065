import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link, Button, Alert } from '@ppay/client'

const StyledAlert = styled(Alert)`
  margin-bottom: 20px;
  align-items: center;
`

export const NoOrder = ({ loading, get }: { loading: boolean; get: () => void }) => {
  const { t } = useTranslation('manualReplenishOrder')
  return (
    <>
      <StyledAlert iconColor="danger">
        <Trans i18nKey="noCardAvailable" t={t}>
          <Link
            target="_blank"
            onClick={() => {
              ;(window as any).fcWidget.open()
            }}
          />
        </Trans>
      </StyledAlert>
      <Button wide loading={loading} disabled={loading} onClick={() => get()}>
        {t('createOrder')}
      </Button>
    </>
  )
}
