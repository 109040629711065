"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _usePrice = require("./usePrice");
Object.keys(_usePrice).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _usePrice[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _usePrice[key];
    }
  });
});
var _usePricesVolume = require("./usePricesVolume");
Object.keys(_usePricesVolume).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _usePricesVolume[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _usePricesVolume[key];
    }
  });
});