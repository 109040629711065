import styled from 'styled-components'

import { Merchant } from '../../../interfaces'
import { FilteredSearch } from './FilteredSearch'
import { Cards, Table } from './List'
import { Without2fa } from './Without2fa'
import { NoData } from './NoData'
import { NoFilteredData } from './NoFilteredData'

const StyledNoData = styled(NoData)`
  margin-top: 10px;
`

const StyledNoFilteredData = styled(NoFilteredData)`
  margin-top: 10px;
`

export const Body = ({
  merchants,
  enabled2fa,
  merchantsLimit,
  filterMerchants,
  onCreateMerchantClick,
  isMobile
}: {
  merchants: Merchant[]
  enabled2fa: boolean
  merchantsLimit: boolean
  filterMerchants: (v: string) => void
  onCreateMerchantClick: () => void
  isMobile: boolean
}) => {
  if (!enabled2fa)
    return (
      <>
        <FilteredSearch disabled onChange={filterMerchants} />
        <Without2fa />
      </>
    )

  if (!merchants) return <StyledNoData onCreateMerchantClick={onCreateMerchantClick} />

  return (
    <>
      <FilteredSearch disabled={merchantsLimit} onChange={filterMerchants} />
      {merchants?.length === 0 ? (
        <StyledNoFilteredData />
      ) : isMobile ? (
        <Cards merchants={merchants} />
      ) : (
        <Table merchants={merchants} />
      )}
    </>
  )
}
