"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTickerConverter = void 0;
var _decimal = _interopRequireDefault(require("decimal.js"));
var _react = require("react");
var _useRecalculateAmount = require("./useRecalculateAmount");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var useTickerConverter = function useTickerConverter(_ref) {
  var price = _ref.price,
    amount = _ref.amount,
    convertedAmount = _ref.convertedAmount,
    ticker = _ref.ticker,
    convertedTicker = _ref.convertedTicker,
    onAmountChange = _ref.onAmountChange,
    onConvertedAmountChange = _ref.onConvertedAmountChange;
  var _useState = (0, _react.useState)(),
    _useState2 = _slicedToArray(_useState, 2),
    focusedInput = _useState2[0],
    setFocusedInput = _useState2[1];
  var recalculateAmount = (0, _useRecalculateAmount.useRecalculateAmount)({
    ticker: ticker,
    price: price && new _decimal.default(1).div(price).toString()
  });
  var recalculateConvertedAmount = (0, _useRecalculateAmount.useRecalculateAmount)({
    ticker: convertedTicker,
    price: price
  });
  (0, _react.useEffect)(function () {
    if (focusedInput === 'amount') {
      onConvertedAmountChange(recalculateConvertedAmount(amount));
    }
  }, [amount, focusedInput]);
  (0, _react.useEffect)(function () {
    if (focusedInput === 'convertedAmount') {
      onAmountChange(recalculateAmount(convertedAmount));
    }
  }, [convertedAmount, focusedInput]);

  // to recalc amount by convertedAmount on price change
  (0, _react.useEffect)(function () {
    if (convertedAmount && price) {
      setFocusedInput(undefined);
      onAmountChange(recalculateAmount(convertedAmount));
    }
  }, [price]);
  return {
    setFocusedInput: setFocusedInput,
    focusedInput: focusedInput
  };
};
exports.useTickerConverter = useTickerConverter;