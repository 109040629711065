"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.restorePassword = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  sendCode: {
    loading: false
  },
  restorePassword: {
    loading: false
  },
  validateCode: {
    loading: false
  }
};
var restorePassword = (0, _toolkit.createReducer)(initialState, function (builder) {
  // send restore
  builder.addCase(_actions.sendRestorePasswordCodeAction.pending, function (state) {
    state.sendCode = {
      loading: true
    };
  });
  builder.addCase(_actions.sendRestorePasswordCodeAction.rejected, function (state, action) {
    var _action$payload;
    state.sendCode.loading = false;
    state.sendCode.remainingTime = (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data.time;
    state.sendCode.email = action.meta.arg;
    state.sendCode.done = true;
  });
  builder.addCase(_actions.sendRestorePasswordCodeAction.fulfilled, function (state, action) {
    state.sendCode.loading = false;
    state.sendCode.done = true;
    state.sendCode.email = action.meta.arg;
    state.sendCode.remainingTime = action.payload.time;
  });
  // restore
  builder.addCase(_actions.restorePasswordAction.pending, function (state) {
    state.restorePassword.loading = true;
  });
  builder.addCase(_actions.restorePasswordAction.rejected, function (state) {
    state.restorePassword.loading = false;
  });
  builder.addCase(_actions.restorePasswordAction.fulfilled, function (state) {
    state.restorePassword.loading = false;
    state.restorePassword.done = true;
  });
  // validate key
  builder.addCase(_actions.validateRestorePasswordCodeAction.pending, function (state) {
    state.validateCode.loading = true;
  });
  builder.addCase(_actions.validateRestorePasswordCodeAction.rejected, function (state) {
    state.validateCode.loading = false;
  });
  builder.addCase(_actions.validateRestorePasswordCodeAction.fulfilled, function (state, action) {
    state.validateCode.loading = false;
    state.validateCode.done = action.payload;
  });
  builder.addCase(_actions.resetRestorePasswordAction, function () {
    return initialState;
  });
});
exports.restorePassword = restorePassword;