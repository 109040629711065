import { CryptoCurrencies } from '@ppay/crypto-constraints'
import { FeeKind, FIAT_BALANCE_TICKERS } from '@ppay/definitions'
import { createReducer } from '@reduxjs/toolkit'
import Decimal from 'decimal.js'

import { Merchant } from '../../../interfaces'
import { CryptoReplenishFees, FiatReplenishFees } from '../../../interfaces/merchant/Fees'
import {
  createMerchantAction,
  getMerchantAction,
  getMerchantsAction,
  recalculateMerchantBalanceAction,
  resetMerchantsAction,
  updateMerchantNotificationsUrl
} from '../../actions'

type State = {
  loading: boolean
  data?: Merchant[]
}

const initialState: State = {
  loading: false
}

export const get = createReducer(initialState, (builder) => {
  builder
    .addCase(getMerchantsAction.pending, (state) => {
      return { ...state, loading: true }
    })
    .addCase(getMerchantsAction.rejected, (state) => {
      state.loading = false
    })
    .addCase(getMerchantsAction.fulfilled, (state, action) => {
      state.loading = false
      const sortedBalancesMerchants = action.payload?.map((data) => {
        return {
          ...data,
          balances: data.balances.sort(
            (prevBalance, nextBalance) =>
              Number(nextBalance.converted) - Number(prevBalance.converted)
          )
        }
      })
      if (!state.data) state.data = sortedBalancesMerchants
      else {
        for (const merchant of sortedBalancesMerchants) {
          const merchantIndex = state.data?.findIndex((el) => el.id === merchant.id)
          if (merchantIndex > 0) {
            state.data.splice(merchantIndex, 1, merchant)
          }
        }
      }
    })
    .addCase(recalculateMerchantBalanceAction, (state, action) => {
      const foundMerchant = state.data?.find(
        (merchant) => merchant.id === action.payload.merchantId
      )
      if (foundMerchant) {
        const foundBalance = foundMerchant.balances.find(
          (balance) => balance.ticker === action.payload.ticker
        )
        Object.assign(foundMerchant, {
          totalBalance: new Decimal(foundMerchant.totalBalance)
            [action.payload.increase ? 'add' : 'sub'](action.payload.convertedValue)
            .toString()
        })
        if (foundBalance) {
          Object.assign(foundBalance, {
            value: new Decimal(foundBalance.value)
              [action.payload.increase ? 'add' : 'sub'](action.payload.value)
              .toString(),
            converted: new Decimal(foundBalance.converted)
              [action.payload.increase ? 'add' : 'sub'](action.payload.convertedValue)
              .toString()
          })
        }
      }
    })
    .addCase(createMerchantAction.fulfilled, (state, action) => {
      state.data = (state.data ?? []).concat({
        ...action.meta.arg,
        id: action.payload,
        totalBalance: '0',
        balances: [],
        replenishFees: {
          crypto: CryptoCurrencies.reduce(
            (obj, { ticker, blockchain }) => ({
              ...obj,
              ticker: {
                ...(obj as CryptoReplenishFees)[ticker],
                [blockchain]: null
              }
            }),
            {}
          ) as CryptoReplenishFees,
          fiat: FIAT_BALANCE_TICKERS.reduce(
            (obj, ticker) => ({
              ...obj,
              [ticker]: { kind: FeeKind.Percent, value: '1.5' }
            }),
            {}
          ) as FiatReplenishFees
        }
      } as Merchant)
    })
    .addCase(updateMerchantNotificationsUrl.fulfilled, (state, action) => {
      const { id, notificationsUrl } = action.meta.arg
      const merchantIndex = state.data?.findIndex((el) => el.id === id)
      if (merchantIndex && merchantIndex >= 0 && state.data)
        state.data[merchantIndex].notificationsUrl = notificationsUrl
    })
    .addCase(getMerchantAction.fulfilled, (state, action) => {
      const id = action.meta.arg
      const merchantIndex = state.data?.findIndex((el) => el.id === id)
      if (merchantIndex && merchantIndex > 0 && state.data)
        state.data[merchantIndex] = action.payload
    })
    .addCase(resetMerchantsAction, () => initialState)
})
