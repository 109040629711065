import { SendBalanceContext } from '@ppay/client'
import React from 'react'

import { useMerchantBalances } from '../../../state'

export const MerchantBalanceProvider = ({
  children,
  merchantId,
  balanceName
}: React.PropsWithChildren<{ merchantId: string; balanceName: string }>) => (
  <SendBalanceContext.Provider
    value={{ useBalances: () => useMerchantBalances(merchantId), balanceName }}
  >
    {children}
  </SendBalanceContext.Provider>
)
