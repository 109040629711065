import React, { useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Ticker } from '@ppay/definitions'
import { NumberFormat, Tooltip, MediumSpan as MediumSpanLib } from '@ppay/client'
import { useMerchantPaymentsTickersStats } from '../../../../../hooks'

const Wrapper = styled.div`
  div:last-child {
    margin-bottom: 0;
  }
`

const SpaceBetweenWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 10px;
`

const MediumSpan = styled(MediumSpanLib)`
  text-wrap: nowrap;
`

const NumberWithTooltip = ({
  amount,
  ticker,
  convertedAmount
}: {
  amount: number
  ticker: Ticker
  convertedAmount: number
}) => {
  const ref = useRef(null)
  return (
    <>
      <span ref={ref}>
        <NumberFormat value={amount} decimals={4} /> {ticker}{' '}
      </span>
      <Tooltip placement="bottom" target={ref}>
        <NumberFormat value={amount} /> {ticker} ~{' '}
        <NumberFormat value={convertedAmount} decimals={2} />$
      </Tooltip>
    </>
  )
}

export const ChartLegend = ({
  tickersStats
}: {
  tickersStats: Array<{ amount: string; convertedAmount: string; ticker: Ticker }>
}) => {
  const { t } = useTranslation('merchant')

  const { shownStats, otherStats } = useMerchantPaymentsTickersStats({ tickersStats })

  return (
    <Wrapper>
      {shownStats.map(({ amount, convertedAmount, ticker }) => {
        return (
          <SpaceBetweenWrapper>
            {ticker}
            <MediumSpan>
              <NumberWithTooltip
                amount={amount}
                ticker={ticker}
                convertedAmount={convertedAmount}
              />
            </MediumSpan>
          </SpaceBetweenWrapper>
        )
      })}
      {otherStats ? (
        <SpaceBetweenWrapper>
          {t('other')}
          <MediumSpan>
            <NumberFormat value={otherStats} decimals={2} />$
          </MediumSpan>
        </SpaceBetweenWrapper>
      ) : null}
    </Wrapper>
  )
}
