"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLogin = exports.useAuthToken = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var useLogin = function useLogin() {
  var _loginState$credentia;
  var loginState = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.login;
  });
  var twoFactorState = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.twoFactor;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    email: (_loginState$credentia = loginState.credentialsFormData) === null || _loginState$credentia === void 0 ? void 0 : _loginState$credentia.email,
    loading: loginState.loading,
    remainingTime: loginState.remainingTime,
    loginWithCredentials: function loginWithCredentials(credentials) {
      dispatch((0, _actions.loginAction)(credentials));
    },
    loginWithEmail2fa: function loginWithEmail2fa(_ref) {
      var code = _ref.code;
      dispatch((0, _actions.loginAction)(_objectSpread(_objectSpread({}, loginState.credentialsFormData), {}, {
        emailKey: code
      })));
    },
    loginWithTotp: function loginWithTotp(_ref2) {
      var code = _ref2.code;
      dispatch((0, _actions.loginAction)(_objectSpread(_objectSpread({}, loginState.credentialsFormData), {}, {
        emailKey: twoFactorState.emailKey,
        token: code
      })));
    }
  };
};
exports.useLogin = useLogin;
var useAuthToken = function useAuthToken() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.login;
  });
  return state.token;
};
exports.useAuthToken = useAuthToken;