"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OutlineButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _theme = require("../../../theme");
var _BaseButton = require("./BaseButton");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var colors = _theme.theme.colors;
var settings = {
  outline: {
    default: {
      color: colors.tertiary,
      background: colors.secondary,
      border: colors.secondaryShade2
    },
    hover: {
      color: colors.tertiary,
      background: '#fbfafd',
      border: colors.secondaryShade2,
      shadow: "0 4px 5px ".concat(colors.tertiary, "1a")
    },
    active: {
      color: colors.tertiary,
      background: colors.secondary,
      border: '#8c82cb'
    },
    loading: {
      color: colors.tertiaryShade2,
      background: colors.secondaryShade2
    }
  },
  outlineLight: {
    default: {
      color: '#9090bb',
      background: colors.secondary,
      border: '#d6d7f5'
    },
    hover: {
      color: '#9090bb',
      background: '#fbfafd',
      border: '#d6d7f5',
      shadow: "0 4px 5px ".concat(colors.tertiary, "1a")
    },
    active: {
      color: '#9090bb',
      background: colors.secondary,
      border: '#8c82cb'
    },
    loading: {
      color: colors.tertiaryShade2,
      background: colors.secondaryShade2
    }
  }
};
var OutlineButton = (0, _styledComponents.default)(_BaseButton.BaseButton)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), function (_ref) {
  var loading = _ref.loading,
    light = _ref.light;
  var buttonColors = settings[light ? 'outlineLight' : 'outline'];
  return "\n      color: ".concat(loading ? buttonColors.loading.color : buttonColors.default.color, ";\n      background-color: ").concat(loading ? buttonColors.loading.background : buttonColors.default.background, ";\n      border-color: ").concat(buttonColors.default.border, ";\n    \n      &:not(:disabled):not(.disabled):hover {\n        color: ").concat(loading ? buttonColors.loading.color : buttonColors.hover.color, ";\n        background-color: ").concat(loading ? buttonColors.loading.background : buttonColors.hover.background, ";\n\n        border-color: ").concat(buttonColors.default.border, ";\n        box-shadow: ").concat(buttonColors.hover.shadow, ";\n      }\n    \n      &:focus,\n      &:not(:disabled):not(.disabled):active {\n        color: ").concat(loading ? buttonColors.loading.color : buttonColors.active.color, ";\n        background-color: ").concat(loading ? buttonColors.loading.background : buttonColors.active.background, ";\n\n        border-color: ").concat(buttonColors.active.border, ";\n      }\n    \n      & svg path {\n        fill: ").concat(loading ? buttonColors.loading.color : buttonColors.default.color, ";\n      }\n  ");
});
exports.OutlineButton = OutlineButton;