import React from 'react'
import styled from 'styled-components'
import {
  LanguageSelect,
  Dropdown,
  DropdownToggle as DropdownToggleUI,
  DropdownMenu,
  DropdownMenuItem,
  Small,
  Svg
} from '@ppay/client'

import { ReactComponent as visa } from './visa.svg'
import { ReactComponent as mastercard } from './mastercard.svg'
import { ReactComponent as pciDss } from './pci-dss.svg'

import { SeparativeLine } from '../SeparativeLine'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Row = styled.div<{ isFiat?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: ${({ isFiat }) => (isFiat ? 'space-between' : 'center')};
  align-items: center;
`

const FiatSupport = styled.div`
  display: flex;
  gap: 8px;
`

const DropdownToggle = styled(DropdownToggleUI)`
  color: ${({ theme }) => theme.colors.tertiary};
  > svg path {
    fill: ${({ theme }) => theme.colors.tertiary};
  }
`

export const Footer = ({ isFiat = false }: { isFiat?: boolean }) => (
  <Column>
    <SeparativeLine />
    <Row isFiat={isFiat}>
      <LanguageSelect
        component={({ options, value, onChange }) => (
          <Dropdown>
            <DropdownToggle>
              <Small>{(options.find((el) => el.value === value) ?? options[0]).label}</Small>
            </DropdownToggle>
            <DropdownMenu>
              {options.map((o) => (
                <DropdownMenuItem
                  key={o.value}
                  onClick={() => onChange(o.value)}
                  title={o.label as any}
                />
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
      />
      {isFiat && (
        <FiatSupport>
          <Svg as={visa} />
          <Svg as={mastercard} />
          <Svg as={pciDss} />
        </FiatSupport>
      )}
    </Row>
  </Column>
)
