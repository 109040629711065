import styled from 'styled-components'
import { InputField } from '@ppay/client'
import { CardData } from '../../../card'

export const CardDataWithMargin = styled(CardData)`
  margin-bottom: 20px;
`

export const Widget = styled.div<{ loading: boolean }>`
  visibility: ${({ loading }) => (loading ? 'hidden' : 'visible')};
  height: ${({ loading }) => (loading ? '0' : 'auto')};
`

export const CardField = styled(InputField)`
  width: 100%;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.secondaryShade};
  border: 1px solid ${({ theme }) => theme.colors.secondaryShade2};
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  &::placeholder {
    color: ${({ theme }) => theme.colors.tertiaryShade};
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.tertiary};
    box-shadow: none;
  }
`
