"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFee = void 0;
var _react = require("react");
var _state = require("../../state");
var useFee = function useFee(_ref) {
  var _withdrawFeeState$val;
  var amount = _ref.amount,
    ticker = _ref.ticker,
    blockchain = _ref.blockchain,
    address = _ref.address,
    withdrawDone = _ref.withdrawDone,
    isLessThanRequired = _ref.isLessThanRequired,
    isFormValid = _ref.isFormValid;
  var _useWithdrawFee = (0, _state.useWithdrawFee)(),
    fetchWithdrawFee = _useWithdrawFee.fetchWithdrawFee,
    withdrawFeeState = _useWithdrawFee.withdrawFeeState,
    resetWithdrawFee = _useWithdrawFee.resetWithdrawFee;
  (0, _react.useEffect)(function () {
    resetWithdrawFee();
  }, [amount, address, ticker, blockchain]);
  (0, _react.useEffect)(function () {
    if (amount && isFormValid && !withdrawFeeState.loading && !withdrawFeeState.value) {
      fetchWithdrawFee({
        ticker: ticker,
        amount: amount,
        blockchain: blockchain,
        address: address
      });
    }
  }, [amount, ticker, isFormValid, withdrawFeeState, blockchain, address]);
  (0, _react.useEffect)(function () {
    if (withdrawDone) {
      resetWithdrawFee();
    }
  }, [withdrawDone]);

  // fetch actual fee if current is less than required
  (0, _react.useEffect)(function () {
    if (isLessThanRequired) fetchWithdrawFee({
      ticker: ticker,
      amount: amount,
      blockchain: blockchain,
      address: address
    });
  }, [isLessThanRequired, ticker, amount, blockchain, address]);
  return {
    fee: (_withdrawFeeState$val = withdrawFeeState.value) !== null && _withdrawFeeState$val !== void 0 ? _withdrawFeeState$val : '0'
  };
};
exports.useFee = useFee;