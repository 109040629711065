"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  BodyWizardControl: true,
  BodyCard: true
};
Object.defineProperty(exports, "BodyCard", {
  enumerable: true,
  get: function get() {
    return _Card.Card;
  }
});
Object.defineProperty(exports, "BodyWizardControl", {
  enumerable: true,
  get: function get() {
    return _WizardControl.WizardControl;
  }
});
var _WizardControl = require("./WizardControl");
var _Card = require("./Card");
var _Body = require("./Body");
Object.keys(_Body).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Body[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Body[key];
    }
  });
});