"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePortfolioBalance = void 0;
var _react = require("react");
var _decimal = _interopRequireDefault(require("decimal.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var SHOW_BALANCE = 6;
var getTotalBalance = function getTotalBalance(balance) {
  return balance.reduce(function (acc, val) {
    return acc.add(val.converted);
  }, new _decimal.default(0)).toNumber();
};
var usePortfolioBalance = function usePortfolioBalance(_ref) {
  var balances = _ref.balances,
    balancesLoading = _ref.balancesLoading;
  var _useState = (0, _react.useState)([]),
    _useState2 = _slicedToArray(_useState, 2),
    minPercentBalances = _useState2[0],
    setMinPercentBalances = _useState2[1];
  var _useState3 = (0, _react.useState)(),
    _useState4 = _slicedToArray(_useState3, 2),
    otherBalanceTotal = _useState4[0],
    setOtherBalanceTotal = _useState4[1];
  (0, _react.useEffect)(function () {
    if (balances && !balancesLoading) {
      var minValueBalances = [];
      var minPercentBalance = {
        percent: 0,
        value: '0'
      };
      var currentTotalBalance = getTotalBalance(balances);
      var newBalances = _toConsumableArray(balances).filter(function (balance) {
        return Number(balance.converted);
      }).sort(function (a, b) {
        return Number(b.converted) - Number(a.converted);
      });
      var _iterator = _createForOfIteratorHelper(newBalances.slice(0, SHOW_BALANCE)),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _step.value,
            converted = _step$value.converted,
            value = _step$value.value,
            ticker = _step$value.ticker;
          var percent = new _decimal.default(converted).div(currentTotalBalance).mul(100).toNumber();
          minPercentBalance.percent += percent;
          minPercentBalance.value = new _decimal.default(minPercentBalance.value).add(converted).toString();
          minValueBalances.push({
            percent: percent,
            value: value,
            ticker: ticker
          });
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      setMinPercentBalances(minValueBalances);
      var otherTotalBalance = getTotalBalance(newBalances.slice(0, SHOW_BALANCE));
      if (newBalances.length > SHOW_BALANCE) {
        setOtherBalanceTotal({
          percent: 100 - otherTotalBalance / currentTotalBalance * 100,
          value: (currentTotalBalance - otherTotalBalance).toString()
        });
      }
    }
  }, [balances, balancesLoading]);
  return {
    minPercentBalances: minPercentBalances,
    otherBalanceTotal: otherBalanceTotal,
    loading: balancesLoading || !balances
  };
};
exports.usePortfolioBalance = usePortfolioBalance;