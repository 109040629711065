import {
  DashboardTransfersWithdrawForm,
  DashboardTransfersWithdrawFormProps,
  Row,
  Col,
  FormGroup,
  Label
} from '@ppay/client'
import { useTranslation } from 'react-i18next'

import { BalanceSelectInput } from './BalanceSelectInput'

export const Form = ({
  balanceOptions,
  balanceOption,
  onBalanceOptionChange,
  merchantsLoading,
  ...props
}: DashboardTransfersWithdrawFormProps & {
  balanceOptions: Array<{ value: string; balance: string; name: string }>
  balanceOption: string
  merchantsLoading: boolean
  onBalanceOptionChange: (balance: string) => unknown
}) => {
  const { t } = useTranslation('transfers')

  return (
    <DashboardTransfersWithdrawForm {...props}>
      {!merchantsLoading && (
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Label>{t('chooseBalanceToWithdraw')}</Label>
              <BalanceSelectInput
                value={balanceOption}
                balanceOptions={balanceOptions}
                onChange={onBalanceOptionChange}
                isSearchable={false}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </DashboardTransfersWithdrawForm>
  )
}
