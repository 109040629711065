import { useBalances } from '@ppay/client'
import { CryptoTicker, FiatTicker } from '@ppay/definitions'
import { useEffect } from 'react'

import { useCreateMerchantTransfer, useMerchantBalances } from '../../state'

export function useTransfer({
  amount,
  fiatTickerTotal,
  ticker,
  onSuccess,
  merchantId,
  from
}: {
  amount: string
  fiatTickerTotal: string
  ticker: CryptoTicker
  fiatTicker: FiatTicker
  onSuccess: () => unknown
  isEnoughBalance?: boolean
  isValidating?: boolean
  merchantId: string
  from?: boolean
}) {
  const { createTransferDone, createTransferLoading, createTransfer, resetCreateTransfer } =
    useCreateMerchantTransfer()
  const { recalculateBalances } = useBalances()
  const { recalculateBalances: recalculateMerchantBalances } = useMerchantBalances(merchantId)

  useEffect(() => {
    resetCreateTransfer()
  }, [from])

  useEffect(() => {
    if (createTransferDone) {
      onSuccess()
      resetCreateTransfer()
      recalculateBalances({
        value: amount,
        convertedValue: fiatTickerTotal,
        ticker,
        increase: !from
      })
      recalculateMerchantBalances({
        value: amount,
        convertedValue: fiatTickerTotal,
        ticker,
        increase: from
      })
    }
  }, [createTransferDone])

  return {
    createWithdraw: () =>
      createTransfer({
        ticker,
        amount,
        merchantId,
        from
      }),
    createWithdrawLoading: createTransferLoading
  }
}
