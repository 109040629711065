import React from 'react'
import { AuthVerifyCodeFromMailForm, useEmailAuth } from '@ppay/client'

import { CardContinue } from '../card'
import { FiatInvoice } from '../../../interfaces'
import { TickerSelect } from './TickerSelect'
import { Ticker } from '@ppay/definitions'

export const EmailAuth = ({
  invoice,
  setTicker,
  ticker
}: {
  invoice: FiatInvoice
  setTicker: (ticker: Ticker) => void
  ticker: Ticker
}) => {
  const {
    email,
    reset,
    code: { remainingTime, send, sendLoading },
    login,
    loading
  } = useEmailAuth()
  return (
    <CardContinue invoice={invoice} back={email ? reset : undefined} isFiat={true}>
      <TickerSelect invoice={invoice} setTicker={setTicker} ticker={ticker} />
      <AuthVerifyCodeFromMailForm
        email={email}
        verify={login}
        verifyLoading={loading}
        send={send}
        sendLoading={sendLoading}
        sendTimeout={remainingTime}
      />
    </CardContinue>
  )
}
