import { mediaQuery, RoundButton } from '@ppay/client'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'

import { routes } from '../../../router'
import { Icon } from '../../../ui'

const Wrapper = styled.div`
  display: flex;
  justify-content: end;
  ${RoundButton}:not(:last-child) {
    margin-right: 20px;
  }
  ${mediaQuery.lessThan('md')`
    justify-content: center;
  `}
`

export const Actions = ({ merchantId }: { merchantId: string }) => {
  const history = useHistory()
  const { state } = useLocation()

  return (
    <Wrapper>
      {/*
      <RoundButton
        icon={<Icon name="wallet" />}
        onClick={() => history.push(`${routes.dashboard.merchants.main}/${merchantId}`)}
      />
      */}
      <RoundButton
        icon={<Icon name="settingsKey" />}
        onClick={() =>
          history.push(`${routes.dashboard.merchants.main}/${merchantId}/settings`, state)
        }
      />
      <RoundButton
        icon={<Icon name="shortArrowRight" />}
        onClick={() => history.push(`${routes.dashboard.merchants.main}/${merchantId}`, state)}
      />
    </Wrapper>
  )
}
