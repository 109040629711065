"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  TransactionInfoTotalAmount: true,
  TransactionInfoAmountItem: true,
  TransactionInfoCard: true
};
Object.defineProperty(exports, "TransactionInfoAmountItem", {
  enumerable: true,
  get: function get() {
    return _AmountItem.AmountItem;
  }
});
Object.defineProperty(exports, "TransactionInfoCard", {
  enumerable: true,
  get: function get() {
    return _Card.Card;
  }
});
Object.defineProperty(exports, "TransactionInfoTotalAmount", {
  enumerable: true,
  get: function get() {
    return _TotalAmount.TotalAmount;
  }
});
var _TotalAmount = require("./TotalAmount");
var _AmountItem = require("./AmountItem");
var _Card = require("./Card");
var _TransactionInfo = require("./TransactionInfo");
Object.keys(_TransactionInfo).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _TransactionInfo[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _TransactionInfo[key];
    }
  });
});