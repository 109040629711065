import { createReducer } from '@reduxjs/toolkit'

import { MerchantBalance } from '../../../interfaces'
import { getMerchantBalancesAction } from '../../actions'

type State = {
  loadingById?: string
  data?: MerchantBalance[]
  merchantId?: string
}

export const getBalances = createReducer({} as State, (builder) => {
  builder.addCase(getMerchantBalancesAction.pending, (_, action) => {
    return { loadingById: action.meta.arg }
  })
  builder.addCase(getMerchantBalancesAction.rejected, (state) => {
    state.loadingById = undefined
  })
  builder.addCase(getMerchantBalancesAction.fulfilled, (state, action) => {
    state.loadingById = undefined
    state.data = action.payload
    state.merchantId = action.meta.arg
  })
})
