"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  exchange: true
};
exports.exchange = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var _transactions = require("../transactions");
var _getAvailablePairs = require("./getAvailablePairs");
var _getInfo = require("./getInfo");
Object.keys(_getInfo).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _getInfo[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getInfo[key];
    }
  });
});
var exchange = (0, _toolkit.combineReducers)({
  getAvailablePairs: _getAvailablePairs.getAvailablePairs,
  getCryptoInfo: (0, _getInfo.createGetInfoReducer)(),
  getFiatInfo: (0, _getInfo.createGetInfoReducer)({
    action: _actions.getExchangeFiatInfoAction,
    resetAction: _actions.resetGetExchangeFiatInfoAction
  }),
  createCrypto: (0, _transactions.createWithdrawReducer)(_actions.createCryptoExchangeAction),
  createFiat: (0, _transactions.createWithdrawReducer)(_actions.createFiatExchangeAction)
});
exports.exchange = exchange;