"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Type = void 0;
var Type;
exports.Type = Type;
(function (Type) {
  Type["DEPOSIT"] = "DEPOSIT";
  Type["WITHDRAWAL"] = "WITHDRAWAL";
  Type["EXCHANGE"] = "EXCHANGE";
})(Type || (exports.Type = Type = {}));