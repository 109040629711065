import {
  // Col,
  Input,
  InputGroupAbsolute,
  SearchInputGroupAddon,
  // SelectInput,
  Button,
  mediaQuery,
  Tooltip,
  SmallMedium
  // breakpoints
} from '@ppay/client'
import React, { createRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { routes } from '../../router'
import { Icon } from '../../'

const ButtonRef = styled.div`
  display: flex;
  width: 100%;
  max-width: 330px;
  margin: 0 0 0 20px;
  ${mediaQuery.lessThan('xxl')`
    max-width: 270px;
  `}
  ${mediaQuery.lessThan('lg')`
    margin: 20px 0 0;
    max-width: 100%;
  `}
`

const Wrapper = styled.div`
  margin-bottom: 20px;
`

export const FilteredSearch = ({ disabled, onChange }: { disabled?: boolean; onChange: any }) => {
  const history = useHistory()
  const ref = createRef<HTMLDivElement>()
  const { t } = useTranslation('merchant')

  // const [sort, setSort] = useState('balance')
  // const isDesktop = useMediaQuery({ minWidth: breakpoints.lg })
  //
  // const showSortInput = isDesktop && !disabled
  // const label = t('sortBy') + ' '
  //
  // const options = [
  //   { value: 'name', label: label + t('table.name') },
  //   { value: 'balance', label: label + t('table.balance') },
  //   { value: 'website', label: label + t('table.website') }
  // ]

  return (
    <Wrapper className="d-flex flex-column flex-lg-row">
      <div className="d-flex flex-grow-1">
        <InputGroupAbsolute>
          <SearchInputGroupAddon addonType="prepend" />
          <Input
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            placeholder={t('search')}
          />
        </InputGroupAbsolute>
        {/* showSortInput && (
          <Col sm={6}>
            <SelectInput
              placeholder={options[0]}
              value={sort}
              defaultValue={options[1]}
              options={options}
              onChange={setSort}
            />
          </Col>
        ) */}
      </div>
      <ButtonRef ref={ref}>
        <Button
          wide
          disabled={disabled}
          type="submit"
          color="secondary"
          onClick={() => history.push(routes.dashboard.merchants.create)}
        >
          {t('createNewMerchantButton')}
          <Icon name="createMerchant" />
        </Button>
      </ButtonRef>
      {disabled && (
        <Tooltip placement="bottom" target={ref}>
          <SmallMedium>{t('createLimit')}</SmallMedium>
        </Tooltip>
      )}
    </Wrapper>
  )
}
