import { useExchange as useExchangeLib } from '@ppay/client'

import { useCreateMerchantCryptoExchange, useMerchantBalances } from '../state'

export const useExchange = (props: any) => {
  const useBalances = useMerchantBalances.bind(null, props.balanceOption)()
  return useExchangeLib({
    ...props,
    fetchBalances: () => null,
    useBalances: () => useBalances,
    useCreateExchange: useCreateMerchantCryptoExchange.bind(null, props.balanceOption)
  })
}
