import {
  DashboardTransferToPage,
  DashboardTransfersWithdrawCardTitle as CardTitle,
  DashboardTransfersWithdrawCardSubtitle as CardSubtitle,
  DashboardTransfersWithdrawConfirmModal as ConfirmModal,
  DashboardTransfersWithdrawTransactionInfoCard as TransactionInfoCard,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  useWithdrawFee,
  useUser,
  useBalancesWithTotal,
  Button as ButtonLib,
  mediaQuery,
  SmallMedium,
  Link,
  breakpoints
} from '@ppay/client'
import { useEffect, useRef, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import get from 'lodash.get'
import styled from 'styled-components'

import {
  useCreateMerchantWithdraw,
  useMerchantExchangeFiatInfo,
  useMerchantExchangeCryptoInfo,
  useMerchants
} from '../../../state'
import { ToggleTransfer } from './ToggleTransfer'
import {
  TransferConfirmModalToBalance,
  TransferForm,
  TransferTransactionInfoToBalanceAfter
} from './transfer'
import { AlertWithButton } from '../AlertWithButton'
import { Form } from './Form'
import {
  useMerchantTransferForm,
  useMerchantTransfer,
  useWithdraw,
  useFiatWithdraw,
  useExchange,
  useFiatExchange
} from '../../../hooks'
import { Breadcrumb, Breadcrumbs } from '../../layout'
import { routes } from '../../router'
import { useMediaQuery } from 'react-responsive'

export const PERSONAL_BALANCE_ID = 'main'

const Alert = styled(AlertWithButton)`
  margin-bottom: 30px;
  ${mediaQuery.lessThan('md')`
    margin-bottom: 20px;
  `}
`

const Button = styled(ButtonLib)`
  width: 100%;
  ${mediaQuery.lessThan('lg')`
    max-width: 223px;
  `}
  ${mediaQuery.greaterThan('lg')`
    max-width: 120px;
    font-size: 14px;
    height: 30px;
  `}
  ${mediaQuery.lessThan('sm')`
    max-width: 100%;
  `}
`

export const Page = () => {
  const { t } = useTranslation(['transfers', 'libCommon', 'routes'])
  const history = useHistory()

  const { merchants, loading, fetchMerchants } = useMerchants()
  const { totalBalanceState, balances, resetTotalBalances } = useBalancesWithTotal()
  const { state } = useLocation<{ merchantId?: string; isExchange?: boolean }>()
  const [balanceOption, setBalanceOption] = useState(state?.merchantId ?? PERSONAL_BALANCE_ID)
  const isLg = useMediaQuery({ maxWidth: breakpoints.lg })
  const [toBalanceOption, setToBalanceOption] = useState(
    state?.merchantId ? PERSONAL_BALANCE_ID : ''
  )
  const { resetWithdraw } = useCreateMerchantWithdraw(balanceOption)
  const { resetWithdrawFee } = useWithdrawFee()
  const isInitialMount = useRef(false)
  const [isTransfer, setIsTransfer] = useState(false)
  const { user } = useUser()
  const withdraw2faEnabled = get(user, 'credentials.withdraw2faEnabled', false)

  const isPersonalBalanceSelected = balanceOption === PERSONAL_BALANCE_ID

  useEffect(() => {
    fetchMerchants()
  }, [])

  useEffect(() => {
    return () => {
      resetTotalBalances()
    }
  }, [])

  useEffect(() => {
    resetWithdrawFee()
  }, [isTransfer])

  useEffect(() => {
    if (merchants && merchants.length && (!state || !state.isExchange)) setIsTransfer(true)
  }, [merchants])

  // fetch user balance if selected personal balance, not on mount (it will be done in package by default)
  useEffect(() => {
    if (!isInitialMount.current) {
      isInitialMount.current = true
    } else {
      resetWithdraw()
    }
  }, [balanceOption])

  useEffect(() => {
    if (merchants && merchants.length && !toBalanceOption && isPersonalBalanceSelected)
      setToBalanceOption(merchants[0].id)
  }, [merchants, toBalanceOption, isPersonalBalanceSelected])

  const merchantsLoading = loading ?? !merchants
  const balanceOptions = [
    { value: PERSONAL_BALANCE_ID, balance: totalBalanceState.data!, name: t('personal') },
    ...(merchants ?? []).map((m) => ({ value: m.id, balance: m.totalBalance, name: m.name }))
  ]
  const toMerchantBalances = merchants?.find((m) => m.id === toBalanceOption)?.balances
  const fromBalanceName = balanceOptions.find((b) => b.value === balanceOption)?.name
  const toBalanceName = balanceOptions.find((b) => b.value === toBalanceOption)?.name ?? ''

  if ((state && state.isExchange) ?? (!merchants || merchants.length))
    return (
      <DashboardTransferToPage
        key={Number(isTransfer)}
        mode={state && state.isExchange && ('CONVERT' as any)}
        useExchange={
          isPersonalBalanceSelected
            ? undefined
            : (props: any) => useExchange({ ...props, balanceOption })
        }
        useFiatExchange={
          isPersonalBalanceSelected
            ? undefined
            : (props: any) => useFiatExchange({ ...props, balanceOption })
        }
        useFiatExchangeInfo={
          isPersonalBalanceSelected
            ? undefined
            : () => useMerchantExchangeFiatInfo(balanceOption) as any
        }
        useCryptoExchangeInfo={
          isPersonalBalanceSelected
            ? undefined
            : () => useMerchantExchangeCryptoInfo(balanceOption) as any
        }
        useFiatWithdraw={
          isTransfer
            ? (props: any) =>
                useMerchantTransfer({
                  ...props,
                  merchantId: isPersonalBalanceSelected ? toBalanceOption : balanceOption,
                  from: isPersonalBalanceSelected
                }) as any
            : isPersonalBalanceSelected
            ? undefined
            : (props: any) => useFiatWithdraw({ ...props, balanceOption })
        }
        useWithdraw={
          isTransfer
            ? (props: any) =>
                useMerchantTransfer({
                  ...props,
                  merchantId: isPersonalBalanceSelected ? toBalanceOption : balanceOption,
                  from: isPersonalBalanceSelected
                }) as any
            : isPersonalBalanceSelected
            ? undefined
            : (props: any) => useWithdraw({ ...props, balanceOption }) as any
        }
        useForm={isTransfer ? useMerchantTransferForm : undefined}
        balances={
          isPersonalBalanceSelected
            ? balances
            : merchants?.find((m) => m.id === balanceOption)?.balances
        }
      >
        <Row>
          <Breadcrumbs>
            <Breadcrumb link={routes.dashboard.home} name={t('routes:home')} />
            <Breadcrumb name={t('routes:withdraw')} />
          </Breadcrumbs>
        </Row>
        {!user?.accountVerified && (
          <Alert
            title={t('libNotifications:userVerification.title')}
            description={
              <SmallMedium>
                <Trans i18nKey="merchant:dashboardUserAlert.title">
                  <Link target="_blank" href={process.env.REACT_APP_CHAT} />
                </Trans>
                <ul className="d-flex flex-column align-items-baseline m-0">
                  <li>{t('merchant:dashboardUserAlert.hint1')}</li>
                  <li>{t('merchant:dashboardUserAlert.hint2')}</li>
                </ul>
              </SmallMedium>
            }
          >
            <Button small={!isLg} onClick={() => window.open(process.env.REACT_APP_CHAT, '_blank')}>
              {t('libCommon:goToSupport')}
            </Button>
          </Alert>
        )}
        <Row $verticalGutters>
          <Col xl={7}>
            <Card>
              <CardHeader>
                <CardTitle>{isTransfer ? t('transferBetween') : undefined}</CardTitle>
                {!isTransfer && <CardSubtitle />}
              </CardHeader>
              <CardBody className="pt-0">
                {isTransfer ? (
                  <TransferForm
                    merchantsLoading={merchantsLoading}
                    balanceOption={balanceOption}
                    balanceOptions={balanceOptions}
                    toBalanceOption={toBalanceOption}
                    onBalanceOptionChange={(balance: string) => {
                      setBalanceOption(balance)
                      if (balance === PERSONAL_BALANCE_ID) setToBalanceOption(merchants![0].id)
                      else setToBalanceOption(PERSONAL_BALANCE_ID)
                    }}
                    onToBalanceOptionChange={(balance: string) => {
                      setToBalanceOption(balance)
                      if (balance === PERSONAL_BALANCE_ID) setBalanceOption(merchants![0].id)
                      else setBalanceOption(PERSONAL_BALANCE_ID)
                    }}
                    balanceName={fromBalanceName}
                  />
                ) : (
                  <Form
                    merchantsLoading={merchantsLoading}
                    balanceOption={balanceOption}
                    balanceOptions={balanceOptions}
                    onBalanceOptionChange={(balance: string) => {
                      setBalanceOption(balance)
                      if (balance === PERSONAL_BALANCE_ID) setToBalanceOption(merchants![0].id)
                      else setToBalanceOption(PERSONAL_BALANCE_ID)
                    }}
                    balanceName={fromBalanceName}
                  />
                )}
                <ConfirmModal
                  withdraw2faEnabled={withdraw2faEnabled && !isTransfer}
                  balanceName={fromBalanceName}
                  toComponent={
                    isTransfer
                      ? () => <TransferConfirmModalToBalance balanceName={toBalanceName} />
                      : undefined
                  }
                >
                  {isTransfer && (
                    <TransferTransactionInfoToBalanceAfter
                      balanceName={toBalanceName}
                      balances={toMerchantBalances}
                    />
                  )}
                </ConfirmModal>
              </CardBody>
            </Card>
          </Col>
          <Col xl={5}>
            <TransactionInfoCard
              tooltipServiceName="0xpay"
              balanceName={fromBalanceName}
              isInternalAddress={isTransfer}
            >
              {isTransfer && (
                <TransferTransactionInfoToBalanceAfter
                  balanceName={toBalanceName}
                  balances={toMerchantBalances}
                />
              )}
            </TransactionInfoCard>
            {isTransfer && (
              <ToggleTransfer
                toggle={() => history.push(routes.dashboard.withdraw)}
                isTransfer={isTransfer}
              />
            )}
          </Col>
        </Row>
      </DashboardTransferToPage>
    )
  return <Redirect to={routes.dashboard.withdraw} />
}
