import styled from 'styled-components'
import { Card as CardLib } from '@ppay/client'
import { Ticker } from '@ppay/definitions'

import { Kind } from './Kind'

const StyledCardLib = styled(CardLib)`
  margin-bottom: 5px;
  padding: 20px;
`

const StyledKind = styled(Kind)`
  margin-bottom: 20px;
`

export const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const CardWrapper = ({
  ticker,
  children
}: {
  ticker: Ticker
  children: React.ReactNode
}) => {
  return (
    <StyledCardLib>
      <StyledKind ticker={ticker} />
      <div className="d-flex flex-column">{children}</div>
    </StyledCardLib>
  )
}
