"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notificationColors = void 0;
var _colors = require("./colors");
var notificationColors = {
  success: {
    color: _colors.colors.success,
    bgColor: _colors.colors.lightSuccess
  },
  warning: {
    color: _colors.colors.warning,
    bgColor: _colors.colors.lightWarning
  },
  danger: {
    color: _colors.colors.danger,
    bgColor: _colors.colors.lightDanger
  }
};
exports.notificationColors = notificationColors;