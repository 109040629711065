"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShadowButton = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _BaseButton = require("./BaseButton");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var ShadowButton = (0, _styledComponents.default)(_BaseButton.BaseButton)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 56px;\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n\n  &:hover,\n  &:focus,\n  &:not(:disabled):not(.disabled):active {\n    color: ", ";\n    background-color: ", ";\n    border-color: ", ";\n  }\n  &:focus,\n  &:not(:disabled):not(.disabled):active {\n    border-color: #8c82cb;\n  }\n\n  svg path {\n    ", "\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.tertiary;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.secondaryShade;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.secondaryShade2;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.tertiary;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.secondaryShade2;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.secondaryShade2;
}, function (_ref7) {
  var loading = _ref7.loading;
  return loading && 'fill: initial;';
});
exports.ShadowButton = ShadowButton;