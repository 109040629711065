"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auth = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../actions");
var auth = function auth(store) {
  return function (next) {
    return function (action) {
      if ((action.type === _actions.loginAction.fulfilled.type || action.type === _actions.oAuthGoogleLoginAction.fulfilled.type || action.type === _actions.emailAuthorizationLoginAction.fulfilled.type) && typeof action.payload === 'string') {
        store.dispatch((0, _actions.setAuthorizationTokenAction)(action.payload));
      } else if (action.type === _actions.setAuthorizationTokenAction.type) {
        localStorage.setItem('token', action.payload);
      } else if (action.type === _actions.logoutAction.type) {
        localStorage.removeItem('token');
      } else if ((0, _toolkit.isRejected)(action) && action.error.message === 'Unauthorized') {
        store.dispatch((0, _actions.logoutAction)());
        return;
      }
      next(action);
    };
  };
};
exports.auth = auth;