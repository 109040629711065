import { notificationsMiddleware, CreateWithdrawSuccessNotification } from '@ppay/client'
import {
  UpdateNotificationsUrlSuccessNotification,
  CreateMerchantSuccessNotification,
  CreateTransferSuccessNotification
} from '../../components'
import {
  updateMerchantNotificationsUrl,
  createMerchantAction,
  createMerchantWithdrawAction,
  createMerchantTransferAction,
  createMerchantFiatWithdrawAction,
  createMerchantCryptoExchangeAction,
  createMerchantFiatExchangeAction
} from '../actions'

const Notification = {
  [updateMerchantNotificationsUrl.fulfilled.type]: () => (
    <UpdateNotificationsUrlSuccessNotification />
  ),
  [createMerchantAction.fulfilled.type]: () => <CreateMerchantSuccessNotification />,
  [createMerchantWithdrawAction.fulfilled.type]: () => <CreateWithdrawSuccessNotification />,
  [createMerchantTransferAction.fulfilled.type]: () => <CreateTransferSuccessNotification />,
  [createMerchantFiatWithdrawAction.fulfilled.type]: () => <CreateWithdrawSuccessNotification />,
  [createMerchantCryptoExchangeAction.fulfilled.type]: () => <CreateWithdrawSuccessNotification />,
  [createMerchantFiatExchangeAction.fulfilled.type]: () => <CreateWithdrawSuccessNotification />
}

export const notifications = notificationsMiddleware(Notification)
