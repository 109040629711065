"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWizardContext = void 0;
var _react = require("react");
var _WizardContext = require("./WizardContext");
var useWizardContext = function useWizardContext() {
  return (0, _react.useContext)(_WizardContext.WizardContext);
};
exports.useWizardContext = useWizardContext;