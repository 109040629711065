"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEmail2faSwitch = void 0;
var _state = require("../state");
var useEmail2faSwitch = function useEmail2faSwitch() {
  var _useUser = (0, _state.useUser)(),
    user = _useUser.user;
  var _useEmail2fa = (0, _state.useEmail2fa)(),
    loading = _useEmail2fa.loading,
    enableEmail2fa = _useEmail2fa.enableEmail2fa,
    disableEmail2fa = _useEmail2fa.disableEmail2fa;
  var checked = !!(user !== null && user !== void 0 && user.credentials.email2faEnabled);
  var disabled = !(user !== null && user !== void 0 && user.credentials.emailConfirmed);
  return {
    checked: checked,
    disabled: disabled || loading,
    isLastChecked: checked && !(user !== null && user !== void 0 && user.credentials.totpEnabled),
    enableForbidden: disabled,
    onChange: function onChange() {
      return checked ? disableEmail2fa() : enableEmail2fa();
    }
  };
};
exports.useEmail2faSwitch = useEmail2faSwitch;