"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUpdateEmail = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useUpdateEmail = function useUpdateEmail() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.user.updateEmail;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    updateEmailLoading: state.loading,
    updateEmail: function updateEmail(email) {
      return dispatch((0, _actions.updateEmailAction)(email));
    }
  };
};
exports.useUpdateEmail = useUpdateEmail;