"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTranslation = void 0;
var _react = require("react");
var _contexts = require("../contexts");
var useTranslation = function useTranslation() {
  var _useContext;
  return (_useContext = (0, _react.useContext)(_contexts.HooksContext)).useTranslation.apply(_useContext, arguments);
};
exports.useTranslation = useTranslation;