import { configureStore } from '@reduxjs/toolkit'
import { authMiddleware as authMiddlewareLib, thunkErrorHandlerMiddleware } from '@ppay/client'

import { manualReplenishOrderMiddleware, notificationsMiddleware } from './middlewares'
import { rootReducer } from './reducers'

export type State = ReturnType<typeof rootReducer>
export type Dispatch = typeof store.dispatch

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authMiddlewareLib,
      thunkErrorHandlerMiddleware,
      notificationsMiddleware,
      manualReplenishOrderMiddleware
    ])
})
