"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Totp", {
  enumerable: true,
  get: function get() {
    return _Container.Container;
  }
});
var _Container = require("./Container");