"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCalculateFiatTickerAmount = useCalculateFiatTickerAmount;
var _decimal = _interopRequireDefault(require("decimal.js"));
var _react = require("react");
var _constants = require("../../constants");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function useCalculateFiatTickerAmount(_ref) {
  var price = _ref.price,
    fiatTicker = _ref.fiatTicker;
  return (0, _react.useCallback)(function (amount) {
    if (!amount || !price) return amount;
    return new _decimal.default(amount).mul(price).toFixed(_constants.TickerDecimals[fiatTicker]);
  }, [price, fiatTicker]);
}