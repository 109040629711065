"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePage = void 0;
var _useCryptoPage = require("./useCryptoPage");
var _useFiatPage = require("./useFiatPage");
var usePage = function usePage(_ref) {
  var mode = _ref.mode;
  var cryptoProps = (0, _useCryptoPage.useCryptoPage)();
  var fiatProps = (0, _useFiatPage.useFiatPage)({
    mode: mode
  });
  return {
    crypto: cryptoProps,
    fiat: fiatProps
  };
};
exports.usePage = usePage;