"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEmailAuth = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useEmailAuth = function useEmailAuth() {
  var sendCodeState = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.emailAuth.sendCode;
  });
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.emailAuth.login;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    logined: state.done,
    loading: state.loading,
    login: function login(_ref) {
      var code = _ref.code;
      return dispatch((0, _actions.emailAuthorizationLoginAction)({
        email: sendCodeState.email,
        code: code
      }));
    },
    email: sendCodeState.email,
    reset: function reset() {
      return dispatch((0, _actions.resetEmailAuthorizationAction)());
    },
    code: {
      remainingTime: sendCodeState.remainingTime,
      sendLoading: sendCodeState.loading,
      sent: sendCodeState.done,
      send: function send(email) {
        return dispatch((0, _actions.sendEmailAuthorizationCodeAction)(email !== null && email !== void 0 ? email : sendCodeState.email));
      }
    }
  };
};
exports.useEmailAuth = useEmailAuth;