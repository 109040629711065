"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUserRequirementsWithPolling = void 0;
var _react = require("react");
var _reactRedux = require("react-redux");
var _hooks = require("../../../hooks");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useUserRequirementsWithPolling = function useUserRequirementsWithPolling() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.user.requirements;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  var interval = 1000 * 15; // 15 sec

  (0, _hooks.usePolling)((0, _react.useCallback)(function () {
    return dispatch((0, _actions.fetchUserRequirementsAction)());
  }, []), true, interval);
  return {
    loading: state.loading,
    requirements: state.data
  };
};
exports.useUserRequirementsWithPolling = useUserRequirementsWithPolling;