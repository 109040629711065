import styled from 'styled-components'

const DEFAULT_AVATAR =
  'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'

export const Avatar = styled.img.attrs((props: { src?: string }) => ({
  src: props.src ?? DEFAULT_AVATAR
}))`
  border-radius: 50%;
  outline: none;
  width: 46px;
  height: 46px;
`
