import { createRootReducer, logoutAction } from '@ppay/client'
import { combineReducers } from '@reduxjs/toolkit'

import { merchants } from './merchants'
import { invoice } from './invoice'
import { kyc } from './kyc'
import { manualReplenishOrder } from './manual-replenish-order'

const combinedReducer = combineReducers({
  lib: createRootReducer({ user: { get: () => {} } }),
  merchants,
  kyc,
  manualReplenishOrder,
  invoice
})

export const root = (...[state, action]: Parameters<typeof combinedReducer>) => {
  if (action.type === logoutAction.type) {
    state = { invoice: state?.invoice } as Parameters<typeof combinedReducer>[0]
  }
  return combinedReducer(state, action)
}
