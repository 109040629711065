"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Header = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactstrap = require("reactstrap");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Header = (0, _styledComponents.default)(_reactstrap.CardHeader)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border: none;\n  padding: 20px 20px 30px;\n  background-color: ", ";\n  &:first-child {\n    border-radius: 6px 6px 0 0;\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondary;
});
exports.Header = Header;