import React, { PropsWithChildren } from 'react'
import { AuthLayout, AuthLayoutHeader } from '@ppay/client'
import { useHistory } from 'react-router-dom'

import { Footer, BodyContainer } from '../../layout'
import { routes } from '../../router'

export const Layout = ({ children, logo }: PropsWithChildren<{ logo: string }>) => {
  const history = useHistory()

  return (
    <AuthLayout
      headerComponent={() => (
        <AuthLayoutHeader
          logo={logo}
          onLogoClick={() => history.push(routes.dashboard.home)}
          aboutPath={process.env.REACT_APP_SITE_URL + '/about'}
        />
      )}
      footerComponent={() => (
        <Footer logo={logo} onLogoClick={() => history.push(routes.dashboard.home)} />
      )}
      bodyContainerComponent={BodyContainer as any}
    >
      {children}
    </AuthLayout>
  )
}
