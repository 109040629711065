"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Option = void 0;
var _reactSelect = require("react-select");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Option = (0, _styledComponents.default)(_reactSelect.components.Option)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 8px 18px !important;\n  color: ", " !important;\n  background-color: ", " !important;\n  &:hover {\n    background-color: ", " !important;\n  }\n  &:not(:last-child) {\n    border-bottom: 1px solid ", ";\n  }\n  cursor: pointer !important;\n"])), function (_ref) {
  var theme = _ref.theme,
    isDisabled = _ref.isDisabled;
  return isDisabled ? theme.colors.tertiaryShade : 'initial';
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.secondaryShade;
}, function (_ref3) {
  var theme = _ref3.theme,
    isDisabled = _ref3.isDisabled;
  return isDisabled ? theme.colors.secondaryShade2 : theme.colors.secondaryShade2;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.secondaryShade2;
});
exports.Option = Option;