"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCryptoInfo = void 0;
var _react = require("react");
var _state = require("../../state");
var useCryptoInfo = function useCryptoInfo(_ref) {
  var amount = _ref.amount,
    toAmount = _ref.toAmount,
    ticker = _ref.ticker,
    balance = _ref.balance,
    toTicker = _ref.toTicker,
    blockchain = _ref.blockchain,
    exchangeDone = _ref.exchangeDone,
    exchangeLoading = _ref.exchangeLoading,
    feeLessThanRequired = _ref.feeLessThanRequired,
    side = _ref.side,
    address = _ref.address,
    setFormValue = _ref.setFormValue,
    useGetInfo = _ref.useGetInfo;
  var infoAmount = side === 'spend' ? amount : toAmount;
  var _ref2 = (useGetInfo !== null && useGetInfo !== void 0 ? useGetInfo : _state.useExchangeCryptoInfo)(),
    exchangeCryptoInfoState = _ref2.exchangeCryptoInfoState,
    getExchangeCryptoInfo = _ref2.getExchangeCryptoInfo,
    resetExchangeCryptoInfo = _ref2.resetExchangeCryptoInfo;
  (0, _react.useEffect)(function () {
    return function () {
      resetExchangeCryptoInfo();
    };
  }, []);
  (0, _react.useEffect)(function () {
    if (exchangeCryptoInfoState.data) {
      if (side === 'spend') {
        setFormValue('toAmount', exchangeCryptoInfoState.data.withdraw.amount);
      } else {
        setFormValue('amount', exchangeCryptoInfoState.data.spend.amount);
      }
    }
  }, [exchangeCryptoInfoState.data]);
  (0, _react.useEffect)(function () {
    if (exchangeDone) {
      resetExchangeCryptoInfo();
    }
  }, [exchangeDone]);
  (0, _react.useEffect)(function () {
    if (!amount) resetExchangeCryptoInfo();
  }, [amount]);
  return {
    exchangeInfo: exchangeCryptoInfoState,
    resetExchangeInfo: resetExchangeCryptoInfo,
    getExchangeInfo: (0, _react.useCallback)(function () {
      getExchangeCryptoInfo({
        side: side,
        spendTicker: ticker,
        ticker: toTicker,
        blockchain: blockchain,
        amount: infoAmount,
        to: address
      });
    }, [infoAmount, side, ticker, toTicker, blockchain, address, feeLessThanRequired])
  };
};
exports.useCryptoInfo = useCryptoInfo;