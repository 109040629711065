import Decimal from 'decimal.js'
import { useDispatch } from 'react-redux'

import { getMerchantWithdrawFeeAction, resetGetMerchantWithdrawFeeAction } from '../../actions'
import { useSelector } from '../useSelector'

export const useMerchantWithdrawFee = (merchantId: string) => {
  const state = useSelector((state) => state.merchants.transactions.getWithdrawFee)
  const dispatch = useDispatch()

  return {
    withdrawFeeState: {
      loading: state.loading,
      value: state.data?.value,
      converted: state.data?.converted,
      isInternal: state.data && new Decimal(state.data.value).eq(0)
    },
    fetchWithdrawFee: (
      params: Omit<Parameters<typeof getMerchantWithdrawFeeAction>[0], 'merchantId'>
    ) => dispatch(getMerchantWithdrawFeeAction({ ...params, merchantId })),
    resetWithdrawFee: () => {
      dispatch(resetGetMerchantWithdrawFeeAction())
    }
  }
}
