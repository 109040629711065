"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTotal = exports.get = void 0;
var _fetch = require("./fetch");
var get = function get() {
  return (0, _fetch.fetch)({
    url: '/balances'
  });
};
exports.get = get;
var getTotal = function getTotal() {
  return (0, _fetch.fetch)({
    url: '/balances/total'
  });
};
exports.getTotal = getTotal;