import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import styled from 'styled-components'
import { CryptoTicker, Ticker, CRYPTO_TICKERS } from '@ppay/definitions'
import {
  emailValidation,
  FormGroup,
  Label,
  Button,
  TickerName,
  TickerIcon,
  TickerSelectInput,
  TickerNumberFormatInputField,
  useBlockchainByTicker,
  SelectInput
} from '@ppay/client'

import { CryptoInvoice } from '../../../interfaces'
import { useInvoiceCryptoUpdatePolling, useInvoiceCryptoUpdate } from '../../../state'
import { EmailFormGroup } from '../EmailFormGroup'
import { CardContinue, CardAmountToPay, CardSeparativeLine, CardData } from '../card'
import { Icon } from './../../ui'

const schema = yup
  .object()
  .shape({
    email: emailValidation().required(),
    amount: yup
      .string()
      .when('$withAmount', (withAmount: boolean, schema: any) =>
        withAmount ? schema : schema.required()
      )
  })
  .required()

const TickerNameColor = styled.span`
  color: ${({ theme }) => theme.colors.tertiaryShade};
  margin-left: 4px;
`
const CardDataWithMargin = styled(CardData)`
  margin: 20px 0;
`

const CurrencyWrapper = styled.div`
  display: flex;
  align-items: center;
  ${TickerIcon} {
    margin-right: 6px;
  }
`

export const Continue = ({ invoice }: { invoice: CryptoInvoice }) => {
  const { t } = useTranslation(['invoice', 'libTransfers'])
  const withAmount = invoice?.amount

  const [ticker, setTicker] = useState<CryptoTicker>(
    (invoice?.amount?.ticker ?? Ticker.BTC) as CryptoTicker
  )
  const { blockchain, blockchains, setBlockchain } = useBlockchainByTicker(ticker)
  const { updateInvoice, loading } = useInvoiceCryptoUpdate()

  const networkOptions = blockchains.map((o) => ({
    value: o,
    label: t('libTransfers:blockchains.' + o)
  }))

  const onSubmit = (value: { email: string; amount: string }) => {
    const data = withAmount
      ? {
          email: value.email
        }
      : {
          email: value.email,
          amount: {
            value: value.amount,
            ticker,
            blockchain
          }
        }

    return updateInvoice({ ...data, invoiceId: invoice.id })
  }

  const { handleSubmit, control } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: invoice.email
      ? {
          email: invoice.email
        }
      : undefined,
    context: {
      withAmount
    }
  })

  useInvoiceCryptoUpdatePolling()

  return (
    <>
      <CardContinue invoice={invoice}>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          {withAmount ? (
            <>
              <CardDataWithMargin
                title={t('currency')}
                value={
                  <CurrencyWrapper>
                    <TickerIcon ticker={ticker} size={24} />
                    <TickerName ticker={ticker} />
                    <TickerNameColor>
                      <TickerName ticker={ticker} full />
                    </TickerNameColor>
                  </CurrencyWrapper>
                }
              />
              <CardDataWithMargin
                title={t('libTransfers:network')}
                value={t(`libTransfers:blockchains.${invoice.amount?.blockchain ?? blockchain}`)}
              />
              <CardAmountToPay
                amount={invoice.baseAmount?.value ?? invoice.amount!.value}
                ticker={invoice.baseAmount?.ticker ?? invoice.amount!.ticker}
                text={t('invoice:amountToPay')}
              />
              <CardSeparativeLine />
              <EmailFormGroup t={t} control={control} />
            </>
          ) : (
            <>
              <CardSeparativeLine />
              <EmailFormGroup t={t} control={control} />
              <FormGroup>
                <Label>{t('invoice:currency')}</Label>
                <TickerSelectInput
                  value={ticker}
                  tickers={CRYPTO_TICKERS}
                  onChange={setTicker}
                  control={control}
                  inputProps={{
                    name: 'ticker',
                    ticker
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t('libTransfers:network')}</Label>
                <SelectInput
                  value={blockchain}
                  options={networkOptions}
                  onChange={setBlockchain}
                  defaultValue={networkOptions[0].value}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t('amount')}</Label>
                <TickerNumberFormatInputField
                  control={control}
                  inputProps={{
                    name: 'amount',
                    ticker
                  }}
                />
              </FormGroup>
            </>
          )}
          <Button type="submit" loading={loading} wide icon={<Icon name="arrowRight" />}>
            {t('continueToPay')}
          </Button>
        </Form>
      </CardContinue>
    </>
  )
}
