import { useState, useRef, useEffect, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'reactstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import {
  Link,
  FormGroup,
  Label,
  Button as FormButton,
  Row,
  Col,
  FormFeedback,
  Notification,
  Spinner,
  theme
} from '@ppay/client'

import { useInvoiceFiat, useUser } from '../../../../../state'
import { Icon } from '../../../../ui'
import { Card, CardSeparativeLine, CardData, CardAmountToPay } from '../../../card'
import { InvoiceProps } from '../../Component'

const CardDataWithMargin = styled(CardData)`
  margin-bottom: 20px;
`

const Widget = styled.div<{ loading: boolean }>`
  visibility: ${({ loading }) => (loading ? 'hidden' : 'visible')};
  height: ${({ loading }) => (loading ? '0' : 'auto')};
`

const CardField = styled.div`
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.secondaryShade};
  border: 1px solid ${({ theme }) => theme.colors.secondaryShade2};
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  &::placeholder {
    color: ${({ theme }) => theme.colors.tertiaryShade};
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.tertiary};
    box-shadow: none;
  }
`

const style = {
  base: {
    color: theme.colors.tertiary,
    'font-size': '16px'
  },
  placeholder: {
    color: theme.colors.tertiaryShade
  },
  error: {
    color: theme.colors.danger
  }
}

declare const PMHostedFields: any

export const PaySoftForm = ({ invoice, children, hideButton }: InvoiceProps) => {
  const {
    t,
    i18n: { language }
  } = useTranslation('invoice')
  const { user } = useUser()
  const { successInvoice, failInvoice, notifyFailInvoice } = useInvoiceFiat()
  const [loading, setLoading] = useState(false)
  const [loadingWidget, setLoadingWidget] = useState(true)

  const PaySoft = useRef<any>()

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()
    setLoading(true)
    PaySoft.current?.Pay((result: { status: string; message: string }) => {
      if (result.status !== 'success') {
        const errorCode = result.message.match(/.*\s*\((\d+)\).*/)
        const code = errorCode ? Number(errorCode[1]) : undefined
        if (code) {
          failInvoice({ code, message: result.message, invoiceId: invoice.id })
        } else {
          setLoading(false)
          toast(<Notification title="Error" content={result.message} type="danger" />)
        }
        notifyFailInvoice({
          code,
          status: result.status,
          message: result.message,
          invoiceId: invoice.id,
          merchantName: invoice.company?.name,
          merchantId: invoice.company?.merchantId,
          provider: 'PAYSOFT',
          accountId: user?.accountId,
          email: invoice.email,
          amount: invoice.amount?.value,
          ticker: invoice.amount?.ticker
        })
      } else {
        successInvoice()
      }
    })
  }

  useEffect(() => {
    PaySoft.current = PMHostedFields({
      params: {
        LMI_MERCHANT_ID: process.env.REACT_APP_INVOICE_MERCHANT_ID,
        LMI_PAYMENT_NO: invoice.id,
        LMI_PAYMENT_AMOUNT: invoice.amount?.value,
        LMI_PAYMENT_DESC: invoice.gatewayMeta
      },
      language: language === 'ua' ? 'uk' : language,
      callbacks: {
        onFieldsLoaded: () => setLoadingWidget(false)
      }
    })
  }, [invoice])

  useEffect(() => {
    const cardNumber = PaySoft.current?.create('cardnumber', '#card-number', { style })
    const cardExpireMonth = PaySoft.current?.create('cardexpireyear', '#card-expire-year', {
      style
    })
    const cardExpireYear = PaySoft.current?.create('cardexpiremonth', '#card-expire-month', {
      style
    })
    const cardCVV = PaySoft.current?.create('cardcvv', '#card-cvc', { style })

    const fields = [cardNumber, cardExpireMonth, cardExpireYear, cardCVV]

    fields.forEach(function (element) {
      element?.addEventListener('change', (event: any) => {
        const displayError = document.getElementById('error-' + element.parentNode.id)!
        if (event.detail.error) {
          displayError.textContent = event.detail.error
          displayError.style.display = 'inline-block'
        } else {
          displayError.textContent = ''
          displayError.style.display = 'none'
        }
      })
    })
  }, [])

  useEffect(() => {
    const iframe = document.querySelector('body > iframe') as HTMLElement | null

    if (!iframe) return

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes') {
          iframe.style.background = theme.colors.secondary
        }
      })
    })

    observer.observe(iframe, {
      attributes: true
    })

    return () => observer.disconnect()
  }, [])

  return (
    <Card id={invoice.id} name={invoice.name} description={invoice.description} isFiat={true}>
      {invoice.company && (
        <CardDataWithMargin
          title={t('company')}
          value={
            <Link target="_blank" href={invoice.company.url}>
              {invoice.company.name}
            </Link>
          }
        />
      )}
      <CardAmountToPay
        amount={invoice.amount!.value}
        ticker={invoice.amount!.ticker}
        text={t('amountToPay')}
      />
      <CardSeparativeLine />
      {children && hideButton ? (
        children
      ) : (
        <Form onSubmit={onSubmit} noValidate>
          {loadingWidget && (
            <div className="d-flex justify-content-center">
              <Spinner color="primary" />
            </div>
          )}
          <Widget loading={loadingWidget}>
            <FormGroup>
              <Label>{t('cardNumber')}</Label>
              <CardField id="card-number" />
              <FormFeedback id="error-card-number" />
            </FormGroup>

            <FormGroup>
              <Row>
                <Col xs={4}>
                  <Label>{t('cardExpireMonth')}</Label>
                  <CardField id="card-expire-month" />
                  <FormFeedback id="error-card-expire-month" />
                </Col>
                <Col xs={4}>
                  <Label>{t('cardExpireYear')}</Label>
                  <CardField id="card-expire-year" />
                  <FormFeedback id="error-card-expire-year" />
                </Col>
                <Col xs={4}>
                  <Label>{t('cardCVC')}</Label>
                  <CardField id="card-cvc" />
                  <FormFeedback id="error-card-cvc" />
                </Col>
              </Row>
            </FormGroup>
            <FormButton type="submit" loading={loading} wide icon={<Icon name="arrowRight" />}>
              {t('pay')}
            </FormButton>
          </Widget>
        </Form>
      )}
    </Card>
  )
}
