"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGetWalletAddress = exports.useCreateWalletAddress = void 0;
var _reactRedux = require("react-redux");
var _useSelector = require("../useSelector");
var _actions = require("../../actions");
var useGetWalletAddress = function useGetWalletAddress() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.user.walletAddress.get;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    loadingByBlockchain: state.loadingByBlockchain,
    addresses: state.addresses,
    getWalletAddress: function getWalletAddress() {
      return dispatch(_actions.getWalletAddressAction.apply(void 0, arguments));
    },
    resetGetWalletAddress: function resetGetWalletAddress() {
      return dispatch((0, _actions.resetGetWalletAddressAction)());
    }
  };
};
exports.useGetWalletAddress = useGetWalletAddress;
var useCreateWalletAddress = function useCreateWalletAddress() {
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    loadingByBlockchain: (0, _useSelector.useSelector)(function (state) {
      return state.lib.user.walletAddress.create;
    }).loadingByBlockchain,
    createWalletAddress: function createWalletAddress() {
      return dispatch(_actions.createWalletAddressAction.apply(void 0, arguments));
    }
  };
};
exports.useCreateWalletAddress = useCreateWalletAddress;