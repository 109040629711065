"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requirements = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  loading: false
};
var requirements = (0, _toolkit.createReducer)(initialState, function (builder) {
  builder.addCase(_actions.fetchUserRequirementsAction.pending, function (state) {
    state.loading = true;
  }).addCase(_actions.fetchUserRequirementsAction.rejected, function (state) {
    state.loading = false;
  }).addCase(_actions.fetchUserRequirementsAction.fulfilled, function (state, action) {
    state.loading = false;
    state.data = action.payload;
  });
});
exports.requirements = requirements;