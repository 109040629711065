"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MenuList = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _select = require("../../select");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var MenuList = (0, _styledComponents.default)(_select.SelectMenuList)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 0 !important;\n  border-radius: 4px;\n  background-color: ", " !important;\n  border: 1px solid ", ";\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondary;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.secondaryShade2;
});
exports.MenuList = MenuList;