import { useCreateFiatExchange } from '@ppay/client'

import { createMerchantFiatExchangeAction } from '../../actions'

export const useCreateMerchantFiatExchange = (merchantId: string) => {
  const { createFiatExchange, ...other } = useCreateFiatExchange({
    action: createMerchantFiatExchangeAction,
    statePath: 'merchants.exchange.createFiat'
  })
  return {
    ...other,
    createFiatExchange: (
      params: Omit<Parameters<typeof createMerchantFiatExchangeAction>[0], 'merchantId'>
    ) => createFiatExchange({ ...params, merchantId })
  }
}
