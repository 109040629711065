"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWithdrawFee = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  loading: false,
  data: undefined
};
var getWithdrawFee = (0, _toolkit.createReducer)(initialState, function (builder) {
  builder.addCase(_actions.getWithdrawFeeAction.pending, function (state) {
    state.loading = true;
    state.data = initialState.data;
  }).addCase(_actions.getWithdrawFeeAction.rejected, function (state) {
    state.loading = false;
  }).addCase(_actions.getWithdrawFeeAction.fulfilled, function (state, action) {
    state.loading = false;
    state.data = action.payload;
  }).addCase(_actions.resetGetWithdrawFeeAction, function () {
    return initialState;
  });
});
exports.getWithdrawFee = getWithdrawFee;