import { useFiatWithdraw as useFiatWithdrawLib } from '@ppay/client'

import { useCreateMerchantFiatWithdraw, useMerchantBalances } from '../state'

export const useFiatWithdraw = (props: any) => {
  return useFiatWithdrawLib({
    ...props,
    useBalances: useMerchantBalances.bind(null, props.balanceOption),
    useCreateFiatWithdraw: useCreateMerchantFiatWithdraw.bind(null, props.balanceOption)
  })
}
