"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCalculatedInfo = void 0;
var _useContextSelector = require("use-context-selector");
var _decimal = _interopRequireDefault(require("decimal.js"));
var _react = require("react");
var _components = require("../../components");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var useCalculatedInfo = function useCalculatedInfo() {
  var _balance$value, _balance$converted;
  var _useContext = (0, _react.useContext)(_components.CreateWithdrawContext),
    useWithdrawFee = _useContext.useWithdrawFee;
  var _useWithdrawFee = useWithdrawFee(),
    _useWithdrawFee$withd = _useWithdrawFee.withdrawFeeState,
    fee = _useWithdrawFee$withd.value,
    convertedTickerFee = _useWithdrawFee$withd.converted;
  var _useContext2 = (0, _react.useContext)(_components.SendBalanceContext),
    useBalances = _useContext2.useBalances;
  var _useBalances = useBalances(),
    getBalanceByTicker = _useBalances.getBalanceByTicker;
  var convertedTickerAmount = (0, _useContextSelector.useContextSelector)(_components.SendPageContext, function (state) {
    return state.convertedTickerAmount;
  });
  var amount = (0, _useContextSelector.useContextSelector)(_components.SendPageContext, function (state) {
    return state.amount;
  });
  var isEnoughBalance = (0, _useContextSelector.useContextSelector)(_components.SendPageContext, function (state) {
    return state.isEnoughBalance;
  });
  var ticker = (0, _useContextSelector.useContextSelector)(_components.SendPageContext, function (state) {
    return state.ticker;
  });
  var balance = getBalanceByTicker(ticker);
  var total = new _decimal.default(amount || '0').add(fee !== null && fee !== void 0 ? fee : '0').toString();
  var convertedTickerTotal = new _decimal.default(convertedTickerAmount || '0').add(convertedTickerFee !== null && convertedTickerFee !== void 0 ? convertedTickerFee : '0').toString();
  var balanceAfterTransaction = new _decimal.default((_balance$value = balance === null || balance === void 0 ? void 0 : balance.value) !== null && _balance$value !== void 0 ? _balance$value : '0').sub(isEnoughBalance ? total : '0').toString();
  var convertedBalanceAfterTransaction = new _decimal.default((_balance$converted = balance === null || balance === void 0 ? void 0 : balance.converted) !== null && _balance$converted !== void 0 ? _balance$converted : '0').sub(isEnoughBalance ? convertedTickerTotal : '0').toString();
  if (new _decimal.default(balanceAfterTransaction).lt(0) && balance) {
    balanceAfterTransaction = balance.value;
    convertedBalanceAfterTransaction = balance.converted;
  }
  return {
    fee: fee,
    convertedTickerFee: convertedTickerFee,
    total: total,
    convertedTickerTotal: convertedTickerTotal,
    balanceAfterTransaction: balanceAfterTransaction,
    convertedBalanceAfterTransaction: convertedBalanceAfterTransaction
  };
};
exports.useCalculatedInfo = useCalculatedInfo;