import { Ticker } from '@ppay/definitions'
import { H6, Small, TickerIcon, TickerName, mediaQuery } from '@ppay/client'
import styled from 'styled-components'

const TertiaryShadeText = styled(Small)`
  color: ${({ theme }) => theme.colors.tertiaryShade};
`

const Wrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  div {
    margin-left: 10px;
  }
  ${mediaQuery.lessThan('md')`
    &, div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 0;
    }
    ${TickerIcon} {
      margin-bottom: 10px;
    }
  `}
`

export const Kind = ({ ticker, className }: { ticker: Ticker; className?: string }) => (
  <Wrapper className={className}>
    <TickerIcon ticker={ticker} size={40} />
    <div>
      <H6>
        <TickerName ticker={ticker} />
      </H6>
      <TertiaryShadeText>
        <TickerName ticker={ticker} full />
      </TertiaryShadeText>
    </div>
  </Wrapper>
)
