import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link, H2 as H2UI } from '@ppay/client'

import { FiatInvoice, CryptoInvoice } from '../../../interfaces'
import { Card } from './Card'
import { Data } from './Data'
import { SeparativeLine } from './SeparativeLine'
import { AmountToPay as AmountToPayUI } from './AmountToPay'
import { BackToWebsiteButton } from './BackToWebsiteButton'
import { PaidAmount } from './PaidAmount'

const H2 = styled(H2UI)`
  margin-bottom: 20px;
  text-align: center;
`

const AmountToPay = styled(AmountToPayUI)`
  margin-bottom: 20px;
`

export const Success = ({ invoice }: { invoice: FiatInvoice | CryptoInvoice }) => {
  const { t } = useTranslation('invoice')

  return (
    <Card id={invoice.id} name={invoice.name} description={invoice.description}>
      {invoice.company && (
        <Data
          title={t('company')}
          value={
            <Link target="_blank" href={invoice.company.url}>
              {invoice.company.name}
            </Link>
          }
        />
      )}
      <SeparativeLine />

      <H2>{t('paid')} 🎉 🎉 🎉</H2>

      <AmountToPay
        amount={invoice.amount!.value}
        ticker={invoice.amount!.ticker}
        text={t('amount')}
      />
      {'paidAmount' in invoice && invoice.paidAmount && (
        <PaidAmount
          amount={invoice.amount!.value}
          paidAmount={invoice!.paidAmount}
          ticker={invoice.amount!.ticker}
        />
      )}
      {invoice.redirectUrl && <BackToWebsiteButton redirectUrl={invoice.redirectUrl} />}
    </Card>
  )
}
