"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  NotificationsContainer: true,
  GlobalToastify: true
};
Object.defineProperty(exports, "GlobalToastify", {
  enumerable: true,
  get: function get() {
    return _Container.GlobalToastify;
  }
});
Object.defineProperty(exports, "NotificationsContainer", {
  enumerable: true,
  get: function get() {
    return _Container.Container;
  }
});
var _Container = require("./Container");
var _Notification = require("./Notification");
Object.keys(_Notification).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Notification[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Notification[key];
    }
  });
});