"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Body = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _layout = require("../../../layout");
var _utils = require("../../../../utils");
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Body = (0, _styledComponents.default)(_layout.LayoutBody)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  z-index: 2;\n  max-width: 500px;\n  width: 100%;\n  margin-top: -323px;\n  ", "\n"])), _utils.mediaQuery.lessThan('sm')(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    max-width: 343px;\n  "]))));
exports.Body = Body;