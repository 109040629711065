"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.signUp = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  loading: false,
  done: false
};
var signUp = (0, _toolkit.createReducer)(initialState, function (builder) {
  builder.addCase(_actions.signUpAction.pending, function (state) {
    state.loading = true;
  });
  builder.addCase(_actions.signUpAction.rejected, function (state) {
    state.loading = false;
  });
  builder.addCase(_actions.signUpAction.fulfilled, function (state) {
    state.loading = false;
    state.done = true;
  });
  builder.addCase(_actions.resetSignUpAction, function () {
    return initialState;
  });
});
exports.signUp = signUp;