import { LayoutHeaderNavItem, LayoutHeaderNav } from '@ppay/client'
import React from 'react'

import { MerchantsDropdown } from './MerchantsDropdown'

export const Nav = ({ children, isSm }: React.PropsWithChildren<{ isSm?: boolean }>) => {
  return (
    <LayoutHeaderNav>
      <LayoutHeaderNavItem>
        <MerchantsDropdown collapse={isSm} />
      </LayoutHeaderNavItem>
      {children}
    </LayoutHeaderNav>
  )
}
