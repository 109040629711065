import { useFiatExchange as useFiatExchangeLib } from '@ppay/client'

import { useCreateMerchantFiatExchange, useMerchantBalances } from '../state'

export const useFiatExchange = (props: any) => {
  const useBalances = useMerchantBalances.bind(null, props.balanceOption)()
  return useFiatExchangeLib({
    ...props,
    fetchBalances: () => null,
    useBalances: () => useBalances,
    useCreateExchange: useCreateMerchantFiatExchange.bind(null, props.balanceOption)
  })
}
