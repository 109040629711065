"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useForm = useForm;
var _yup = require("@hookform/resolvers/yup");
var _react = require("react");
var _reactHookForm = require("react-hook-form");
var yup = _interopRequireWildcard(require("yup"));
var _validations = require("../../validations");
var _useCalculateAmount = require("./useCalculateAmount");
var _useCalculateFiatTickerAmount = require("./useCalculateFiatTickerAmount");
var _excluded = ["ticker", "fiatTicker", "onAmountCleared", "price", "balance", "blockchain", "defaultValues", "context"];
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var baseSchema = yup.object().shape({
  address: _validations.cryptoAddressValidation.required(),
  amount: _validations.enoughBalanceValidation.required()
}).required();
function useForm(_ref) {
  var _props$schema;
  var ticker = _ref.ticker,
    fiatTicker = _ref.fiatTicker,
    onAmountCleared = _ref.onAmountCleared,
    price = _ref.price,
    balance = _ref.balance,
    blockchain = _ref.blockchain,
    _ref$defaultValues = _ref.defaultValues,
    defaultValues = _ref$defaultValues === void 0 ? {} : _ref$defaultValues,
    _ref$context = _ref.context,
    context = _ref$context === void 0 ? {} : _ref$context,
    props = _objectWithoutProperties(_ref, _excluded);
  var schema = (_props$schema = props.schema) !== null && _props$schema !== void 0 ? _props$schema : baseSchema;
  var calculateAmount = (0, _useCalculateAmount.useCalculateAmount)({
    price: price,
    ticker: ticker
  });
  var calculateFiatTickerAmount = (0, _useCalculateFiatTickerAmount.useCalculateFiatTickerAmount)({
    price: price,
    fiatTicker: fiatTicker
  });
  var _useState = (0, _react.useState)(),
    _useState2 = _slicedToArray(_useState, 2),
    focusedInput = _useState2[0],
    setFocusedInput = _useState2[1];
  var formProviderProps = (0, _reactHookForm.useForm)({
    mode: 'onTouched',
    resolver: (0, _yup.yupResolver)(schema),
    context: _objectSpread({
      ticker: ticker,
      blockchain: blockchain,
      balance: balance
    }, context),
    defaultValues: _objectSpread({
      amount: '',
      fiatTickerAmount: '',
      address: ''
    }, defaultValues)
  });

  // watch inputs values change
  var amount = (0, _reactHookForm.useWatch)({
    name: 'amount',
    control: formProviderProps.control
  });
  var fiatTickerAmount = (0, _reactHookForm.useWatch)({
    name: 'fiatTickerAmount',
    control: formProviderProps.control
  });
  var address = (0, _reactHookForm.useWatch)({
    name: 'address',
    control: formProviderProps.control
  });
  (0, _react.useEffect)(function () {
    if (!amount) onAmountCleared();
  }, [amount]);
  (0, _react.useEffect)(function () {
    if (focusedInput === 'amount' && price) formProviderProps.setValue('fiatTickerAmount', calculateFiatTickerAmount(amount));
  }, [amount, price, focusedInput]);
  (0, _react.useEffect)(function () {
    if (focusedInput === 'fiatTickerAmount' && price) {
      formProviderProps.setValue('amount', calculateAmount(fiatTickerAmount));
    }
  }, [fiatTickerAmount, price, focusedInput]);

  // recalculate fiat ticker amount in case of price change (ticker changed)
  (0, _react.useEffect)(function () {
    if (price) {
      setFocusedInput(undefined);
      formProviderProps.setValue('fiatTickerAmount', calculateFiatTickerAmount(amount));
    }
  }, [price]);

  // trigger amount validation when balance changes (because of fee change)
  (0, _react.useEffect)(function () {
    if (amount) {
      formProviderProps.trigger('amount');
    }
  }, [balance]);

  // trigger address validation when ticker changes
  (0, _react.useEffect)(function () {
    if (address) formProviderProps.trigger('address');
  }, [ticker, blockchain]);
  return {
    formProviderProps: formProviderProps,
    amount: amount,
    fiatTickerAmount: fiatTickerAmount,
    address: address,
    setFocusedInput: setFocusedInput
  };
}