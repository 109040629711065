import { useCreateWithdraw } from '@ppay/client'

import { createMerchantFiatWithdrawAction } from '../../actions'

export const useCreateMerchantFiatWithdraw = (merchantId: string) => {
  const { createWithdraw, ...other } = useCreateWithdraw({
    action: createMerchantFiatWithdrawAction,
    statePath: 'merchants.transactions.createFiatWithdraw'
  })
  return {
    ...other,
    createWithdraw: (
      params: Omit<Parameters<typeof createMerchantFiatWithdrawAction>[0], 'merchantId'>
    ) => createWithdraw({ ...params, merchantId })
  }
}
