import React, { useEffect } from 'react'
import { Requirements, Ticker } from '@ppay/definitions'
import { logoutAction, Spinner, useAuthToken, useUser } from '@ppay/client'

import { FiatInvoice } from '../../../interfaces'
import { useDispatch } from '../../../state'

import { Component } from './Component'
import { EmailAuth } from './EmailAuth'
import { KYCContainer } from './KYCContainer'

export const UserContainer = ({
  invoice,
  ticker,
  setTicker
}: {
  invoice: FiatInvoice
  setTicker: (ticker: Ticker) => void
  ticker: Ticker
}) => {
  const { user, fetchUser } = useUser()
  const token = useAuthToken()
  const dispatch = useDispatch()

  useEffect(() => {
    if (token && invoice.requirements.values.length) fetchUser()
  }, [token])

  if (!invoice.requirements.values.length)
    return <Component invoice={invoice} ticker={ticker} setTicker={setTicker} />

  const isKYCRequired = Boolean(
    invoice.requirements.values.find(
      (requirement) => requirement.ticker === ticker && requirement.value === Requirements.KYC
    )
  )
  if (!isKYCRequired) return <Component invoice={invoice} ticker={ticker} setTicker={setTicker} />

  if (!token) return <EmailAuth invoice={invoice} ticker={ticker} setTicker={setTicker} />

  return !user ? (
    <div className="d-flex justify-content-center mt-auto">
      <Spinner color="primary" />
    </div>
  ) : (
    <KYCContainer
      logout={() => dispatch(logoutAction())}
      invoice={invoice}
      setTicker={setTicker}
      ticker={ticker}
      email={user.credentials.email}
    />
  )
}
