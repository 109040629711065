"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePersonal = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  loading: false
};
var updatePersonal = (0, _toolkit.createReducer)(initialState, function (builder) {
  builder.addCase(_actions.updatePersonalAction.pending, function (state) {
    state.loading = true;
  });
  builder.addCase(_actions.updatePersonalAction.rejected, function (state) {
    state.loading = false;
  });
  builder.addCase(_actions.updatePersonalAction.fulfilled, function (state) {
    state.loading = false;
  });
});
exports.updatePersonal = updatePersonal;