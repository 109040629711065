import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link, H2, H3 } from '@ppay/client'

import { FiatInvoice } from '../../../interfaces'
import {
  Card,
  CardAmountToPay as CardAmountToPayUI,
  CardData,
  CardSeparativeLine,
  CardBackToWebsiteButton
} from '../card'
import { Icon } from '../../ui'

const CardAmountToPay = styled(CardAmountToPayUI)`
  margin: 20px 0;
`
const BankAnswer = styled(H3)`
  margin: 20px 0;
`

export const Failure = ({ invoice }: { invoice: FiatInvoice }) => {
  const { t } = useTranslation('invoice')

  return (
    <Card id={invoice.id} name={invoice.name} description={invoice.description} isFiat={true}>
      {invoice.company && (
        <CardData
          title={t('company')}
          value={
            <Link target="_blank" href={invoice.company.url}>
              {invoice.company.name}
            </Link>
          }
        />
      )}
      <CardSeparativeLine />

      <div className="text-center">
        <H2>{t('failed')} 😔</H2>

        <BankAnswer>
          {t('declined')}: {invoice.paymentError?.message}
        </BankAnswer>

        <Link href={process.env.REACT_APP_CHAT} target="_blank" color="tertiaryShade">
          {t('contact0xpaySupport')}{' '}
          <Icon name="arrowRotate" rotate={180} size={10} color="tertiaryShade" />
        </Link>
      </div>

      <CardAmountToPay
        amount={invoice.amount!.value}
        ticker={invoice.amount!.ticker}
        text={t('amount')}
      />
      {invoice.redirectUrl && <CardBackToWebsiteButton redirectUrl={invoice.redirectUrl} />}
    </Card>
  )
}
