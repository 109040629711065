export const routes = {
  auth: {
    layout: '/auth',
    login: '/auth/login',
    signUp: '/auth/sign-up',
    restore: '/auth/restore',
    providePersonal: '/auth/provide-personal'
  },
  dashboard: {
    layout: '/',
    home: '/',
    withdraw: '/send/v2',
    oldWithdraw: '/send',
    replenish: '/replenish',
    wallets: '/wallets',
    settings: '/settings',
    transactions: '/transactions',
    merchants: {
      main: '/merchants',
      create: '/merchants/create'
    }
  },
  invoices: {
    layout: '/invoices',
    fiat: '/invoices/fiat',
    crypto: '/invoices/crypto'
  },
  notFound: '/not-found'
}
