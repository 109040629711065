import { combineReducers } from '@reduxjs/toolkit'

import { transactions } from './transactions'
import { create } from './create'
import { get } from './get'
import { updateNotificationsUrl } from './updateNotificationsUrl'
import { getBalances } from './getBalances'
import { getTotalBalance } from './getTotalBalance'
import { exchange } from './exchange'
import { getPaymentsStats } from './getPaymentsStats'

export const merchants = combineReducers({
  create,
  getBalances,
  getTotalBalance,
  transactions,
  get,
  updateNotificationsUrl,
  exchange,
  getPaymentsStats
})
