import { InvoiceApi } from '../../../../api'

import { updateInvoiceFiatAction } from '../../../actions'
import { useDispatch } from '../../useDispatch'
import { useSelector } from '../../useSelector'

export const useUpdate = () => {
  const createState = useSelector((state) => state.invoice.fiat.update)
  const dispatch = useDispatch()

  return {
    loading: createState.loading,
    updateInvoice: (params: Parameters<typeof InvoiceApi.updateFiat>[0]) =>
      dispatch(updateInvoiceFiatAction(params))
  }
}
