import { RouterLink as RouterLinkLib, Link } from '@ppay/client'
import React from 'react'

const RouterLink = ({
  children,
  href,
  ...props
}: React.PropsWithChildren<{ href: string; itemProp: string }>) => (
  <RouterLinkLib to={href} {...props}>
    {children}
  </RouterLinkLib>
)

export const Breadcrumb = ({ link, name }: { link?: string; name: string }) => {
  const LinkComponent = link === process.env.REACT_APP_SITE_URL ? Link : RouterLink
  return (
    <div itemScope itemType="http://data-vocabulary.org/Breadcrumb">
      {link ? (
        <LinkComponent href={link} itemProp="url">
          <span itemProp="title">{name}</span>
        </LinkComponent>
      ) : (
        <span itemProp="title">{name}</span>
      )}
    </div>
  )
}
