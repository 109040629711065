import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Ticker } from '@ppay/definitions'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardSubtitle,
  H4,
  Spinner,
  SeparativeLine as SeparativeLineUI,
  RoundButton,
  mediaQuery,
  DashboardPortfolioCardTotalBalance,
  DashboardPortfolioCardChart,
  usePortfolioBalance
} from '@ppay/client'

import { routes } from '../../../router'
import { useMerchantBalances, useTotalMerchantBalance } from '../../../../state'
import { Icon } from '../../../ui'

const SeparativeLine = styled(SeparativeLineUI)`
  margin: 20px 0;
`

const Label = styled.span`
  margin-right: 10px;
  cursor: pointer;
  ${mediaQuery.lessThan('sm')`
    display:none;
  `}
`

const ActiveZoneWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-of-type) {
    margin-right: 20px;
  }
`

export const PortfolioOverviewCard = ({
  merchantId,
  className
}: {
  merchantId: string
  className?: string
}) => {
  const history = useHistory()
  const { t } = useTranslation('merchant')

  const { balances: merchantBalances, balancesLoading } = useMerchantBalances(merchantId)
  const { totalBalance } = useTotalMerchantBalance(merchantId)

  const { loading, minPercentBalances, otherBalanceTotal } = usePortfolioBalance({
    balances: merchantBalances,
    balancesLoading
  })

  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle tag={H4}>{t('portfolioOverview')}</CardTitle>
        <CardSubtitle>{t('portfolioOverviewDesc')}</CardSubtitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spinner color="primary" />
        ) : (
          <DashboardPortfolioCardChart
            balances={minPercentBalances}
            otherBalances={otherBalanceTotal}
          />
        )}
        <SeparativeLine />
        <DashboardPortfolioCardTotalBalance
          label={t('totalMerchantBalances')}
          value={totalBalance}
          ticker={Ticker.USD}
        />
        <SeparativeLine />
        <div className="d-flex justify-content-center">
          <ActiveZoneWrapper
            onClick={() => history.push(routes.dashboard.withdraw, { merchantId })}
          >
            <Label>{t('transfer')}</Label>
            <RoundButton icon={<Icon name="transfer" />} />
          </ActiveZoneWrapper>

          <ActiveZoneWrapper
            onClick={() =>
              history.push(`${routes.dashboard.merchants.main}/${merchantId}/settings`)
            }
          >
            <Label>{t('settings')}</Label>
            <RoundButton icon={<Icon name="settingsKey" />} />
          </ActiveZoneWrapper>
        </div>
      </CardBody>
    </Card>
  )
}
