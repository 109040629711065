"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSignUp = void 0;
var _reactRedux = require("react-redux");
var _useSelector = require("../useSelector");
var _actions = require("../../actions");
var useSignUp = function useSignUp() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.signUp;
  });
  return {
    signUp: function signUp(data) {
      dispatch((0, _actions.signUpAction)(data));
    },
    reset: function reset() {
      return dispatch((0, _actions.resetSignUpAction)());
    },
    loading: state.loading,
    done: state.done
  };
};
exports.useSignUp = useSignUp;