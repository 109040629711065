"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.prices = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../actions");
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var initialState = {
  get: {
    data: {}
  },
  getVolume: {
    loading: false,
    data: {}
  }
};
var prices = (0, _toolkit.createReducer)(initialState, function (builder) {
  // get prices
  builder.addCase(_actions.getPriceAction.pending, function (state, action) {
    state.get = _objectSpread(_objectSpread({}, state.get), {}, {
      loadingByTicker: action.meta.arg.ticker
    });
  });
  builder.addCase(_actions.getPriceAction.rejected, function (state) {
    state.get.loadingByTicker = undefined;
  });
  builder.addCase(_actions.getPriceAction.fulfilled, function (state, action) {
    var _state$get$data$from;
    var _action$payload = action.payload,
      from = _action$payload.from,
      to = _action$payload.to,
      value = _action$payload.value;
    state.get.loadingByTicker = undefined;
    state.get.data[from] = _objectSpread(_objectSpread({}, (_state$get$data$from = state.get.data[from]) !== null && _state$get$data$from !== void 0 ? _state$get$data$from : {}), {}, _defineProperty({}, to, value));
  });
  // get prices volume
  builder.addCase(_actions.getPriceVolumeAction.pending, function (state) {
    state.getVolume.loading = true;
  });
  builder.addCase(_actions.getPriceVolumeAction.rejected, function (state) {
    state.getVolume.loading = false;
  });
  builder.addCase(_actions.getPriceVolumeAction.fulfilled, function (state, action) {
    state.getVolume.loading = false;
    state.getVolume.data[action.meta.arg] = action.payload;
  });
  builder.addCase(_actions.resetGetPriceVolumeAction, function (state) {
    return _objectSpread(_objectSpread({}, state), {}, {
      getVolume: initialState.getVolume
    });
  });
});
exports.prices = prices;