"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVolume = exports.get = void 0;
var _definitions = require("@ppay/definitions");
var _fetch = require("./fetch");
var get = function get(ticker) {
  var toTicker = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _definitions.Ticker.USD;
  return (0, _fetch.fetch)({
    url: "/prices/".concat(ticker, "?toTicker=").concat(toTicker)
  });
};
exports.get = get;
var getVolume = function getVolume(ticker) {
  return (0, _fetch.fetch)({
    url: "/prices/".concat(ticker, "/volume")
  });
};
exports.getVolume = getVolume;