import { useCallback } from 'react'
import { usePolling as usePollingLib } from '@ppay/client'
import { useParams } from 'react-router-dom'

import { useDispatch } from '../../useDispatch'
import { getInvoiceCryptoAction } from '../../../actions'

export const useUpdatePolling = () => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()

  usePollingLib(
    useCallback(() => dispatch(getInvoiceCryptoAction(id)), []),
    true,
    5000
  )
}
