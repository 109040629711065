"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Table = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactstrap = require("reactstrap");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Table = (0, _styledComponents.default)(_reactstrap.Table)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  border-collapse: separate;\n  border-spacing: 0 10px;\n  margin: -10px 0;\n  th,\n  thead th {\n    border: none;\n  }\n"])));
exports.Table = Table;