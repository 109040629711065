"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _useToggle = require("./useToggle");
Object.keys(_useToggle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useToggle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useToggle[key];
    }
  });
});
var _useLoginWizard = require("./useLoginWizard");
Object.keys(_useLoginWizard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useLoginWizard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useLoginWizard[key];
    }
  });
});
var _useRestorePasswordWithWizard = require("./useRestorePasswordWithWizard");
Object.keys(_useRestorePasswordWithWizard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useRestorePasswordWithWizard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useRestorePasswordWithWizard[key];
    }
  });
});
var _useSignUpWithWizard = require("./useSignUpWithWizard");
Object.keys(_useSignUpWithWizard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useSignUpWithWizard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useSignUpWithWizard[key];
    }
  });
});
var _withdraw = require("./withdraw");
Object.keys(_withdraw).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _withdraw[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _withdraw[key];
    }
  });
});
var _useEmail2faSwitch = require("./useEmail2faSwitch");
Object.keys(_useEmail2faSwitch).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useEmail2faSwitch[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useEmail2faSwitch[key];
    }
  });
});
var _useEnableTotpWithWizard = require("./useEnableTotpWithWizard");
Object.keys(_useEnableTotpWithWizard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useEnableTotpWithWizard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useEnableTotpWithWizard[key];
    }
  });
});
var _useBlockchainByTicker = require("./useBlockchainByTicker");
Object.keys(_useBlockchainByTicker).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useBlockchainByTicker[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useBlockchainByTicker[key];
    }
  });
});
var _usePortfolioBalance = require("./usePortfolioBalance");
Object.keys(_usePortfolioBalance).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _usePortfolioBalance[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _usePortfolioBalance[key];
    }
  });
});
var _useTranslation = require("./useTranslation");
Object.keys(_useTranslation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useTranslation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useTranslation[key];
    }
  });
});
var _replenish = require("./replenish");
Object.keys(_replenish).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _replenish[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _replenish[key];
    }
  });
});
var _useWithdraw2faSwitch = require("./useWithdraw2faSwitch");
Object.keys(_useWithdraw2faSwitch).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useWithdraw2faSwitch[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useWithdraw2faSwitch[key];
    }
  });
});
var _usePolling = require("./usePolling");
Object.keys(_usePolling).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _usePolling[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _usePolling[key];
    }
  });
});
var _exchange = require("./exchange");
Object.keys(_exchange).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _exchange[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _exchange[key];
    }
  });
});
var _send = require("./send");
Object.keys(_send).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _send[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _send[key];
    }
  });
});