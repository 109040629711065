import { Ticker } from '@ppay/definitions'
import { TickerNumberFormat, SelectInput, SelectInputProps, P, Small } from '@ppay/client'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const TertiarySmall = styled(Small)`
  color: ${({ theme }) => theme.colors.tertiaryShade};
  margin-left: 4px;
`

export const BalanceSelectInput = ({
  balanceOptions,
  ...props
}: Omit<SelectInputProps, 'value' | 'options'> & {
  value: string
  balanceOptions: Array<{ value: string; balance: string; name: string }>
}) => {
  const { t } = useTranslation('libCommon')
  const options = balanceOptions.map((o) => {
    return {
      value: o.value,
      label: (
        <div className="d-flex align-items-center justify-content-between">
          <P>
            {o.name} <Small>{t('balance')}</Small>
          </P>
          {o.balance && (
            <TertiarySmall>
              <TickerNumberFormat value={o.balance} ticker={Ticker.USD} /> {Ticker.USD}
            </TertiarySmall>
          )}
        </div>
      )
    }
  })

  return (
    <SelectInput
      {...props}
      options={options}
      defaultValue={options[0].value}
      isSearchable={false}
    />
  )
}
