"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEnableTotpWithWizard = void 0;
var _react = require("react");
var _state = require("../state");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var useEnableTotpWithWizard = function useEnableTotpWithWizard() {
  var _getTotpKeyState$data, _getTotpKeyState$data2, _getTotpKeyState$data3, _getTotpKeyState$data4;
  var _useState = (0, _react.useState)(0),
    _useState2 = _slicedToArray(_useState, 2),
    step = _useState2[0],
    setStep = _useState2[1];
  var _useTotpKey = (0, _state.useTotpKey)(),
    getTotpKeyState = _useTotpKey.getTotpKeyState,
    getTotpKey = _useTotpKey.getTotpKey,
    resetTotpKey = _useTotpKey.resetTotpKey;
  var _useEnableTotp = (0, _state.useEnableTotp)(),
    enableTotpState = _useEnableTotp.enableTotpState,
    resetEnableTotp = _useEnableTotp.resetEnableTotp,
    _enableTotp = _useEnableTotp.enableTotp;

  // getKey
  (0, _react.useEffect)(function () {
    if (step === 1 && !getTotpKeyState.data && !getTotpKeyState.loading) getTotpKey();
  }, [step, getTotpKeyState.data]);

  // reset enable state on success
  (0, _react.useEffect)(function () {
    if (enableTotpState.done) {
      resetEnableTotp();
    }
  }, [enableTotpState.done]);

  // reset get key on unmount
  (0, _react.useEffect)(function () {
    return function () {
      setStep(0);
      resetTotpKey();
    };
  }, []);
  return {
    step: step,
    setStep: setStep,
    enableTotpLoading: enableTotpState.loading,
    enableTotp: function enableTotp(params) {
      return _enableTotp({
        key: getTotpKeyState.data.key,
        token: params.code
      });
    },
    setPrevStep: function setPrevStep() {
      return setStep(step - 1);
    },
    setNextStep: function setNextStep() {
      return setStep(step + 1);
    },
    totpKeyLoading: getTotpKeyState.loading || !getTotpKeyState.data,
    totpKey: (_getTotpKeyState$data = (_getTotpKeyState$data2 = getTotpKeyState.data) === null || _getTotpKeyState$data2 === void 0 ? void 0 : _getTotpKeyState$data2.key) !== null && _getTotpKeyState$data !== void 0 ? _getTotpKeyState$data : '',
    totpQrUrl: (_getTotpKeyState$data3 = (_getTotpKeyState$data4 = getTotpKeyState.data) === null || _getTotpKeyState$data4 === void 0 ? void 0 : _getTotpKeyState$data4.otpauthUrl) !== null && _getTotpKeyState$data3 !== void 0 ? _getTotpKeyState$data3 : ''
  };
};
exports.useEnableTotpWithWizard = useEnableTotpWithWizard;