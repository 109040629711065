"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Tab = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Tab = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: relative;\n  padding: 6px 16px 10px;\n  color: ", ";\n  cursor: ", ";\n  white-space: nowrap;\n  &:after {\n    content: '';\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background: ", ";\n    height: 4px;\n    border-radius: ", ";\n  }\n"])), function (_ref) {
  var theme = _ref.theme,
    active = _ref.active,
    disabled = _ref.disabled;
  return active ? theme.colors.tertiary : disabled ? theme.colors.secondaryShade2 : theme.colors.tertiaryShade;
}, function (_ref2) {
  var disabled = _ref2.disabled;
  return disabled ? 'default' : 'pointer';
}, function (_ref3) {
  var theme = _ref3.theme,
    active = _ref3.active;
  return active ? theme.colors.primary : theme.colors.secondaryShade2;
}, function (_ref4) {
  var active = _ref4.active;
  return active ? '2px' : 0;
});
exports.Tab = Tab;