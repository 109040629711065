"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Container = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactstrap = require("reactstrap");
var _utils = require("../../utils");
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Container = (0, _styledComponents.default)(_reactstrap.Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: 1320px;\n  ", "\n"])), _utils.mediaQuery.greaterThan('md')(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 0 30px;\n  "]))));
exports.Container = Container;