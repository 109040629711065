import { Col, Row } from '@ppay/client'

import { Form } from './Form'
import { InformationSection } from '../InformationSection'

export const Notifications = ({ merchantId }: { merchantId: string }) => {
  return (
    <Row>
      <Col xl={6}>
        <Form merchantId={merchantId} />
      </Col>
      <Col xl={6}>
        <InformationSection />
      </Col>
    </Row>
  )
}
