"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _FormFeedback = require("./FormFeedback");
Object.keys(_FormFeedback).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _FormFeedback[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _FormFeedback[key];
    }
  });
});
var _FormProps = require("./FormProps");
Object.keys(_FormProps).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _FormProps[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _FormProps[key];
    }
  });
});
var _FormGroup = require("./FormGroup");
Object.keys(_FormGroup).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _FormGroup[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _FormGroup[key];
    }
  });
});
var _Label = require("./Label");
Object.keys(_Label).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Label[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Label[key];
    }
  });
});
var _InfoLabel = require("./InfoLabel");
Object.keys(_InfoLabel).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _InfoLabel[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _InfoLabel[key];
    }
  });
});
var _inputs = require("./inputs");
Object.keys(_inputs).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _inputs[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _inputs[key];
    }
  });
});