"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.emailAuth = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  sendCode: {
    loading: false
  },
  login: {
    loading: false
  }
};
var emailAuth = (0, _toolkit.createReducer)(initialState, function (builder) {
  builder.addCase(_actions.sendEmailAuthorizationCodeAction.pending, function (state, action) {
    state.sendCode = {
      loading: true,
      email: action.meta.arg
    };
  });
  builder.addCase(_actions.sendEmailAuthorizationCodeAction.rejected, function (state, action) {
    var _action$payload;
    state.sendCode.loading = false;
    state.sendCode.remainingTime = (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data.time;
    state.sendCode.email = action.meta.arg;
    state.sendCode.done = true;
  });
  builder.addCase(_actions.sendEmailAuthorizationCodeAction.fulfilled, function (state, action) {
    state.sendCode.loading = false;
    state.sendCode.done = true;
    state.sendCode.email = action.meta.arg;
    state.sendCode.remainingTime = action.payload.time;
  });
  builder.addCase(_actions.emailAuthorizationLoginAction.pending, function (state) {
    state.login.loading = true;
  });
  builder.addCase(_actions.emailAuthorizationLoginAction.rejected, function (state) {
    state.login.loading = false;
  });
  builder.addCase(_actions.emailAuthorizationLoginAction.fulfilled, function (state) {
    state.login.loading = false;
    state.login.done = true;
  });
  builder.addCase(_actions.resetEmailAuthorizationAction, function () {
    return initialState;
  });
});
exports.emailAuth = emailAuth;