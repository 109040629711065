"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.twoFactor = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  resend: {
    loading: false
  }
};
var twoFactor = (0, _toolkit.createReducer)(initialState, function (builder) {
  // default login 2fa
  builder.addCase(_actions.loginAction.fulfilled, function (state, action) {
    if (typeof action.payload !== 'string') {
      var _action$payload, _action$payload2;
      if ((_action$payload = action.payload) !== null && _action$payload !== void 0 && _action$payload.email2fa) {
        state.kind = 'email';
      } else if ((_action$payload2 = action.payload) !== null && _action$payload2 !== void 0 && _action$payload2.totp) {
        state.kind = 'google';
        state.emailKey = action.meta.arg.emailKey;
      }
    } else {
      state.kind = undefined;
    }
  });
  // oauth login 2fa
  builder.addCase(_actions.oAuthGoogleLoginAction.fulfilled, function (state, action) {
    var _action$payload3;
    if (typeof action.payload !== 'string' && (_action$payload3 = action.payload) !== null && _action$payload3 !== void 0 && _action$payload3.totp) {
      state.kind = 'google';
    } else {
      state.kind = undefined;
    }
  });

  // resend
  builder.addCase(_actions.resendEmailTwoFactorAction.pending, function (state) {
    state.resend = {
      loading: true
    };
  });
  builder.addCase(_actions.resendEmailTwoFactorAction.fulfilled, function (state, action) {
    state.resend.loading = false;
    state.resend.remainingTime = action.payload.time;
  });
  builder.addCase(_actions.resendEmailTwoFactorAction.rejected, function (state, action) {
    var _action$payload4;
    state.resend.loading = false;
    state.resend.remainingTime = (_action$payload4 = action.payload) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data.time;
  });
  // reset
  builder.addCase(_actions.resetTwoFactorAction, function (state) {
    state.kind = undefined;
    state.emailKey = undefined;
    state.resend.remainingTime = undefined;
  });
});
exports.twoFactor = twoFactor;