"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.email2fa = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _enable = require("./enable");
var _disable = require("./disable");
var email2fa = (0, _toolkit.combineReducers)({
  enable: _enable.enable,
  disable: _disable.disable
});
exports.email2fa = email2fa;