"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  FormLink: true,
  FormCol: true,
  FormRow: true,
  FormButton: true,
  PasswordForm: true
};
Object.defineProperty(exports, "FormButton", {
  enumerable: true,
  get: function get() {
    return _Button.Button;
  }
});
Object.defineProperty(exports, "FormCol", {
  enumerable: true,
  get: function get() {
    return _Col.Col;
  }
});
Object.defineProperty(exports, "FormLink", {
  enumerable: true,
  get: function get() {
    return _Link.Link;
  }
});
Object.defineProperty(exports, "FormRow", {
  enumerable: true,
  get: function get() {
    return _Row.Row;
  }
});
Object.defineProperty(exports, "PasswordForm", {
  enumerable: true,
  get: function get() {
    return _Password.Password;
  }
});
var _Link = require("./Link");
var _Col = require("./Col");
var _Row = require("./Row");
var _Button = require("./Button");
var _Password = require("./Password");
var _TermsFields = require("./TermsFields");
Object.keys(_TermsFields).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _TermsFields[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _TermsFields[key];
    }
  });
});