"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SingleValue = void 0;
var _reactSelect = require("react-select");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var SingleValue = (0, _styledComponents.default)(_reactSelect.components.SingleValue)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: static !important;\n  transform: none !important;\n  max-width: none !important;\n  padding-right: 16px;\n  width: 100%;\n"])));
exports.SingleValue = SingleValue;