import { createAsyncThunk, createAction } from '@reduxjs/toolkit'

import { InvoiceApi, InvoiceNotExistError } from '../../../api'
import { CryptoInvoice } from '../../../interfaces'

export const getInvoiceCryptoAction = createAsyncThunk<
  CryptoInvoice,
  string,
  { rejectValue: { error: InvoiceNotExistError; withoutNotification: boolean } }
>('invoice/crypto/get', async (id, { rejectWithValue }) => {
  try {
    return await InvoiceApi.getCrypto(id)
  } catch (e) {
    if (e instanceof InvoiceNotExistError)
      return rejectWithValue({ error: e, withoutNotification: true })
    throw e
  }
})

export const updateInvoiceCryptoAction = createAsyncThunk(
  'invoice/crypto/update',
  async (params: Parameters<typeof InvoiceApi.updateCrypto>[0]) =>
    await InvoiceApi.updateCrypto(params)
)

export const expireInvoiceCryptoAction = createAction('invoice/crypto/expire')
