"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValueContainer = void 0;
var _reactSelect = require("react-select");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var defaultBackground = function defaultBackground(props) {
  return props.theme.colors.secondaryShade;
};
var ValueContainer = (0, _styledComponents.default)(_reactSelect.components.ValueContainer)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 40px;\n  font-weight: 400;\n  box-shadow: none;\n  border-radius: 4px;\n  background-color: ", ";\n  background-image: none;\n  padding: 8px 16px !important;\n  color: ", ";\n  &:disabled {\n    background-color: ", ";\n    border-color: ", ";\n    &::placeholder {\n      color: ", ";\n    }\n  }\n  &:not(:disabled):not(.disabled):focus {\n    color: ", ";\n    background-color: ", ";\n    box-shadow: none;\n    border-color: ", ";\n  }\n  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n  cursor: pointer;\n  input {\n    height: 0;\n  }\n"])), defaultBackground, function (_ref) {
  var theme = _ref.theme;
  return theme.colors.tertiary;
}, defaultBackground, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.secondaryShade2;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.secondaryShade2;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.tertiary;
}, defaultBackground, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.secondaryShade2;
});
exports.ValueContainer = ValueContainer;