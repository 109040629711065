import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { H5, breakpoints } from '@ppay/client'

import { useUser } from '../../../../state'

const UserFullName = styled(H5)`
  display: flex;
  align-items: center;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.secondary};
`

export const UserAccount = () => {
  const { user } = useUser()
  const name = user?.personal.name
  const surname = user?.personal.surname

  const isTable = useMediaQuery({ maxWidth: breakpoints.lg })

  const fullName = isTable
    ? `${name?.[0].toLocaleUpperCase()}. ${surname?.[0].toLocaleUpperCase()}`
    : `${name} ${surname}`

  return <UserFullName>{fullName}</UserFullName>
}
