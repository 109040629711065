"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUpdatePassword = void 0;
var _reactRedux = require("react-redux");
var _useSelector = require("../useSelector");
var _actions = require("../../actions");
var useUpdatePassword = function useUpdatePassword() {
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    updatePasswordState: (0, _useSelector.useSelector)(function (state) {
      return state.lib.user.updatePassword;
    }),
    updatePassword: function updatePassword() {
      dispatch(_actions.updatePasswordAction.apply(void 0, arguments));
    }
  };
};
exports.useUpdatePassword = useUpdatePassword;