import { useTranslation } from 'react-i18next'
import { Notification } from '@ppay/client'

export const CreateMerchantSuccess = () => {
  const { t } = useTranslation('notifications')

  return (
    <Notification
      type="success"
      title={t('merchants.create.title')}
      content={t('merchants.create.description')}
    />
  )
}

export const UpdateNotificationsUrlSuccess = () => {
  const { t } = useTranslation('notifications')

  return (
    <Notification
      type="success"
      title={t('success')}
      content={t('merchants.updateNotificationsUrl.success')}
    />
  )
}

export const CreateTransferSuccess = () => {
  return <Notification content={'Transfer'} type="success" title={'Success'} />
}
