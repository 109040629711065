import { useDispatch } from 'react-redux'

import { getMerchantPaymentsStatsAction } from '../../actions'
import { useSelector } from '../useSelector'

export const useMerchantPaymentsStats = (merchantId: string) => {
  const state = useSelector((state) => state.merchants.getPaymentsStats)
  const dispatch = useDispatch()

  const fetchPaymentsStats = (
    params: Omit<Parameters<typeof getMerchantPaymentsStatsAction>[0], 'merchantId'>
  ) => {
    dispatch(getMerchantPaymentsStatsAction({ ...params, merchantId }))
  }

  return {
    fetchPaymentsStats,
    loading: state.loading,
    stats: state.data
  }
}
