"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFiatExchange = useFiatExchange;
var _react = require("react");
var _state = require("../../state");
var _excluded = ["amount", "toAmount", "fiatTickerAmount", "address", "ticker", "fiatTicker", "toTicker", "onSuccess", "isEnoughBalance", "isValidating", "blockchain", "side", "fee", "exchangeFee", "price"];
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function useFiatExchange(_ref) {
  var _props$useCreateExcha, _props$useBalances, _props$fetchBalances, _balances$balancesSta;
  var amount = _ref.amount,
    toAmount = _ref.toAmount,
    fiatTickerAmount = _ref.fiatTickerAmount,
    address = _ref.address,
    ticker = _ref.ticker,
    fiatTicker = _ref.fiatTicker,
    toTicker = _ref.toTicker,
    onSuccess = _ref.onSuccess,
    isEnoughBalance = _ref.isEnoughBalance,
    isValidating = _ref.isValidating,
    blockchain = _ref.blockchain,
    side = _ref.side,
    fee = _ref.fee,
    exchangeFee = _ref.exchangeFee,
    price = _ref.price,
    props = _objectWithoutProperties(_ref, _excluded);
  var _ref2 = ((_props$useCreateExcha = props.useCreateExchange) !== null && _props$useCreateExcha !== void 0 ? _props$useCreateExcha : _state.useCreateFiatExchange)(),
    fiatExchangeState = _ref2.fiatExchangeState,
    createFiatExchange = _ref2.createFiatExchange,
    resetFiatExchange = _ref2.resetFiatExchange;
  var balances = ((_props$useBalances = props.useBalances) !== null && _props$useBalances !== void 0 ? _props$useBalances : _state.useBalances)();
  var fetchBalances = (_props$fetchBalances = props.fetchBalances) !== null && _props$fetchBalances !== void 0 ? _props$fetchBalances : balances.fetchBalances;
  var isBalances = !((_balances$balancesSta = balances.balancesState) !== null && _balances$balancesSta !== void 0 && _balances$balancesSta.data);
  (0, _react.useEffect)(function () {
    if (isBalances) fetchBalances();
  }, [isBalances]);
  (0, _react.useEffect)(function () {
    if (fiatExchangeState.done) {
      onSuccess();
      resetFiatExchange();
      balances.recalculateBalances({
        value: amount,
        convertedValue: fiatTickerAmount,
        ticker: ticker,
        increase: false
      });
    }
  }, [fiatExchangeState.done]);

  // reset withdraw state (feeLessThanRequired) to not fetch new fee on ticker or amount change
  (0, _react.useEffect)(function () {
    if (fiatExchangeState.feeLessThanRequired) resetFiatExchange();
  }, [ticker, amount, fiatExchangeState.feeLessThanRequired]);
  return {
    createExchange: function createExchange(formFields) {
      return createFiatExchange({
        ticker: toTicker,
        spendTicker: ticker,
        exchangeFee: exchangeFee,
        fee: fee,
        side: side,
        amount: side === 'spend' ? amount : toAmount,
        to: address.replaceAll(' ', ''),
        price: price,
        withdrawKey: formFields === null || formFields === void 0 ? void 0 : formFields.withdrawKey
      });
    },
    createExchangeLoading: fiatExchangeState.loading,
    feeLessThanRequired: !!fiatExchangeState.feeLessThanRequired
  };
}