import { createAsyncThunk } from '@reduxjs/toolkit'

import { ManualReplenishOrderApi } from '../../api'

export const getManualReplenishOrderAction = createAsyncThunk(
  'manual-replenish-order/get',
  async (id: string) => {
    return ManualReplenishOrderApi.get(id)
  }
)

export const queueManualReplenishOrderAction = createAsyncThunk(
  'manual-replenish-order/queue',
  async (id: string) => {
    return ManualReplenishOrderApi.queue(id)
  }
)
