"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWithdraw2fa = exports.useSendWithdraw2faKey = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector2 = require("../useSelector");
var useWithdraw2fa = function useWithdraw2fa() {
  var state = (0, _useSelector2.useSelector)(function (state) {
    return state.lib.user.withdraw2fa;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    loading: state.enable.loading || state.disable.loading,
    disableState: state.disable,
    enableWithdraw2fa: function enableWithdraw2fa() {
      return dispatch((0, _actions.enableWithdraw2faAction)());
    },
    disableWithdraw2fa: function disableWithdraw2fa(code) {
      return dispatch((0, _actions.disableWithdraw2faAction)(code));
    }
  };
};
exports.useWithdraw2fa = useWithdraw2fa;
var useSendWithdraw2faKey = function useSendWithdraw2faKey() {
  var _useSelector = (0, _useSelector2.useSelector)(function (state) {
      return state.lib.user.withdraw2fa;
    }),
    send = _useSelector.send;
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    loading: send.loading,
    remainingTime: send.remainingTime,
    sendWithdraw2faKey: function sendWithdraw2faKey() {
      return dispatch((0, _actions.sendWithdraw2faKeyAction)());
    }
  };
};
exports.useSendWithdraw2faKey = useSendWithdraw2faKey;