import { useSelector } from '../useSelector'
import { useDispatch } from '../useDispatch'
import { createMerchantTransferAction, resetCreateMerchantTransferAction } from '../../actions'

export const useCreateMerchantTransfer = () => {
  const state = useSelector((state) => state.merchants.transactions.createTransfer)
  const dispatch = useDispatch()

  return {
    createTransferLoading: state.loading,
    createTransferDone: state.done,
    createTransfer: (...params: Parameters<typeof createMerchantTransferAction>) =>
      dispatch(createMerchantTransferAction(...params)),
    resetCreateTransfer: () => dispatch(resetCreateMerchantTransferAction())
  }
}
