import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Row, Col, H1, H4 } from '@ppay/client'

import { MerchantPaymentsStats } from '../../../../../interfaces'

import { Chart } from './Chart'
import { ChartLegend } from './ChartLegend'
import { MerchantPaymentsStatsType } from '../../../../../constants'

const PointStatsCount = styled(H1)`
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
`

const ChartHeadingTitle = styled(H4)`
  margin-bottom: 5px;
`

const ChartHeading = styled.div`
  margin-bottom: 20px;
`

export const ChartWithLegend = ({
  stats,
  type
}: {
  stats: MerchantPaymentsStats[]
  type: MerchantPaymentsStatsType
}) => {
  const { t } = useTranslation('merchant')
  const [pointIndex, setPointIndex] = useState<number>(stats.length - 1)
  const tickersStats = stats[pointIndex].stats

  return (
    <Row>
      <Col xs={12} md={6}>
        <ChartHeading>
          <ChartHeadingTitle>
            {type === MerchantPaymentsStatsType.IN ? t('deposits') : t('withdrawals')}
          </ChartHeadingTitle>
          <PointStatsCount>{tickersStats.reduce((acc, c) => acc + c.count, 0)}</PointStatsCount>
        </ChartHeading>
        <Chart data={stats} setPointIndex={setPointIndex} pointIndex={pointIndex} />
      </Col>
      <Col xs={12} md={6}>
        <ChartLegend tickersStats={stats[pointIndex].stats} />
      </Col>
    </Row>
  )
}
