import { FC, PropsWithChildren } from 'react'
import { InvoiceStatus, Ticker } from '@ppay/definitions'

import { FiatInvoice } from '../../../interfaces'
import { CardSuccess as Success } from '../card'
import { Continue } from './Continue'
import { Pay } from './Pay'
import { Failure } from './Failure'
import { TimerExpired } from './TimerExpired'

export interface InvoiceProps {
  invoice: FiatInvoice
  ticker: Ticker
  setTicker: (ticker: Ticker) => void
  hideButton?: boolean
  back?: () => void
  email?: string
  isFiat?: boolean
  children?: React.ReactNode
}

const invoiceComponent: Record<InvoiceStatus, FC<InvoiceProps>> = {
  [InvoiceStatus.CREATED]: Continue,
  [InvoiceStatus.PENDING]: Pay,
  [InvoiceStatus.PAID]: Success,
  [InvoiceStatus.DONE]: Success,
  [InvoiceStatus.FAILED]: Failure
}

export const Component = ({
  invoice,
  setTicker,
  ticker,
  hideButton,
  email,
  back,
  children
}: PropsWithChildren<{
  invoice: FiatInvoice
  setTicker: (ticker: Ticker) => void
  ticker: Ticker
  hideButton?: boolean
  back?: () => void
  email?: string
}>) => {
  const InvoiceComponent = invoiceComponent[invoice.status]

  if (invoice.expiredAt && invoice.expiredAt < Date.now()) return <TimerExpired invoice={invoice} />

  return (
    <InvoiceComponent
      invoice={invoice}
      ticker={ticker}
      back={back}
      setTicker={setTicker}
      hideButton={hideButton}
      children={children}
      email={email}
      isFiat={true}
    />
  )
}
