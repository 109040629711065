import { createReducer } from '@reduxjs/toolkit'

import { updateMerchantNotificationsUrl } from '../../actions'

type State = {
  loading: boolean
}

const initialState: State = {
  loading: false
}

export const updateNotificationsUrl = createReducer(initialState, (builder) => {
  builder
    .addCase(updateMerchantNotificationsUrl.pending, (state) => {
      state.loading = true
    })
    .addCase(updateMerchantNotificationsUrl.rejected, (state) => {
      state.loading = false
    })
    .addCase(updateMerchantNotificationsUrl.fulfilled, (state) => {
      state.loading = false
    })
})
