"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TransactionsDetailsCard", {
  enumerable: true,
  get: function get() {
    return _Card.Card;
  }
});
Object.defineProperty(exports, "TransactionsDetailsRecent", {
  enumerable: true,
  get: function get() {
    return _Recent.Recent;
  }
});
Object.defineProperty(exports, "TransactionsDetailsTable", {
  enumerable: true,
  get: function get() {
    return _Table.Table;
  }
});
var _Table = require("./Table");
var _Card = require("./Card");
var _Recent = require("./Recent");