"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFiatReplenishFee = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useFiatReplenishFee = function useFiatReplenishFee(ticker) {
  var _state$data;
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.transactions.getFiatReplenishFee;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    fiatReplenishFee: (_state$data = state.data) === null || _state$data === void 0 ? void 0 : _state$data[ticker],
    fetchReplenishFee: function fetchReplenishFee() {
      return dispatch((0, _actions.getReplenishFeeAction)());
    }
  };
};
exports.useFiatReplenishFee = useFiatReplenishFee;