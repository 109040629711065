import { useDispatch } from 'react-redux'
import { getMerchantAction } from '../../actions'

import { useSelector } from '../useSelector'

export const useMerchant = (id: string) => {
  const state = useSelector((state) => state.merchants.get)
  const dispatch = useDispatch()

  return {
    merchant: state.data?.find((el) => el.id === id),
    fetchMerchant: () => dispatch(getMerchantAction(id))
  }
}
