import { useTranslation } from 'react-i18next'

import { CardRow, CardWrapper } from '../CardWrapper'
import { CryptoTickerFees } from './TableValue'
import { getFeeValueString } from '../helpers'

export const Card = ({ ticker, fees }: CryptoTickerFees) => {
  const { t } = useTranslation(['paymentMethodsTable', 'libTransfers'])

  return (
    <CardWrapper ticker={ticker}>
      <CardRow>
        <span className="me-2">{t('fee')}</span>
      </CardRow>
      {Object.entries(fees).map(([blockchain, fee]) => (
        <CardRow>
          <span className="me-2">{t(`libTransfers:blockchains.${blockchain}`)}</span>
          <span>{getFeeValueString(ticker, fee!)}</span>
        </CardRow>
      ))}
    </CardWrapper>
  )
}
