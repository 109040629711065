"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTransactions = void 0;
var _reactRedux = require("react-redux");
var _lodash = _interopRequireDefault(require("lodash.get"));
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var useTransactions = function useTransactions(params) {
  var _params$getAction, _params$getCSVAction, _params$statePath;
  var getAction = (_params$getAction = params === null || params === void 0 ? void 0 : params.getAction) !== null && _params$getAction !== void 0 ? _params$getAction : _actions.getTransactionsAction;
  var getCSVAction = (_params$getCSVAction = params === null || params === void 0 ? void 0 : params.getCSVAction) !== null && _params$getCSVAction !== void 0 ? _params$getCSVAction : _actions.getTransactionsCSVAction;
  var statePath = (_params$statePath = params === null || params === void 0 ? void 0 : params.statePath) !== null && _params$statePath !== void 0 ? _params$statePath : 'lib.transactions.get';
  var dispatch = (0, _reactRedux.useDispatch)();
  var transactionsState = (0, _useSelector.useSelector)(function (state) {
    return (0, _lodash.default)(state, statePath);
  });
  return {
    transactionsState: transactionsState,
    saveCSV: function saveCSV(params) {
      return dispatch(getCSVAction(params));
    },
    hasMore: transactionsState.pages - transactionsState.page > 1,
    fetchTransactions: function fetchTransactions(params) {
      return dispatch(getAction(params));
    },
    fetchMoreTransactions: function fetchMoreTransactions(params) {
      return dispatch(getAction(_objectSpread({
        page: transactionsState.page + 1
      }, params)));
    }
  };
};
exports.useTransactions = useTransactions;