import { Card, CardTitle, CardHeader, CardBody, Spinner, SeeMore } from '@ppay/client'
import { useTranslation } from 'react-i18next'

import { useMerchantCreateLimit } from '../../../../hooks'
import { Merchant } from '../../../../interfaces'
import { List } from './List'

export const Component = ({
  className,
  loading,
  merchants,
  onSeeMore,
  onShowOne,
  onCreate
}: {
  className?: string
  loading: boolean
  merchants?: Merchant[]
  onSeeMore: () => void
  onShowOne: (merchantId: string) => void
  onCreate: () => void
}) => {
  const { t } = useTranslation('merchant')
  const { title } = useMerchantCreateLimit()

  return (
    <Card className={className}>
      <CardHeader className="d-flex justify-content-between">
        <CardTitle>{title}</CardTitle>
        <SeeMore onClick={onSeeMore}>{t('allMerchants')}</SeeMore>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Spinner color="primary" />
        ) : (
          <List merchants={merchants} onShowOne={onShowOne} onCreate={onCreate} />
        )}
      </CardBody>
    </Card>
  )
}
