import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link, P } from '@ppay/client'

import { logo } from './images'
import { Icon } from '../../ui'

const Wrapper = styled.div`
  padding: 60px 0;
`

const Logo = styled.img`
  min-width: 155px;
`

const Links = styled.div`
  margin-top: 20px;
  ${Link} {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.tertiaryShade};
`

export const Layout = ({ children, isP2P = false }: { children: ReactNode; isP2P?: boolean }) => {
  const { t } = useTranslation('invoice')

  return (
    <Wrapper className="d-flex flex-column min-vh-100 align-items-center">
      {children}
      <div className="d-flex flex-column align-items-center mt-auto">
        <LogoWrapper>
          <Link href={process.env.REACT_APP_SITE_URL} target="_blank">
            <Logo src={logo} alt="0xPay logo" />
          </Link>
          {isP2P && <P>P2P</P>}
        </LogoWrapper>
        <Links>
          <Link href="https://docs.0xpay.app/legal/privacy-policy" color="tertiaryShade">
            {t('footer.links.privacyPolicy')}
          </Link>
          <Link href="https://docs.0xpay.app/legal/terms-of-service" color="tertiaryShade">
            {t('footer.links.termsOfService')}
          </Link>
          <Link href={process.env.REACT_APP_CHAT} target="_blank" color="tertiaryShade">
            {t('footer.links.contactSupport')}{' '}
            <Icon name="arrowRotate" rotate={180} size={10} color="tertiaryShade" />
          </Link>
        </Links>
      </div>
    </Wrapper>
  )
}
