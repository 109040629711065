"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  TransactionsPage: true
};
Object.defineProperty(exports, "TransactionsPage", {
  enumerable: true,
  get: function get() {
    return _Container.Container;
  }
});
var _Container = require("./Container");
var _TransactionsCard = require("./TransactionsCard");
Object.keys(_TransactionsCard).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _TransactionsCard[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _TransactionsCard[key];
    }
  });
});