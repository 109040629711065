import { emailValidation, FormGroup, InputField, Label, InfoLabel, Button } from '@ppay/client'
import React from 'react'
import { Form } from 'reactstrap'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'

import { urlValidation } from '../../../../../../validations'

const nameValidation = yup
  .string()
  // eslint-disable-next-line no-template-curly-in-string
  .max(25, '${path} length must be less then 25')
  // eslint-disable-next-line no-template-curly-in-string
  .matches(/^[a-zA-Zа-яА-Я0-9 ,.’-]+$/i, '${path} must be valid')

const schema = yup
  .object()
  .shape({
    notificationsEmail: emailValidation().required(),
    name: nameValidation.required(),
    companyName: nameValidation.required(),
    domain: yup.string().test(urlValidation).required()
  })
  .required()

export const Component = ({
  submitLoading,
  onSubmit
}: {
  submitLoading?: boolean
  onSubmit: (formData: yup.InferType<typeof schema>) => unknown
}) => {
  const { t } = useTranslation(['merchant', 'libCommon'])
  const { handleSubmit, control } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema)
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormGroup>
        <Label>{t('name')}</Label>
        <InputField
          control={control}
          inputProps={{
            name: 'name',
            placeholder: t('name')
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('companyName')}</Label>
        <InputField
          control={control}
          inputProps={{
            name: 'companyName',
            placeholder: t('companyName')
          }}
        />
      </FormGroup>
      <FormGroup>
        <InfoLabel info={t('domainTooltip')}>{t('domain')}</InfoLabel>
        <InputField
          control={control}
          inputProps={{
            name: 'domain',
            placeholder: 'example.com'
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>{t('notificationsEmail')}</Label>
        <InputField
          control={control}
          inputProps={{
            name: 'notificationsEmail',
            type: 'email',
            placeholder: 'email@.com'
          }}
        />
      </FormGroup>
      <Button loading={submitLoading} type="submit" color="secondary" wide>
        {t('libCommon:continue')}
      </Button>
    </Form>
  )
}
