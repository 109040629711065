"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Nav = void 0;
var _reactstrap = require("reactstrap");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _NavItem = require("./NavItem");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Nav = (0, _styledComponents.default)(_reactstrap.Nav).attrs(function () {
  return {
    tabs: true
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border-radius: 8px;\n  padding: 5px 2.5px;\n  display: flex;\n  flex-direction: ", ";\n  border: none;\n  margin-bottom: 20px;\n  ", " {\n    padding: 0 2.5px;\n    &:not(:last-child) {\n      margin-bottom: ", ";\n    }\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondaryShade;
}, function (_ref2) {
  var column = _ref2.column;
  return column ? 'column' : 'row';
}, _NavItem.NavItem, function (_ref3) {
  var column = _ref3.column;
  return column && '5px';
});
exports.Nav = Nav;