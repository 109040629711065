import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'reactstrap'
import { toast } from 'react-toastify'
import {
  Link,
  Label,
  FormFeedback,
  FormGroup,
  Row,
  Col,
  Button as FormButton,
  Notification
} from '@ppay/client'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { InvoiceApi } from '../../../../../api'
import { Card, CardAmountToPay, CardSeparativeLine } from '../../../card'
import { Icon } from '../../../../ui'
import { useInvoiceFiat, useUser } from '../../../../../state'

import { CardDataWithMargin, Widget, CardField } from './ui'
import { CreditCardData, cardValidationSchema } from './card'
import { getStatus } from './status'
import { ThreeDSecureAuth } from './ThreeDSecureAuth'
import { InvoiceProps } from '../../Component'

export const AnyMoneyForm = ({ invoice, children, hideButton }: InvoiceProps) => {
  const { t } = useTranslation('invoice')
  const { failInvoice, notifyFailInvoice, successInvoice } = useInvoiceFiat()
  const { user } = useUser()

  const [acsUrl, setAcsUrl] = useState<string>()
  const [loading, setLoading] = useState(false)

  const { handleSubmit, control } = useForm<yup.InferType<typeof cardValidationSchema>>({
    mode: 'onTouched',
    resolver: yupResolver(cardValidationSchema)
  })

  const failInvoiceWithMessage = (message: string, status: string) => {
    toast(<Notification title="Error" type="danger" content={message} />)
    failInvoice({ code: 0, message, invoiceId: invoice.id })
    notifyFailInvoice({
      code: 0,
      message,
      invoiceId: invoice.id,
      status,
      provider: 'ANYMONEY',
      merchantName: invoice.company?.name,
      merchantId: invoice.company?.merchantId,
      accountId: user?.accountId,
      email: invoice.email,
      amount: invoice.amount?.value,
      ticker: invoice.amount?.ticker
    })
  }

  const onSubmit = async (data: CreditCardData) => {
    setLoading(true)
    const externalId = await InvoiceApi.fiatInvoiceCreateH2H(invoice.id, {
      number: data.number,
      expire: { month: data.expiryMonth, year: data.expiryYear },
      code: data.cvc
    })

    const { acsUrl: fetchedAcsUrl, status } = await getStatus(invoice.id, externalId)
    if (!fetchedAcsUrl) {
      failInvoiceWithMessage('Cannot start 3DS authentication', status)
    } else {
      setAcsUrl(fetchedAcsUrl)
    }
    setLoading(false)
  }

  const succeed3DS = () => {
    successInvoice()
    setAcsUrl(undefined) // hide modal
  }

  const fail3DS = () => {
    failInvoiceWithMessage('3DS authorization failed', 'wait')
    setAcsUrl(undefined) // hide modal
  }

  return (
    <Card id={invoice.id} name={invoice.name} description={invoice.description} isFiat={true}>
      {invoice.company && (
        <CardDataWithMargin
          title={t('company')}
          value={
            <Link target="_blank" href={invoice.company.url}>
              {invoice.company.name}
            </Link>
          }
        />
      )}
      <CardAmountToPay
        amount={invoice.amount!.value}
        ticker={invoice.amount!.ticker}
        text={t('amountToPay')}
      />
      <CardSeparativeLine />
      {children && hideButton ? (
        children
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)} onValidate>
          <Widget loading={false}>
            <FormGroup>
              <Label>{t('cardNumber')}</Label>
              <CardField
                control={control as any}
                inputProps={{ name: 'number', placeholder: t('cardNumber') }}
              />
              <FormFeedback id="error-card-number" />
            </FormGroup>

            <FormGroup>
              <Row>
                <Col xs={4}>
                  <Label>{t('cardExpireMonth')}</Label>
                  <CardField
                    control={control as any}
                    inputProps={{ name: 'expiryMonth', placeholder: 'MM' }}
                  />
                  <FormFeedback id="error-card-expire-month" />
                </Col>
                <Col xs={4}>
                  <Label>{t('cardExpireYear')}</Label>
                  <CardField
                    control={control as any}
                    inputProps={{ name: 'expiryYear', placeholder: 'YY' }}
                  />
                  <FormFeedback id="error-card-expire-year" />
                </Col>
                <Col xs={4}>
                  <Label>{t('cardCVC')}</Label>
                  <CardField
                    control={control as any}
                    inputProps={{ name: 'cvc', placeholder: 'CVC' }}
                  />
                  <FormFeedback id="error-card-cvc" />
                </Col>
              </Row>
            </FormGroup>
            <FormButton type="submit" loading={loading} wide icon={<Icon name="arrowRight" />}>
              {t('pay')}
            </FormButton>
          </Widget>
        </Form>
      )}
      <ThreeDSecureAuth
        acsUrl={acsUrl}
        handlePaymentError={fail3DS}
        handlePaymentSuccess={succeed3DS}
        close={() => setAcsUrl(undefined)}
      />
    </Card>
  )
}
