import { useCreateCryptoExchange } from '@ppay/client'

import { createMerchantCryptoExchangeAction } from '../../actions'

export const useCreateMerchantCryptoExchange = (merchantId: string) => {
  const { createCryptoExchange, ...other } = useCreateCryptoExchange({
    action: createMerchantCryptoExchangeAction,
    statePath: 'merchants.exchange.createCrypto'
  })
  return {
    ...other,
    createCryptoExchange: (
      params: Omit<Parameters<typeof createMerchantCryptoExchangeAction>[0], 'merchantId'>
    ) => createCryptoExchange({ ...params, merchantId })
  }
}
