"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toAmountValidation = exports.amountValidation = exports.MIN_OR_MAX_VALIDATION_NAME = void 0;
exports.useForm = useForm;
var _yup = require("@hookform/resolvers/yup");
var _definitions = require("@ppay/definitions");
var _decimal = _interopRequireDefault(require("decimal.js"));
var _react = require("react");
var _reactHookForm = require("react-hook-form");
var yup = _interopRequireWildcard(require("yup"));
var _validations = require("../../validations");
var _withdraw = require("../withdraw");
var _excluded = ["ticker", "toTicker", "fiatTicker", "onAmountCleared", "price", "balance", "toTickerBalance", "blockchain", "defaultValues", "context", "exchangePair", "side", "limits", "maxLiquidity"];
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var SPEND_AMOUNT_ADDITIONAL_PERCENT = 0.03;
var MIN_OR_MAX_VALIDATION_NAME = 'MIN_OR_MAX_VALIDATION_NAME';
exports.MIN_OR_MAX_VALIDATION_NAME = MIN_OR_MAX_VALIDATION_NAME;
var amountValidation = yup.string().test({
  name: MIN_OR_MAX_VALIDATION_NAME,
  test: function test(value, _ref) {
    var options = _ref.options;
    var _ref2 = options.context,
      exchangePair = _ref2.exchangePair,
      side = _ref2.side,
      limits = _ref2.limits,
      ticker = _ref2.ticker,
      maxLiquidity = _ref2.maxLiquidity;
    if (!exchangePair || !value) return true;
    var dValue = new _decimal.default(value);
    if (side === 'spend') {
      var stepDp = new _decimal.default(exchangePair.exchange.amountStep).dp();
      var min = exchangePair.exchange.min && new _decimal.default(exchangePair.exchange.min).mul(1 + SPEND_AMOUNT_ADDITIONAL_PERCENT).toDecimalPlaces(stepDp);
      var max = exchangePair.exchange.max && new _decimal.default(exchangePair.exchange.max).mul(1 - SPEND_AMOUNT_ADDITIONAL_PERCENT).toDecimalPlaces(stepDp);
      if (limits !== null && limits !== void 0 && limits.min) min = _decimal.default.max(min || 0, new _decimal.default(limits.min).mul(1 + SPEND_AMOUNT_ADDITIONAL_PERCENT)[exchangePair.price.pair[0] === ticker ? 'div' : 'mul'](exchangePair.price.value)).toDecimalPlaces(stepDp);
      if (maxLiquidity) max = _decimal.default.min(new _decimal.default(max || Infinity), new _decimal.default(maxLiquidity.exchange).mul(1 - SPEND_AMOUNT_ADDITIONAL_PERCENT).toDecimalPlaces(stepDp)).toDecimalPlaces(stepDp);
      if (min && dValue.lt(min)) return this.createError({
        message: 'Min amount is ' + min
      });
      if (max && dValue.gt(max)) return this.createError({
        message: 'Max amount is ' + max
      });
    }
    return true;
  }
}).when('$side', function (side, schema) {
  return side === 'spend' ? schema.test({
    name: _validations.ENOUGH_BALANCE_VALIDATION_NAME,
    test: function test(value, _ref3) {
      var options = _ref3.options;
      var _ref4 = options.context,
        balance = _ref4.balance;
      if (!value || new _decimal.default(balance).gte(value)) return true;
      return this.createError({
        params: {
          balanceValidation: true
        },
        message: 'Not enough balance'
      });
    }
  }).required() : schema;
});
exports.amountValidation = amountValidation;
var toAmountValidation = yup.string().test({
  name: MIN_OR_MAX_VALIDATION_NAME,
  test: function test(value, _ref5) {
    var options = _ref5.options;
    if (!value || new _decimal.default(value).gt(0)) return true;
    return this.createError({
      message: 'Amount should be greater than 0'
    });
  }
}).test({
  name: MIN_OR_MAX_VALIDATION_NAME,
  test: function test(value, _ref6) {
    var options = _ref6.options;
    var _ref7 = options.context,
      exchangePair = _ref7.exchangePair,
      side = _ref7.side,
      limits = _ref7.limits,
      maxLiquidity = _ref7.maxLiquidity;
    if (!exchangePair || !value) return true;
    var dValue = new _decimal.default(value);
    if (side === 'withdraw') {
      var _max;
      var stepDp = new _decimal.default(exchangePair.target.amountStep).dp();
      var min, max;
      if (limits !== null && limits !== void 0 && limits.min) min = new _decimal.default(limits.min).toDecimalPlaces(stepDp);
      if (maxLiquidity) max = _decimal.default.min(new _decimal.default((_max = max) !== null && _max !== void 0 ? _max : Infinity), new _decimal.default(maxLiquidity.target).toDecimalPlaces(stepDp)).toDecimalPlaces(stepDp);
      if (min && dValue.lt(min)) return this.createError({
        message: 'Min amount is ' + min
      });
      if (max && dValue.gt(max)) return this.createError({
        message: 'Max amount is ' + max
      });
    }
    return true;
  }
}).when('$side', function (side, schema) {
  return side === 'withdraw' ? schema.test({
    name: _validations.ENOUGH_BALANCE_VALIDATION_NAME,
    test: function test(value, _ref8) {
      var options = _ref8.options;
      var _ref9 = options.context,
        ticker = _ref9.ticker,
        balance = _ref9.balance,
        toTickerBalance = _ref9.toTickerBalance;
      if (!value) return true;
      if (toTickerBalance && new _decimal.default(toTickerBalance).lt(value)) return this.createError({
        params: {
          balanceValidation: true
        },
        message: "Not enough ".concat(ticker, " balance")
      });
      var amount = this.parent.amount;
      if (!value || !amount || new _decimal.default(balance).gte(amount)) return true;
      return this.createError({
        params: {
          balanceValidation: true
        },
        message: "Not enough ".concat(ticker, " balance")
      });
    }
  }).required() : schema;
});
exports.toAmountValidation = toAmountValidation;
var baseSchema = yup.object().shape({
  address: _validations.cryptoAddressValidation.required(),
  amount: amountValidation,
  toAmount: toAmountValidation
}).required();
function useForm(_ref10) {
  var _props$schema;
  var ticker = _ref10.ticker,
    toTicker = _ref10.toTicker,
    fiatTicker = _ref10.fiatTicker,
    onAmountCleared = _ref10.onAmountCleared,
    price = _ref10.price,
    balance = _ref10.balance,
    toTickerBalance = _ref10.toTickerBalance,
    blockchain = _ref10.blockchain,
    _ref10$defaultValues = _ref10.defaultValues,
    defaultValues = _ref10$defaultValues === void 0 ? {} : _ref10$defaultValues,
    _ref10$context = _ref10.context,
    context = _ref10$context === void 0 ? {} : _ref10$context,
    exchangePair = _ref10.exchangePair,
    side = _ref10.side,
    limits = _ref10.limits,
    maxLiquidity = _ref10.maxLiquidity,
    props = _objectWithoutProperties(_ref10, _excluded);
  var schema = (_props$schema = props.schema) !== null && _props$schema !== void 0 ? _props$schema : baseSchema;
  var calculateFiatTickerAmount = (0, _withdraw.useCalculateWithdrawFiatTickerAmount)({
    price: price,
    fiatTicker: fiatTicker
  });
  var _useState = (0, _react.useState)(),
    _useState2 = _slicedToArray(_useState, 2),
    focusedInput = _useState2[0],
    setFocusedInput = _useState2[1];
  var formProviderProps = (0, _reactHookForm.useForm)({
    mode: 'onTouched',
    resolver: (0, _yup.yupResolver)(schema),
    context: _objectSpread({
      ticker: ticker,
      blockchain: blockchain,
      balance: balance,
      toTickerBalance: toTickerBalance,
      exchangePair: exchangePair,
      side: side,
      validateAddressTicker: toTicker,
      maxLiquidity: maxLiquidity,
      limits: _definitions.FIAT_TICKERS.includes(toTicker) ? limits : undefined
    }, context),
    defaultValues: _objectSpread({
      amount: '',
      toAmount: '',
      address: ''
    }, defaultValues)
  });

  // watch inputs values change
  var amount = (0, _reactHookForm.useWatch)({
    name: 'amount',
    control: formProviderProps.control
  });
  var toAmount = (0, _reactHookForm.useWatch)({
    name: 'toAmount',
    control: formProviderProps.control
  });
  var address = (0, _reactHookForm.useWatch)({
    name: 'address',
    control: formProviderProps.control
  });
  (0, _react.useEffect)(function () {
    if (!amount) onAmountCleared === null || onAmountCleared === void 0 ? void 0 : onAmountCleared();
  }, [amount]);

  // trigger address validation when ticker changes
  (0, _react.useEffect)(function () {
    if (address) formProviderProps.trigger('address');
  }, [ticker, toTicker]);
  (0, _react.useEffect)(function () {
    if (maxLiquidity) {
      formProviderProps.trigger('toAmount');
      formProviderProps.trigger('amount');
    }
  }, [amount, toAmount, maxLiquidity]);
  (0, _react.useEffect)(function () {
    formProviderProps.reset({
      amount: '',
      toAmount: '',
      address: ''
    }, {
      keepTouched: false,
      keepErrors: false,
      keepDirty: false
    });
  }, [toTicker, ticker]);
  (0, _react.useEffect)(function () {
    if (toAmount) formProviderProps.trigger('toAmount');
    if (amount) formProviderProps.trigger('amount');
  }, [exchangePair === null || exchangePair === void 0 ? void 0 : exchangePair.exchange.min, exchangePair === null || exchangePair === void 0 ? void 0 : exchangePair.exchange.max, exchangePair === null || exchangePair === void 0 ? void 0 : exchangePair.price, amount]);
  return {
    formProviderProps: formProviderProps,
    amount: amount,
    toAmount: toAmount,
    fiatTickerAmount: calculateFiatTickerAmount(amount),
    address: address,
    setFocusedInput: setFocusedInput,
    focusedInput: focusedInput
  };
}