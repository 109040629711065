import { Card, CardHeader, CardBody, CardTitle, H4, Row } from '@ppay/client'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TabPane, TabContent } from 'reactstrap'
import { useParams } from 'react-router-dom'

import { SettingsTabs, TabsNav } from './TabsNav'
import { ApiKeys } from './ApiKeys'
import { Notifications } from './Notifications'
import { PaymentMethods } from './PaymentMethods'
import { WithMerchantExists } from '../WithMerchantExists'
import { Breadcrumb, Breadcrumbs } from '../../../layout'
import { routes } from '../../../router'
import { useRefetchMerchantById } from '../../../../hooks'

export const Page = () => {
  const [activeTab, setActiveTab] = useState(SettingsTabs.PAYMENT_METHODS)
  const { id } = useParams<{ id: string }>()
  const { merchant } = useRefetchMerchantById(id)
  const { t } = useTranslation(['merchant', 'routes'])

  return (
    <WithMerchantExists>
      <Row>
        <Breadcrumbs>
          <Breadcrumb link={routes.dashboard.home} name={t('routes:home')} />
          <Breadcrumb link={routes.dashboard.merchants.main} name={t('routes:merchants')} />
          <Breadcrumb
            link={routes.dashboard.merchants.main + `/${merchant?.id}`}
            name={merchant?.name ?? ''}
          />
          <Breadcrumb name={t('routes:merchantSettings')} />
        </Breadcrumbs>
      </Row>
      <Card>
        <CardHeader>
          <CardTitle tag={H4}>{t('merchantSettings')}</CardTitle>
        </CardHeader>
        <CardBody className="pt-0">
          <TabsNav activeTab={activeTab} onTabChange={setActiveTab} />
          <TabContent activeTab={activeTab}>
            <TabPane tabId={SettingsTabs.PAYMENT_METHODS}>
              <PaymentMethods merchantId={id} />
            </TabPane>
            <TabPane tabId={SettingsTabs.API_KEYS}>
              <ApiKeys merchantId={id} />
            </TabPane>
            <TabPane tabId={SettingsTabs.NOTIFICATIONS}>
              <Notifications merchantId={id} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </WithMerchantExists>
  )
}
