import { SlackChannels } from '@ppay/definitions'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { InvoiceApi, InvoiceNotExistError, SlackNotificationsApi } from '../../../api'
import { FiatInvoice, FailNotification } from '../../../interfaces'

export const getInvoiceFiatAction = createAsyncThunk<
  FiatInvoice,
  string,
  { rejectValue: InvoiceNotExistError }
>('invoice/fiat/get', async (id, { rejectWithValue }) => {
  try {
    return await InvoiceApi.getFiat(id)
  } catch (e) {
    if (e instanceof InvoiceNotExistError) return rejectWithValue(e)
    throw e
  }
})

export const updateInvoiceFiatAction = createAsyncThunk(
  'invoice/fiat/update',
  async (params: Parameters<typeof InvoiceApi.updateFiat>[0]) => await InvoiceApi.updateFiat(params)
)

export const failInvoiceFiatAction = createAsyncThunk(
  'invoice/fiat/fail',
  async (params: Parameters<typeof InvoiceApi.failFiat>[0]) => await InvoiceApi.failFiat(params)
)

export const failInvoiceNotification = createAsyncThunk(
  'invoice/fiat/fail/notification',
  async ({
    invoiceId,
    status,
    message,
    merchantId,
    merchantName,
    email,
    provider,
    accountId,
    amount,
    ticker,
    code
  }: FailNotification) => {
    let notification =
      `Fiat invoice ${invoiceId} failed with` +
      `\n - merchant: ${merchantName} (id=${merchantId})` +
      `\n - provider: ${provider}` +
      `\n - email: ${email}` +
      `\n - status: ${status}` +
      `\n - message: ${message}`

    if (accountId) notification = notification + `\n - accountId: ${accountId}`
    if (code) notification = notification + `\n - code: ${code}`
    if (amount) notification = notification + `\n - amount: ${amount}`
    if (ticker) notification = notification + `\n - currency: ${ticker}`

    await SlackNotificationsApi.send(SlackChannels.FiatReplenishmentIssues, notification)
  }
)

export const successInvoiceFiatAction = createAction('invoice/fiat/success')
