import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

import { useMerchants } from '../../../../state'
import { Component } from './Component'
import { routes } from '../../../router'

export const Container = ({ className }: { className?: string }) => {
  const { merchants } = useMerchants()
  const history = useHistory()
  const { fetchMerchants } = useMerchants()

  useEffect(() => {
    fetchMerchants()
  }, [])

  return (
    <Component
      className={className}
      loading={!merchants}
      merchants={merchants}
      onSeeMore={() => history.push(routes.dashboard.merchants.main)}
      onShowOne={(merchantId: string) =>
        history.push(routes.dashboard.merchants.main + '/' + merchantId, {
          merchantId
        })
      }
      onCreate={() => history.push(routes.dashboard.merchants.create)}
    />
  )
}
