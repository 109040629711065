import { StatusTitle } from '@ppay/client'
import { useTranslation } from 'react-i18next'

export const Status = ({ verified }: { verified?: boolean }) => {
  const { t } = useTranslation('merchant')

  return (
    <StatusTitle
      dotProps={{ color: verified ? 'success' : 'danger' }}
      text={t(verified ? 'verified' : 'notVerified')}
    />
  )
}
