import { CryptoTicker, FiatBalanceTicker } from '@ppay/definitions'
import { useMerchant } from '../../../../../state'
import { Component } from './Component'

export const Container = ({ merchantId }: { merchantId: string }) => {
  const { merchant } = useMerchant(merchantId)

  return (
    <Component
      cryptoFees={Object.entries(merchant!.replenishFees.crypto).map(([ticker, fees]) => ({
        ticker: ticker as CryptoTicker,
        fees
      }))}
      fiatBalanceFees={Object.entries(merchant!.replenishFees.fiat).map(([ticker, fee]) => ({
        ticker: ticker as FiatBalanceTicker,
        fee: fee!
      }))}
    />
  )
}
