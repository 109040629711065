import { FiatTicker, FIAT_TICKERS } from '@ppay/definitions'
import { useContextSelector } from 'use-context-selector'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import {
  SendPageContext,
  CreateWithdrawProvider,
  CreateFiatWithdrawProvider,
  CryptoSendForm,
  FiatSendForm,
  SendForm,
  SendBalanceProvider,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  DashboardTransfersWithdrawCardTitle as CardTitle,
  DashboardTransfersWithdrawCardSubtitle as CardSubtitle,
  SendTransactionCryptoInfoCard,
  SendTransactionFiatInfoCard,
  useBalancesWithTotal,
  FormGroup,
  Label,
  Button as ButtonLib,
  mediaQuery,
  SmallMedium,
  Link,
  breakpoints
} from '@ppay/client'
import { useTranslation, Trans } from 'react-i18next'
import { useState } from 'react'

import { Breadcrumb, Breadcrumbs } from '../../layout'
import { routes } from '../../router'
import { AlertWithButton } from '../AlertWithButton'
import { useMerchants, useUser } from '../../../state'
import { BalanceSelectInput } from '../withdraw/BalanceSelectInput'
import { MerchantCreateCryptoWithdrawProvider } from './MerchantCreateCryptoWithdrawProvider'
import { MerchantCreateFiatWithdrawProvider } from './MerchantCreateFiatWithdrawProvider'
import { MerchantBalanceProvider } from './MerchantBalanceProvider'
import { ToggleTransfer } from './ToggleTransfer'

export const PERSONAL_BALANCE_ID = 'main'

const Alert = styled(AlertWithButton)`
  margin-bottom: 30px;
  ${mediaQuery.lessThan('md')`
    margin-bottom: 20px;
  `}
`

const Button = styled(ButtonLib)`
  width: 100%;
  ${mediaQuery.lessThan('lg')`
    max-width: 223px;
  `}
  ${mediaQuery.greaterThan('lg')`
    max-width: 120px;
    font-size: 14px;
    height: 30px;
  `}
  ${mediaQuery.lessThan('sm')`
    max-width: 100%;
  `}
`

export const Content = () => {
  const { t } = useTranslation(['transfers', 'libCommon', 'routes'])
  const history = useHistory()
  const ticker = useContextSelector(SendPageContext, ({ ticker }) => ticker)

  const isFiat = FIAT_TICKERS.includes(ticker as FiatTicker)
  const isLg = useMediaQuery({ maxWidth: breakpoints.lg })
  const { merchants, loading: merchantsLoading } = useMerchants()
  const { totalBalanceState } = useBalancesWithTotal()
  const { user } = useUser()

  const { state } = useLocation<{ merchantId?: string }>()
  const [merchantId, setMerchantId] = useState<string>(state?.merchantId ?? '')

  const balanceOptions = [
    { value: '', balance: totalBalanceState.data!, name: t('personal') },
    ...(merchants ?? []).map((m) => ({ value: m.id, balance: m.totalBalance, name: m.name }))
  ]

  const FormComponent = isFiat ? FiatSendForm : CryptoSendForm
  const TransactionInfoComponent = isFiat
    ? SendTransactionFiatInfoCard
    : SendTransactionCryptoInfoCard

  const children = (
    <>
      <Row>
        <Breadcrumbs>
          <Breadcrumb link={routes.dashboard.home} name={t('routes:home')} />
          <Breadcrumb name={t('routes:withdraw')} />
        </Breadcrumbs>
      </Row>
      {!user?.accountVerified && (
        <Alert
          title={t('libNotifications:userVerification.title')}
          description={
            <SmallMedium>
              <Trans i18nKey="merchant:dashboardUserAlert.title">
                <Link target="_blank" href={process.env.REACT_APP_CHAT} />
              </Trans>
              <ul className="d-flex flex-column align-items-baseline m-0">
                <li>{t('merchant:dashboardUserAlert.hint1')}</li>
                <li>{t('merchant:dashboardUserAlert.hint2')}</li>
              </ul>
            </SmallMedium>
          }
        >
          <Button small={!isLg} onClick={() => window.open(process.env.REACT_APP_CHAT, '_blank')}>
            {t('libCommon:goToSupport')}
          </Button>
        </Alert>
      )}
      <Row $verticalGutters>
        <Col xl={7}>
          <Card>
            <CardHeader>
              <CardTitle />
              <CardSubtitle />
            </CardHeader>
            <CardBody className="pt-0">
              <SendForm>
                <FormComponent>
                  {!merchantsLoading && (
                    <Row>
                      <Col xs={12}>
                        <FormGroup>
                          <Label>{t('chooseBalanceToWithdraw')}</Label>
                          <BalanceSelectInput
                            value={merchantId}
                            balanceOptions={balanceOptions}
                            onChange={setMerchantId}
                            isSearchable={false}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </FormComponent>
              </SendForm>
            </CardBody>
          </Card>
        </Col>
        <Col xl={5}>
          <TransactionInfoComponent />
          {merchants && merchants.length > 0 && (
            <ToggleTransfer
              toggle={() => history.push(routes.dashboard.oldWithdraw)}
              isTransfer={false}
            />
          )}
        </Col>
      </Row>
    </>
  )

  if (merchantId) {
    const WithdrawProviderComponent = isFiat
      ? MerchantCreateFiatWithdrawProvider
      : MerchantCreateCryptoWithdrawProvider
    return (
      <MerchantBalanceProvider
        merchantId={merchantId}
        balanceName={balanceOptions.find((b) => b.value === merchantId)!.name}
      >
        <WithdrawProviderComponent merchantId={merchantId}>{children}</WithdrawProviderComponent>
      </MerchantBalanceProvider>
    )
  } else {
    const WithdrawProviderComponent = isFiat ? CreateFiatWithdrawProvider : CreateWithdrawProvider
    return (
      <SendBalanceProvider>
        <WithdrawProviderComponent>{children}</WithdrawProviderComponent>
      </SendBalanceProvider>
    )
  }
}
