"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  ProfileSaveChangesButton: true
};
Object.defineProperty(exports, "ProfileSaveChangesButton", {
  enumerable: true,
  get: function get() {
    return _SaveChangesButton.SaveChangesButton;
  }
});
var _Profile = require("./Profile");
Object.keys(_Profile).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Profile[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Profile[key];
    }
  });
});
var _SaveChangesButton = require("./SaveChangesButton");