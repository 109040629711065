"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Body = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Row = require("./Row");
var _Data = require("./Data");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Body = _styledComponents.default.tbody(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border: 1px solid ", ";\n  ", " {\n    transition: 0.2s;\n    ", " {\n      border-top: 1px solid ", ";\n      border-bottom: 1px solid ", ";\n      &:first-of-type {\n        border-left: 1px solid ", ";\n        border-top-left-radius: 4px;\n        border-bottom-left-radius: 4px;\n      }\n      &:last-of-type {\n        border-right: 1px solid ", ";\n        border-top-right-radius: 4px;\n        border-bottom-right-radius: 4px;\n      }\n    }\n    &:hover {\n      background-color: ", " !important;\n      ", " {\n        border-color: ", " !important;\n      }\n    }\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondaryShade2;
}, _Row.Row, _Data.Data, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.secondaryShade2;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.secondaryShade2;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.secondaryShade2;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.secondaryShade2;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.secondaryShade;
}, _Data.Data, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.lightPrimary;
});
exports.Body = Body;