import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { FIAT_BALANCE_TICKERS, Ticker } from '@ppay/definitions'
import { FormGroup, Label, TickerName, TickerIcon, TickerSelectInput } from '@ppay/client'

import { FiatInvoice } from '../../../interfaces'
import { CardAmountToPay, CardSeparativeLine, CardData } from '../card'

const TickerNameColor = styled.span`
  color: ${({ theme }) => theme.colors.tertiaryShade};
  margin-left: 4px;
`
const CardDataWithMargin = styled(CardData)`
  margin: 20px 0;
`

const CurrencyWrapper = styled.div`
  display: flex;
  align-items: center;
  ${TickerIcon} {
    margin-right: 6px;
  }
`

export const TickerSelect = ({
  invoice,
  setTicker,
  ticker
}: {
  invoice: FiatInvoice
  setTicker: (ticker: Ticker) => void
  ticker: Ticker
}) => {
  const { t } = useTranslation('invoice')

  const withAmount = invoice?.amount

  return (
    <>
      {withAmount ? (
        <>
          <CardDataWithMargin
            title={t('currency')}
            value={
              <CurrencyWrapper>
                <TickerIcon ticker={invoice.amount!.ticker} size={24} />
                <TickerName ticker={invoice.amount!.ticker} />
                <TickerNameColor>
                  <TickerName ticker={invoice.amount!.ticker} full />
                </TickerNameColor>
              </CurrencyWrapper>
            }
          />
          <CardAmountToPay
            amount={invoice.amount!.value}
            ticker={invoice.amount!.ticker}
            text={t('amountToPay')}
          />
          <CardSeparativeLine />
        </>
      ) : (
        <>
          <CardSeparativeLine />
          <FormGroup>
            <Label>{t('currency')}</Label>
            <TickerSelectInput
              onChange={setTicker}
              value={ticker}
              tickers={FIAT_BALANCE_TICKERS}
              inputProps={{
                name: 'ticker',
                ticker
              }}
            />
          </FormGroup>
        </>
      )}
    </>
  )
}
