"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CardSubtitle", {
  enumerable: true,
  get: function get() {
    return _Subtitle.Subtitle;
  }
});
Object.defineProperty(exports, "CardTitle", {
  enumerable: true,
  get: function get() {
    return _Title.Title;
  }
});
var _Title = require("./Title");
var _Subtitle = require("./Subtitle");