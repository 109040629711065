"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "RestorePasswordPage", {
  enumerable: true,
  get: function get() {
    return _Page.Page;
  }
});
var _Page = require("./Page");