import React from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  Col,
  Row,
  DashboardHomeBalanceCard,
  DashboardHomeRecentTransactionsCard,
  breakpoints
} from '@ppay/client'
import { useTranslation } from 'react-i18next'

import { Banner } from './Banner'
import { routes } from '../../router'
import { MerchantsListCard } from './MerchantsListCard'
import { UserTransactionsNoData } from '../../transactions'
import { useMerchants } from '../../../state'
import { Breadcrumb, Breadcrumbs } from '../../layout'

export const Page = () => {
  const isSm = useMediaQuery({ maxWidth: breakpoints.xl })
  const { merchants, loading } = useMerchants()
  const { t } = useTranslation('routes')

  return (
    <>
      <Row>
        <Breadcrumbs>
          <Breadcrumb name={t('home')} />
        </Breadcrumbs>
      </Row>
      <Row $verticalGutters>
        <Col xl={7}>
          <Row $verticalGutters>
            {merchants && !merchants?.length && !loading && (
              <Col xs={12}>
                <Banner />
              </Col>
            )}
            <Col xs={12}>
              {isSm ? (
                <MerchantsListCard />
              ) : (
                <DashboardHomeRecentTransactionsCard
                  transactionsPath={routes.dashboard.transactions}
                  noData={<UserTransactionsNoData />}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col xl={5}>
          <Row $verticalGutters>
            <Col xs={12}>
              <DashboardHomeBalanceCard
                walletsPath={routes.dashboard.wallets}
                withdrawPath={routes.dashboard.withdraw}
                replenishPath={routes.dashboard.replenish}
              />
            </Col>
            <Col xs={12}>
              {isSm ? (
                <DashboardHomeRecentTransactionsCard
                  transactionsPath={routes.dashboard.transactions}
                  noData={<UserTransactionsNoData />}
                />
              ) : (
                <MerchantsListCard />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
