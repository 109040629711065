"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFiatWithdraw = useFiatWithdraw;
var _react = require("react");
var _state = require("../../state");
var _useFiatFee2 = require("./useFiatFee");
var _excluded = ["amount", "fiatTickerTotal", "total", "address", "ticker", "fiatTicker", "onSuccess", "isEnoughBalance", "isValidating"];
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function useFiatWithdraw(_ref) {
  var _props$useCreateFiatW, _props$useBalances, _props$fetchBalances, _balances$balancesSta;
  var amount = _ref.amount,
    fiatTickerTotal = _ref.fiatTickerTotal,
    total = _ref.total,
    address = _ref.address,
    ticker = _ref.ticker,
    fiatTicker = _ref.fiatTicker,
    onSuccess = _ref.onSuccess,
    isEnoughBalance = _ref.isEnoughBalance,
    isValidating = _ref.isValidating,
    props = _objectWithoutProperties(_ref, _excluded);
  var isFormValid = isEnoughBalance && !isValidating;
  var _ref2 = ((_props$useCreateFiatW = props.useCreateFiatWithdraw) !== null && _props$useCreateFiatW !== void 0 ? _props$useCreateFiatW : _state.useCreateFiatWithdraw)(),
    withdrawState = _ref2.withdrawState,
    _createWithdraw = _ref2.createWithdraw,
    resetWithdraw = _ref2.resetWithdraw;
  var _useFiatFee = (0, _useFiatFee2.useFiatFee)({
      ticker: ticker,
      isFormValid: isFormValid,
      isLessThanRequired: withdrawState.feeLessThanRequired,
      withdrawDone: withdrawState.done,
      amount: amount
    }),
    fiatFee = _useFiatFee.fiatFee;
  var balances = ((_props$useBalances = props.useBalances) !== null && _props$useBalances !== void 0 ? _props$useBalances : _state.useBalances)();
  var fetchBalances = (_props$fetchBalances = props.fetchBalances) !== null && _props$fetchBalances !== void 0 ? _props$fetchBalances : balances.fetchBalances;
  var isBalances = !((_balances$balancesSta = balances.balancesState) !== null && _balances$balancesSta !== void 0 && _balances$balancesSta.data);
  (0, _react.useEffect)(function () {
    if (isBalances) fetchBalances();
  }, [isBalances]);
  (0, _react.useEffect)(function () {
    if (withdrawState.done) {
      onSuccess();
      balances.recalculateBalances({
        value: total,
        convertedValue: fiatTickerTotal,
        ticker: ticker,
        increase: false
      });
      resetWithdraw();
    }
  }, [withdrawState.done]);

  // reset withdraw state (feeLessThanRequired) to not fetch new fee on ticker or amount change
  (0, _react.useEffect)(function () {
    if (withdrawState.feeLessThanRequired) resetWithdraw();
  }, [ticker, amount, withdrawState.feeLessThanRequired]);
  return {
    createWithdraw: function createWithdraw(formFields) {
      return _createWithdraw({
        ticker: ticker,
        fee: fiatFee,
        amount: amount,
        withdrawKey: formFields === null || formFields === void 0 ? void 0 : formFields.withdrawKey,
        to: address.replaceAll(' ', '')
      });
    },
    createWithdrawLoading: withdrawState.loading,
    feeLessThanRequired: !!withdrawState.feeLessThanRequired
  };
}