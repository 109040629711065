"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _CopyInputGroupAddon = require("./CopyInputGroupAddon");
Object.keys(_CopyInputGroupAddon).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _CopyInputGroupAddon[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _CopyInputGroupAddon[key];
    }
  });
});
var _CopyInput = require("./CopyInput");
Object.keys(_CopyInput).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _CopyInput[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _CopyInput[key];
    }
  });
});