"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useExchangeCryptoInfo = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useExchangeCryptoInfo = function useExchangeCryptoInfo() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.exchange.getCryptoInfo;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    exchangeCryptoInfoState: state,
    getExchangeCryptoInfo: function getExchangeCryptoInfo(params) {
      return dispatch((0, _actions.getExchangeCryptoInfoAction)(params));
    },
    resetExchangeCryptoInfo: function resetExchangeCryptoInfo() {
      return dispatch((0, _actions.resetGetExchangeCryptoInfoAction)());
    }
  };
};
exports.useExchangeCryptoInfo = useExchangeCryptoInfo;