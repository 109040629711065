import { useDispatch } from 'react-redux'

import {
  getMerchantFiatWithdrawFeeAction,
  resetGetMerchantFiatWithdrawFeeAction
} from '../../actions'
import { useSelector } from '../useSelector'

export const useMerchantFiatWithdrawFee = (merchantId: string) => {
  const state = useSelector((state) => state.merchants.transactions.getFiatWithdrawFee)
  const dispatch = useDispatch()

  return {
    withdrawFeeState: {
      loading: state.loading,
      value: state.data?.value,
      converted: state.data?.converted
    },
    fetchWithdrawFee: (
      params: Omit<Parameters<typeof getMerchantFiatWithdrawFeeAction>[0], 'merchantId'>
    ) => dispatch(getMerchantFiatWithdrawFeeAction({ ...params, merchantId })),
    resetWithdrawFee: () => {
      dispatch(resetGetMerchantFiatWithdrawFeeAction())
    }
  }
}
