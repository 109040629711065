import {
  Route,
  lazy,
  NotificationsContainer,
  GlobalStyles,
  ScrollToTop,
  Spinner
} from '@ppay/client'
import React, { Suspense } from 'react'
import { Switch, BrowserRouter as Router, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'

import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'

import {
  routes,
  PublicRoute,
  PrivateRoute,
  LayoutImages,
  WithUserPersonal,
  MerchantTransactionsPage,
  AuthLayout,
  DashboardLayout,
  InvoiceFiatPage,
  InvoiceCryptoPage,
  NotFoundPage
} from './components'
import { store } from './state'

const SuspenseLoader = () => (
  <div className="d-flex h-100">
    <Spinner color="primary" />
  </div>
)

const {
  LoginPage,
  RestorePasswordPage,
  SignUpPage,
  OAuthPage,
  ConfirmEmailPage,
  HomePage,
  MerchantPage,
  MerchantSettingsPage,
  ProvidePersonalPage,
  CreateMerchantPage,
  SettingsPage,
  MerchantsPage,
  WithdrawPage,
  WalletsPage,
  TransactionsPage,
  ReplenishPage,
  SendPage
} = lazy(() => import('./components'))

export function App() {
  return (
    <Provider store={store}>
      <Router>
        <NotificationsContainer />
        <GlobalStyles />
        <ScrollToTop />
        <Switch>
          <Route path={routes.auth.layout}>
            <AuthLayout logo={LayoutImages.logo}>
              <Suspense fallback={<SuspenseLoader />}>
                <Switch>
                  <PublicRoute path={routes.auth.login} exact title="Login to your account">
                    <LoginPage />
                  </PublicRoute>
                  <PublicRoute path={routes.auth.restore} exact title="Restore Password">
                    <RestorePasswordPage />
                  </PublicRoute>
                  <PublicRoute path={routes.auth.signUp} exact title="Sign Up">
                    <SignUpPage />
                  </PublicRoute>
                  <PublicRoute exact path="/auth/oauth/" title="OAuth">
                    <OAuthPage />
                  </PublicRoute>
                  <PublicRoute
                    restricted={false}
                    path={routes.auth.providePersonal}
                    title="Provide Personal"
                  >
                    <WithUserPersonal provided={false}>
                      <ProvidePersonalPage />
                    </WithUserPersonal>
                  </PublicRoute>
                  <PublicRoute
                    restricted={false}
                    exact
                    path="/auth/confirm-email/:key"
                    title="Email Confirmation"
                  >
                    <ConfirmEmailPage />
                  </PublicRoute>
                  <PublicRoute path="*">
                    <Redirect to={routes.auth.login} />
                  </PublicRoute>
                </Switch>
              </Suspense>
            </AuthLayout>
          </Route>

          <Route path={routes.invoices.layout} title="Invoice">
            <Suspense fallback={<SuspenseLoader />}>
              <Switch>
                <Route path={routes.invoices.crypto + '/:id'} title="Invoice">
                  <InvoiceCryptoPage />
                </Route>
                <Route path={routes.invoices.fiat + '/:id'} title="Invoice">
                  <InvoiceFiatPage />
                </Route>
                <Route
                  path="*"
                  component={() => {
                    window.location.href = process.env.REACT_APP_SITE_URL ?? ''
                    return null
                  }}
                />
              </Switch>
            </Suspense>
          </Route>
          <Route path={routes.notFound}>
            <NotFoundPage />
          </Route>

          <PrivateRoute>
            <DashboardLayout>
              <Suspense fallback={<SuspenseLoader />}>
                <Switch>
                  <Route exact path={routes.dashboard.home} title="Dashboard">
                    <HomePage />
                  </Route>
                  <Route exact path={routes.dashboard.oldWithdraw} title="Dashboard | Withdraw">
                    <WithdrawPage />
                  </Route>
                  <Route exact path={routes.dashboard.withdraw} title="Dashboard | Withdraw">
                    <SendPage />
                  </Route>
                  <Route
                    exact
                    path={routes.dashboard.transactions}
                    title="Dashboard | Transactions"
                  >
                    <TransactionsPage />
                  </Route>
                  <Route exact path={routes.dashboard.replenish} title="Dashboard | Replenish">
                    <ReplenishPage />
                  </Route>
                  <Route exact path={routes.dashboard.wallets} title="Dashboard | Wallets">
                    <WalletsPage />
                  </Route>
                  <Route exact path={routes.dashboard.settings} title="Dashboard | Settings">
                    <SettingsPage />
                  </Route>
                  <Route
                    exact
                    path={routes.dashboard.merchants.create}
                    title="Dashboard | Merchants | Create"
                  >
                    <CreateMerchantPage />
                  </Route>

                  <Route
                    exact
                    path={routes.dashboard.merchants.main}
                    title="Dashboard | Merchants List"
                  >
                    <MerchantsPage />
                  </Route>

                  <Route path={routes.dashboard.merchants.main + '/:id'}>
                    <Switch>
                      <Route
                        exact
                        path={routes.dashboard.merchants.main + '/:id/settings'}
                        title="Dashboard | Merchant | Settings"
                      >
                        <MerchantSettingsPage />
                      </Route>
                      <Route
                        exact
                        path={routes.dashboard.merchants.main + '/:id/transactions'}
                        title="Dashboard | Merchant | Transactions"
                      >
                        <MerchantTransactionsPage />
                      </Route>
                      <Route
                        exact
                        path={routes.dashboard.merchants.main + '/:id'}
                        title="Dashboard | Merchant"
                      >
                        <MerchantPage />
                      </Route>
                      <Route path="*">
                        <Redirect to={routes.notFound} />
                      </Route>
                    </Switch>
                  </Route>

                  <Route path="*">
                    <Redirect to={routes.notFound} />
                  </Route>
                </Switch>
              </Suspense>
            </DashboardLayout>
          </PrivateRoute>
        </Switch>
      </Router>
    </Provider>
  )
}
