"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Icon = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Svg = require("../../../Svg");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Icon = (0, _styledComponents.default)(_Svg.Svg)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  & > * {\n    fill: ", ";\n  }\n"])), function (_ref) {
  var theme = _ref.theme,
    checked = _ref.checked,
    disabled = _ref.disabled;
  return !checked || disabled ? 'transparent' : theme.colors.primary;
});
exports.Icon = Icon;