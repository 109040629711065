import { Spinner } from '@ppay/client'
import { PropsWithChildren } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import { useMerchant, useMerchants } from '../../../state'
import { routes } from '../../router'

export const WithMerchantExists = ({ children }: PropsWithChildren<{}>) => {
  const { id } = useParams<{ id: string }>()
  const merchant = useMerchant(id)
  const { merchants, loading } = useMerchants()

  if (loading || !merchants)
    return (
      <div className="d-flex justify-content-center">
        <Spinner color="primary" />
      </div>
    )

  if (!merchant) return <Redirect to={routes.dashboard.merchants.main} />

  return <>{children}</>
}
