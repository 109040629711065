import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { mediaQuery, Alert, useUser } from '@ppay/client'
import get from 'lodash.get'

import { KeyInput } from './KeyInput'
import { useMerchant } from '../../../../../state'

const StyledKeyInput = styled(KeyInput)`
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`
const StyledAlert = styled(Alert)`
  margin-top: 14px;
  ${mediaQuery.lessThan('md')`
    br {
      display: none;
    }
  `}
`

export const ApiKeys = ({ merchantId }: { merchantId: string }) => {
  const { user } = useUser()
  const { merchant } = useMerchant(merchantId)
  const { t } = useTranslation('merchant')

  const withdraw2faEnabled = get(user, 'credentials.withdraw2faEnabled', false)
  const privateKeyNotLoaded = !withdraw2faEnabled && !merchant!.privateKey

  return (
    <>
      <StyledKeyInput label={t('merchantId')} value={merchant!.id} visibleText />
      <StyledKeyInput
        label={t('privateKey')}
        value={
          withdraw2faEnabled ? t('withdraw2faTurnOn') : merchant!.privateKey ?? t('reloadWindow')
        }
        copyDisabled={withdraw2faEnabled || privateKeyNotLoaded}
        visibleText={withdraw2faEnabled || privateKeyNotLoaded}
      />
      <StyledAlert iconColor="danger">
        <Trans i18nKey="merchant:privateKeyAlert">
          <i />
        </Trans>
      </StyledAlert>
    </>
  )
}
