"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Svg = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Svg = _styledComponents.default.svg(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n  transform: rotate(", ");\n  path {\n    fill: ", ";\n  }\n"])), function (_ref) {
  var size = _ref.size;
  return size && "height: ".concat(size, "px; width: ").concat(size, "px;");
}, function (_ref2) {
  var rotate = _ref2.rotate;
  return rotate ? rotate + 'deg' : '0';
}, function (_ref3) {
  var color = _ref3.color,
    theme = _ref3.theme;
  return color ? theme.colors[color] : undefined;
});
exports.Svg = Svg;