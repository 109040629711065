"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFiatFee = void 0;
var _react = require("react");
var _useContextSelector = require("use-context-selector");
var _components = require("../../components");
var useFiatFee = function useFiatFee(_ref) {
  var isFormValid = _ref.isFormValid;
  var _useContext = (0, _react.useContext)(_components.CreateWithdrawContext),
    useCreateWithdraw = _useContext.useCreateWithdraw,
    useWithdrawFee = _useContext.useWithdrawFee;
  var _useCreateWithdraw = useCreateWithdraw(),
    withdrawState = _useCreateWithdraw.withdrawState;
  var amount = (0, _useContextSelector.useContextSelector)(_components.SendPageContext, function (state) {
    return state.amount;
  });
  var ticker = (0, _useContextSelector.useContextSelector)(_components.SendPageContext, function (state) {
    return state.ticker;
  });
  var _ref2 = useWithdrawFee(),
    fetchWithdrawFee = _ref2.fetchWithdrawFee,
    withdrawFeeState = _ref2.withdrawFeeState,
    resetWithdrawFee = _ref2.resetWithdrawFee;
  (0, _react.useEffect)(function () {
    resetWithdrawFee();
  }, [amount, ticker]);
  (0, _react.useEffect)(function () {
    return function () {
      resetWithdrawFee();
    };
  }, []);
  (0, _react.useEffect)(function () {
    if (amount && isFormValid && !withdrawFeeState.loading && !withdrawFeeState.value) {
      fetchWithdrawFee({
        ticker: ticker,
        amount: amount
      });
    }
  }, [amount, ticker, isFormValid, withdrawFeeState]);
  (0, _react.useEffect)(function () {
    if (withdrawState.done) {
      resetWithdrawFee();
    }
  }, [withdrawState.done]);

  // fetch actual fee if current is less than required
  (0, _react.useEffect)(function () {
    if (withdrawState.feeLessThanRequired) fetchWithdrawFee({
      ticker: ticker,
      amount: amount
    });
  }, [withdrawState.feeLessThanRequired, ticker, amount]);
  return {
    value: withdrawFeeState.value,
    converted: withdrawFeeState.converted
  };
};
exports.useFiatFee = useFiatFee;