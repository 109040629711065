"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CryptoConfirmModal", {
  enumerable: true,
  get: function get() {
    return _Crypto.Crypto;
  }
});
Object.defineProperty(exports, "FiatConfirmModal", {
  enumerable: true,
  get: function get() {
    return _Fiat.Fiat;
  }
});
var _Fiat = require("./Fiat");
var _Crypto = require("./Crypto");