"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBalances = void 0;
var _reactRedux = require("react-redux");
var _react = require("react");
var _useSelector2 = require("../useSelector");
var _actions = require("../../actions");
var useBalances = function useBalances() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var _useSelector = (0, _useSelector2.useSelector)(function (state) {
      return state.lib.balances;
    }),
    get = _useSelector.get;
  var fetchBalances = (0, _react.useCallback)(function () {
    dispatch((0, _actions.getBalancesAction)());
  }, [dispatch]);
  (0, _react.useEffect)(function () {
    if (!get.data) fetchBalances();
  }, [get.data]);
  return {
    balances: get.data,
    balancesLoading: get.loading || !get.data,
    getBalanceByTicker: function getBalanceByTicker(ticker) {
      var _get$data;
      return (_get$data = get.data) === null || _get$data === void 0 ? void 0 : _get$data.find(function (balance) {
        return balance.ticker === ticker;
      });
    },
    fetchBalances: fetchBalances,
    recalculateBalances: function recalculateBalances(props) {
      dispatch((0, _actions.recalculateBalancesAction)(props));
    }
  };
};
exports.useBalances = useBalances;