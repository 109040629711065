"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createGetTransactionsReducer = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _decimal = _interopRequireDefault(require("decimal.js"));
var _fileSaver = _interopRequireDefault(require("file-saver"));
var _actions = require("../../actions");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var initialState = {
  loading: false,
  page: 0,
  pages: 0,
  data: undefined,
  csvLoading: false
};
var createGetTransactionsReducer = function createGetTransactionsReducer(getActionParam, getCSVActionParam) {
  return (0, _toolkit.createReducer)(initialState, function (builder) {
    var getAction = getActionParam !== null && getActionParam !== void 0 ? getActionParam : _actions.getTransactionsAction;
    var getCSVAction = getCSVActionParam !== null && getCSVActionParam !== void 0 ? getCSVActionParam : _actions.getTransactionsCSVAction;
    builder.addCase(getAction.pending, function (state, action) {
      var _action$meta$arg;
      if (!((_action$meta$arg = action.meta.arg) !== null && _action$meta$arg !== void 0 && _action$meta$arg.page)) {
        return _objectSpread(_objectSpread(_objectSpread({}, state), initialState), {}, {
          loading: true
        });
      }
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    }).addCase(getAction.rejected, function (state) {
      state.loading = false;
    }).addCase(getAction.fulfilled, function (state, action) {
      var _state$data;
      var _action$payload = action.payload,
        page = _action$payload.page,
        pages = _action$payload.pages,
        entities = _action$payload.entities;
      var entitiesMerge = entities.map(function (entitie) {
        var _entitie$paidAmount$v, _entitie$paidAmount;
        var amountDue = new _decimal.default((_entitie$paidAmount$v = (_entitie$paidAmount = entitie.paidAmount) === null || _entitie$paidAmount === void 0 ? void 0 : _entitie$paidAmount.value) !== null && _entitie$paidAmount$v !== void 0 ? _entitie$paidAmount$v : 0).sub(entitie.amount.value);
        return _objectSpread(_objectSpread({}, entitie), entitie !== null && entitie !== void 0 && entitie.paidAmount ? {
          amountDue: amountDue.toString(),
          isFullyPaid: amountDue.isZero(),
          isOverPaid: amountDue.isPositive()
        } : {});
      });
      state.data = page ? [].concat(_toConsumableArray((_state$data = state.data) !== null && _state$data !== void 0 ? _state$data : []), _toConsumableArray(entitiesMerge)) : entitiesMerge;
      state.loading = false;
      state.pages = pages;
      state.page = page;
    }).addCase(getCSVAction.pending, function (state) {
      state.csvLoading = true;
    }).addCase(getCSVAction.rejected, function (state) {
      state.csvLoading = false;
    }).addCase(getCSVAction.fulfilled, function (state, action) {
      state.csvLoading = false;
      var blob = new Blob([action.payload], {
        type: 'text/csv;charset=utf-8'
      });
      (0, _fileSaver.default)(blob, "transactions-".concat(Date.now(), ".csv"));
    });
  });
};
exports.createGetTransactionsReducer = createGetTransactionsReducer;