"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWithdraw2faSwitch = void 0;
var _state = require("../state");
var useWithdraw2faSwitch = function useWithdraw2faSwitch() {
  var _useUser = (0, _state.useUser)(),
    user = _useUser.user;
  var _useWithdraw2fa = (0, _state.useWithdraw2fa)(),
    loading = _useWithdraw2fa.loading,
    enableWithdraw2fa = _useWithdraw2fa.enableWithdraw2fa;
  var checked = !!(user !== null && user !== void 0 && user.credentials.withdraw2faEnabled);
  var isEmailNotConfirmed = !(user !== null && user !== void 0 && user.credentials.emailConfirmed);
  return {
    checked: checked,
    disabled: loading || isEmailNotConfirmed,
    enableForbidden: isEmailNotConfirmed,
    onChange: function onChange() {
      return !checked && enableWithdraw2fa();
    }
  };
};
exports.useWithdraw2faSwitch = useWithdraw2faSwitch;