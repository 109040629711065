import { createReducer } from '@reduxjs/toolkit'
import { updateInvoiceCryptoAction } from '../../../actions'

type State = {
  loading: boolean
}

const initialState: State = {
  loading: false
}

export const update = createReducer(initialState, (builder) => {
  builder
    .addCase(updateInvoiceCryptoAction.pending, (state) => {
      state.loading = true
    })
    .addCase(updateInvoiceCryptoAction.rejected, (state) => {
      state.loading = false
    })
})
