"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.colors = void 0;
var colors = {
  success: '#41c6a7',
  lightSuccess: '#eafbf9',
  warning: '#ff8401',
  lightWarning: '#fff3e7',
  danger: '#d95d7b',
  lightDanger: '#fbf3f3',
  primary: '#3139ce',
  lightPrimary: '#d6d7f5',
  primaryShade2: '#4c54e8',
  purple: '#4b3c90',
  // kind of white
  secondary: '#ffffff',
  secondaryShade: '#f6f6f6',
  secondaryShade2: '#e3e5e5',
  // kind of black
  tertiary: '#25253e',
  tertiaryShade: '#919699',
  tertiaryShade2: '#9b9b9b'
};
exports.colors = colors;