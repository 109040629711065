"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  PageContext: true
};
Object.defineProperty(exports, "PageContext", {
  enumerable: true,
  get: function get() {
    return _Context.Context;
  }
});
var _Context = require("./Context");
var _Page = require("./Page");
Object.keys(_Page).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Page[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Page[key];
    }
  });
});