"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TickerConverter = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _utils = require("../../../../../utils");
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var TickerConverter = _styledComponents.default.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  margin-top: 20px;\n  ", "\n"])), _utils.mediaQuery.lessThan('md')(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    flex-direction: column;\n    margin-top: 30px;\n  "]))));
exports.TickerConverter = TickerConverter;