import { breakpoints } from '@ppay/client'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { useUser, useMerchants } from '../../state'
import { removeTrailingSlash } from '../../utils'
import { Merchant } from '../../interfaces'
import { routes } from '../../components'

const filteredFields: Array<keyof Merchant> = ['name', 'totalBalance', 'domain']

type Value = Merchant['name'] | Merchant['totalBalance'] | Merchant['domain']

export const usePage = () => {
  const { loading, merchants, fetchMerchants } = useMerchants()
  const [filteredMerchants, setFilteredMerchants] = useState(merchants)
  const { user } = useUser()
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md })
  const history = useHistory()

  useEffect(() => {
    fetchMerchants()
  }, [])

  useEffect(() => {
    if (merchants) {
      const formattedMerchants = merchants.map(({ domain, ...rest }: Merchant) => ({
        ...rest,
        domain: removeTrailingSlash(domain)
      }))
      setFilteredMerchants(formattedMerchants)
    }
  }, [merchants])

  return {
    loading: loading ?? !merchants,
    merchants: filteredMerchants,
    email2faEnabled: !!user?.credentials.email2faEnabled,
    totpEnabled: !!user?.credentials.totpEnabled,
    isMobile,
    onCreateMerchantClick: () => history.push(routes.dashboard.merchants.create),
    filterMerchants: (value: string) => {
      const result = merchants?.filter((m: Merchant) =>
        filteredFields.some((key) => (m[key] as Value).includes(value))
      )
      setFilteredMerchants(result ?? merchants)
    }
  }
}
