"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropdownIndicator = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _DropdownIndicator = require("../../ui/forms/inputs/select/DropdownIndicator");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var DropdownIndicator = (0, _styledComponents.default)(_DropdownIndicator.DropdownIndicator)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  svg path {\n    fill: ", ";\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondaryShade;
});
exports.DropdownIndicator = DropdownIndicator;