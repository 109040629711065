"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LayoutBody", {
  enumerable: true,
  get: function get() {
    return _Body.Body;
  }
});
Object.defineProperty(exports, "LayoutBodyContainer", {
  enumerable: true,
  get: function get() {
    return _BodyContainer.BodyContainer;
  }
});
Object.defineProperty(exports, "LayoutFooter", {
  enumerable: true,
  get: function get() {
    return _footer.Footer;
  }
});
Object.defineProperty(exports, "LayoutFooterContainer", {
  enumerable: true,
  get: function get() {
    return _footer.FooterContainer;
  }
});
Object.defineProperty(exports, "LayoutFooterDropdownCollapse", {
  enumerable: true,
  get: function get() {
    return _footer.FooterDropdownCollapse;
  }
});
Object.defineProperty(exports, "LayoutFooterLanguageSelect", {
  enumerable: true,
  get: function get() {
    return _footer.FooterLanguageSelect;
  }
});
Object.defineProperty(exports, "LayoutFooterLogo", {
  enumerable: true,
  get: function get() {
    return _footer.FooterLogo;
  }
});
Object.defineProperty(exports, "LayoutFooterMenuItem", {
  enumerable: true,
  get: function get() {
    return _footer.FooterMenuItem;
  }
});
Object.defineProperty(exports, "LayoutFooterSeparator", {
  enumerable: true,
  get: function get() {
    return _footer.FooterSeparator;
  }
});
Object.defineProperty(exports, "LayoutFooterSocials", {
  enumerable: true,
  get: function get() {
    return _footer.FooterSocials;
  }
});
Object.defineProperty(exports, "LayoutFooterTitle", {
  enumerable: true,
  get: function get() {
    return _footer.FooterTitle;
  }
});
Object.defineProperty(exports, "LayoutHeader", {
  enumerable: true,
  get: function get() {
    return _header.Header;
  }
});
Object.defineProperty(exports, "LayoutHeaderBrand", {
  enumerable: true,
  get: function get() {
    return _header.HeaderBrand;
  }
});
Object.defineProperty(exports, "LayoutHeaderCollapse", {
  enumerable: true,
  get: function get() {
    return _header.HeaderCollapse;
  }
});
Object.defineProperty(exports, "LayoutHeaderContainer", {
  enumerable: true,
  get: function get() {
    return _header.HeaderContainer;
  }
});
Object.defineProperty(exports, "LayoutHeaderDropdownCollapse", {
  enumerable: true,
  get: function get() {
    return _header.HeaderDropdownCollapse;
  }
});
Object.defineProperty(exports, "LayoutHeaderLanguageDropdown", {
  enumerable: true,
  get: function get() {
    return _header.HeaderLanguageDropdown;
  }
});
Object.defineProperty(exports, "LayoutHeaderLogo", {
  enumerable: true,
  get: function get() {
    return _header.HeaderLogo;
  }
});
Object.defineProperty(exports, "LayoutHeaderNav", {
  enumerable: true,
  get: function get() {
    return _header.HeaderNav;
  }
});
Object.defineProperty(exports, "LayoutHeaderNavItem", {
  enumerable: true,
  get: function get() {
    return _header.HeaderNavItem;
  }
});
Object.defineProperty(exports, "LayoutHeaderToggler", {
  enumerable: true,
  get: function get() {
    return _header.HeaderToggler;
  }
});
Object.defineProperty(exports, "LayoutLogo", {
  enumerable: true,
  get: function get() {
    return _Logo.Logo;
  }
});
var _Body = require("./Body");
var _footer = require("./footer");
var _BodyContainer = require("./BodyContainer");
var _header = require("./header");
var _Logo = require("./Logo");