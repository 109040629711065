"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaySoftStatus = void 0;
var PaySoftStatus = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL'
};
exports.PaySoftStatus = PaySoftStatus;