"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Row = void 0;
var _reactstrap = require("reactstrap");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _utils = require("../../../utils");
var _Col = require("./Col");
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Row = (0, _styledComponents.default)(_reactstrap.Row)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-left: -15px;\n  margin-right: -15px;\n  ", "\n  ", "\n"])), function (_ref) {
  var $verticalGutters = _ref.$verticalGutters;
  return $verticalGutters && "--bs-gutter-y: 30px;\n    & > ".concat(_Col.Col, " {\n      margin-top: 30px; \n    }\n  ");
}, function (_ref2) {
  var $verticalGutters = _ref2.$verticalGutters,
    $mobileVerticalGutters = _ref2.$mobileVerticalGutters;
  return $verticalGutters && _utils.mediaQuery.lessThan('md')(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      --bs-gutter-y: ", "px;\n      & > ", " {\n        margin-top: ", "px; \n      }\n    "])), $mobileVerticalGutters !== null && $mobileVerticalGutters !== void 0 ? $mobileVerticalGutters : 20, _Col.Col, $mobileVerticalGutters !== null && $mobileVerticalGutters !== void 0 ? $mobileVerticalGutters : 20);
});
exports.Row = Row;