import { updateMerchantNotificationsUrl } from '../../actions'
import { useDispatch } from '../useDispatch'
import { useSelector } from '../useSelector'

export const useUpdateMerchantNotificationsUrl = () => {
  const { loading } = useSelector((state) => state.merchants.updateNotificationsUrl)
  const dispatch = useDispatch()

  return {
    loading,
    updateNotificationsUrl: (id: string, notificationsUrl: string) =>
      dispatch(updateMerchantNotificationsUrl({ id, notificationsUrl }))
  }
}
