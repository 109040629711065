"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  TableRow: true,
  TableHeader: true,
  TableData: true,
  TableBody: true,
  TableHead: true
};
Object.defineProperty(exports, "TableBody", {
  enumerable: true,
  get: function get() {
    return _Body.Body;
  }
});
Object.defineProperty(exports, "TableData", {
  enumerable: true,
  get: function get() {
    return _Data.Data;
  }
});
Object.defineProperty(exports, "TableHead", {
  enumerable: true,
  get: function get() {
    return _Head.Head;
  }
});
Object.defineProperty(exports, "TableHeader", {
  enumerable: true,
  get: function get() {
    return _Header.Header;
  }
});
Object.defineProperty(exports, "TableRow", {
  enumerable: true,
  get: function get() {
    return _Row.Row;
  }
});
var _tableData = require("./table-data");
Object.keys(_tableData).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _tableData[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _tableData[key];
    }
  });
});
var _Table = require("./Table");
Object.keys(_Table).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _Table[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _Table[key];
    }
  });
});
var _Row = require("./Row");
var _Header = require("./Header");
var _Data = require("./Data");
var _Body = require("./Body");
var _Head = require("./Head");