"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePaySoft = void 0;
var _react = require("react");
var _reactI18next = require("react-i18next");
var _constants = require("../../constants");
var _theme = require("../../theme");
var _state = require("../../state");
var _useDebounce = require("./useDebounce");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var style = {
  base: {
    color: _theme.theme.colors.tertiary,
    'font-size': '16px'
  },
  placeholder: {
    color: _theme.theme.colors.tertiaryShade
  },
  error: {
    color: _theme.theme.colors.danger
  }
};
var usePaySoft = function usePaySoft(_ref) {
  var amount = _ref.amount,
    onSubmit = _ref.onSubmit;
  var _useTranslation = (0, _reactI18next.useTranslation)(),
    language = _useTranslation.i18n.language;
  var _useState = (0, _react.useState)(null),
    _useState2 = _slicedToArray(_useState, 2),
    status = _useState2[0],
    setStatus = _useState2[1];
  var _useState3 = (0, _react.useState)(''),
    _useState4 = _slicedToArray(_useState3, 2),
    errorMessage = _useState4[0],
    setErrorMessage = _useState4[1];
  var _useState5 = (0, _react.useState)(false),
    _useState6 = _slicedToArray(_useState5, 2),
    loading = _useState6[0],
    setLoading = _useState6[1];
  var _useState7 = (0, _react.useState)(false),
    _useState8 = _slicedToArray(_useState7, 2),
    loadingWidget = _useState8[0],
    setLoadingWidget = _useState8[1];
  var paySoftRef = (0, _react.useRef)();
  var _useUser = (0, _state.useUser)(),
    user = _useUser.user;
  var value = (0, _useDebounce.useDebounce)(amount, 1000);
  (0, _react.useEffect)(function () {
    var _paySoftRef$current, _paySoftRef$current2, _paySoftRef$current3, _paySoftRef$current4;
    paySoftRef.current = PMHostedFields({
      params: {
        LMI_MERCHANT_ID: process.env.REACT_APP_INVOICE_MERCHANT_ID,
        LMI_PAYMENT_AMOUNT: value,
        LMI_PAYMENT_NO: user === null || user === void 0 ? void 0 : user.id,
        LMI_PAYMENT_DESC: user === null || user === void 0 ? void 0 : user.fiatReplenishGateweyMeta
      },
      language: language === 'ua' ? 'uk' : language,
      callbacks: {
        onFieldsLoaded: function onFieldsLoaded() {
          return setLoadingWidget(false);
        }
      }
    });
    var cardNumber = (_paySoftRef$current = paySoftRef.current) === null || _paySoftRef$current === void 0 ? void 0 : _paySoftRef$current.create('cardnumber', '#card-number', {
      style: style
    });
    var cardExpireMonth = (_paySoftRef$current2 = paySoftRef.current) === null || _paySoftRef$current2 === void 0 ? void 0 : _paySoftRef$current2.create('cardexpireyear', '#card-expire-year', {
      style: style
    });
    var cardExpireYear = (_paySoftRef$current3 = paySoftRef.current) === null || _paySoftRef$current3 === void 0 ? void 0 : _paySoftRef$current3.create('cardexpiremonth', '#card-expire-month', {
      style: style
    });
    var cardCVV = (_paySoftRef$current4 = paySoftRef.current) === null || _paySoftRef$current4 === void 0 ? void 0 : _paySoftRef$current4.create('cardcvv', '#card-cvc', {
      style: style
    });
    var fields = [cardNumber, cardExpireMonth, cardExpireYear, cardCVV];
    fields.forEach(function (element) {
      element === null || element === void 0 ? void 0 : element.addEventListener('change', function (event) {
        var displayError = document.getElementById('error-' + element.parentNode.id);
        if (event.detail.error) {
          displayError.textContent = event.detail.error;
          displayError.style.display = 'inline-block';
        } else {
          displayError.textContent = '';
          displayError.style.display = 'none';
        }
      });
    });
  }, [value, language]);
  var handleSubmit = function handleSubmit(event) {
    var _paySoftRef$current5;
    event.preventDefault();
    onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
    setLoading(true);
    (_paySoftRef$current5 = paySoftRef.current) === null || _paySoftRef$current5 === void 0 ? void 0 : _paySoftRef$current5.Pay(function (result) {
      if (result.status !== 'success') {
        setLoading(false);
        var error = result.message.match(/(.*\s*)\((\d+)\).*/);
        if (error) {
          setErrorMessage(error[1]);
          setStatus(_constants.PaySoftStatus.FAIL);
        }
      } else {
        setLoading(false);
        setStatus(_constants.PaySoftStatus.SUCCESS);
      }
    });
  };
  return {
    paySoftRef: paySoftRef,
    loadingWidget: loadingWidget,
    status: status,
    loading: loading,
    errorMessage: errorMessage,
    handleSubmit: handleSubmit,
    clearStatus: function clearStatus() {
      return setStatus(null);
    }
  };
};
exports.usePaySoft = usePaySoft;