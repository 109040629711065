import { useEffect } from 'react'
import get from 'lodash.get'

import { useMerchant, useUser } from '../../state'

export const useRefetchById = (id: string) => {
  const { user } = useUser()
  const { merchant, fetchMerchant } = useMerchant(id)

  useEffect(() => {
    if (!merchant?.privateKey && !get(user, 'credentials.withdraw2faEnabled', false)) {
      fetchMerchant()
    }
  }, [merchant?.privateKey])

  return {
    merchant
  }
}
