import {
  Row,
  DashboardTransfersReplenishPage,
  breakpoints,
  mediaQuery,
  Button as ButtonLib,
  SmallMedium,
  Link
} from '@ppay/client'
import { useTranslation, Trans } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { AlertWithButton } from '../AlertWithButton'

import { Breadcrumb, Breadcrumbs } from '../../layout'
import { routes } from '../../router'
import { useUser } from '../../../state'

const Alert = styled(AlertWithButton)`
  margin-bottom: 30px;
  ${mediaQuery.lessThan('md')`
    margin-bottom: 20px;
  `}
`

const Button = styled(ButtonLib)`
  width: 100%;
  ${mediaQuery.lessThan('lg')`
    max-width: 223px;
  `}
  ${mediaQuery.greaterThan('lg')`
    max-width: 120px;
    font-size: 14px;
    height: 30px;
  `}
  ${mediaQuery.lessThan('sm')`
    max-width: 100%;
  `}
`

export const ReplenishPage = () => {
  const isLg = useMediaQuery({ maxWidth: breakpoints.lg })
  const { t } = useTranslation(['libNotifications', 'libCommon', 'routes'])
  const { user } = useUser()

  return (
    <>
      <Row>
        <Breadcrumbs>
          <Breadcrumb link={routes.dashboard.home} name={t('routes:home')} />
          <Breadcrumb name={t('routes:replenish')} />
        </Breadcrumbs>
      </Row>
      {!user?.accountVerified && (
        <Alert
          title={t('userVerification.title')}
          description={
            <SmallMedium>
              <Trans i18nKey="merchant:dashboardUserAlert.title">
                <Link target="_blank" href={process.env.REACT_APP_CHAT} />
              </Trans>
              <ul className="d-flex flex-column align-items-baseline m-0">
                <li>{t('merchant:dashboardUserAlert.hint1')}</li>
                <li>{t('merchant:dashboardUserAlert.hint2')}</li>
              </ul>
            </SmallMedium>
          }
        >
          <Button small={!isLg} onClick={() => window.open(process.env.REACT_APP_CHAT, '_blank')}>
            {t('libCommon:goToSupport')}
          </Button>
        </Alert>
      )}
      <DashboardTransfersReplenishPage />
    </>
  )
}
