"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Footer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _mediaQuery = require("../../../utils/mediaQuery");
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Footer = _styledComponents.default.footer(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: ", ";\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 40px 0 40px 0;\n  ", "\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.tertiaryShade;
}, _mediaQuery.mediaQuery.lessThan('lg')(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 40px 0;\n  "]))));
exports.Footer = Footer;