import { useSelector } from '../useSelector'
import { useDispatch } from '../useDispatch'
import { createMerchantAction, resetCreateMerchantAction } from '../../actions'

export const useCreateMerchant = () => {
  const state = useSelector((state) => state.merchants.create)
  const dispatch = useDispatch()

  return {
    createMerchantLoading: state.loading,
    merchantCreated: state.done,
    createMerchant: (...params: Parameters<typeof createMerchantAction>) =>
      dispatch(createMerchantAction(...params)),
    resetCreateMerchant: () => dispatch(resetCreateMerchantAction())
  }
}
