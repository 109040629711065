"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  loading: false,
  token: typeof window !== 'undefined' ? localStorage.getItem('token') : null
};
var login = (0, _toolkit.createReducer)(initialState, function (builder) {
  builder.addCase(_actions.loginAction.pending, function (state) {
    state.loading = true;
  });
  builder.addCase(_actions.loginAction.rejected, function (state, action) {
    var _action$payload;
    state.loading = false;
    if ((_action$payload = action.payload) !== null && _action$payload !== void 0 && _action$payload.data.time) {
      state.remainingTime = action.payload.data.time;
    }
  });
  builder.addCase(_actions.loginAction.fulfilled, function (state, action) {
    state.loading = false;
    if (typeof action.payload !== 'string') {
      state.credentialsFormData = {
        email: action.meta.arg.email,
        password: action.meta.arg.password
      };
    }
  });
  builder.addCase(_actions.setAuthorizationTokenAction, function (state, action) {
    state.token = action.payload;
  });
  builder.addCase(_actions.logoutAction, function (state) {
    state.token = null;
  });
});
exports.login = login;