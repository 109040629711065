"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Head = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Head = _styledComponents.default.thead(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: sticky;\n  top: 0;\n  background-color: ", ";\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondary;
});
exports.Head = Head;