import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownMenuItem,
  LayoutHeaderDropdownCollapse,
  H5
} from '@ppay/client'

import { useMerchants } from '../../../../state'
import { routes } from '../../../router'
import { Icon } from '../../../ui'

export const MerchantsDropdown = ({ collapse }: { collapse?: boolean }) => {
  const { t } = useTranslation('layout')
  const { fetchMerchants, merchants } = useMerchants()
  const history = useHistory()

  useEffect(() => {
    fetchMerchants()
  }, [])

  const Component = collapse ? LayoutHeaderDropdownCollapse : DropdownMenu

  return (
    <Dropdown collapse={collapse}>
      <DropdownToggle id="merchantsCollapse" onClick={function noRefCheck() {}}>
        <H5>{t('header.merchants')}</H5>
      </DropdownToggle>
      <Component toggler="#merchantsCollapse">
        <DropdownMenuItem
          title={
            (
              <>
                {t('header.allMerchants')} <Icon color="secondary" name="arrowRight" />
              </>
            ) as any
          }
          onClick={() => history.push(routes.dashboard.merchants.main)}
        />
        {merchants?.map((m) => (
          <DropdownMenuItem
            onClick={() => history.push(routes.dashboard.merchants.main + '/' + m.id)}
            key={m.id}
            title={m.name}
          />
        ))}
      </Component>
    </Dropdown>
  )
}
