import { createReducer, isRejectedWithValue } from '@reduxjs/toolkit'
import { CryptoInvoiceStatus } from '@ppay/definitions'

import { CryptoInvoice } from '../../../../interfaces'
import {
  expireInvoiceCryptoAction,
  getInvoiceCryptoAction,
  updateInvoiceCryptoAction
} from '../../../actions'

type State = {
  loading: boolean
  data?: CryptoInvoice
  notExist?: boolean
}

const initialState: State = {
  loading: false,
  notExist: false
}

export const get = createReducer(initialState, (builder) => {
  builder
    // get invoice
    .addCase(getInvoiceCryptoAction.pending, (state) => {
      state.loading = true
    })
    .addCase(getInvoiceCryptoAction.rejected, (state, action) => {
      state.loading = false
      if (isRejectedWithValue(action)) {
        state.notExist = true
      }
    })
    .addCase(getInvoiceCryptoAction.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    // update invoice
    .addCase(updateInvoiceCryptoAction.fulfilled, (state, action) => {
      if (action.meta.arg.amount) {
        state.data!.amount = action.meta.arg.amount
      }
    })
    // expire invoice
    .addCase(expireInvoiceCryptoAction, (state) => {
      state.data!.status = CryptoInvoiceStatus.EXPIRED
    })
})
