"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFiatPage = void 0;
var _react = require("react");
var _definitions = require("@ppay/definitions");
var _reactRouterDom = require("react-router-dom");
var _state = require("../../state");
var _components = require("../../components");
var _useCalculateAmount2 = require("./useCalculateAmount");
var _useFiatForm2 = require("./useFiatForm");
var _usePaySoft2 = require("./usePaySoft");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var useFiatPage = function useFiatPage(_ref) {
  var _location$state, _price$value;
  var mode = _ref.mode;
  var location = (0, _reactRouterDom.useLocation)();
  var locationTicker = (_location$state = location.state) === null || _location$state === void 0 ? void 0 : _location$state.ticker;
  var _useState = (0, _react.useState)(locationTicker !== null && locationTicker !== void 0 ? locationTicker : _definitions.Ticker.UAH),
    _useState2 = _slicedToArray(_useState, 2),
    ticker = _useState2[0],
    setTicker = _useState2[1];
  var _useUser = (0, _state.useUser)(),
    user = _useUser.user;
  var _useFiatReplenishFee = (0, _state.useFiatReplenishFee)(ticker),
    fiatReplenishFee = _useFiatReplenishFee.fiatReplenishFee,
    fetchReplenishFee = _useFiatReplenishFee.fetchReplenishFee;
  var _useUserRequirements = (0, _state.useUserRequirements)(),
    requirements = _useUserRequirements.requirements,
    requirementsLoading = _useUserRequirements.loading,
    fetchRequirements = _useUserRequirements.fetchRequirements;
  var price = (0, _state.usePrice)({
    ticker: ticker
  });
  var _useFiatForm = (0, _useFiatForm2.useFiatForm)({
      fee: fiatReplenishFee !== null && fiatReplenishFee !== void 0 ? fiatReplenishFee : '0'
    }),
    amount = _useFiatForm.amount,
    formProps = _useFiatForm.formProps;
  var _useCalculateAmount = (0, _useCalculateAmount2.useCalculateAmount)({
      amount: amount,
      price: (_price$value = price === null || price === void 0 ? void 0 : price.value) !== null && _price$value !== void 0 ? _price$value : '0',
      percent: fiatReplenishFee !== null && fiatReplenishFee !== void 0 ? fiatReplenishFee : '0'
    }),
    value = _useCalculateAmount.value,
    convertedValue = _useCalculateAmount.convertedValue,
    feeValue = _useCalculateAmount.feeValue,
    convertedFeeValue = _useCalculateAmount.convertedFeeValue,
    totalValue = _useCalculateAmount.totalValue,
    convertedTotalValue = _useCalculateAmount.convertedTotalValue;
  var _usePaySoft = (0, _usePaySoft2.usePaySoft)({
      amount: totalValue
    }),
    loadingWidget = _usePaySoft.loadingWidget,
    loading = _usePaySoft.loading,
    errorMessage = _usePaySoft.errorMessage,
    status = _usePaySoft.status,
    handleSubmit = _usePaySoft.handleSubmit,
    clearStatus = _usePaySoft.clearStatus;
  (0, _react.useEffect)(function () {
    fetchRequirements();
    if (!fiatReplenishFee && mode === _components.DashboardTransfersReplenishMode.FIAT) {
      fetchReplenishFee();
    }
  }, [ticker, fiatReplenishFee, mode]);
  var toggleModal = function toggleModal() {
    clearStatus();
    formProps.reset({
      amount: ''
    }, {
      keepTouched: false
    });
  };
  var tickerRequirements = requirements === null || requirements === void 0 ? void 0 : requirements.values.filter(function (value) {
    return value.ticker === ticker;
  });
  var provider = user.fiat.replenishGatewayProviders[ticker];
  var isPaymentProviderSupported = !(tickerRequirements !== null && tickerRequirements !== void 0 && tickerRequirements.length);
  return {
    ticker: ticker,
    tickers: _definitions.FIAT_BALANCE_TICKERS,
    value: value,
    convertedValue: convertedValue,
    feeValue: feeValue,
    convertedFeeValue: convertedFeeValue,
    totalValue: totalValue,
    convertedTotalValue: convertedTotalValue,
    loadingWidget: loadingWidget || price.loading || !fiatReplenishFee || requirementsLoading,
    loading: loading,
    errorMessage: errorMessage,
    status: status,
    formProps: formProps,
    disabled: !isPaymentProviderSupported || !formProps.formState.isValid || !amount,
    fee: fiatReplenishFee !== null && fiatReplenishFee !== void 0 ? fiatReplenishFee : '0',
    convertedTicker: _definitions.Ticker.USD,
    setTicker: setTicker,
    handleSubmit: handleSubmit,
    toggleModal: toggleModal,
    isPaymentProviderSupported: isPaymentProviderSupported,
    replenishGatewayProvider: provider
  };
};
exports.useFiatPage = useFiatPage;