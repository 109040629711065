"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAvailablePairs = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  loading: false
};
var getAvailablePairs = (0, _toolkit.createReducer)(initialState, function (builder) {
  builder.addCase(_actions.getExchangeAvailablePairsAction.pending, function (state) {
    state.loading = true;
  }).addCase(_actions.getExchangeAvailablePairsAction.rejected, function (state) {
    state.loading = false;
  }).addCase(_actions.getExchangeAvailablePairsAction.fulfilled, function (state, action) {
    state.loading = false;
    state.data = action.payload;
  });
});
exports.getAvailablePairs = getAvailablePairs;