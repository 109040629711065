import { Col, Row, Card, CardHeader, CardBody, CardTitle, Spinner } from '@ppay/client'
import { useTranslation } from 'react-i18next'

import { useMerchantCreateLimit, useMerchantsPage } from '../../../hooks'
import { Body } from './Body'
import { Breadcrumb, Breadcrumbs } from '../../layout'
import { routes } from '../../router'

export const Page = () => {
  const {
    merchants,
    email2faEnabled,
    totpEnabled,
    isMobile,
    filterMerchants,
    onCreateMerchantClick
  } = useMerchantsPage()
  const { title, merchantsLimit } = useMerchantCreateLimit()
  const { t } = useTranslation('routes')

  return (
    <>
      <Row>
        <Breadcrumbs>
          <Breadcrumb link={routes.dashboard.home} name={t('home')} />
          <Breadcrumb name={t('merchants')} />
        </Breadcrumbs>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>{title}</CardTitle>
            </CardHeader>
            <CardBody className="d-flex flex-column justify-content-center">
              {!merchants ? (
                <Spinner color="primary" />
              ) : (
                <Body
                  merchants={merchants!}
                  merchantsLimit={merchantsLimit}
                  enabled2fa={email2faEnabled || totpEnabled}
                  isMobile={isMobile}
                  filterMerchants={filterMerchants}
                  onCreateMerchantClick={onCreateMerchantClick}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
