"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUpdatePersonal = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var _useUser2 = require("./useUser");
var useUpdatePersonal = function useUpdatePersonal() {
  var dispatch = (0, _reactRedux.useDispatch)();
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.user.updatePersonal;
  });
  var _useUser = (0, _useUser2.useUser)(),
    user = _useUser.user;
  return {
    personal: user === null || user === void 0 ? void 0 : user.personal,
    updatePersonalLoading: state.loading,
    updatePersonal: function updatePersonal(personal) {
      dispatch((0, _actions.updatePersonalAction)(personal));
    }
  };
};
exports.useUpdatePersonal = useUpdatePersonal;