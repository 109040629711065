"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withdraw2fa = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _enable = require("./enable");
var _disable = require("./disable");
var _send = require("./send");
var withdraw2fa = (0, _toolkit.combineReducers)({
  enable: _enable.enable,
  disable: _disable.disable,
  send: _send.send
});
exports.withdraw2fa = withdraw2fa;