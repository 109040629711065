"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FailModal", {
  enumerable: true,
  get: function get() {
    return _Fail.Fail;
  }
});
Object.defineProperty(exports, "SuccessModal", {
  enumerable: true,
  get: function get() {
    return _Success.Success;
  }
});
var _Success = require("./Success");
var _Fail = require("./Fail");