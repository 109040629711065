import { DashboardTransactionsCard, TransactionsPerPage, AllTransactionsTable } from '@ppay/client'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMerchantTransactions } from '../../../../state'
import { MerchantTransactionsNoData } from '../../../transactions'

export const Transactions = ({
  transactionsPath,
  merchantId
}: {
  transactionsPath: string
  merchantId: string
}) => {
  const { transactionsState, hasMore, fetchTransactions, fetchMoreTransactions } =
    useMerchantTransactions(merchantId)
  const { t } = useTranslation('transactionsTable')

  useEffect(() => {
    fetchTransactions({ perPage: TransactionsPerPage.RECENT })
  }, [])

  const loadMore = () => {
    fetchMoreTransactions({ perPage: TransactionsPerPage.RECENT })
  }

  const history = useHistory()

  return (
    <DashboardTransactionsCard
      onViewAll={() => {
        history.push(transactionsPath)
      }}
      onViewFull={(id: string) => {
        history.push(transactionsPath, { search: id })
      }}
      transactions={transactionsState.data}
      isLoading={transactionsState.loading}
      isCSVLoading={transactionsState.csvLoading}
      loadMore={loadMore}
      hasMore={hasMore}
      title={t('titles.transaction')}
      subtitle={t('subtitle')}
      noData={<MerchantTransactionsNoData />}
      tableComponent={AllTransactionsTable}
    />
  )
}
