import { createWithdrawReducer, createGetExchangeInfoReducer } from '@ppay/client'
import { combineReducers } from '@reduxjs/toolkit'

import {
  createMerchantCryptoExchangeAction,
  createMerchantFiatExchangeAction,
  getMerchantCryptoExchangeInfoAction,
  getMerchantFiatExchangeInfoAction,
  resetMerchantGetExchangeCryptoInfoAction,
  resetMerchantGetExchangeFiatInfoAction
} from '../../actions'

export const exchange = combineReducers({
  createCrypto: createWithdrawReducer(createMerchantCryptoExchangeAction),
  createFiat: createWithdrawReducer(createMerchantFiatExchangeAction),
  getCryptoInfo: createGetExchangeInfoReducer({
    action: getMerchantCryptoExchangeInfoAction,
    resetAction: resetMerchantGetExchangeCryptoInfoAction
  }),
  getFiatInfo: createGetExchangeInfoReducer({
    action: getMerchantFiatExchangeInfoAction,
    resetAction: resetMerchantGetExchangeFiatInfoAction
  })
})
