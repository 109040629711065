import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Label,
  FormGroup,
  InputField,
  Row,
  Col,
  DashboardSettingsProfileSaveChangesButton as SubmitButton
} from '@ppay/client'
import { Form } from 'reactstrap'

import { nameValidation } from '../../../../validations'
import { useUpdatePersonal } from '../../../../state'

const schema = yup
  .object()
  .shape({
    name: nameValidation().required(),
    surname: nameValidation().required()
  })
  .required()

export const UpdatePersonalForm = () => {
  const { t } = useTranslation(['libCommon', 'libAuth', 'auth', 'common'])
  const { updatePersonal, updatePersonalLoading, personal } = useUpdatePersonal()
  const { handleSubmit, control } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: personal?.name,
      surname: personal?.surname
    }
  })

  return (
    <Form onSubmit={handleSubmit(updatePersonal)} noValidate>
      <Row>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label>{t('auth:name')}</Label>
            <InputField
              control={control}
              inputProps={{
                name: 'name',
                placeholder: t('auth:name')
              }}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Label>{t('auth:lastName')}</Label>
            <InputField
              control={control}
              inputProps={{
                name: 'surname',
                placeholder: t('auth:lastName')
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={6}>
          <SubmitButton loading={updatePersonalLoading} wide />
        </Col>
      </Row>
    </Form>
  )
}
