"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ProvidePersonalForm", {
  enumerable: true,
  get: function get() {
    return _Form.Form;
  }
});
Object.defineProperty(exports, "ProvidePersonalPage", {
  enumerable: true,
  get: function get() {
    return _Page.Page;
  }
});
Object.defineProperty(exports, "useProvidePersonalForm", {
  enumerable: true,
  get: function get() {
    return _hooks.useForm;
  }
});
var _Page = require("./Page");
var _Form = require("./Form");
var _hooks = require("./hooks");