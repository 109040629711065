import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useTranslation, Trans } from 'react-i18next'
import {
  LayoutFooter as LayoutFooterUI,
  LayoutFooterLogo,
  LayoutFooterSeparator as FooterSeparator,
  LayoutFooterMenuItem as MenuItem,
  LayoutFooterLanguageSelect,
  LayoutFooterTitle as Title,
  LayoutFooterSocials,
  mediaQuery,
  Small,
  P,
  breakpoints,
  Badge,
  Col as ColUI,
  Row as RowUI,
  LayoutFooterDropdownCollapse,
  DropdownToggle,
  Link
} from '@ppay/client'

type Links = {
  title: string | React.ReactNode
  link?: string
  id: string
}

type MenuItems = {
  title: string
  links: Links[]
}

const LayoutFooter = styled(LayoutFooterUI)`
  background-color: ${({ theme }) => theme.colors.tertiary};
`

const AcceptPaymentsEasy = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  flex: none;
  ${mediaQuery.lessThan('md')`
    br {
     display: none;
    }
  `}
`

const Logo = styled(LayoutFooterLogo)`
  ${mediaQuery.lessThan('md')`
    margin-bottom: 13px;
  `}
  ${mediaQuery.greaterThan('xl')`
    margin-bottom: 13px;
  `}
`

const Col = styled(ColUI)`
  &:first-child {
    ${mediaQuery.lessThan('xxl')`
      margin-bottom: 30px;
  `}
  }
  ${mediaQuery.lessThan('md')`
    margin-bottom: 20px;
    &:first-child {
      margin-bottom: 30px;
    }
    &:last-child {
      margin-bottom: 0;
      margin-top: 10px;
    }
  `}
`

const Row = styled(RowUI)`
  width: -webkit-fill-available;
  width: -moz-available;
`

const Soon = styled(Badge)`
  margin-left: 10px;
`

const LanguageSelect = styled(LayoutFooterLanguageSelect)`
  ${mediaQuery.greaterThan('lg')`
    margin-bottom: 30px;
`}
  ${mediaQuery.lessThan('md')`
    margin-top: 10px;
`}
`

export const Footer = ({ logo, onLogoClick }: { logo: string; onLogoClick: () => unknown }) => {
  const {
    t,
    i18n: { language }
  } = useTranslation('layout')
  const isSm = useMediaQuery({ maxWidth: breakpoints.sm })
  const isXl = useMediaQuery({ minWidth: breakpoints.xl })
  const locale = language === 'ua' ? 'en' : language

  const menuItems: MenuItems[] = [
    {
      title: t('footer.links.products.title'),
      links: [
        {
          title: t('footer.links.products.links.apiDocs'),
          link: process.env.REACT_APP_DOCS,
          id: 'myfooter-api'
        },
        {
          title: (
            <>
              {t('footer.links.products.links.nonСustodialSDK')}
              <Soon>{t('footer.soon')}</Soon>
            </>
          ),
          id: 'myfooter-sdk'
        },
        {
          title: (
            <>
              {t('footer.links.products.links.walletSdk')}
              <Soon>{t('footer.soon')}</Soon>
            </>
          ),
          id: 'myfooter-wallet'
        },
        {
          title: (
            <>
              {t('footer.links.products.links.saasSolution')}
              <Soon>{t('footer.soon')}</Soon>
            </>
          ),
          id: 'myfooter-saas'
        }
      ]
    },
    {
      title: t('footer.links.info.title'),
      links: [
        {
          title: t('footer.links.info.links.about'),
          link: `${process.env.REACT_APP_SITE_URL}/${language}/about`,
          id: 'myfooter-about'
        },
        {
          title: t('footer.links.info.links.support'),
          link: process.env.REACT_APP_CHAT,
          id: 'myfooter-support'
        },
        {
          title: t('footer.links.info.links.presentation'),
          link: `https://0xpay.app/0xpay_pitch_${locale}.pdf`,
          id: 'myfooter-present'
        },
        {
          title: `${t('footer.links.info.links.becomePartner')} 🔥`,
          link: 'https://docs.google.com/forms/d/e/1FAIpQLSceA_Na_3gPyEcejDwmlZONt1auQrQpwYan9sAB2aacl8OTqA/viewform',
          id: 'myfooter-partner'
        },
        {
          title: t('footer.links.info.links.termsOfService'),
          link: 'https://docs.0xpay.app/legal/terms-of-service',
          id: 'footer-terms'
        },
        {
          title: t('footer.links.info.links.privacyPolicy'),
          link: 'https://docs.0xpay.app/legal/privacy-policy',
          id: 'footer-privacy'
        }
      ]
    }
  ]

  const socials = (
    <LayoutFooterSocials
      links={[
        ['Medium', 'https://medium.com/0xpay'],
        ['Reddit', 'https://www.reddit.com/user/0xpay'],
        ['Facebook', 'https://www.facebook.com/0xpay-110467198333073/?ref=page_internal'],
        ['Instagram', 'https://www.instagram.com/0xpayofficial/'],
        ['Twitter', 'https://twitter.com/0xpayofficial'],
        ['Telegram', 'https://t.me/official_0xpay']
      ]}
    />
  )

  return (
    <LayoutFooter>
      <Row className="justify-content-between">
        <Col
          className="d-flex justify-content-center justify-content-md-start order-0 flex-column align-items-center align-items-md-start"
          xs={12}
          md={6}
          xl="auto"
        >
          <Logo src={logo} onClick={onLogoClick} />
          <AcceptPaymentsEasy as={isSm ? Small : P}>
            <Trans i18nKey="layout:footer.acceptPaymentsEasy">
              <br />
            </Trans>
          </AcceptPaymentsEasy>
        </Col>
        {menuItems.map((col, i) => (
          <Col
            key={i}
            xs={12}
            md="auto"
            className={`d-flex flex-column align-items-center align-items-xl-start order-${
              i + 1
            } order-md-${i + 2} order-xl-${i + 1}`}
          >
            {isSm ? (
              <MobileMenuDropdown id={'menuLinks' + i} title={col.title} links={col.links} />
            ) : (
              <>
                <Title className="mb-3">{col.title}</Title>
                {col.links.map((l, i) => (
                  <MenuItem id={l.id} key={i} link={l.link} target="_blank">
                    {l.title}
                  </MenuItem>
                ))}
              </>
            )}
          </Col>
        ))}
        <Col
          className={`d-flex justify-content-center justify-content-md-end order-${
            menuItems.length + 1
          } order-md-1 order-xl-${menuItems.length + 1}`}
          xs={12}
          md={6}
          xl="auto"
        >
          <div>
            <LanguageSelect />
            {isXl && socials}
          </div>
        </Col>
        {!isXl && (
          <Col
            xs={12}
            md="auto"
            className={`d-flex justify-content-center justify-content-md-start order-${
              menuItems.length + 1
            }`}
          >
            {socials}
          </Col>
        )}
      </Row>
      <FooterSeparator />
      <Small className="text-center">
        <Trans i18nKey="layout:footer.text">
          <Link
            href="https://www.registrucentras.lt/jar/p_en/dok.php?kod=306111804"
            target="_blank"
            color="tertiaryShade"
            className="text-decoration-underline"
          />
        </Trans>
      </Small>
    </LayoutFooter>
  )
}

const MobileMenuDropdown = ({
  id,
  title,
  links
}: {
  id: string
  title: string
  links: Links[]
}) => (
  <>
    <DropdownToggle id={id} onClick={function noRefCheck() {}}>
      <Title className="mb-0">{title}</Title>
    </DropdownToggle>
    <LayoutFooterDropdownCollapse toggler={id}>
      {links.map((l, i) => (
        <MenuItem id={l.id} key={i} link={l.link} target="_blank">
          {l.title}
        </MenuItem>
      ))}
    </LayoutFooterDropdownCollapse>
  </>
)
