import React from 'react'
import { Spinner, useUserRequirementsWithPolling } from '@ppay/client'
import { Requirements, RequirementsStatus, Ticker } from '@ppay/definitions'

import { FiatInvoice } from '../../../interfaces'

import { Component } from './Component'
import { CardKYC } from '../card'

export const KYCContainer = ({
  invoice,
  setTicker,
  ticker,
  email,
  logout
}: {
  invoice: FiatInvoice
  ticker: Ticker
  setTicker: (ticker: Ticker) => void
  logout: () => void
  email: string
}) => {
  const { requirements } = useUserRequirementsWithPolling()

  if (!requirements)
    return (
      <div className="d-flex justify-content-center mt-auto">
        <Spinner color="primary" />
      </div>
    )

  const userKYCStatus = requirements.processed.find(
    (requirement) => requirement.value === Requirements.KYC
  )?.status

  const hideButton = userKYCStatus !== RequirementsStatus.COMPLETED

  return (
    <Component
      invoice={invoice}
      setTicker={setTicker}
      ticker={ticker}
      hideButton={hideButton}
      email={email}
      back={logout}
    >
      <CardKYC invoiceRequirements={invoice.requirements} ticker={ticker} status={userKYCStatus} />
    </Component>
  )
}
