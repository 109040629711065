import { theme as themeLib } from '@ppay/client'
import merge from 'lodash.merge'

const colors = {
  blue: '#e5efff'
}

export const theme = merge(themeLib, { colors })

export type Theme = typeof theme
