"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createGetInfoReducer = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  loading: false
};
var createGetInfoReducer = function createGetInfoReducer(params) {
  var _params$action, _params$resetAction;
  var action = (_params$action = params === null || params === void 0 ? void 0 : params.action) !== null && _params$action !== void 0 ? _params$action : _actions.getExchangeCryptoInfoAction;
  var resetAction = (_params$resetAction = params === null || params === void 0 ? void 0 : params.resetAction) !== null && _params$resetAction !== void 0 ? _params$resetAction : _actions.resetGetExchangeCryptoInfoAction;
  return (0, _toolkit.createReducer)(initialState, function (builder) {
    builder.addCase(action.pending, function (state) {
      state.loading = true;
      state.maxLiquidity = undefined;
    }).addCase(action.rejected, function (state, action) {
      var _action$payload;
      state.loading = false;
      state.maxLiquidity = (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.error.data;
    }).addCase(action.fulfilled, function (state, action) {
      state.loading = false;
      state.data = action.payload;
    }).addCase(resetAction, function () {
      return initialState;
    });
  });
};
exports.createGetInfoReducer = createGetInfoReducer;