"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCalculateAmount = void 0;
var _react = require("react");
var _decimal = _interopRequireDefault(require("decimal.js"));
var _definitions = require("@ppay/definitions");
var _constants = require("../../constants");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var useCalculateAmount = function useCalculateAmount(_ref) {
  var _ref$amount = _ref.amount,
    amount = _ref$amount === void 0 ? '0' : _ref$amount,
    price = _ref.price,
    percent = _ref.percent;
  var value = new _decimal.default(amount || 0);
  var fee = value.mul(percent);
  return (0, _react.useMemo)(function () {
    return {
      value: value.toString(),
      convertedValue: value.mul(price).toString(),
      feeValue: fee.toString(),
      convertedFeeValue: fee.mul(price).toString(),
      totalValue: value.add(fee).toFixed(_constants.TickerDecimals[_definitions.Ticker.USD]),
      convertedTotalValue: value.mul(price).add(fee.mul(price)).toFixed(_constants.TickerDecimals[_definitions.Ticker.USD])
    };
  }, [amount, price, percent]);
};
exports.useCalculateAmount = useCalculateAmount;