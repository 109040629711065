import { useSelector } from '../useSelector'
import { useDispatch } from '../useDispatch'
import { getMerchantsAction, resetMerchantsAction } from '../../actions'

export const useMerchants = () => {
  const state = useSelector((state) => state.merchants.get)
  const dispatch = useDispatch()

  return {
    loading: state.loading,
    merchants: state.data,
    fetchMerchants: () => dispatch(getMerchantsAction()),
    resetMerchants: () => dispatch(resetMerchantsAction())
  }
}
