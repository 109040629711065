"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Input = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactstrap = require("reactstrap");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var defaultBackground = function defaultBackground(props) {
  return props.theme.colors.secondaryShade;
};
var Input = (0, _styledComponents.default)(_reactstrap.Input).attrs(function (_ref) {
  var autoComplete = _ref.autoComplete;
  return {
    autoComplete: autoComplete !== null && autoComplete !== void 0 ? autoComplete : 'off'
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &:not([type='checkbox']):not([type='radio']) {\n    margin-left: 0 !important;\n    z-index: unset !important;\n    padding: ", " !important;\n    height: 40px;\n    border-radius: 4px !important;\n    background-color: ", ";\n    caret-color: ", ";\n    color: ", ";\n    background-image: none;\n    border-color: ", ";\n    text-overflow: ellipsis;\n    overflow: hidden;\n    font-size: 16px;\n    font-weight: 400;\n    &::placeholder {\n      color: ", ";\n    }\n    &:not(:disabled):not(.disabled):not(:focus):hover {\n      border-color: ", ";\n    }\n    &:disabled {\n      user-select: none;\n      background-color: ", ";\n      border-color: ", ";\n      &::placeholder {\n        color: ", ";\n      }\n    }\n    &:focus {\n      border-color: ", ";\n      color: ", ";\n      background-color: ", ";\n      box-shadow: none;\n    }\n  }\n  &[type='checkbox'],\n  &[type='radio'] {\n    visibility: hidden;\n  }\n\n  /* Chrome, Safari, Edge, Opera */\n  &[type='number']::-webkit-outer-spin-button,\n  &[type='number']::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  /* Firefox */\n  &[type='number'] {\n    -moz-appearance: textfield;\n  }\n"])), function (props) {
  return props.type === 'textarea' ? '16px' : '8px 16px';
}, defaultBackground, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.primary;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.tertiary;
}, function (_ref4) {
  var theme = _ref4.theme,
    invalid = _ref4.invalid;
  return invalid ? theme.colors.danger : theme.colors.secondaryShade2;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.tertiaryShade;
}, function (_ref6) {
  var theme = _ref6.theme,
    invalid = _ref6.invalid;
  return invalid ? theme.colors.danger : theme.colors.tertiaryShade;
}, defaultBackground, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.secondaryShade2;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.tertiaryShade;
}, function (_ref9) {
  var theme = _ref9.theme,
    invalid = _ref9.invalid;
  return invalid ? theme.colors.danger : theme.colors.primary;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.colors.tertiary;
}, defaultBackground);
exports.Input = Input;