"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.email = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../actions");
var initialState = {
  confirm: {
    loading: false
  },
  sendConfirm: {
    loading: false
  }
};
var email = (0, _toolkit.createReducer)(initialState, function (builder) {
  builder.addCase(_actions.confirmEmailAction.pending, function (state) {
    state.confirm = {
      loading: true
    };
  });
  builder.addCase(_actions.confirmEmailAction.rejected, function (state) {
    state.confirm.loading = false;
    state.confirm.done = true;
  });
  builder.addCase(_actions.confirmEmailAction.fulfilled, function (state) {
    state.confirm.loading = false;
    state.confirm.done = true;
  });
  builder.addCase(_actions.enableEmail2faAction.fulfilled, function (state) {
    state.confirm.done = undefined;
  });
  builder.addCase(_actions.disableEmail2faAction.fulfilled, function (state) {
    state.confirm.done = undefined;
  });
  // send confirm
  builder.addCase(_actions.sendConfirmEmailAction.pending, function (state) {
    state.sendConfirm = {
      loading: true
    };
  });
  builder.addCase(_actions.sendConfirmEmailAction.rejected, function (state, action) {
    var _action$payload;
    state.sendConfirm.loading = false;
    state.sendConfirm.remainingTime = (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.data.time;
    state.sendConfirm.done = true;
  });
  builder.addCase(_actions.sendConfirmEmailAction.fulfilled, function (state, action) {
    state.sendConfirm.loading = false;
    state.sendConfirm.done = true;
    state.sendConfirm.remainingTime = action.payload.time;
  });
});
exports.email = email;