import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Decimal from 'decimal.js'
import { H3, TickerSymbol, TickerNumberFormat } from '@ppay/client'
import { Ticker } from '@ppay/definitions'

import { Icon } from '../../ui'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.lightWarning};
  ${H3} {
    margin-bottom: 0;
    margin-left: 10px;
  }
  ${Icon} {
    margin-right: 12px;
  }
`

export const PaidAmount = ({
  amount,
  paidAmount,
  ticker
}: {
  amount: number | string
  paidAmount: number | string
  ticker: Ticker
}) => {
  const { t } = useTranslation('invoice')

  const amountDue = new Decimal(paidAmount).sub(amount)

  if (amountDue.isZero()) return null

  return (
    <Wrapper>
      <Icon name="warning" /> {t(amountDue.isPositive() ? 'overpaid' : 'underpaid')}:
      <H3>
        {amountDue.isPositive() && '+'}
        <TickerNumberFormat value={amountDue.toString()} ticker={ticker} />{' '}
        <TickerSymbol ticker={ticker} />
      </H3>
    </Wrapper>
  )
}
