import { Card as CardUI, H6, Small, Button as ButtonUI, mediaQuery } from '@ppay/client'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Icon } from '../../../../ui'

const Card = styled(CardUI)`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.secondaryShade};
  ${mediaQuery.lessThan('xl')`
    margin-top: 20px
  `}
`
const Button = styled(ButtonUI)`
  ${mediaQuery.lessThan('md')`
    max-width: 100%;
  `}
`

export const ConnectionCard = () => {
  const { t } = useTranslation('merchant')

  return (
    <Card>
      <H6>{t('connectionInformation.title')}</H6>
      <Small className="mb-3">{t('connectionInformation.description')}</Small>
      <Button
        color="secondary"
        icon={<Icon name="folder" />}
        onClick={() => window.open(process.env.REACT_APP_DOCS, '_blank')}
      >
        {t('connectionInformation.apiDocs')}
      </Button>
    </Card>
  )
}
