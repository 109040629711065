import { NotFoundPage, AuthLayoutHeader } from '@ppay/client'
import { useHistory } from 'react-router-dom'

import { Footer, LayoutImages } from '../layout'
import { routes } from '../router'

export const NotFound = () => {
  const history = useHistory()
  const onClick = () => history.push(routes.dashboard.home)

  return (
    <NotFoundPage
      footerComponent={() => <Footer logo={LayoutImages.logo} onLogoClick={onClick} />}
      headerComponent={() => (
        <AuthLayoutHeader
          logo={LayoutImages.logo}
          aboutPath={process.env.REACT_APP_SITE_URL + '/about'}
          onLogoClick={onClick}
        />
      )}
      onBtnClick={onClick}
    />
  )
}
