import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Ticker } from '@ppay/definitions'
import {
  Link,
  Button as FormButton,
  Small,
  P,
  InputGroupAbsolute,
  CopyInputGroupAddon,
  CopyInput,
  FormGroup,
  Label
} from '@ppay/client'

import { ManualReplenishOrder } from '../../../../../interfaces'
import { Icon } from '../../../../ui'

const Note = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.blue};
  display: flex;
  align-items: center;
`
const NoteIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`

const Description = styled(P)`
  margin-top: 20px;
`
const NumberList = styled.ol`
  margin: 0px;
  padding-left: 25px;
`

export const CreatedOrder = ({
  order,
  amount,
  ticker,
  loading,
  queue
}: {
  order: ManualReplenishOrder
  amount: string
  ticker: Ticker
  loading: boolean
  queue: () => void
}) => {
  const { t } = useTranslation('manualReplenishOrder')

  return (
    <>
      <FormGroup>
        <Label>{t('cardNumber')}:</Label>
        <InputGroupAbsolute>
          <CopyInputGroupAddon value={order.card.number} addonType="append" />
          <CopyInput value={order.card.number} />
        </InputGroupAbsolute>
      </FormGroup>
      {order.card.name && (
        <FormGroup>
          <Label>{t('name')}:</Label>
          <InputGroupAbsolute>
            <CopyInputGroupAddon value={order.card.name} addonType="append" />
            <CopyInput value={order.card.name} />
          </InputGroupAbsolute>
        </FormGroup>
      )}
      <Note>
        <NoteIconWrapper>
          <Icon name="info" size={20} />
        </NoteIconWrapper>
        <Small>
          {t('p2pNote')}
          <NumberList>
            <li>
              <Trans
                i18nKey="note1"
                t={t}
                values={{
                  amount,
                  ticker,
                  cardNumber: order.card.number
                }}
              >
                <b />
                <b />
                <b />
              </Trans>
            </li>
            <li>
              <Trans i18nKey="note2" t={t}>
                <b />
              </Trans>
            </li>
            <li>{t('note3')}</li>
          </NumberList>
        </Small>
      </Note>
      <FormButton
        loading={loading}
        disabled={loading}
        onClick={() => queue()}
        wide
        icon={<Icon name="arrowRight" />}
      >
        {t('paid')}
      </FormButton>
      <Description>
        <Trans i18nKey="liveSupport" t={t}>
          <Link
            target="_blank"
            onClick={() => {
              ;(window as any).fcWidget.open()
            }}
          />
        </Trans>
      </Description>
    </>
  )
}
