import { useCallback } from 'react'
import { usePolling as usePollingLib } from '@ppay/client'

import { useDispatch } from '../useDispatch'
import { useSelector } from '../useSelector'
import { getManualReplenishOrderAction } from '../../actions'
import { ManualReplenishOrderStatus } from '@ppay/definitions'

export const useManualReplenishOrderWithPolling = (id: string) => {
  const state = useSelector((state) => state.manualReplenishOrder)
  const dispatch = useDispatch()
  const shouldPoll =
    state.data!.status !== ManualReplenishOrderStatus.COMPLETED &&
    state.data!.status !== ManualReplenishOrderStatus.FAILED

  usePollingLib(
    useCallback(() => dispatch(getManualReplenishOrderAction(id)), []),
    shouldPoll,
    15000
  )
}
