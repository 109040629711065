"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Button = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _theme = require("../../../theme");
var _BaseButton = require("./BaseButton");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var colors = _theme.theme.colors;
var settings = {
  primary: {
    default: {
      color: colors.secondary,
      background: colors.primary,
      border: colors.primary
    },
    hover: {
      color: colors.secondary,
      background: '#1d25ba',
      border: 'transparent',
      shadow: "0 4px 5px ".concat(colors.tertiary, "1a")
    },
    active: {
      color: colors.secondary,
      background: colors.primary,
      border: '#1d25ba'
    },
    loading: {
      color: '#a89ee9',
      background: '#5058ed'
    }
  },
  secondary: {
    default: {
      color: colors.primary,
      background: colors.lightPrimary,
      border: colors.lightPrimary
    },
    hover: {
      color: colors.primary,
      background: '#cccdeb',
      border: 'transparent',
      shadow: "0 4px 5px ".concat(colors.tertiary, "1a")
    },
    active: {
      color: colors.primary,
      background: colors.lightPrimary,
      border: '#8c82cb'
    },
    loading: {
      color: '#8478cf',
      background: '#d6d7f5'
    }
  }
};
var Button = (0, _styledComponents.default)(_BaseButton.BaseButton)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), function (_ref) {
  var _ref$color = _ref.color,
    color = _ref$color === void 0 ? 'primary' : _ref$color,
    loading = _ref.loading;
  var buttonColors = settings[color];
  return "\n      color: ".concat(loading ? buttonColors.loading.color : buttonColors.default.color, ";\n      background-color: ").concat(loading ? buttonColors.loading.background : buttonColors.default.background, ";\n      border-color: ").concat(buttonColors.default.border, ";\n    \n      &:not(:disabled):not(.disabled):hover {\n        color: ").concat(loading ? buttonColors.loading.color : buttonColors.hover.color, ";\n        background-color: ").concat(loading ? buttonColors.loading.background : buttonColors.hover.background, ";\n        border-color: ").concat(buttonColors.default.border, ";\n        box-shadow: ").concat(buttonColors.hover.shadow, ";\n      }\n    \n      &:focus,\n      &:not(:disabled):not(.disabled):active {\n        color: ").concat(loading ? buttonColors.loading.color : buttonColors.active.color, ";\n        background-color: ").concat(loading ? buttonColors.loading.background : buttonColors.active.background, ";\n        border-color: ").concat(buttonColors.active.border, ";\n      }\n    \n      & svg path {\n        fill: ").concat(loading ? buttonColors.loading.color : buttonColors.default.color, "\n    \n    ");
});
exports.Button = Button;