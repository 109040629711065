import { CreateWithdrawContext, useCreateFiatWithdraw } from '@ppay/client'
import React from 'react'

import { useCreateMerchantFiatWithdraw, useMerchantFiatWithdrawFee } from '../../../state'

export const MerchantCreateFiatWithdrawProvider = ({
  children,
  merchantId
}: React.PropsWithChildren<{ merchantId: string }>) => {
  return (
    <CreateWithdrawContext.Provider
      value={{
        useCreateWithdraw: (() =>
          useCreateMerchantFiatWithdraw(merchantId)) as typeof useCreateFiatWithdraw,
        useWithdrawFee: () => useMerchantFiatWithdrawFee(merchantId)
      }}
    >
      {children}
    </CreateWithdrawContext.Provider>
  )
}
