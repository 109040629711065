"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _actions = require("../../../actions");
var create = (0, _toolkit.createReducer)({}, function (builder) {
  builder.addCase(_actions.createWalletAddressAction.pending, function (state, action) {
    state.loadingByBlockchain = action.meta.arg;
  });
  builder.addCase(_actions.createWalletAddressAction.rejected, function (state) {
    state.loadingByBlockchain = undefined;
  });
  builder.addCase(_actions.createWalletAddressAction.fulfilled, function (state) {
    state.loadingByBlockchain = undefined;
  });
});
exports.create = create;