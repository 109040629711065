import {
  Row,
  Col,
  mediaQuery,
  Button as ButtonLib,
  breakpoints,
  Link,
  SmallMedium
} from '@ppay/client'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { useMerchant } from '../../../state'
import { routes } from '../../router'
import { Breadcrumb, Breadcrumbs } from '../../layout'
import { Transactions } from './Transactions'
import { PortfolioOverviewCard } from './PortfolioOverviewCard'
import { InputOutputFlowCard } from './InputOutputFlowCard'
import { WithMerchantExists } from './WithMerchantExists'
import { AlertWithButton } from '../AlertWithButton'

const Alert = styled(AlertWithButton)`
  margin-bottom: 30px;
  ${mediaQuery.lessThan('md')`
    margin-bottom: 20px;
  `}
`

const Button = styled(ButtonLib)`
  width: 100%;
  ${mediaQuery.lessThan('lg')`
    max-width: 223px;
  `}
  ${mediaQuery.greaterThan('lg')`
    max-width: 120px;
    font-size: 14px;
    height: 30px;
  `}
  ${mediaQuery.lessThan('sm')`
    max-width: 100%;
  `}
`

export const Page = () => {
  const { id } = useParams<{ id: string }>()
  const { merchant } = useMerchant(id)
  const isLg = useMediaQuery({ maxWidth: breakpoints.lg })
  const { t } = useTranslation(['libNotifications', 'libCommon', 'routes'])

  return (
    <WithMerchantExists>
      <Row>
        <Breadcrumbs>
          <Breadcrumb link={routes.dashboard.home} name={t('routes:home')} />
          <Breadcrumb link={routes.dashboard.merchants.main} name={t('routes:merchants')} />
          <Breadcrumb name={merchant?.name ?? ''} />
        </Breadcrumbs>
      </Row>
      {!merchant?.verified && (
        <Alert
          title={t('merchantVerification.title')}
          description={
            <SmallMedium>
              <Trans i18nKey="merchant:dashboardMerchantAlert.title">
                <Link target="_blank" href={process.env.REACT_APP_CHAT} />
              </Trans>
              <ul className="d-flex flex-column align-items-baseline m-0">
                <li>{t('merchant:dashboardMerchantAlert.hint1')}</li>
                <li>{t('merchant:dashboardMerchantAlert.hint2')}</li>
              </ul>
            </SmallMedium>
          }
        >
          <Button small={!isLg} onClick={() => window.open(process.env.REACT_APP_CHAT, '_blank')}>
            {t('libCommon:goToSupport')}
          </Button>
        </Alert>
      )}
      <Row $verticalGutters>
        <Col xl={6}>
          <PortfolioOverviewCard merchantId={id} />
        </Col>
        <Col xl={6}>
          <InputOutputFlowCard merchantId={id} merchantName={merchant?.name ?? ''} />
        </Col>
        <Col xs={12}>
          <Transactions
            merchantId={id}
            transactionsPath={`${routes.dashboard.merchants.main}/${id}/transactions`}
          />
        </Col>
      </Row>
    </WithMerchantExists>
  )
}
