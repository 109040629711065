import { createReducer } from '@reduxjs/toolkit'

import { getTotalMerchantBalanceAction } from '../../actions'

type State = {
  loadingById?: string
  data?: string
  merchantId?: string
}

const initialState: State = {}

export const getTotalBalance = createReducer(initialState, (builder) => {
  builder.addCase(getTotalMerchantBalanceAction.pending, (_, action) => {
    return { loadingById: action.meta.arg }
  })
  builder.addCase(getTotalMerchantBalanceAction.rejected, (state) => {
    state.loadingById = undefined
  })
  builder.addCase(getTotalMerchantBalanceAction.fulfilled, (state, action) => {
    state.loadingById = undefined
    state.data = action.payload.total
    state.merchantId = action.meta.arg
  })
})
