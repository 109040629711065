"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchInput = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Input = require("../Input");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var SearchInput = (0, _styledComponents.default)(_Input.Input)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute !important;\n  background: none !important;\n  &:not([value='']):focus {\n    background: ", " !important;\n  }\n  top: 0;\n  left: 0;\n  padding: 0 !important;\n  height: auto !important;\n  border: none;\n  &:focus {\n    box-shadow: none !important;\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondaryShade;
});
exports.SearchInput = SearchInput;