"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RouterLink = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _reactRouterDom = require("react-router-dom");
var _Link = require("./Link");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var RouterLink = (0, _styledComponents.default)(_reactRouterDom.Link)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), _Link.getLinkStyles);
exports.RouterLink = RouterLink;