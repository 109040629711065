"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFiatWithdrawFee = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useFiatWithdrawFee = function useFiatWithdrawFee() {
  var _state$data, _state$data2;
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.transactions.getFiatWithdrawFee;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    withdrawFeeState: {
      loading: state.loading,
      value: (_state$data = state.data) === null || _state$data === void 0 ? void 0 : _state$data.value,
      converted: (_state$data2 = state.data) === null || _state$data2 === void 0 ? void 0 : _state$data2.converted
    },
    fetchWithdrawFee: function fetchWithdrawFee() {
      return dispatch(_actions.getFiatWithdrawFeeAction.apply(void 0, arguments));
    },
    resetWithdrawFee: function resetWithdrawFee() {
      dispatch((0, _actions.resetGetFiatWithdrawFeeAction)());
    }
  };
};
exports.useFiatWithdrawFee = useFiatWithdrawFee;