import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { compose } from 'redux'

import { useCreateMerchant } from '../../../../../../state'
import { removeProtocol, removeTrailingSlash } from '../../../../../../utils'
import { routes } from '../../../../../router'
import { Component } from './Component'

export const Container = () => {
  const history = useHistory()
  const { createMerchant, resetCreateMerchant, createMerchantLoading, merchantCreated } =
    useCreateMerchant()

  useEffect(() => {
    if (merchantCreated) history.push(routes.dashboard.merchants.main)
    return () => {
      resetCreateMerchant()
    }
  }, [merchantCreated])

  return (
    <Component
      onSubmit={(values) =>
        createMerchant({
          ...values,
          domain: compose(removeProtocol, removeTrailingSlash)(values.domain)
        })
      }
      submitLoading={createMerchantLoading}
    />
  )
}
