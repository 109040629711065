"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "SignUpCredentialsForm", {
  enumerable: true,
  get: function get() {
    return _CredentialsForm.CredentialsForm;
  }
});
Object.defineProperty(exports, "SignUpPage", {
  enumerable: true,
  get: function get() {
    return _Page.Page;
  }
});
var _Page = require("./Page");
var _CredentialsForm = require("./CredentialsForm");