"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCreateFiatExchange = void 0;
var _lodash = _interopRequireDefault(require("lodash.get"));
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var useCreateFiatExchange = function useCreateFiatExchange(params) {
  var _params$action, _params$statePath;
  var action = (_params$action = params === null || params === void 0 ? void 0 : params.action) !== null && _params$action !== void 0 ? _params$action : _actions.createFiatExchangeAction;
  var statePath = (_params$statePath = params === null || params === void 0 ? void 0 : params.statePath) !== null && _params$statePath !== void 0 ? _params$statePath : 'lib.exchange.createFiat';
  var state = (0, _useSelector.useSelector)(function (state) {
    return (0, _lodash.default)(state, statePath);
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    fiatExchangeState: {
      done: state.done,
      loading: state.loading,
      feeLessThanRequired: state.feeLessThanRequired
    },
    createFiatExchange: function createFiatExchange(params) {
      return dispatch(action(params));
    },
    resetFiatExchange: function resetFiatExchange() {} // TODO add reset
  };
};
exports.useCreateFiatExchange = useCreateFiatExchange;