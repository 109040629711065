"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Card = void 0;
var _reactstrap = require("reactstrap");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Header = require("./Header");
var _Body = require("./Body");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Card = (0, _styledComponents.default)(_reactstrap.Card)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border: none;\n  border-radius: 6px;\n  background-color: ", ";\n  box-shadow: ", ";\n  }\n  ", " + ", " {\n    padding-top: 0 !important;\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondary;
}, function (_ref2) {
  var theme = _ref2.theme;
  return "0 20px 50px ".concat(theme.colors.tertiary + '05', ", -20px 20px 50px ").concat(theme.colors.tertiary + '05');
}, _Header.Header, _Body.Body);
exports.Card = Card;