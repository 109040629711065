import {
  useUser,
  DashboardSettingsPage,
  DashboardSettingsSecurity,
  Row,
  Spinner
} from '@ppay/client'
import React, { useState } from 'react'
import styled from 'styled-components'
import { TabPane as TabPaneRS, TabContent } from 'reactstrap'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { SettingsTabs, TabsNav } from './TabsNav'
import { Profile } from './Profile'
import { UserCard } from './UserCard'
import { Breadcrumb, Breadcrumbs } from '../../layout'
import { routes } from '../../router'

const TabPane = styled(TabPaneRS)`
  width: 100%;
`

export const Page = () => {
  const { state } = useLocation<{ tab?: string }>()
  const [activeTab, setActiveTab] = useState(state?.tab ?? SettingsTabs.PROFILE)
  const { t } = useTranslation('routes')
  const { user, loading } = useUser()

  if (!user || loading)
    return (
      <div className="d-flex justify-content-center align-items-center flex-grow-1">
        <Spinner color="primary" />
      </div>
    )

  return (
    <>
      <Row>
        <Breadcrumbs>
          <Breadcrumb link={routes.dashboard.home} name={t('home')} />
          <Breadcrumb name={t('settings')} />
        </Breadcrumbs>
      </Row>
      <DashboardSettingsPage
        tabsNav={<TabsNav activeTab={activeTab} onTabChange={setActiveTab} />}
        userCard={<UserCard credentials={user!.credentials} setActiveTab={setActiveTab} />}
        tabsContent={({ className }) => (
          <TabContent className={className} activeTab={activeTab}>
            <TabPane tabId={SettingsTabs.PROFILE}>
              <Profile />
            </TabPane>
            <TabPane tabId={SettingsTabs.SECURITY}>
              <DashboardSettingsSecurity />
            </TabPane>
          </TabContent>
        )}
      />
    </>
  )
}
