"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRestorePassword = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useRestorePassword = function useRestorePassword() {
  var sendCodeState = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.restorePassword.sendCode;
  });
  var validateCodeState = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.restorePassword.validateCode;
  });
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.restorePassword.restorePassword;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    restored: state.done,
    loading: state.loading,
    reset: function reset() {
      return dispatch((0, _actions.resetRestorePasswordAction)());
    },
    restore: function restore(_ref) {
      var code = _ref.code,
        password = _ref.password;
      return dispatch((0, _actions.restorePasswordAction)({
        email: sendCodeState.email,
        code: code,
        password: password
      }));
    },
    email: sendCodeState.email,
    code: {
      remainingTime: sendCodeState.remainingTime,
      sendLoading: sendCodeState.loading,
      sent: sendCodeState.done,
      send: function send(email) {
        return dispatch((0, _actions.sendRestorePasswordCodeAction)(email !== null && email !== void 0 ? email : sendCodeState.email));
      },
      validated: validateCodeState.done,
      validate: function validate(code) {
        return dispatch((0, _actions.validateRestorePasswordCodeAction)({
          email: sendCodeState.email,
          code: code
        }));
      }
    }
  };
};
exports.useRestorePassword = useRestorePassword;