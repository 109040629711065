"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCalculateAmount = useCalculateAmount;
var _decimal = _interopRequireDefault(require("decimal.js"));
var _react = require("react");
var _constants = require("../../constants");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function useCalculateAmount(_ref) {
  var price = _ref.price,
    ticker = _ref.ticker;
  return (0, _react.useCallback)(function (fiatTickerAmount) {
    if (!fiatTickerAmount) return fiatTickerAmount;
    return new _decimal.default(fiatTickerAmount).div(price).toFixed(_constants.TickerDecimals[ticker]);
  }, [price, ticker]);
}