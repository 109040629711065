import { useTransactions as useTransactionsLib } from '@ppay/client'

import { getMerchantTransactionsAction, getMerchantTransactionsCSVAction } from '../../actions'

type GetParams = Parameters<typeof getMerchantTransactionsAction>[0]
type GetParamsFromMerchantClient = Omit<GetParams, 'merchantId'>

type GetCSVParams = Parameters<typeof getMerchantTransactionsCSVAction>[0]
type GetCSVParamsFromMerchantClient = Omit<GetCSVParams, 'merchantId'>

export const useMerchantTransactions = (merchantId: string) => {
  const { transactionsState, hasMore, fetchTransactions, fetchMoreTransactions, saveCSV } =
    useTransactionsLib<GetParams, GetCSVParams>({
      getAction: getMerchantTransactionsAction,
      getCSVAction: getMerchantTransactionsCSVAction,
      statePath: 'merchants.transactions.get'
    })

  return {
    transactionsState,
    hasMore,
    saveCSV: (params: GetCSVParamsFromMerchantClient) => saveCSV({ ...params, merchantId }),
    fetchTransactions: (params: GetParamsFromMerchantClient) =>
      fetchTransactions({ ...params, merchantId }),
    fetchMoreTransactions: (params: GetParamsFromMerchantClient) =>
      fetchMoreTransactions({ ...params, merchantId })
  }
}
