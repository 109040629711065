"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFiatWithdrawLimits = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useFiatWithdrawLimits = function useFiatWithdrawLimits() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.transactions.getFiatWithdrawLimits;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    fiatWithdrawLimits: state.data,
    fiatWithdrawLoadingByTicker: state.loadingByTicker,
    getFiatWithdrawLimits: function getFiatWithdrawLimits() {
      dispatch(_actions.getFiatWithdrawLimitsAction.apply(void 0, arguments));
    }
  };
};
exports.useFiatWithdrawLimits = useFiatWithdrawLimits;