"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SmallMedium = exports.Small = exports.SeoH3 = exports.SeoH2 = exports.SeoH1 = exports.P = exports.MediumSpan = exports.H6 = exports.H5 = exports.H4 = exports.H3 = exports.H2 = exports.H1 = exports.GlobalFonts = exports.ExtraSmall = exports.A = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var GlobalFonts = (0, _styledComponents.createGlobalStyle)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  body {\n    font-family: 'Formular', sans-serif !important;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 1.4;\n    color: ", ";\n  }\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.tertiary;
});
exports.GlobalFonts = GlobalFonts;
var SeoH1 = _styledComponents.default.h1(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-size: 48px;\n  font-weight: 700;\n  line-height: 1.2;\n"])));
exports.SeoH1 = SeoH1;
var H1 = _styledComponents.default.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: 48px;\n  font-weight: 700;\n  line-height: 1.2;\n"])));
exports.H1 = H1;
var SeoH2 = _styledComponents.default.h2(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  font-size: 36px;\n  font-weight: 500;\n  line-height: 1.2;\n"])));
exports.SeoH2 = SeoH2;
var H2 = _styledComponents.default.p(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-size: 36px;\n  font-weight: 500;\n  line-height: 1.2;\n"])));
exports.H2 = H2;
var SeoH3 = _styledComponents.default.h3(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-size: 22px;\n  font-weight: 500;\n  line-height: 1.2;\n"])));
exports.SeoH3 = SeoH3;
var H3 = _styledComponents.default.p(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  font-size: 22px;\n  font-weight: 500;\n  line-height: 1.2;\n"])));
exports.H3 = H3;
var H4 = _styledComponents.default.h4(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  font-size: 20px;\n  font-weight: 500;\n  line-height: 1.2;\n"])));
exports.H4 = H4;
var H5 = _styledComponents.default.h5(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 1.2;\n"])));
exports.H5 = H5;
var H6 = _styledComponents.default.h6(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 1.2;\n"])));
exports.H6 = H6;
var P = _styledComponents.default.p(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 1.4;\n  margin: 0;\n"])));
exports.P = P;
var ExtraSmall = _styledComponents.default.small(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 1.4;\n"])));
exports.ExtraSmall = ExtraSmall;
var Small = _styledComponents.default.small(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 1.4;\n"])));
exports.Small = Small;
var SmallMedium = _styledComponents.default.small(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 1.4;\n"])));
exports.SmallMedium = SmallMedium;
var A = _styledComponents.default.a(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.primary;
});
exports.A = A;
var MediumSpan = _styledComponents.default.span(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  font-weight: 500;\n  line-height: 1.2;\n"])));
exports.MediumSpan = MediumSpan;