import { DashboardTransactionsPage } from '@ppay/client'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMerchant, useMerchantTransactions } from '../../../state'
import { MerchantTransactionsNoData } from '../../transactions'
import { WithMerchantExists } from './WithMerchantExists'
import { Breadcrumb, Breadcrumbs } from '../../layout'
import { routes } from '../../router'

export const MerchantTransactions = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation(['transactionsTable', 'routes'])
  const { merchant } = useMerchant(id)

  return (
    <WithMerchantExists>
      <Breadcrumbs>
        <Breadcrumb link={routes.dashboard.home} name={t('routes:home')} />
        <Breadcrumb link={routes.dashboard.merchants.main} name={t('routes:merchants')} />
        <Breadcrumb
          link={routes.dashboard.merchants.main + `/${merchant?.id}`}
          name={merchant?.name ?? ''}
        />
        <Breadcrumb name={t('routes:merchantTransactions')} />
      </Breadcrumbs>
      <DashboardTransactionsPage
        title={t('titles.transaction')}
        subtitle={t('subtitle')}
        useTransactions={useMerchantTransactions.bind(null, id)}
        noData={<MerchantTransactionsNoData />}
        showModalViewAll
      />
    </WithMerchantExists>
  )
}
