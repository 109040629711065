"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enoughBalance = exports.ENOUGH_BALANCE_VALIDATION_NAME = void 0;
var _decimal = _interopRequireDefault(require("decimal.js"));
var yup = _interopRequireWildcard(require("yup"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ENOUGH_BALANCE_VALIDATION_NAME = 'enoughBalance';
exports.ENOUGH_BALANCE_VALIDATION_NAME = ENOUGH_BALANCE_VALIDATION_NAME;
var enoughBalance = yup.number().transform(function (value) {
  return isNaN(value) ? undefined : value;
}).test({
  name: ENOUGH_BALANCE_VALIDATION_NAME,
  test: function test(value, _ref) {
    var options = _ref.options;
    var _ref2 = options.context,
      balance = _ref2.balance;
    if (value === undefined || new _decimal.default(balance).gte(value)) return true;
    return this.createError({
      params: {
        balanceValidation: true
      },
      message: 'Not enough balance'
    });
  }
});
exports.enoughBalance = enoughBalance;