import { CreateWithdrawContext, useCreateWithdraw } from '@ppay/client'
import React from 'react'

import { useCreateMerchantWithdraw, useMerchantWithdrawFee } from '../../../state'

export const MerchantCreateCryptoWithdrawProvider = ({
  children,
  merchantId
}: React.PropsWithChildren<{ merchantId: string }>) => {
  return (
    <CreateWithdrawContext.Provider
      value={{
        useCreateWithdraw: (() =>
          useCreateMerchantWithdraw(merchantId)) as typeof useCreateWithdraw,
        useWithdrawFee: () => useMerchantWithdrawFee(merchantId)
      }}
    >
      {children}
    </CreateWithdrawContext.Provider>
  )
}
