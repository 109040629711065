import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Spinner } from '@ppay/client'
import { FiatReplenishGatewayProvider, Ticker } from '@ppay/definitions'

import { useInvoiceFiat } from '../../../state'
import { Layout } from '../layout'

import { UserContainer } from './UserContainer'

export const InvoiceContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { notExist, invoice, fetchInvoice } = useInvoiceFiat()

  useEffect(() => {
    fetchInvoice(id)
  }, [id])

  useEffect(() => {
    if (notExist) {
      window.location.replace(process.env.REACT_APP_SITE_URL ?? '')
    }
  }, [notExist])

  useEffect(() => {
    if (invoice?.paymentProvider === FiatReplenishGatewayProvider.MANUAL) {
      const widget = (window as any).fcWidget
      if (widget) {
        widget.show()
        widget.user.setEmail(invoice.email)
      }
    }
  }, [invoice?.paymentProvider])

  const [ticker, setTicker] = useState<Ticker>(invoice?.amount?.ticker ?? Ticker.UAH)

  useEffect(() => {
    setTicker(invoice?.amount?.ticker ?? Ticker.UAH)
  }, [invoice?.amount?.ticker])

  return (
    <Layout isP2P={invoice?.paymentProvider === FiatReplenishGatewayProvider.MANUAL}>
      {!invoice ? (
        <div className="d-flex justify-content-center mt-auto">
          <Spinner color="primary" />
        </div>
      ) : (
        <UserContainer invoice={invoice} setTicker={setTicker} ticker={ticker} />
      )}
    </Layout>
  )
}
