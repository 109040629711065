import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button, H2 as H2UI, Card as CardUI, mediaQuery } from '@ppay/client'

import { Image } from './Image'
import { routes } from '../../../router'

const Card = styled(CardUI)`
  position: relative;
  background-image: radial-gradient(61.43% 61.43% at 69.46% 53.75%, #184671 0%, #0c0825 100%);
  box-shadow: 0 20px 50px ${({ theme }) => theme.colors.tertiary}0D,
    -20px 20px 50px ${({ theme }) => theme.colors.tertiary}0D;
  border: 2px solid ${({ theme }) => theme.colors.secondary + '10'};
  border-radius: 6px;
  min-height: 280px;
  padding: 35px 0 35px 37px;
  overflow: hidden;
  ${mediaQuery.lessThan('md')`
    align-items: center;
    padding: 30px 24px 40px;
    min-height: auto;
  `}

  ${H2UI}, ${Button} {
    z-index: 2;
  }
`

const H2 = styled(H2UI)`
  position: relative;
  max-width: 380px;
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 42px;
  ${mediaQuery.lessThan('md')`
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
  `}
  ${mediaQuery.lessThan('sm')`
    font-size: 22px;
  `}
`

export const Container = () => {
  const { t } = useTranslation('merchant')
  const history = useHistory()

  return (
    <Card>
      <H2>{t('banner.title')}</H2>
      <Button onClick={() => history.push(routes.dashboard.merchants.create)}>
        {t('banner.buttonLabel')}
      </Button>
      <Image />
    </Card>
  )
}
