import { Link, Button } from '@ppay/client'
import { useTranslation } from 'react-i18next'

export const BackToWebsiteButton = ({ redirectUrl }: { redirectUrl: string }) => {
  const { t } = useTranslation('invoice')

  return (
    <Link href={redirectUrl}>
      <Button wide>{t('backToWebsite')}</Button>
    </Link>
  )
}
