import { useSelector } from '../../useSelector'
import { useDispatch } from '../../useDispatch'

import {
  failInvoiceFiatAction,
  getInvoiceFiatAction,
  successInvoiceFiatAction,
  failInvoiceNotification
} from '../../../actions'
import { InvoiceApi } from '../../../../api'

export const useFiat = () => {
  const getState = useSelector((state) => state.invoice.fiat.get)
  const dispatch = useDispatch()

  return {
    invoice: getState.data,
    notExist: getState.notExist,
    fetchInvoice: (id: string) => dispatch(getInvoiceFiatAction(id)),
    successInvoice: () => dispatch(successInvoiceFiatAction()),
    failInvoice: (params: Parameters<typeof InvoiceApi.failFiat>[0]) =>
      dispatch(failInvoiceFiatAction(params)),
    notifyFailInvoice: (params: Parameters<typeof failInvoiceNotification>[0]) =>
      dispatch(failInvoiceNotification(params))
  }
}
