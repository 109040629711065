"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScrollToTop = void 0;
var _react = require("react");
var _reactRouterDom = require("react-router-dom");
var ScrollToTop = function ScrollToTop() {
  var _useLocation = (0, _reactRouterDom.useLocation)(),
    pathname = _useLocation.pathname;
  (0, _react.useEffect)(function () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [pathname]);
  return null;
};
exports.ScrollToTop = ScrollToTop;