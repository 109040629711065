import { createReducer, isRejectedWithValue } from '@reduxjs/toolkit'
import { InvoiceStatus } from '@ppay/definitions'

import { FiatInvoice } from '../../../../interfaces'
import {
  getInvoiceFiatAction,
  updateInvoiceFiatAction,
  failInvoiceFiatAction,
  successInvoiceFiatAction,
  queueManualReplenishOrderAction
} from '../../../actions'

type State = {
  loading: boolean
  data?: FiatInvoice
  notExist?: boolean
}

const initialState: State = {
  loading: false,
  notExist: false
}

export const get = createReducer(initialState, (builder) => {
  builder
    // get invoice
    .addCase(getInvoiceFiatAction.pending, (state) => {
      state.loading = true
    })
    .addCase(getInvoiceFiatAction.rejected, (state, action) => {
      state.loading = false
      if (isRejectedWithValue(action)) {
        state.notExist = true
      }
    })
    .addCase(getInvoiceFiatAction.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    // update invoice
    .addCase(updateInvoiceFiatAction.fulfilled, (state, action) => {
      state.data = action.payload
      state.data!.status = InvoiceStatus.PENDING
    })
    // success invoice
    .addCase(successInvoiceFiatAction, (state) => {
      state.data!.status = InvoiceStatus.DONE
    })
    // fail invoice
    .addCase(failInvoiceFiatAction.fulfilled, (state, action) => {
      state.data!.status = InvoiceStatus.FAILED
      state.data!.paymentError = action.meta.arg
    })

    .addCase(queueManualReplenishOrderAction.fulfilled, (state) => {
      state.data!.expiredAt = undefined
    })
})
