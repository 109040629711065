import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import styled from 'styled-components'
import Decimal from 'decimal.js'
import {
  Label,
  Link,
  H6,
  P,
  Small,
  Progress as ProgressUI,
  mediaQuery,
  breakpoints,
  CountdownTime,
  QRCodeBlockchain,
  WithReveal,
  InputGroupAbsolute,
  CopyInputGroupAddon,
  CopyInput,
  TickerNumberFormat
} from '@ppay/client'
import { BlockchainExplorer } from '@ppay/crypto-constraints'

import { useInvoiceCrypto, useInvoiceCryptoPayPolling } from '../../../state'
import { CryptoInvoice } from '../../../interfaces'
import { Card, CardAmountToPay as CardAmountToPayUI, CardData, CardSeparativeLine } from '../card'

const CardDataWithMargin = styled(CardData)`
  margin-bottom: 10px;
`
const CardAmountToPay = styled(CardAmountToPayUI)`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  text-align: left;

  ${mediaQuery.lessThan('sm')`
    align-items: center;
  `}
`

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  ${mediaQuery.lessThan('sm')`
    flex-direction: column;
  `}
`

const QRCodeWrapper = styled.div`
  box-shadow: 0 8px 16px ${({ theme }) => theme.colors.tertiary}0D,
    0px 4px 8px ${({ theme }) => theme.colors.tertiary}0D;
  border-radius: 6px;
  width: 168px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-right: 30px;
  ${P} {
    margin-top: 10px;
  }
  ${mediaQuery.lessThan('sm')`
    margin-right: 0;
    margin-bottom: 20px;
  `}
`

const Info = styled(Small)`
  color: ${({ theme }) => theme.colors.tertiaryShade};
`

const AmountInfo = styled(Info)`
  ${H6} {
    display: inline;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.tertiary};
  }
`

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  ${Small} {
    margin-left: 10px;
  }
`

const Progress = styled(ProgressUI)`
  display: flex;
  flex-grow: 1;
  ${mediaQuery.lessThan('sm')`
    width: 100%;
  `}
`

const AddressWrapper = styled.div`
  margin-bottom: 20px;
`

const BaseAmount = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryShade2};
  width: 100%;
`

const renderer = ({ days, hours, minutes, seconds }: CountdownRenderProps) => (
  <Small>
    <CountdownTime days={days} hours={hours} minutes={minutes} seconds={seconds} />
  </Small>
)

export const Pay = ({ invoice }: { invoice: CryptoInvoice }) => {
  const { t } = useTranslation('invoice')
  const { expireInvoice } = useInvoiceCrypto()

  const [progress, setProgress] = useState(0)

  const explorer = BlockchainExplorer[invoice.amount!.blockchain]
  const isSm = useMediaQuery({ maxWidth: breakpoints.sm })

  const onTick = () => {
    if (invoice?.createdAt && invoice?.clientExpiredAt)
      setProgress(
        (Date.now() - invoice?.createdAt) / (invoice?.clientExpiredAt - invoice?.createdAt)
      )
  }

  useInvoiceCryptoPayPolling()

  const decimalPayAmount = new Decimal(invoice.amount!.value!).sub(invoice.paidAmount ?? '0')

  return (
    <Card id={invoice.id} name={invoice.name} description={invoice.description}>
      <CardDataWithMargin
        title={t('company')}
        value={
          <Link target="_blank" href={invoice.company.url}>
            {invoice.company.name}
          </Link>
        }
      />
      <CardSeparativeLine />
      <div>
        <Label>
          {t('timeRemaining')}:{' '}
          {invoice?.clientExpiredAt && isSm && (
            <Countdown
              date={invoice.clientExpiredAt}
              renderer={renderer}
              onComplete={expireInvoice}
              onTick={onTick}
            />
          )}
        </Label>
        <ProgressWrapper>
          <Progress value={progress * 100} color="success" />
          {invoice?.clientExpiredAt && !isSm && (
            <Countdown
              date={invoice.clientExpiredAt}
              renderer={renderer}
              onComplete={expireInvoice}
              onTick={onTick}
            />
          )}
        </ProgressWrapper>
        <Info>{t('closeAfterPay')}</Info>
      </div>
      <InfoWrapper>
        <QRCodeWrapper>
          <QRCodeBlockchain
            size={148}
            address={invoice.address ?? ''}
            blockchain={invoice.amount!.blockchain}
          />
          <P>{explorer.network}</P>
        </QRCodeWrapper>

        <div>
          <CardAmountToPay
            amount={decimalPayAmount.toString()}
            ticker={invoice.amount!.ticker}
            text={t('amountToPay')}
          >
            {invoice.baseAmount && (
              <BaseAmount>
                <div className="d-flex justify-content-between">
                  <P>{t('rate')}:</P>
                  <div className="text-nowrap">
                    <TickerNumberFormat ticker={invoice.amount!.ticker} value={1} />{' '}
                    {invoice.amount!.ticker} ={' '}
                    <TickerNumberFormat
                      ticker={invoice.baseAmount!.ticker}
                      value={invoice.price!}
                    />{' '}
                    {invoice.baseAmount!.ticker}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <P>{t('equivalent')}:</P>
                  <TickerNumberFormat
                    ticker={invoice.baseAmount!.ticker}
                    value={invoice.baseAmount!.value}
                  />{' '}
                  {invoice.baseAmount!.ticker}
                </div>
              </BaseAmount>
            )}
          </CardAmountToPay>
          <AmountInfo>
            <Trans
              i18nKey="invoice:transferCrypto"
              values={{
                amount: decimalPayAmount.toFixed(decimalPayAmount.decimalPlaces()),
                ticker: invoice.amount!.ticker
              }}
            >
              <H6 />
              <i />
            </Trans>
          </AmountInfo>
        </div>
      </InfoWrapper>
      <AddressWrapper>
        <Label>{t('walletAddress')}:</Label>

        <WithReveal revealed={!!invoice.address} label={t('creatingAddress')}>
          <InputGroupAbsolute>
            <CopyInputGroupAddon value={invoice.address!} addonType="append" />
            <CopyInput value={invoice.address!} />
          </InputGroupAbsolute>
        </WithReveal>
      </AddressWrapper>

      <Info>{t('alertCryptoPay')}</Info>
    </Card>
  )
}
