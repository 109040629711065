import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button, H4, Svg, useTranslation } from '@ppay/client'
import { Ticker } from '@ppay/definitions'

import { FiatInvoice } from '../../../../interfaces'
import { useKYCFormUrl } from '../../../../state'
import { Icon } from '../../../ui'

import { ReactComponent as amlBot } from './aml-bot.svg'
import { Wrapper } from './Wrapper'

const StyledSvg = styled(Svg)`
  margin-bottom: 10px;
`

export const Start = ({
  invoiceRequirements,
  ticker
}: {
  invoiceRequirements: FiatInvoice['requirements']
  ticker: Ticker
}) => {
  const { t } = useTranslation('kyc')
  const { url, loading, fetchUrl } = useKYCFormUrl()

  useEffect(() => {
    fetchUrl({ requirementsId: invoiceRequirements.id, redirectUrl: window.location.href, ticker })
  }, [])

  return (
    <>
      <Wrapper>
        <StyledSvg as={amlBot} />
        <H4> {t('start.title')}</H4>
      </Wrapper>
      <Button
        loading={loading}
        wide
        icon={<Icon name="arrowRight" />}
        onClick={() => {
          window.open(url)
        }}
      >
        {t('pass')}
      </Button>
    </>
  )
}
