import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Spinner } from '@ppay/client'

import { Layout } from '../layout'
import { Component } from './Component'
import { useInvoiceCrypto } from '../../../state'

export const Container = () => {
  const { id } = useParams<{ id: string }>()

  const { notExist, invoice, fetchInvoice } = useInvoiceCrypto()

  useEffect(() => {
    fetchInvoice(id)
  }, [id])

  useEffect(() => {
    if (notExist) {
      window.location.replace(process.env.REACT_APP_SITE_URL ?? '')
    }
  }, [notExist])

  return (
    <Layout>
      {!invoice ? (
        <div className="d-flex justify-content-center mt-auto">
          <Spinner color="primary" />
        </div>
      ) : (
        <Component invoice={invoice} />
      )}
    </Layout>
  )
}
