"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stylesBy = stylesBy;
function stylesBy(key) {
  function stylesByProps(options, defaultValue) {
    return function (props) {
      var _options$index;
      var index = key(props);
      if (!index) return defaultValue;
      return (_options$index = options[index]) !== null && _options$index !== void 0 ? _options$index : defaultValue;
    };
  }
  return stylesByProps;
}