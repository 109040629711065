import * as yup from 'yup'
import { useWithdrawForm, enoughBalanceValidation } from '@ppay/client'
import Decimal from 'decimal.js'
import { FiatTicker, FIAT_TICKERS } from '@ppay/definitions'

const MIN_FIAT_AMOUNT = '25'

const schema = yup
  .object()
  .shape({
    amount: enoughBalanceValidation
      .when('$isFiat', (isFiat: boolean, schema: any) =>
        isFiat
          ? schema.test(
              'minFiatAmount',
              `Amount must be at least ${MIN_FIAT_AMOUNT}`,
              (value: string) => {
                if (!value) return true
                return !new Decimal(value).lt(MIN_FIAT_AMOUNT)
              }
            )
          : schema
      )
      .required()
  })
  .required()

export const useTransferForm = (props: any) => {
  const isFiat = FIAT_TICKERS.includes(props.ticker as FiatTicker)

  return useWithdrawForm({ ...props, schema, context: { isFiat } })
}
