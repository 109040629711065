"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Badge = void 0;
var _reactstrap = require("reactstrap");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var Badge = (0, _styledComponents.default)(_reactstrap.Badge)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: 6px;\n  background-color: ", " !important;\n  color: ", ";\n  padding: 5px 10px;\n  font-weight: 400;\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondary + '1a';
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.secondary;
});
exports.Badge = Badge;