"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _ApiError = require("./ApiError");
Object.keys(_ApiError).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ApiError[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ApiError[key];
    }
  });
});
var _ResendCodeLimitError = require("./ResendCodeLimitError");
Object.keys(_ResendCodeLimitError).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ResendCodeLimitError[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ResendCodeLimitError[key];
    }
  });
});
var _WithdrawFeeLessThanRequiredError = require("./WithdrawFeeLessThanRequiredError");
Object.keys(_WithdrawFeeLessThanRequiredError).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _WithdrawFeeLessThanRequiredError[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _WithdrawFeeLessThanRequiredError[key];
    }
  });
});
var _ExchangeNotEnoughLiquidityError = require("./ExchangeNotEnoughLiquidityError");
Object.keys(_ExchangeNotEnoughLiquidityError).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ExchangeNotEnoughLiquidityError[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ExchangeNotEnoughLiquidityError[key];
    }
  });
});
var _LoginLimitError = require("./LoginLimitError");
Object.keys(_LoginLimitError).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _LoginLimitError[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _LoginLimitError[key];
    }
  });
});