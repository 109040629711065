import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Row, Col, Card, CardHeader, CardBody, CardTitle, H4, useUser } from '@ppay/client'

import { Form } from './Form'
import { routes } from '../../../../router'
import { useMerchantCreateLimit } from '../../../../../hooks'
import { Breadcrumb, Breadcrumbs } from '../../../../layout'

export const Page = () => {
  const { t } = useTranslation(['merchant', 'routes'])
  const history = useHistory()
  const { user } = useUser()
  const enabled2fa = !!user?.credentials.email2faEnabled || !!user?.credentials.totpEnabled
  const { merchantsLimit } = useMerchantCreateLimit()

  React.useEffect(() => {
    if (!enabled2fa || merchantsLimit) history.push(routes.dashboard.merchants.main)
  }, [])

  return (
    <>
      <Row>
        <Breadcrumbs>
          <Breadcrumb link={routes.dashboard.home} name={t('routes:home')} />
          <Breadcrumb link={routes.dashboard.merchants.main} name={t('routes:merchants')} />
          <Breadcrumb name={t('routes:createMerchant')} />
        </Breadcrumbs>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={7} xl={5}>
          <Card>
            <CardHeader>
              <CardTitle tag={H4}>{t('createNewMerchant')}</CardTitle>
            </CardHeader>
            <CardBody className="pt-0">
              <Form />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}
