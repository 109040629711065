"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePage = void 0;
var _definitions = require("@ppay/definitions");
var _decimal = _interopRequireDefault(require("decimal.js"));
var _react = require("react");
var _reactI18next = require("react-i18next");
var _reactRouterDom = require("react-router-dom");
var _constants = require("../../constants");
var _state = require("../../state");
var _useForm2 = require("./useForm");
var _useWithdraw2 = require("./useWithdraw");
var _useBlockchainByTicker = require("../useBlockchainByTicker");
var _validations = require("../../validations");
var _useFiatForm = require("./useFiatForm");
var _useFiatWithdraw = require("./useFiatWithdraw");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var usePage = function usePage(params) {
  var _ref, _balances$find, _params$useForm, _params$useFiatWithdr, _params$useWithdraw, _fiatWithdrawFeeState, _withdrawFeeState$val, _formProviderProps$fo, _params$tickers, _price$value;
  var location = (0, _reactRouterDom.useLocation)();
  var _useTranslation = (0, _reactI18next.useTranslation)('libTransfers'),
    t = _useTranslation.t;

  // redux state
  var _useUser = (0, _state.useUser)(),
    user = _useUser.user;
  var _useWithdrawFee = (0, _state.useWithdrawFee)(),
    resetWithdrawFee = _useWithdrawFee.resetWithdrawFee,
    withdrawFeeState = _useWithdrawFee.withdrawFeeState;
  var _useFiatWithdrawFee = (0, _state.useFiatWithdrawFee)(),
    fiatWithdrawFeeState = _useFiatWithdrawFee.withdrawFeeState;
  var _useFiatWithdrawLimit = (0, _state.useFiatWithdrawLimits)(),
    fiatWithdrawLimits = _useFiatWithdrawLimit.fiatWithdrawLimits,
    fiatWithdrawLoadingByTicker = _useFiatWithdrawLimit.fiatWithdrawLoadingByTicker,
    getFiatWithdrawLimits = _useFiatWithdrawLimit.getFiatWithdrawLimits;

  // local state
  var _useState = (0, _react.useState)((_ref = location.state && location.state.ticker) !== null && _ref !== void 0 ? _ref : _definitions.CRYPTO_TICKERS[0]),
    _useState2 = _slicedToArray(_useState, 2),
    ticker = _useState2[0],
    setTicker = _useState2[1];
  var _useState3 = (0, _react.useState)(_definitions.FIAT_TICKERS[0]),
    _useState4 = _slicedToArray(_useState3, 2),
    fiatTicker = _useState4[0],
    setFiatTicker = _useState4[1];
  var _useBlockchainByTicke = (0, _useBlockchainByTicker.useBlockchainByTicker)(_definitions.FIAT_TICKERS.includes(ticker) ? _definitions.Ticker.BTC : ticker),
    blockchain = _useBlockchainByTicke.blockchain,
    blockchains = _useBlockchainByTicke.blockchains,
    setBlockchain = _useBlockchainByTicke.setBlockchain;
  var _useState5 = (0, _react.useState)(false),
    _useState6 = _slicedToArray(_useState5, 2),
    modalOpen = _useState6[0],
    setModalOpened = _useState6[1];
  var price = (0, _state.usePrice)({
    ticker: ticker,
    convertedTicker: fiatTicker
  });
  var _useBalances = (0, _state.useBalances)(),
    getBalanceByTicker = _useBalances.getBalanceByTicker;
  var balances = params.balances;
  var balance = (_balances$find = balances === null || balances === void 0 ? void 0 : balances.find(function (b) {
    return b.ticker === ticker;
  })) !== null && _balances$find !== void 0 ? _balances$find : getBalanceByTicker(ticker);
  var limits = fiatWithdrawLimits[ticker];
  var isFiat = _definitions.FIAT_TICKERS.includes(ticker);
  (0, _react.useEffect)(function () {
    if (!limits && fiatWithdrawLoadingByTicker !== ticker && isFiat) {
      var provider = user === null || user === void 0 ? void 0 : user.fiat.withdrawGatewayProviders[ticker];
      if (!provider) return console.error('Withdraw gateway provider not provided');
      getFiatWithdrawLimits({
        provider: provider,
        ticker: ticker
      });
    }
  }, [ticker]);
  var useForm = (_params$useForm = params.useForm) !== null && _params$useForm !== void 0 ? _params$useForm : isFiat ? function (props) {
    return (0, _useFiatForm.useFiatForm)(_objectSpread(_objectSpread({}, props), {}, {
      limits: limits
    }));
  } : _useForm2.useForm;
  var useWithdraw = isFiat ? (_params$useFiatWithdr = params.useFiatWithdraw) !== null && _params$useFiatWithdr !== void 0 ? _params$useFiatWithdr : _useFiatWithdraw.useFiatWithdraw : (_params$useWithdraw = params.useWithdraw) !== null && _params$useWithdraw !== void 0 ? _params$useWithdraw : _useWithdraw2.useWithdraw;
  var props = {
    balance: '0',
    fee: isFiat ? (_fiatWithdrawFeeState = fiatWithdrawFeeState.value) !== null && _fiatWithdrawFeeState !== void 0 ? _fiatWithdrawFeeState : '0' : (_withdrawFeeState$val = withdrawFeeState.value) !== null && _withdrawFeeState$val !== void 0 ? _withdrawFeeState$val : '0',
    total: '0',
    balanceAfterTransaction: '0',
    fiatTickerBalance: '0',
    fiatTickerFee: isFiat ? fiatWithdrawFeeState.converted ? new _decimal.default(fiatWithdrawFeeState.converted).toFixed(_constants.TickerDecimals[_definitions.Ticker.USD], _decimal.default.ROUND_UP) : '0' : withdrawFeeState.converted ? new _decimal.default(withdrawFeeState.converted).toFixed(_constants.TickerDecimals[_definitions.Ticker.USD], _decimal.default.ROUND_UP) : '0',
    fiatTickerTotal: '0',
    fiatBalanceAfterTransaction: '0'
  };
  if (balance) {
    props.balance = balance.value;
    props.fiatTickerBalance = balance.converted;
    props.balanceAfterTransaction = balance.value;
    props.fiatBalanceAfterTransaction = balance.converted;
  }
  var _useForm = useForm({
      ticker: ticker,
      fiatTicker: fiatTicker,
      onAmountCleared: resetWithdrawFee,
      price: price.value,
      blockchain: blockchain,
      balance: new _decimal.default(props.balance).sub(props.fee).toString()
    }),
    amount = _useForm.amount,
    fiatTickerAmount = _useForm.fiatTickerAmount,
    address = _useForm.address,
    formProviderProps = _useForm.formProviderProps,
    setFocusedInput = _useForm.setFocusedInput;
  var isEnoughBalance = ((_formProviderProps$fo = formProviderProps.formState.errors.amount) === null || _formProviderProps$fo === void 0 ? void 0 : _formProviderProps$fo.type) !== _validations.ENOUGH_BALANCE_VALIDATION_NAME;
  if (isEnoughBalance) {
    if (amount) props.total = amount;
    if (fiatTickerAmount) props.fiatTickerTotal = fiatTickerAmount;
  }
  if (props.fee) {
    props.total = new _decimal.default(props.total).add(props.fee).toString();
    props.fiatTickerTotal = new _decimal.default(props.fiatTickerTotal).add(props.fiatTickerFee).toString();
  }
  props.balanceAfterTransaction = new _decimal.default(props.balance).sub(props.total).toString();
  props.fiatBalanceAfterTransaction = new _decimal.default(props.fiatTickerBalance).sub(props.fiatTickerTotal).toString();
  var _useWithdraw = useWithdraw({
      blockchain: blockchain,
      amount: amount,
      fiatTickerTotal: props.fiatTickerTotal,
      total: props.total,
      ticker: ticker,
      fiatTicker: fiatTicker,
      address: address,
      isEnoughBalance: isEnoughBalance,
      isValidating: formProviderProps.formState.isValidating,
      onSuccess: function onSuccess() {
        formProviderProps.reset({
          amount: '',
          fiatTickerAmount: '',
          address: ''
        }, {
          keepTouched: false
        });
        setModalOpened(false);
      }
    }),
    createWithdraw = _useWithdraw.createWithdraw,
    createWithdrawLoading = _useWithdraw.createWithdrawLoading,
    feeLessThanRequired = _useWithdraw.feeLessThanRequired;
  return _objectSpread(_objectSpread({
    balances: balances,
    blockchain: blockchain,
    blockchains: blockchains,
    setBlockchain: setBlockchain,
    loading: !balance || fiatWithdrawLoadingByTicker === ticker || !limits && _definitions.FIAT_TICKERS.includes(ticker),
    fiatTickerAmount: fiatTickerAmount || '0',
    amount: amount || '0',
    fiatTicker: fiatTicker,
    ticker: ticker,
    setFiatTicker: setFiatTicker,
    setTicker: setTicker,
    tickers: (_params$tickers = params.tickers) !== null && _params$tickers !== void 0 ? _params$tickers : [].concat(_toConsumableArray(_definitions.CRYPTO_TICKERS), _toConsumableArray(_definitions.FIAT_BALANCE_TICKERS)),
    fiatTickers: [_definitions.Ticker.USD],
    onFormSubmit: function onFormSubmit() {
      setModalOpened(true);
    },
    formSubmitDisabled: price.loading || !props.fee,
    modalOpen: modalOpen,
    toggleModal: function toggleModal() {
      return setModalOpened(!modalOpen);
    },
    address: address,
    onAmountInputFocus: function onAmountInputFocus() {
      return setFocusedInput('amount');
    },
    onFiatTickerAmountInputFocus: function onFiatTickerAmountInputFocus() {
      return setFocusedInput('fiatTickerAmount');
    },
    onUseMaxClick: function onUseMaxClick() {},
    onModalSubmit: createWithdraw,
    modalSubmitLoading: createWithdrawLoading,
    modalSubmitDisabled: !isEnoughBalance,
    priceLoading: price.loading,
    price: (_price$value = price.value) !== null && _price$value !== void 0 ? _price$value : '0',
    modalSubmitBtnText: t(!isEnoughBalance ? 'notEnoughBalance' : feeLessThanRequired ? 'rateChanged' : 'confirm&send')
  }, props), {}, {
    isInternalAddress: !withdrawFeeState.loading && withdrawFeeState.value === '0',
    formProviderProps: formProviderProps,
    isFiat: isFiat
  });
};
exports.usePage = usePage;