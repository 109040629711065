"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SeparativeLine = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var SeparativeLine = _styledComponents.default.hr(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border: none;\n  height: 1px;\n  width: 100%;\n  margin: 0;\n"])), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.secondaryShade2;
});
exports.SeparativeLine = SeparativeLine;