import React from 'react'
import { RequirementsStatus, Ticker } from '@ppay/definitions'

import { FiatInvoice } from '../../../../interfaces'

import { Start } from './Start'
import { Pending } from './Pending'
import { Failed } from './Failed'
import { Completed } from './Completed'

export const KYC = ({
  ticker,
  invoiceRequirements,
  status
}: {
  invoiceRequirements: FiatInvoice['requirements']
  status?: RequirementsStatus
  ticker: Ticker
}) => {
  switch (status) {
    case undefined:
      return <Start invoiceRequirements={invoiceRequirements} ticker={ticker} />
    case RequirementsStatus.PENDING:
      return <Pending />
    case RequirementsStatus.FAILED:
      return <Failed invoiceRequirements={invoiceRequirements} ticker={ticker} />
    case RequirementsStatus.COMPLETED:
      return <Completed />
  }
}
