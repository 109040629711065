import { createReducer } from '@reduxjs/toolkit'

import {
  getMerchantFiatWithdrawFeeAction,
  resetGetMerchantFiatWithdrawFeeAction
} from '../../../actions'

type State = {
  loading: boolean
  data?: {
    value: string
    converted: string
  }
}

const initialState: State = { loading: false, data: undefined }

export const getFiatWithdrawFee = createReducer(initialState, (builder) => {
  builder
    .addCase(getMerchantFiatWithdrawFeeAction.pending, (state) => {
      state.loading = true
      state.data = initialState.data
    })
    .addCase(getMerchantFiatWithdrawFeeAction.rejected, (state) => {
      state.loading = false
    })
    .addCase(getMerchantFiatWithdrawFeeAction.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    .addCase(resetGetMerchantFiatWithdrawFeeAction, () => initialState)
})
