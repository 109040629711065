"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTwoFactor = void 0;
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
var useTwoFactor = function useTwoFactor() {
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.auth.twoFactor;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    kind: state.kind,
    emailKey: state.emailKey,
    resendLoading: state.resend.loading,
    resendRemainingTime: state.resend.remainingTime,
    reset: function reset() {
      return dispatch((0, _actions.resetTwoFactorAction)());
    },
    resend: function resend(email) {
      return dispatch((0, _actions.resendEmailTwoFactorAction)(email));
    }
  };
};
exports.useTwoFactor = useTwoFactor;