import { InfoLabel, Button, InputField } from '@ppay/client'
import React from 'react'
import { Form as FormRS } from 'reactstrap'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { compose } from 'redux'

import { useMerchant, useUpdateMerchantNotificationsUrl } from '../../../../../state'
import { urlValidation } from '../../../../../validations'
import { removeProtocol, removeTrailingSlash } from '../../../../../utils'

const FormButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`

const schema = yup
  .object()
  .shape({
    notificationsUrl: yup
      .string()
      .test('includes', 'not includes merchant domain', (value, { options }) => {
        if (!value) return true
        const { merchantDomain } = options.context as { merchantDomain: string }
        return value.includes(merchantDomain)
      })
      .test(urlValidation)
      .required()
  })
  .required()

export const Form = ({ merchantId }: { merchantId: string }) => {
  const { t } = useTranslation('merchant')
  const { loading, updateNotificationsUrl } = useUpdateMerchantNotificationsUrl()
  const { merchant } = useMerchant(merchantId)

  const { handleSubmit, control } = useForm<yup.InferType<typeof schema>>({
    context: {
      merchantDomain: merchant!.domain,
      withIpAddress: true
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = ({ notificationsUrl }: { notificationsUrl: string }) => {
    updateNotificationsUrl(
      merchant!.id,
      compose(removeProtocol, removeTrailingSlash)(notificationsUrl)
    )
  }

  return (
    <FormRS onSubmit={handleSubmit(onSubmit)}>
      <InfoLabel info={t('notificationsUrlTooltip')}>{t('notificationsUrl')}</InfoLabel>
      <InputField
        control={control}
        inputProps={{
          name: 'notificationsUrl',
          placeholder: 'example.com',
          defaultValue: merchant?.notificationsUrl
        }}
      />
      <FormButton loading={loading} color="secondary" wide>
        {t('confirm&Update')}
      </FormButton>
    </FormRS>
  )
}
