import { useWithdraw as useWithdrawLib } from '@ppay/client'

import { useCreateMerchantWithdraw, useMerchantBalances } from '../state'

export const useWithdraw = (props: any) => {
  return useWithdrawLib({
    ...props,
    fetchBalances: () => null,
    useBalances: useMerchantBalances.bind(null, props.balanceOption),
    useCreateWithdraw: useCreateMerchantWithdraw.bind(null, props.balanceOption)
  })
}
