import { useDispatch } from 'react-redux'

import {
  getMerchantFiatExchangeInfoAction,
  resetMerchantGetExchangeFiatInfoAction
} from '../../actions'
import { useSelector } from '../useSelector'

export const useMerchantExchangeFiatInfo = (merchantId: string) => {
  const state = useSelector((state) => state.merchants.exchange.getFiatInfo)
  const dispatch = useDispatch()

  return {
    exchangeFiatInfoState: state,
    getExchangeFiatInfo: (params: Parameters<typeof getMerchantFiatExchangeInfoAction>[0]) =>
      dispatch(getMerchantFiatExchangeInfoAction({ ...params, merchantId })),
    resetExchangeFiatInfo: () => dispatch(resetMerchantGetExchangeFiatInfoAction())
  }
}
