import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import Cookies from 'js-cookie'

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend((language: any, namespace: any, callback: any) => {
          import(`@ppay/client/src/locales/${language}/${namespace}.json`)
            .then((resources) => {
              callback(null, resources)
            })
            .catch((error) => {
              callback(error, null)
            })
        })
      ]
    },
    whitelist: ['en', 'ru', 'ua'],
    lng: Cookies.get('locale') ?? 'en',
    ns: [
      'auth',
      'merchant',
      'kyc',
      'invoice',
      'manualReplenishOrder',
      'notifications',
      'transfers',
      'libAuth',
      'libCommon',
      'libErrors',
      'libLayout',
      'libNotifications',
      'libSettings',
      'libTransactionsTable',
      'libTransaction',
      'libTransfers',
      'libWallets'
    ]
  })

export default i18n
