"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWithdrawFee = void 0;
var _decimal = _interopRequireDefault(require("decimal.js"));
var _reactRedux = require("react-redux");
var _actions = require("../../actions");
var _useSelector = require("../useSelector");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var useWithdrawFee = function useWithdrawFee() {
  var _state$data, _state$data2;
  var state = (0, _useSelector.useSelector)(function (state) {
    return state.lib.transactions.getWithdrawFee;
  });
  var dispatch = (0, _reactRedux.useDispatch)();
  return {
    withdrawFeeState: {
      loading: state.loading,
      value: (_state$data = state.data) === null || _state$data === void 0 ? void 0 : _state$data.value,
      converted: (_state$data2 = state.data) === null || _state$data2 === void 0 ? void 0 : _state$data2.converted,
      isInternal: state.data && new _decimal.default(state.data.value).eq(0)
    },
    fetchWithdrawFee: function fetchWithdrawFee() {
      return dispatch(_actions.getWithdrawFeeAction.apply(void 0, arguments));
    },
    resetWithdrawFee: function resetWithdrawFee() {
      dispatch((0, _actions.resetGetWithdrawFeeAction)());
    }
  };
};
exports.useWithdrawFee = useWithdrawFee;