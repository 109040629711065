import { P, Button } from '@ppay/client'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Icon } from '../../ui'

const Or = styled(P)`
  margin: 10px 0;
`

export const ToggleTransfer = ({
  isTransfer,
  toggle
}: {
  isTransfer?: boolean
  toggle: () => unknown
}) => {
  const { t } = useTranslation('transfers')
  return (
    <div className="text-center">
      <Or>{t('or')}</Or>
      <Button
        wide
        color="secondary"
        iconPosition="left"
        icon={<Icon name="transfer" />}
        onClick={toggle}
      >
        {isTransfer ? t('backToWithdrawal') : t('transferBetween')}
      </Button>
    </div>
  )
}
