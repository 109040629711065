import { mediaQuery, H4, P as PUI } from '@ppay/client'
import React, { PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'

import { Icon } from '../ui'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 2px solid;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.lightDanger};
  border-color: ${({ theme }) => theme.colors.danger};
  > div {
    padding-right: 40px;
  }
  ${mediaQuery.lessThan('lg')`
    flex-direction: column;
    padding-right: 20px;
    > div {
      padding-right: 0;
      margin-bottom: 20px;
    }
  `}
  ${mediaQuery.greaterThan('lg')`
    padding: 25px 20px;
    flex-direction: row;
    > div {
      margin-bottom: 0;
    }
  `}
`

const StyledIcon = styled(Icon)`
  min-width: 20px;
  margin-right: 8px;
`

const P = styled(PUI)`
  color: ${({ theme }) => theme.colors.tertiaryShade};
`

export const AlertWithButton = ({
  className,
  children,
  title,
  description
}: PropsWithChildren<{
  className?: string
  title: string
  description: string | ReactNode
}>) => (
  <Wrapper className={className}>
    <div className="d-flex align-items-center w-100">
      <StyledIcon name="exclamationPoint" color="danger" />
      <div>
        <H4>{title}</H4>
        <P>{description}</P>
      </div>
    </div>
    {children}
  </Wrapper>
)
