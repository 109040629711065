import { DashboardTransactionsPage, Row } from '@ppay/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { UserTransactionsNoData } from '../../transactions'
import { Breadcrumb, Breadcrumbs } from '../../layout'
import { routes } from '../../router'

export const TransactionsPage = () => {
  const { t } = useTranslation('routes')

  return (
    <>
      <Row>
        <Breadcrumbs>
          <Breadcrumb link={routes.dashboard.home} name={t('home')} />
          <Breadcrumb name={t('transactions')} />
        </Breadcrumbs>
      </Row>
      <DashboardTransactionsPage noData={<UserTransactionsNoData />} showModalViewAll />
    </>
  )
}
