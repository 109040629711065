import React from 'react'
import styled from 'styled-components'
import { H4, Svg, useTranslation } from '@ppay/client'

import { ReactComponent as completed } from './completed.svg'
import { Wrapper } from './Wrapper'

const StyledSvg = styled(Svg)`
  margin-bottom: 10px;
`

export const Completed = () => {
  const { t } = useTranslation('kyc')

  return (
    <Wrapper>
      <StyledSvg as={completed} />
      <H4> {t('completed.title')}</H4>
    </Wrapper>
  )
}
