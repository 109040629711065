"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  createRootReducer: true,
  createGetExchangeInfoReducer: true
};
Object.defineProperty(exports, "createGetExchangeInfoReducer", {
  enumerable: true,
  get: function get() {
    return _exchange.createGetInfoReducer;
  }
});
exports.createRootReducer = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _user = require("./user");
var _balances = require("./balances");
var _auth = require("./auth");
var _prices = require("./prices");
var _transactions = require("./transactions");
Object.keys(_transactions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _transactions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _transactions[key];
    }
  });
});
var _exchange = require("./exchange");
var createRootReducer = function createRootReducer(addons) {
  return (0, _toolkit.combineReducers)({
    user: (0, _user.createUserWithAddons)(addons.user),
    auth: _auth.auth,
    balances: _balances.balances,
    prices: _prices.prices,
    transactions: _transactions.transactions,
    exchange: _exchange.exchange
  });
};
exports.createRootReducer = createRootReducer;