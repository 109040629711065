import { Ticker, CRYPTO_TICKERS, FIAT_BALANCE_TICKERS } from '@ppay/definitions'
import React, { useEffect, useState } from 'react'
import { MerchantPaymentsStatsPeriod, MerchantPaymentsStatsType } from '../../../../constants'

import { useMerchantPaymentsStats } from '../../../../state'
import { Component } from './Component'

export const Container = ({
  merchantId,
  merchantName
}: {
  merchantId: string
  merchantName: string
}) => {
  const { stats, fetchPaymentsStats, loading } = useMerchantPaymentsStats(merchantId)

  const [tickers, setTickers] = useState<Ticker[]>([...CRYPTO_TICKERS, ...FIAT_BALANCE_TICKERS])
  const [type, setType] = useState<MerchantPaymentsStatsType>(MerchantPaymentsStatsType.IN)
  const [period, setPeriod] = useState<MerchantPaymentsStatsPeriod>(
    MerchantPaymentsStatsPeriod.DAILY
  )

  useEffect(() => {
    fetchPaymentsStats({ type, tickers, period })
  }, [type, tickers, period, merchantId])

  return (
    <Component
      merchantName={merchantName}
      loading={loading}
      stats={stats}
      tickers={tickers}
      setTickers={setTickers}
      period={period}
      setPeriod={setPeriod}
      type={type}
      setType={setType}
    />
  )
}
