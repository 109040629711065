import { useTranslation } from 'react-i18next'
import { useMerchants } from '../../state'

const MAX_MERCHANTS_COUNT = 10

export const useCreateLimit = () => {
  const { t } = useTranslation('merchant')
  const { merchants } = useMerchants()

  return {
    merchantsLimit: merchants?.length ? merchants?.length >= MAX_MERCHANTS_COUNT : false,
    title: `${t('yourMerchants')} ${
      merchants ? `(${merchants?.length ?? 0}/${MAX_MERCHANTS_COUNT})` : ''
    }`
  }
}
