import React from 'react'
import { DashboardSettingsUserCard, DashboardSettingsUserCardItem } from '@ppay/client'
import { useTranslation } from 'react-i18next'

import { User } from '../../../interfaces'
import { SettingsTabs } from './TabsNav'

export const UserCard = ({
  credentials,
  setActiveTab
}: {
  credentials: User['credentials']
  setActiveTab: (s: string) => void
}) => {
  const { t } = useTranslation('settings')

  return (
    <DashboardSettingsUserCard confirmed={!!credentials.emailConfirmed}>
      <DashboardSettingsUserCardItem
        title={t('twoFactor')}
        confirmed={!!(credentials.emailConfirmed ?? credentials?.totpEnabled)}
        onClick={() => setActiveTab(SettingsTabs.SECURITY)}
      />
      <DashboardSettingsUserCardItem
        title={t('email')}
        confirmed={!!credentials?.emailConfirmed}
        onClick={() => setActiveTab(SettingsTabs.PROFILE)}
      />
    </DashboardSettingsUserCard>
  )
}
