import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Link } from '@ppay/client'

import { FiatInvoice, CryptoInvoice } from '../../../interfaces'
import { Card, CardData } from './'

const CardDataWithMargin = styled(CardData)`
  margin-top: 20px;
`

export const Continue = ({
  invoice,
  back,
  children,
  isFiat
}: PropsWithChildren<{
  invoice: FiatInvoice | CryptoInvoice
  back?: () => void
  isFiat?: boolean
}>) => {
  const { t } = useTranslation('invoice')

  return (
    <Card
      id={invoice.id}
      name={invoice.name}
      description={invoice.description}
      back={back}
      isFiat={isFiat}
    >
      {invoice.company && (
        <CardData
          title={t('company')}
          value={
            <Link target="_blank" href={invoice.company.url}>
              {invoice.company.name}
            </Link>
          }
        />
      )}
      {invoice.meta && <CardDataWithMargin title={t('meta')} value={invoice.meta} />}
      {children}
    </Card>
  )
}
