"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFiatForm = void 0;
var _reactHookForm = require("react-hook-form");
var _yup = require("@hookform/resolvers/yup");
var yup = _interopRequireWildcard(require("yup"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var schema = yup.object().shape({
  amount: yup.string().test('amount', "Amount should be positive number", function (value) {
    return Number(value) > 0;
  })
}).required();
var useFiatForm = function useFiatForm(_ref) {
  var fee = _ref.fee;
  var formProps = (0, _reactHookForm.useForm)({
    mode: 'onTouched',
    resolver: (0, _yup.yupResolver)(schema),
    defaultValues: {
      amount: ''
    },
    context: {
      fee: fee
    }
  });
  var amount = (0, _reactHookForm.useWatch)({
    name: 'amount',
    control: formProps.control
  });
  return {
    amount: amount,
    formProps: formProps
  };
};
exports.useFiatForm = useFiatForm;